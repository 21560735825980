import React from "react"
import { Result, Button } from "antd"
import * as Commons from "common/common"

const NotFound = props => {
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <Result
          status="404"
          title="404"
          subTitle={Commons.error404Msg}
          extra={
            <Button type="primary" href={Commons.rootURL}>
              TOPに戻る
            </Button>
          }
        />
      </div>
    </div>
  )
}

export default NotFound
