import { Form, Radio, Select } from "antd"
import { cn } from "common/utilities"
import Switch from "../Switch"

export default function ChildAge() {
  const occasionForm = Form.useFormInstance()
  const isChildAgeShow = Form.useWatch("childAgeShow")
  const isChildAgeLimitShow = Form.useWatch("childAgeLimitShow")
  const minAge = Form.useWatch("minAge")
  const maxAge = Form.useWatch("maxAge")

  return (
    <div className="flex flex-col ml-3 gap-y-[6px]">
      <div className="flex gap-7 items-center">
        <span>・年齢</span>
        <Form.Item name="childAgeShow">
          <Switch name="childAgeShow" />
        </Form.Item>
      </div>

      {isChildAgeShow && (
        <>
          <Form.Item name="childAgeRq" className="pl-4">
            <Radio.Group>
              <Radio value={true}>必須</Radio>
              <Radio value={false}>任意</Radio>
            </Radio.Group>
          </Form.Item>
          <div className="flex flex-col pl-4 gap-y-3">
            <div className="flex gap-7 items-center">
              <span>子どもの年齢上限設定表示</span>
              <Form.Item name="childAgeLimitShow">
                <Switch name="childAgeLimitShow" />
              </Form.Item>
            </div>

            {isChildAgeLimitShow && (
              <Form.Item
                required
                label="子どもの年齢上限設定"
                className={cn(
                  "flex items-center gap-x-2 w-full",
                  "[&>div]:flex-row",
                  "[&>div]:w-full",
                  "[&_.ant-form-item-control-input-content]:flex",
                  // "[&_.ant-form-item-control-input-content]:items-center",
                  "[&_.ant-form-item-control-input-content]:gap-x-2"
                )}
              >
                <Form.Item
                  name="minAge"
                  rules={[
                    {
                      required: isChildAgeLimitShow,
                      message: "最低年齢は必須です",
                    },
                  ]}
                  className="flex-1"
                >
                  <Select
                    onChange={() => {
                      occasionForm.resetFields(["maxAge"])
                    }}
                    options={Array.from({ length: 19 }, (_, i) => ({
                      value: i,
                      label: i,
                      disabled: maxAge && i >= maxAge + 1,
                    }))}
                  />
                </Form.Item>
                <div className="h-8 flex items-center">
                  <span>〜</span>
                </div>
                <Form.Item
                  name="maxAge"
                  rules={[
                    {
                      required: isChildAgeLimitShow,
                      message: "最大年齢は必須です",
                    },
                  ]}
                  className="flex-1"
                >
                  <Select
                    options={Array.from({ length: 19 }, (_, i) => ({
                      value: i,
                      label: i,
                      disabled: minAge && i <= minAge - 1,
                    }))}
                  />
                </Form.Item>
              </Form.Item>
            )}
          </div>
        </>
      )}
    </div>
  )
}
