import CancelFeature from "../Home/CancelFeature"
import BookingOptions from "../Home/BookingOptions"
import { Button, Col, Divider, Form, Modal, Row, message } from "antd"
import * as Commons from "common/common"
import Moment from "moment"
import { extendMoment } from "moment-range"
import "moment/locale/ja"
import { useEffect, useState } from "react"
import MultiImageUpload from "components/MultiImageUpload"

import {
  OccasionUpdateDescFormItem,
  OccasionUpdateNameFormItem,
  OccasionUpdateUnitTypeFormItem,
  OccasionUpdateMaxReservationFormItem,
  OccasionUpdateMaxPerPersonFormItem,
  OccasionUpdateEventApplicationPeriodFormItem,
  OccasionUpdatePreschoolCountFormItem,
  OccasionUpdateCanOverlapFormItem,
  OccasionUpdateRegisterMessageFormItem,
  OccasionUpdateRemindMessage24HrFormItem,
  OccasionUpdateRemindMessage30MinFormItem,
  OccasionUpdatePriceFormItem,
} from "components/OccasionFormItem"

const moment = extendMoment(Moment)
moment.locale("ja")

const OccasionEditModal = (props) => {
  const {
    updateModalVisible,
    occasion,
    history,
    fetchOccasionData,
    hideUpdateModal,
  } = props
  const isMountedRef = Commons.useIsMountedRef()
  const [occasionUpdateForm] = Form.useForm()

  const [openRegistration, setOpenRegistration] = useState(false)
  const [hasBaseTicketPrice, setHasBaseTicketPrice] = useState(false)

  const [eventUpdateLoading, setEventUpdateLoading] = useState(false)
  const [isUnitTypeArea, setIsUnitTypeArea] = useState(false)
  const [occasionImages, setOccasionImages] = useState([])

  const resetForm = () => {
    occasionUpdateForm.setFieldsValue({
      ...occasion,
      occasionUpdateName: occasion.title || "",
      occasionImages: occasion?.occasionImages
        ? occasion.occasionImages.map((image) => {
            return { image: image?.picUrl }
          })
        : [{}],
      hasBaseTicketPrice: occasion.hasBaseTicketPrice || false,
      baseTicketPrice: occasion.baseTicketPrice || 0,
      occasionUpdateDesc: occasion.description || "",
      occasionUpdateMaxAllow: occasion.maxPerPerson || 0,
      occasionUpdateCancel: occasion.canCancel || false,
      occasionUpdateCancelTime: occasion.timeCancel || 0,
      occasionRegisterForceCancel: occasion.canForceCancel || false,
      occasionUpdateCancelMessage: occasion.textCancel || "",
      occasionUpdateRegisterMessage: occasion.regMessage || "",
      occasionUpdateRemindMessage: occasion.remindMessage || "",
      occasionUpdateRemind10Message: occasion.remindMessage1 || "",
      childNoteLabel: occasion.childNoteLabel || "",
      generalNoteLabel: occasion.generalNoteLabel || "",
      parentNameShow: occasion.parentNameShow || false,
      parentPhoneShow: occasion.parentPhoneShow || false,
      parentNameRq: occasion.parentNameRq || false,
      parentPhoneRq: occasion.parentPhoneRq || false,
      childNoteRq: occasion.childNoteRq || false,
      generalNoteRq: occasion.generalNoteRq || false,
      occasionUpdateSimpleCount: occasion.isSimpleCount || false,
      occasionUpdatePreschool: occasion.isPreschoolCount || false,
      occasionUpdateCanOverlap: occasion.canOverlap || false,
      childAgeLimitRq: occasion.childAgeLimitRq || false,
      childAgeLimitShow: occasion.childAgeLimitShow,
      ...((!occasion.isSimpleCount && occasion.minAge) ||
      (!occasion.isSimpleCount && occasion.minAge === 0)
        ? {
            minAge: occasion.minAge,
          }
        : {}),
      ...((!occasion.isSimpleCount && occasion.maxAge) ||
      (!occasion.isSimpleCount && occasion.maxAge === 0)
        ? {
            maxAge: occasion.maxAge,
          }
        : {}),
      ...(occasion.isOpenRegistration &&
      occasion.startRegister &&
      occasion.endRegister
        ? {
            startRegister: moment(occasion.startRegister),
            endRegister: moment(occasion.endRegister),
            isOpenRegistration: occasion.isOpenRegistration,
          }
        : {}),
    })
    setHasBaseTicketPrice(occasion.hasBaseTicketPrice)
    setOpenRegistration(occasion.isOpenRegistration)
  }

  const handleUpdateSubmit = () => {
    const values = occasionUpdateForm.getFieldValue()
    setEventUpdateLoading(true)

    let isUpdated = false
    const formData = new FormData()

    const addToFormData = (occasionAttr, formDataValue, allowEmpty = false) => {
      const formValue = values[formDataValue]
      const occasionValue = occasion[occasionAttr]

      if (formValue !== occasionValue) {
        if (allowEmpty) {
          formData.append(occasionAttr, formValue)
          isUpdated = true
        } else {
          if (formValue !== "") {
            formData.append(occasionAttr, formValue)
            isUpdated = true
          }
        }
      }
      return false
    }

    addToFormData("title", "occasionUpdateName")
    addToFormData("description", "occasionUpdateDesc")
    addToFormData("maxPerPerson", "occasionUpdateMaxAllow")
    addToFormData("isPreschoolCount", "occasionUpdatePreschool")
    addToFormData("canOverlap", "occasionUpdateCanOverlap")
    addToFormData("regMessage", "occasionUpdateRegisterMessage", true)
    addToFormData("remindMessage", "occasionUpdateRemindMessage", true)
    addToFormData("remindMessage1", "occasionUpdateRemind10Message", true)
    addToFormData("unitType", "unitType")
    addToFormData("maxPerReservation", "maxPerReservation")
    addToFormData("hasBaseTicketPrice", "hasBaseTicketPrice")
    addToFormData("canCancel", "occasionUpdateCancel")

    // addImageUploadToFormData
    if (occasionImages && occasionImages.length > 0) {
      formData.append(
        "occasionImagesData",
        JSON.stringify(
          occasionImages.map((image, i) => ({
            originalName: image?.file?.name || image?.picUrl,
            showOrder: i,
          }))
        )
      )

      occasionImages
        .filter((image) => image?.file)
        .map((image) => image?.file)
        .forEach((imageData) => {
          formData.append("occasionImages", imageData)
        })
      isUpdated = true
    } else {
      formData.append("occasionImages", "")
      formData.append("occasionImagesData", "")
    }

    formData.append("isSimpleCount", values.occasionUpdateSimpleCount)
    if (!values.isSimpleCount) {
      addToFormData("parentNameRq", "parentNameRq")
      addToFormData("parentNameShow", "parentNameShow")
      addToFormData("parentPhoneRq", "parentPhoneRq")
      addToFormData("parentPhoneShow", "parentPhoneShow")
      addToFormData("childNoteRq", "childNoteRq")
      addToFormData("childNoteLabel", "childNoteLabel")
      addToFormData("generalNoteRq", "generalNoteRq")
      addToFormData("generalNoteLabel", "generalNoteLabel")
      addToFormData("childAgeLimitRq", "childAgeLimitRq")
      addToFormData("childAgeLimitShow", "childAgeLimitShow")
      addToFormData("fullNameShow", "fullNameShow")
      addToFormData("childNoteShow", "childNoteShow")
      addToFormData("childAgeShow", "childAgeShow")
      addToFormData("generalNoteShow", "generalNoteShow")
      addToFormData("fullNameRq", "fullNameRq")
      addToFormData("childAgeRq", "childAgeRq")
    }

    if (
      values.hasBaseTicketPrice &&
      values.baseTicketPrice !== occasion.baseTicketPrice
    ) {
      isUpdated = true
      formData.append("baseTicketPrice", values.baseTicketPrice)
    }
    // EVENT CANCEL

    if (values.occasionUpdateCancel) {
      addToFormData("timeCancel", "occasionUpdateCancelTime")
      addToFormData("canForceCancel", "occasionRegisterForceCancel")
    } else {
      if (occasion.canForceCancel === true) {
        isUpdated = true
        formData.append("canForceCancel", false)
      }
    }
    addToFormData("textCancel", "occasionUpdateCancelMessage", true)

    if (!occasion.isSimpleCount && values.minAge !== occasion.minAge) {
      isUpdated = true
      formData.append("minAge", values.minAge)
    }
    if (!occasion.isSimpleCount && values.maxAge !== occasion.maxAge) {
      isUpdated = true
      formData.append("maxAge", values.maxAge)
    }

    if (
      values.isOpenRegistration &&
      moment(values.startRegister).format("YYYY-MM-DD HH:mm") !==
        moment(occasion.startRegister).format("YYYY-MM-DD HH:mm")
    ) {
      isUpdated = true
      formData.append("startRegister", values.startRegister)
    }

    if (
      values.isOpenRegistration &&
      moment(values.endRegister).format("YYYY-MM-DD HH:mm") !==
        moment(occasion.endRegister).format("YYYY-MM-DD HH:mm")
    ) {
      isUpdated = true
      formData.append("endRegister", values.endRegister)
    }

    if (
      (values.startRegister &&
        moment(values.startRegister).format("YYYY-MM-DD HH:mm") !==
          moment(occasion.startRegister).format("YYYY-MM-DD HH:mm")) ||
      (values.endRegister &&
        moment(values.endRegister).format("YYYY-MM-DD HH:mm") !==
          moment(occasion.endRegister).format("YYYY-MM-DD HH:mm")) ||
      (values.isOpenRegistration !== undefined &&
        occasion.isOpenRegistration !== values.isOpenRegistration)
    ) {
      isUpdated = true
      formData.append("isOpenRegistration", values.isOpenRegistration)
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    }

    if (isUpdated) {
      Commons.axiosInstance
        .put(
          Commons.apiOccasions + "/" + occasion?.occasionId,
          formData,
          config
        )
        .then((response) => {
          if (isMountedRef.current && response) {
            // occasionUpdateForm.resetFields()

            fetchOccasionData()
            hideUpdateModal()

            message.success(Commons.successUpdateMsg)
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          } else {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            setEventUpdateLoading(false)
          }
        })
    } else {
      setEventUpdateLoading(false)
      hideUpdateModal()
    }
  }

  useEffect(() => {
    if (!updateModalVisible) {
      resetForm()
      setOccasionImages([])
    }
  }, [updateModalVisible, occasionUpdateForm])

  useEffect(() => {
    if (updateModalVisible && occasion) {
      resetForm()
      setOccasionImages(occasion?.occasionImages || [])
    }

    // eslint-disable-next-line
  }, [updateModalVisible, occasion])

  return (
    <Modal
      open={updateModalVisible}
      title="イベント情報変更"
      onCancel={hideUpdateModal}
      footer={null}
      centered
    >
      <div className="p-2">
        <Form
          form={occasionUpdateForm}
          layout="vertical"
          initialValues={{
            occasionUpdateName: "",
            occasionUpdateDesc: "",
            occasionUpdateMaxAllow: 10,
            occasionUpdateCancel: false,
            occasionUpdateCancelTime: 15,
            occasionUpdateCancelMessage: "",
            occasionUpdateRegisterMessage: "",
            occasionUpdateRemindMessage: "",
            occasionUpdateRemind10Message: "",
            hasBaseTicketPrice: false,
            baseTicketPrice: 0,
            occasionUpdateRemark: "",
            occasionUpdateSimpleCount:
              process.env.REACT_APP_DEFAULT_SIMPLE_COUNT === "true"
                ? true
                : false,
            occasionUpdatePreschool: false,
            occasionUpdateCanOverlap: false,
            unitType: "personCapacity",
            isUnitTypeArea: false,
            maxPerReservation: 10,
          }}
          onFinish={handleUpdateSubmit}
          requiredMark={true}
          scrollToFirstError
        >
          <>
            <MultiImageUpload
              key={"imageUploadFormItem"}
              form={occasionUpdateForm}
              occasionImages={occasionImages}
              setOccasionImages={setOccasionImages}
              isPreview={false}
            />
            <OccasionUpdateNameFormItem />
            <OccasionUpdateDescFormItem />
            <OccasionUpdateUnitTypeFormItem
              setIsUnitTypeArea={setIsUnitTypeArea}
            />
            <OccasionUpdateMaxReservationFormItem
              occasion={occasion}
              occasionUpdateForm={occasionUpdateForm}
            />
            <OccasionUpdateMaxPerPersonFormItem
              occasion={occasion}
              occasionUpdateForm={occasionUpdateForm}
            />

            <OccasionUpdateEventApplicationPeriodFormItem
              setOpenRegistration={setOpenRegistration}
              occasionUpdateForm={occasionUpdateForm}
              openRegistration={openRegistration}
            />

            <OccasionUpdatePreschoolCountFormItem />
            <OccasionUpdateCanOverlapFormItem />
            <Divider />
            <OccasionUpdateRegisterMessageFormItem />
            <Divider />
            <OccasionUpdateRemindMessage24HrFormItem />
            <Divider />
            <OccasionUpdateRemindMessage30MinFormItem />
            <Divider />

            <OccasionUpdatePriceFormItem
              setHasBaseTicketPrice={setHasBaseTicketPrice}
              hasBaseTicketPrice={hasBaseTicketPrice}
            />

            <CancelFeature />

            <BookingOptions />

            <Row justify="center">
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  key="ok"
                  loading={eventUpdateLoading}
                >
                  変更
                </Button>
              </Col>
            </Row>
          </>
        </Form>
      </div>
    </Modal>
  )
}

export default OccasionEditModal
