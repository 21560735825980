import React, { useState, useCallback, useEffect, useRef } from "react"
import { withRouter, Link } from "react-router-dom"
import {
  Button,
  Card,
  Radio,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Steps,
  Tag,
  Upload,
} from "antd"
import {
  InboxOutlined,
  ClockCircleTwoTone,
  QuestionCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import styled from "styled-components"
import Moment from "moment"
import "moment/locale/ja"
import { extendMoment } from "moment-range"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import momentPlugin from "@fullcalendar/moment"
import { isMobileOnly } from "react-device-detect"
import io from "socket.io-client"
import * as Commons from "common/common"
import jaJP from "antd/lib/date-picker/locale/ja_JP"
import OrderModal from "./OrderModal"
import Switch from "./Switch"
import Group from "./Group"
import CancelFeature from "./CancelFeature"
import BookingOptions from "./BookingOptions"
import MultiImageUpload from "components/MultiImageUpload"

const moment = extendMoment(Moment)
moment.locale("ja")

const CalendarWrapper = styled.div`
  .fc .fc-button {
    padding: 0;
    background-color: #fff;
    color: #00bcd4;
    border-radius: 0;
    border: 1px solid #00bcd4;
    vertical-align: bottom;
    margin-bottom: 0.5rem;
  }

  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
  }

  .fc .fc-button-primary:focus {
    box-shadow: none;
  }

  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #00bcd4;
    border-color: #00bcd4;
  }

  .fc .fc-toolbar-title {
    display: inline-block;
    vertical-align: middle;
    margin-left: 1em;
    margin-right: 1em;
    font-size: 2em;
    font-weight: bold;
    white-space: pre-wrap;
    text-align: center;
  }

  .fc .fc-timeline-header-row-chrono .fc-timeline-slot-frame {
    justify-content: center;
  }

  .fc-datagrid-cell-frame {
    background-color: #00b3c4;
    color: white;
    font-weight: bold;
  }

  .fc .fc-resource-timeline-divider {
    width: 0;
  }

  .fc .fc-datagrid-cell-cushion {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events {
    padding-bottom: 0;
  }

  .fc-CustomPrevMonth-button,
  .fc-CustomNextMonth-button,
  .fc-CustomPrevWeek-button,
  .fc-CustomThisWeek-button,
  .fc-CustomNextWeek-button {
    padding: 0.25rem !important;
    margin: 0.25rem !important;
  }

  .fc-day-sat {
    color: #00c2ff;
  }

  .fc-day-sun {
    color: #c40055;
  }

  .fc .fc-bg-event {
    opacity: 0.7;
    font-weight: bold;
  }

  .fc-timegrid-slot {
    height: 2em !important;
    border-bottom: 0 !important;
  }

  .fc .fc-timegrid-col.fc-day-past {
    background-color: rgba(195, 195, 195, 0.2);
  }

  .fc .fc-timegrid-col.fc-day-today {
    background-color: rgba(255, 220, 40, 0.05);
    background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.05));
  }

  .fc-timegrid-event {
    border-radius: 0;
  }

  .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0;
  }
`

const StyledDashedButton = styled(Button)`
  &.ant-btn-dashed {
    border-color: #8c8c8c;
  }

  &.ant-btn-dashed:hover,
  .ant-btn-dashed:focus {
    border-color: #21acd7;
    color: #21acd7;
  }
`

const CardTime = styled.div`
  width: fit-content;

  .titile {
    box-sizing: border-box;
    font-weight: 500;
    background-color: white;
    width: fit-content;
    margin-bottom: 10px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`

const FormItem = styled(Form.Item)`
  margin-bottom: ${(props) => (props.$isOpenRegistration ? "0" : "24px")};
`

// const getBase64 = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()

//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = (error) => reject(error)
//   })
// }

const steps = [
  {
    key: 0,
    title: "基本設定",
  },
  {
    key: 1,
    title: "日付設定",
  },
  {
    key: 2,
    title: "確認",
  },
]

const cardGridStyle = {
  width: "100%",
  textAlign: "center",
  color: "#21acd7",
  padding: "16px",
}

const deleteCardGridStyle = {
  width: "100%",
  textAlign: "center",
  color: "#f5222d",
  padding: "16px",
}

const {
  GET_UNIT_TYPE_LABEL,
  GET_MAX_PEOPLE_PER_RESERVATION_UNIT_TYPE_LABEL,
  GET_MAX_RESERVATIONS_UNIT_TYPE_LABEL,
} = Commons

const { TextArea } = Input
const { FormInstance } = Form
const { Step } = Steps
const { Option } = Select

export default function AddOccasionsPopup({
  history,
  showLoadingPageSpin,
  hideLoadingPageSpin,
  isOpen,
  onCancel,
}) {
  const isMountedRef = Commons.useIsMountedRef()
  const occurrenceManageCalendarRef = useRef()
  const occurrenceForm = useRef(FormInstance)
  const [occasionForm] = Form.useForm()
  const [openRegistration, setOpenRegistration] = useState(false)
  const [minTimeRegistration, setMinTimeRegistration] = useState(false)
  const [isUnitTypeArea, setIsUnitTypeArea] = useState(false)
  const [hasBaseTicketPriceFeature, setHasBaseTicketPriceFeature] =
    useState(false)
  const [eventSubmitLoading, setEventSubmitLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [eventInterval, setEventInterval] = useState(
    Commons.BUSINESS_INTERVAL_TIME_VALUE
  )
  const [occasions, setOccasions] = useState([])
  const [occasionImages, setOccasionImages] = useState([])
  const [occurrences, setOccurrences] = useState([])
  const [eventTemplates, setEventTemplates] = useState([])

  const fetchOccasionsOverview = useCallback(() => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    Commons.axiosInstance
      .get(Commons.apiOccasions + "/overview")
      .then((response) => {
        if (isMountedRef.current && response) {
          setOccasions(response.data || [])
          setOccasionImages([])

          occasionForm.setFieldsValue({
            occasionImages: [{}],
          })
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }, [isMountedRef, history, showLoadingPageSpin, hideLoadingPageSpin])

  const fetchEventTemplates = useCallback(() => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    Commons.axiosInstance
      .get(Commons.apiEvents + "/list")
      .then((response) => {
        if (isMountedRef.current && response) {
          setEventTemplates(response.data || [])
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }, [isMountedRef, history, showLoadingPageSpin, hideLoadingPageSpin])

  const fetchEventTemplateDetail = (occasionId) => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    Commons.axiosInstance
      .get(Commons.apiEvents + "/detail/" + occasionId)
      .then((response) => {
        if (isMountedRef.current && response) {
          if (response.data) {
            // setIsUnitTypeArea(response.data.unitType || "personCapacity")
            occasionForm.setFieldsValue({
              occasionRegisterName: response.data.title || "",
              occasionRegisterDesc: response.data.description || "",
              occasionRegisterMaxAllow: response.data.maxPerPerson || 0,
              occasionRegisterCancel: response.data.canCancel || false,
              occasionRegisterForceCancel:
                response.data.canForceCancel || false,
              occasionRegisterCancelTime: response.data.timeCancel || 0,
              occasionRegisterCancelMessage: response.data.textCancel || "",
              occasionRegisterRegisterMessage: response.data.regMessage || "",
              occasionRegisterRemindMessage: response.data.remindMessage || "",
              unitType: response.data.unitType
                ? response.data.unitType
                : "personCapacity",
              maxPerReservation: response.data.maxPerReservation || 10,
              hasBaseTicketPrice: response.data.hasBaseTicketPrice || false,
              baseTicketPrice: response.data.baseTicketPrice || 0,

              occasionRegisterPreschool:
                response.data.isPreschoolCount || false,

              ...((!response.data.isPreschoolCount && response.data.minAge) ||
              (!response.data.isPreschoolCount && response.data.minAge === 0)
                ? {
                    minAge: response.data.minAge,
                  }
                : {}),
              ...((!response.data.isPreschoolCount && response.data.maxAge) ||
              (!response.data.isPreschoolCount && response.data.maxAge === 0)
                ? {
                    maxAge: response.data.maxAge,
                  }
                : {}),
              ...(response.data.isOpenRegistration &&
              response.data.startRegister &&
              response.data.endRegister
                ? {
                    startRegister: moment(response.data.startRegister),
                    endRegister: moment(response.data.endRegister),
                    isOpenRegistration: response.data.isOpenRegistration,
                  }
                : {}),
            })
            setOpenRegistration(response.data.isOpenRegistration || false)
            // if (
            //   !response.data.isPreschoolCount &&
            //   response.data.minAge &&
            //   response.data.maxAge
            // ) {
            //   setMinAge(response.data.minAge)
            // }

            // TODO: Set image from template ?
            // setOccasionImages([])

            // occasionForm.setFieldsValue({
            //   occasionImages: [{}],
            // })
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const addImageUploadToFormData = (formData) => {
    if (occasionImages && occasionImages.length > 0) {
      formData.append(
        "occasionImagesData",
        JSON.stringify(
          occasionImages.map((image, i) => ({
            originalName: image?.file?.name || image?.picUrl,
            showOrder: i,
          }))
        )
      )

      occasionImages
        .filter((image) => image?.file)
        .map((image) => image?.file)
        .forEach((imageData) => {
          formData.append("occasionImages", imageData)
        })
    } else {
      formData.append("occasionImages", "")
      formData.append("occasionImagesData", "")
    }
  }

  const handleCreateSubmit = () => {
    const values = occasionForm.getFieldValue()

    setEventSubmitLoading(true)

    const formData = new FormData()

    // EVENT MAIN
    formData.append("title", occasionForm.getFieldValue("occasionRegisterName"))
    formData.append(
      "description",
      occasionForm.getFieldValue("occasionRegisterDesc")
    )
    formData.append(
      "maxPerPerson",
      occasionForm.getFieldValue("occasionRegisterMaxAllow")
    )
    formData.append(
      "isSimpleCount",
      occasionForm.getFieldValue("occasionRegisterSimpleCount")
    )
    formData.append(
      "isPreschoolCount",
      occasionForm.getFieldValue("occasionRegisterPreschool")
    )
    formData.append(
      "canOverlap",
      occasionForm.getFieldValue("occasionRegisterCanOverlap")
    )
    formData.append(
      "regMessage",
      occasionForm.getFieldValue("occasionRegisterRegisterMessage")
    )
    formData.append(
      "remindMessage",
      occasionForm.getFieldValue("occasionRegisterRemindMessage")
    )
    formData.append(
      "remindMessage1",
      occasionForm.getFieldValue("occasionRegisterRemind10Message")
    )

    addImageUploadToFormData(formData)

    formData.append("unitType", values.unitType)
    formData.append("maxPerReservation", values.maxPerReservation)
    formData.append("hasBaseTicketPrice", values.hasBaseTicketPrice)
    if (values.hasBaseTicketPrice) {
      formData.append("baseTicketPrice", values.baseTicketPrice)
    }

    // EVENT CANCEL
    formData.append(
      "canCancel",
      occasionForm.getFieldValue("occasionRegisterCancel")
    )
    if (occasionForm.getFieldValue("occasionRegisterCancel")) {
      formData.append(
        "timeCancel",
        occasionForm.getFieldValue("occasionRegisterCancelTime")
      )
      formData.append(
        "canForceCancel",
        occasionForm.getFieldValue("occasionRegisterForceCancel") ?? false
      )
    } else {
      formData.append("canForceCancel", false)
    }
    formData.append(
      "textCancel",
      occasionForm.getFieldValue("occasionRegisterCancelMessage")
    )

    if (
      (!occasionForm.getFieldValue("occasionRegisterSimpleCount") &&
        occasionForm.getFieldValue("minAge")) ||
      (!occasionForm.getFieldValue("occasionRegisterSimpleCount") &&
        occasionForm.getFieldValue("minAge") === 0)
    ) {
      formData.append("minAge", occasionForm.getFieldValue("minAge"))
    }
    if (
      (!occasionForm.getFieldValue("occasionRegisterSimpleCount") &&
        occasionForm.getFieldValue("maxAge")) ||
      (!occasionForm.getFieldValue("occasionRegisterSimpleCount") &&
        occasionForm.getFieldValue("maxAge") === 0)
    ) {
      formData.append("maxAge", occasionForm.getFieldValue("maxAge"))
    }

    if (
      occasionForm.getFieldValue("isOpenRegistration") &&
      occasionForm.getFieldValue("startRegister") &&
      occasionForm.getFieldValue("endRegister")
    ) {
      formData.append(
        "isOpenRegistration",
        occasionForm.getFieldValue("isOpenRegistration")
      )
      formData.append(
        "startRegister",
        occasionForm.getFieldValue("startRegister")
      )
      formData.append("endRegister", occasionForm.getFieldValue("endRegister"))
    }

    // EVENT PATICULAR
    if (!values["occasionRegisterSimpleCount"]) {
      formData.append("fullNameShow", values["fullNameShow"])
      formData.append("childNoteShow", values["childNoteShow"])
      formData.append("childAgeShow", values["childAgeShow"])
      formData.append("generalNoteShow", values["generalNoteShow"])
      formData.append("fullNameRq", values["fullNameRq"])
      formData.append(
        "parentNameRq",
        occasionForm.getFieldValue("parentNameRq")
      )
      formData.append(
        "parentNameShow",
        occasionForm.getFieldValue("parentNameShow")
      )
      formData.append(
        "parentPhoneRq",
        occasionForm.getFieldValue("parentPhoneRq")
      )
      formData.append(
        "parentPhoneShow",
        occasionForm.getFieldValue("parentPhoneShow")
      )
      formData.append("childNoteRq", occasionForm.getFieldValue("childNoteRq"))
      formData.append(
        "childNoteLabel",
        occasionForm.getFieldValue("childNoteLabel")
      )
      formData.append(
        "generalNoteRq",
        occasionForm.getFieldValue("generalNoteRq")
      )
      formData.append(
        "generalNoteLabel",
        occasionForm.getFieldValue("generalNoteLabel")
      )
      formData.append(
        "childAgeLimitRq",
        occasionForm.getFieldValue("childAgeLimitRq")
      )
      formData.append(
        "childAgeLimitShow",
        occasionForm.getFieldValue("childAgeLimitShow")
      )
    }
    // EVENT OCCURRENCES
    occurrences
      .map((occurrence) => ({
        startAt: new Date(occurrence.startAt).toISOString(),
        endAt: new Date(occurrence.endAt).toISOString(),
        maxAttendee: occurrence.maxAttendee,
      }))
      .map((occurrence) => {
        formData.append("occurrences[]", JSON.stringify(occurrence))
        return false
      })

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    }

    Commons.axiosInstance
      .post(Commons.apiOccasions, formData, config)
      .then((response) => {
        if (isMountedRef.current && response) {
          occasionForm.resetFields()

          setCurrentStep(0)
          setOccurrences([])
          setEventInterval(Commons.BUSINESS_INTERVAL_TIME_VALUE)

          fetchOccasionsOverview()
          onCancel()
          setOpenRegistration(false)
          message.success(Commons.successCreateMsg)
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setEventSubmitLoading(false)
        }
      })
  }

  useEffect(() => {
    fetchOccasionsOverview()
    fetchEventTemplates()

    return () => {
      Modal.destroyAll()
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const socket = io(Commons.siteURL, { path: "/socket.io" })

    socket.on("newEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
        fetchEventTemplates()
      }
    })

    socket.on("updateEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
        fetchEventTemplates()
      }
    })

    socket.on("updateOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
      }
    })

    socket.on("deleteOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
      }
    })

    socket.on("deleteEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
        fetchEventTemplates()
      }
    })

    socket.on("newRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
      }
    })

    socket.on("cancelRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
      }
    })

    socket.on("confirmRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
      }
    })

    return () => {
      socket.off("newEvent")
      socket.off("updateEvent")
      socket.off("updateOccurrence")
      socket.off("deleteOccurrence")
      socket.off("deleteEvent")
      socket.off("newRegistration")
      socket.off("cancelRegistration")
      socket.off("confirmRegistration")

      socket.disconnect()
    }

    // eslint-disable-next-line
  }, [])

  const nextStep = () => {
    occasionForm
      .validateFields()
      .then(() => {
        setCurrentStep(currentStep + 1)
      })
      .catch(() => {})
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const clearOccurrence = () => {
    setOccurrences([])
  }

  const handleTemplateChange = (occasionId) => {
    fetchEventTemplateDetail(occasionId)
  }

  return (
    <Modal
      open={isOpen}
      title="新規イベント"
      onCancel={onCancel}
      footer={null}
      width={720}
      centered
    >
      <div className="p-2">
        <Form
          form={occasionForm}
          layout="vertical"
          initialValues={{
            occasionRegisterName: "",
            occasionRegisterDesc: "",
            occasionRegisterMaxAllow: 10,
            occasionRegisterCancel: false,
            occasionRegisterCancelTime: 15,
            occasionRegisterCancelMessage: "",
            occasionRegisterRegisterMessage: "",
            occasionRegisterRemindMessage: "",
            occasionRegisterRemind10Message: "",
            hasBaseTicketPrice: false,
            baseTicketPrice: null,
            occasionRegisterRemark: "",
            parentNameRq: false,
            parentPhoneRq: false,
            isOpenRegistration: false,
            childNoteRq: false,
            parentNameShow: false,
            parentPhoneShow: false,
            generalNoteRq: false,
            childAgeRq: false,
            childAgeShow: false,
            childAgeLimitRq: false,
            fullNameShow: false,
            fullNameRq: false,
            childNoteShow: false,
            generalNoteShow: false,
            childNoteLabel: "",
            generalNoteLabel: "",
            childAgeLimitShow: false,
            occasionRegisterSimpleCount:
              process.env.REACT_APP_DEFAULT_SIMPLE_COUNT === "true"
                ? true
                : false,
            occasionRegisterPreschool: false,
            occasionRegisterCanOverlap: false,
            occurrenceMaxParticipation: 10,
            occurrenceTimeInterval: "" + Commons.BUSINESS_INTERVAL_TIME_VALUE,
            unitType: "personCapacity",
            isUnitTypeArea: false,
            maxPerReservation: 10,
          }}
          onFinish={handleCreateSubmit}
          requiredMark={true}
          scrollToFirstError
        >
          {currentStep === 0 ? (
            <>
              <Row justify="end" className="mb-4">
                <Col>
                  <Select
                    placeholder="テンプレートを選択してください"
                    onChange={handleTemplateChange}
                    style={{ width: "300px", textAlign: "center" }}
                  >
                    {eventTemplates.map((template) => (
                      <Option
                        key={template.occasionId + ""}
                        value={template.occasionId + ""}
                      >
                        {template.title}のテンプレート
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <MultiImageUpload
                key={"imageUploadFormItem"}
                form={occasionForm}
                occasionImages={occasionImages}
                setOccasionImages={setOccasionImages}
                isPreview={false}
              />
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="occasionRegisterName"
                    label="イベント名"
                    rules={[
                      {
                        required: true,
                        message: "イベント名は必須です",
                      },
                    ]}
                  >
                    <Input
                      placeholder="イベント名を入力してください"
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="occasionRegisterDesc"
                    label="イベント内容"
                    rules={[
                      {
                        required: true,
                        message: "イベント内容は必須です",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="イベント内容を入力してください"
                      autoSize
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="unitType"
                    label="イベントの単位"
                    rules={[
                      {
                        required: true,
                        message: "イベントの単位は必須です",
                      },
                    ]}
                  >
                    <Radio.Group>
                      {Commons.unitTypes.map((unit) => (
                        <Radio
                          key={unit.value}
                          value={unit.value}
                          onChange={(e) =>
                            setIsUnitTypeArea(e.target.value === "area")
                          }
                        >
                          {unit.label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="maxPerReservation"
                    label={GET_MAX_PEOPLE_PER_RESERVATION_UNIT_TYPE_LABEL(
                      occasionForm.getFieldValue("unitType")
                    )}
                    rules={[
                      {
                        required: true,
                        message: `${GET_MAX_PEOPLE_PER_RESERVATION_UNIT_TYPE_LABEL(
                          occasionForm.getFieldValue("unitType")
                        )}は必須です`,
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={GET_MAX_PEOPLE_PER_RESERVATION_UNIT_TYPE_LABEL(
                        occasionForm.getFieldValue("unitType")
                      )}
                      min={1}
                      type="number"
                      onPressEnter={(e) => {
                        e.preventDefault()
                      }}
                      onWheel={(e) => e.target.blur()}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.unitType !== currentValues.unitType
                    }
                  >
                    {({ getFieldValue }) => (
                      <Form.Item
                        name="occasionRegisterMaxAllow"
                        label={`1アカウントで${GET_MAX_RESERVATIONS_UNIT_TYPE_LABEL(
                          occasionForm.getFieldValue("unitType")
                        )}`}
                        rules={[
                          {
                            required: true,
                            message: `${GET_MAX_RESERVATIONS_UNIT_TYPE_LABEL(
                              occasionForm.getFieldValue("unitType")
                            )}は必須です`,
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder={`1アカウントで${GET_MAX_RESERVATIONS_UNIT_TYPE_LABEL(
                            occasionForm.getFieldValue("unitType")
                          )}`}
                          min={1}
                          type="number"
                          onPressEnter={(e) => {
                            e.preventDefault()
                          }}
                          onWheel={(e) => e.target.blur()}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Group title="イベント応募期間設定">
                <Row>
                  <Col span={24}>
                    <FormItem
                      $isOpenRegistration={openRegistration}
                      name="isOpenRegistration"
                      // label="イベント応募期間の設定が可能"
                      className="whitespace-pre-line"
                      valuePropName="checked"
                    >
                      <Switch
                        name="isOpenRegistration"
                        onClick={(e) => {
                          setOpenRegistration(e)
                          if (e === true)
                            occasionForm.setFieldsValue({
                              startRegister: 0,
                              endRegister: 0,
                            })
                        }}
                      />
                    </FormItem>
                  </Col>
                </Row>
                {openRegistration ? (
                  <Row style={{ marginBottom: 20 }}>
                    <Col span={24}>
                      <Space direction="vertical" size={15}>
                        <CardTime>
                          <div className="titile">開始時間の選択</div>

                          <Space>
                            <Form.Item
                              name="startRegister"
                              rules={[
                                {
                                  required: true,
                                  message: "",
                                },
                              ]}
                            >
                              <DatePicker
                                locale={jaJP}
                                placeholder=""
                                format="YYYY-MM-DD HH:mm"
                                showTime={{
                                  defaultValue: moment("00:00:00", "HH:mm"),
                                }}
                                onChange={(value) => {
                                  setMinTimeRegistration(value)
                                  occasionForm.resetFields(["endRegister"])
                                }}
                              />
                            </Form.Item>
                            {/* <TimePicker placeholder="" format={"HH:mm"} /> */}
                          </Space>
                        </CardTime>

                        <CardTime>
                          <div className="titile">終了時間の選択</div>
                          <Space>
                            <Form.Item
                              name="endRegister"
                              rules={[
                                {
                                  required: true,
                                  message: "",
                                },
                              ]}
                            >
                              <DatePicker
                                locale={jaJP}
                                disabledDate={(current) => {
                                  return (
                                    minTimeRegistration &&
                                    current &&
                                    moment(current).format("YYYY-MM-DD") <
                                      moment(minTimeRegistration).format(
                                        "YYYY-MM-DD"
                                      )
                                  )
                                }}
                                disabledTime={(current) => {
                                  if (
                                    moment(current).format("YYYY-MM-DD") ===
                                    moment(minTimeRegistration).format(
                                      "YYYY-MM-DD"
                                    )
                                  ) {
                                    return {
                                      disabledHours: () =>
                                        minTimeRegistration &&
                                        Array.from(
                                          { length: 60 },
                                          (_, i) => i
                                        ).filter(
                                          (i) =>
                                            i <
                                            Number(
                                              moment(
                                                minTimeRegistration
                                              ).format("HH")
                                            )
                                        ),
                                      disabledMinutes: () =>
                                        Array.from(
                                          { length: 60 },
                                          (_, i) => i
                                        ).filter(
                                          (i) =>
                                            i <
                                            Number(
                                              moment(
                                                minTimeRegistration
                                              ).format("mm")
                                            )
                                        ),
                                    }
                                  }
                                }}
                                placeholder=""
                                format="YYYY-MM-DD HH:mm"
                                showTime={{
                                  defaultValue: moment("00:00:00", "HH:mm"),
                                }}
                              />
                            </Form.Item>
                            {/* <TimePicker placeholder="" format={"HH:mm"} /> */}
                          </Space>
                        </CardTime>
                      </Space>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Group>

              <Group title="「未就学児の同伴者」を参加者としてカウントする">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="occasionRegisterPreschool"
                      valuePropName="checked"
                    >
                      <Switch name="occasionRegisterPreschool" />
                    </Form.Item>
                  </Col>
                </Row>
              </Group>

              <Group title="他のイベントと同時に予約を可能にする">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="occasionRegisterCanOverlap"
                      valuePropName="checked"
                      style={{ marginBottom: 0 }}
                    >
                      <Switch name="occasionRegisterCanOverlap" />
                    </Form.Item>
                  </Col>
                </Row>
              </Group>

              <Divider />

              <Group title="イベント予約されるとLINEに送られるメッセージ">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="occasionRegisterRegisterMessage"
                      help="※設定されていない場合は、LINE側でメッセージが送信されません。"
                      style={{ marginBottom: 0 }}
                    >
                      <TextArea
                        placeholder="メッセージを入力してください"
                        autoSize
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Group>

              <Divider />

              <Group title="リマインドメッセージ（イベント前日に届く）">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="occasionRegisterRemindMessage"
                      help="※1日過ぎた後の予約は直ぐに届きます。設定されていない場合は、LINE側でメッセージが送信されません。"
                      style={{ marginBottom: 0 }}
                    >
                      <TextArea
                        placeholder="メッセージを入力してください"
                        autoSize
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Group>

              <Divider />

              <Group title="リマインドメッセージ（予約30分前に届く）">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="occasionRegisterRemind10Message"
                      help="※設定されていない場合は、LINE側でメッセージが送信されません。"
                      style={{ marginBottom: 0 }}
                    >
                      <TextArea
                        placeholder="メッセージを入力してください"
                        autoSize
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Group>

              <Divider />

              <Group title="イベント参加料金機能">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name={`hasBaseTicketPrice`}
                      valuePropName="checked"
                    >
                      <Switch
                        name={`hasBaseTicketPrice`}
                        onChange={(e) => setHasBaseTicketPriceFeature(e)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Group>
              {occasionForm.getFieldValue("hasBaseTicketPrice") === true && (
                <Form.Item
                  name={`baseTicketPrice`}
                  label="参加料金"
                  rules={[
                    {
                      required: true,
                      message: "参加料金は必須です",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="参加料金"
                    min={0}
                    type="number"
                    onPressEnter={(e) => {
                      e.preventDefault()
                    }}
                    onWheel={(e) => e.target.blur()}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              )}
              <CancelFeature />

              <BookingOptions />
            </>
          ) : (
            ""
          )}

          {currentStep === 1 ? (
            <>
              <Row justify="space-between">
                <Col>
                  <Form.Item
                    name="occurrenceMaxParticipation"
                    label={
                      occasionForm.getFieldValue("unitType") === "area"
                        ? `既定参加可能最大区画数`
                        : `既定参加可能最大人数`
                    }
                    rules={[
                      {
                        required: true,
                        message: "既定参加可能最大人数は必須です",
                      },
                    ]}
                  >
                    <Commons.NumericInput
                      autoFocus
                      placeholder="既定参加可能最大人数を入力してください"
                      onPressEnter={(e) => {
                        e.preventDefault()
                      }}
                      onChange={(value) => {
                        if (occurrenceForm.current) {
                          occurrenceForm.current.setFieldsValue({
                            occurrenceMaxParticipation: value,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="occurrenceTimeInterval" label="時間期間">
                    <Select
                      onChange={(value) => {
                        setEventInterval(parseInt(value))
                        clearOccurrence()
                      }}
                    >
                      <Option value="10">10分</Option>
                      <Option value="15">15分</Option>
                      <Option value="20">20分</Option>
                      <Option value="25">25分</Option>
                      <Option value="30">30分</Option>
                      <Option value="35">35分</Option>
                      <Option value="40">40分</Option>
                      <Option value="45">45分</Option>
                      <Option value="50">50分</Option>
                      <Option value="55">55分</Option>
                      <Option value="60">60分</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <CalendarWrapper>
                <FullCalendar
                  locale="ja"
                  ref={occurrenceManageCalendarRef}
                  plugins={[timeGridPlugin, interactionPlugin, momentPlugin]}
                  initialView="timeGridWeek"
                  height="64.7vh"
                  customButtons={{
                    CustomPrevMonth: {
                      text: "先月",
                      click: function () {
                        occurrenceManageCalendarRef.current
                          .getApi()
                          .gotoDate(
                            moment(
                              occurrenceManageCalendarRef.current
                                .getApi()
                                .getDate()
                            )
                              .subtract(1, "month")
                              .toDate()
                          )
                      },
                    },
                    CustomNextMonth: {
                      text: "来月",
                      click: function () {
                        occurrenceManageCalendarRef.current
                          .getApi()
                          .gotoDate(
                            moment(
                              occurrenceManageCalendarRef.current
                                .getApi()
                                .getDate()
                            )
                              .add(1, "month")
                              .toDate()
                          )
                      },
                    },
                    CustomPrevWeek: {
                      text: "先週",
                      click: function () {
                        occurrenceManageCalendarRef.current.getApi().prev()
                      },
                    },
                    CustomThisWeek: {
                      text: "今週",
                      click: function () {
                        occurrenceManageCalendarRef.current.getApi().today()
                      },
                    },
                    CustomNextWeek: {
                      text: "来週",
                      click: function () {
                        occurrenceManageCalendarRef.current.getApi().next()
                      },
                    },
                  }}
                  headerToolbar={{
                    left: "CustomPrevMonth,CustomNextMonth",
                    center: "title",
                    right: "CustomPrevWeek,CustomThisWeek,CustomNextWeek",
                  }}
                  titleFormat={(date) => {
                    return moment(date.date).format("YYYY年M月")
                  }}
                  firstDay={1}
                  businessHours={false}
                  allDaySlot={false}
                  slotLabelFormat={{
                    hour: "2-digit",
                    minute: "2-digit",
                    omitZeroMinute: false,
                  }}
                  slotDuration={`00:${eventInterval}:00`}
                  slotMinTime={Commons.BUSINESS_OPEN_TIME}
                  slotMaxTime={Commons.BUSINESS_CLOSE_TIME}
                  slotLabelInterval={`00:${eventInterval}:00`}
                  dayHeaderFormat={(date) => {
                    return moment(date.date).format("D（ddd）")
                  }}
                  eventTimeFormat={{
                    hour: "2-digit",
                    minute: "2-digit",
                    meridiem: false,
                  }}
                  slotEventOverlap={false}
                  displayEventTime={true}
                  displayEventEnd={false}
                  nowIndicator={true}
                  selectable={true}
                  selectOverlap={false}
                  unselectAuto={true}
                  selectAllow={(selectInfo) =>
                    moment().isBefore(selectInfo.start)
                  }
                  events={occurrences.map((occurrence) => {
                    return {
                      groupId: "background",
                      display: "background",
                      backgroundColor: "#9ff0fc",
                      start: occurrence.startAt,
                      end: occurrence.endAt,
                    }
                  })}
                  eventContent={(arg) => {
                    if (arg.event.groupId === "background") {
                      return (
                        <Row justify="center" className="cursor-pointer">
                          <Col>
                            <ClockCircleTwoTone
                              twoToneColor="#21acd7"
                              className="text-lg p-1"
                            />
                          </Col>
                        </Row>
                      )
                    }
                  }}
                  eventClick={(eventInfo) => {
                    if (eventInfo.event.groupId === "background") {
                      Modal.confirm({
                        title: "イベントの時間削除",
                        icon: (
                          <QuestionCircleOutlined
                            style={{ color: "#f5222d" }}
                          />
                        ),
                        content: (
                          <Row
                            gutter={[0, 16]}
                            className="mt-4"
                            justify="center"
                          >
                            <Col span={24}>
                              <Card
                                bodyStyle={{
                                  maxHeight: "50vh",
                                  overflow: "auto",
                                }}
                              >
                                <Card.Grid
                                  hoverable={false}
                                  style={deleteCardGridStyle}
                                >
                                  {`${moment(eventInfo.event.start).format(
                                    "YYYY年M月D日 HH:mm"
                                  )}`}
                                </Card.Grid>
                              </Card>
                            </Col>
                          </Row>
                        ),
                        okText: "削除",
                        okButtonProps: {
                          danger: true,
                        },
                        onOk: () => {
                          setOccurrences(
                            occurrences.filter(
                              (occurrence) =>
                                occurrence.startAt !==
                                moment(eventInfo.event.start).format(
                                  "YYYY-MM-DD HH:mm"
                                )
                            )
                          )
                        },
                        cancelText: "閉じる",
                      })
                    }
                  }}
                  longPressDelay={1}
                  select={(selectInfo) => {
                    Modal.confirm({
                      title: "イベントの時間登録",
                      icon: (
                        <QuestionCircleOutlined style={{ color: "#21acd7" }} />
                      ),
                      content: (
                        <Row gutter={[0, 16]} className="mt-4" justify="center">
                          <Col span={24}>
                            <Card
                              bodyStyle={{
                                maxHeight: "50vh",
                                overflow: "auto",
                              }}
                            >
                              {Array.from(
                                moment
                                  .range(selectInfo.start, selectInfo.end)
                                  .by("minutes", {
                                    step: eventInterval,
                                    excludeEnd: true,
                                  })
                              ).map((chunk) => (
                                <Card.Grid
                                  hoverable={false}
                                  style={cardGridStyle}
                                  key={chunk}
                                >
                                  {moment(chunk).format("YYYY年M月D日 HH:mm")}
                                </Card.Grid>
                              ))}
                            </Card>
                          </Col>
                          <Col>
                            <Form
                              ref={occurrenceForm}
                              layout="vertical"
                              initialValues={{
                                occurrenceMaxParticipation:
                                  occasionForm.getFieldValue(
                                    "occurrenceMaxParticipation"
                                  ) || 10,
                              }}
                              size="small"
                              requiredMark={true}
                              scrollToFirstError
                            >
                              <Form.Item
                                name="occurrenceMaxParticipation"
                                label="参加可能最大人数"
                                rules={[
                                  {
                                    required: true,
                                    message: "参加可能最大人数は必須です",
                                  },
                                ]}
                              >
                                <Commons.NumericInput
                                  autoFocus
                                  placeholder="参加可能最大人数を入力してください"
                                  onPressEnter={(e) => {
                                    e.preventDefault()
                                  }}
                                />
                              </Form.Item>
                            </Form>
                          </Col>
                        </Row>
                      ),
                      okText: "登録",
                      onOk: () => {
                        occurrenceForm.current
                          .validateFields()
                          .then((values) => {
                            const dateRange = moment.range(
                              selectInfo.start,
                              selectInfo.end
                            )

                            const dateChunk = Array.from(
                              dateRange.by("minutes", {
                                step: eventInterval,
                                excludeEnd: true,
                              })
                            )

                            setOccurrences([
                              ...occurrences,
                              ...dateChunk.map((date) => ({
                                maxAttendee:
                                  values.occurrenceMaxParticipation || 10,
                                startAt:
                                  moment(date).format("YYYY-MM-DD HH:mm"),
                                endAt: moment(date)
                                  .add(eventInterval, "minutes")
                                  .format("YYYY-MM-DD HH:mm"),
                              })),
                            ])
                          })
                          .catch((error) => {})
                      },
                      cancelText: "閉じる",
                    })
                  }}
                />
              </CalendarWrapper>
              <Row justify="center mt-4">
                <Col>
                  <Button
                    style={{ margin: "0 8px" }}
                    onClick={() => clearOccurrence()}
                  >
                    すべての日付選択をクリア
                  </Button>
                </Col>
              </Row>
              <Divider />
            </>
          ) : (
            ""
          )}
          {currentStep === 2 ? (
            <Descriptions column={1} bordered labelStyle={{ width: "140px" }}>
              <Descriptions.Item label="イベント画像">
                <MultiImageUpload
                  key={"imageUploadPreview"}
                  form={occasionForm}
                  occasionImages={occasionImages}
                  setOccasionImages={setOccasionImages}
                  isPreview={true}
                />
              </Descriptions.Item>
              <Descriptions.Item label="イベント名">
                <span>
                  {occasionForm.getFieldValue("occasionRegisterName") || "ー"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="イベントの単位">
                <span>
                  {Commons.unitTypes.find(
                    (item) =>
                      item.value === occasionForm.getFieldValue("unitType")
                  )?.label || "ー"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={GET_MAX_PEOPLE_PER_RESERVATION_UNIT_TYPE_LABEL(
                  occasionForm.getFieldValue("unitType")
                )}
              >
                <span>
                  {occasionForm.getFieldValue("maxPerReservation") || "ー"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="保護者氏名">
                <span>
                  {occasionForm.getFieldValue("parentNameRq") || "ー"}
                  {occasionForm.getFieldValue("parentNameShow") || "ー"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="ご連絡先電話番号">
                <span>
                  {occasionForm.getFieldValue("parentPhoneRq") || "ー"}
                  {occasionForm.getFieldValue("parentPhoneShow") || "ー"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label=" 参加者のメッセージ">
                <span>
                  {occasionForm.getFieldValue("childNoteRq") || "ー"}
                  {occasionForm.getFieldValue("childNoteLabel") || "ー"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label=" 予約のメッセージ">
                <span>
                  {occasionForm.getFieldValue("generalNoteRq") || "ー"}
                  {occasionForm.getFieldValue("generalNoteLabel") || "ー"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="イベント内容">
                <span>
                  {occasionForm.getFieldValue("occasionRegisterDesc") || "ー"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={`1アカウントで${GET_MAX_RESERVATIONS_UNIT_TYPE_LABEL(
                  occasionForm.getFieldValue("unitType")
                )}`}
              >
                <span>
                  {occasionForm.getFieldValue("occasionRegisterMaxAllow") ||
                    "ー"}
                  {GET_UNIT_TYPE_LABEL(occasionForm.getFieldValue("unitType"))}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="未就学児を参加者とカウントする">
                <span>
                  {occasionForm.getFieldValue("occasionRegisterPreschool")
                    ? "カウントする"
                    : "カウントしない"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="他のイベントも同時に予約することが可能">
                <span>
                  {occasionForm.getFieldValue("occasionRegisterCanOverlap")
                    ? "可能"
                    : "不可能"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="イベント参加料金機能">
                <span>
                  {occasionForm.getFieldValue("hasBaseTicketPrice")
                    ? "有効"
                    : "無効"}
                </span>
              </Descriptions.Item>
              {occasionForm.getFieldValue("hasBaseTicketPrice") ? (
                <Descriptions.Item label="参加料金">
                  <span>
                    {occasionForm.getFieldValue("baseTicketPrice") || "ー"}円
                  </span>
                </Descriptions.Item>
              ) : (
                ""
              )}
              <Descriptions.Item label="キャンセル機能">
                <span>
                  {occasionForm.getFieldValue("occasionRegisterCancel")
                    ? "キャンセル可"
                    : "キャンセル不可"}
                </span>
              </Descriptions.Item>
              {occasionForm.getFieldValue("occasionRegisterCancel") ? (
                <Descriptions.Item label="キャンセル可能時間">
                  <span>
                    {occasionForm.getFieldValue("occasionRegisterCancelTime") ||
                      "ー"}
                    分
                  </span>
                </Descriptions.Item>
              ) : (
                ""
              )}
              <Descriptions.Item label="キャンセルに関する説明文">
                <span>
                  {occasionForm.getFieldValue(
                    "occasionRegisterCancelMessage"
                  ) || "ー"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="イベント一覧">
                {occurrences.length > 0 ? (
                  <Row
                    gutter={[8, 8]}
                    style={{ overflow: "auto", maxHeight: "260px" }}
                  >
                    {occurrences.map((occurrence) => (
                      <Col span={8} key={occurrence.startAt}>
                        <Row>
                          <Col
                            span={24}
                            className="p-2 text-center border border-b-0 border-solid border-primary bg-primary text-white"
                          >
                            <span>
                              {moment(occurrence.startAt).format(
                                "YYYY年M月D日"
                              )}
                            </span>
                          </Col>
                          <Col
                            span={12}
                            className="p-2 text-center border border-r-0 border-solid border-primary"
                          >
                            <span>
                              {moment(occurrence.startAt).format("HH:mm")}
                            </span>
                          </Col>
                          <Col
                            span={12}
                            className="p-2 text-center border border-solid border-primary"
                          >
                            <span>{occurrence.maxAttendee}人</span>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  "ー"
                )}
              </Descriptions.Item>
            </Descriptions>
          ) : (
            ""
          )}
          <Row gutter={[8, 8]} justify="center">
            {isMobileOnly ? (
              ""
            ) : (
              <Col span={24}>
                <Steps progressDot current={currentStep} className="p-4">
                  {steps.map((step) => (
                    <Step key={step.key} title={step.title} />
                  ))}
                </Steps>
              </Col>
            )}
            <Col>
              <Button key="back" onClick={onCancel}>
                閉じる
              </Button>
            </Col>
            <Col>
              {currentStep > 0 && (
                <Button style={{ margin: "0 8px" }} onClick={() => prevStep()}>
                  前のステップ
                </Button>
              )}
            </Col>
            <Col>
              {currentStep < 2 && (
                <Button type="primary" onClick={() => nextStep()}>
                  次のステップ
                </Button>
              )}
            </Col>
            <Col>
              {currentStep === 2 && (
                <Button
                  type="primary"
                  htmlType="submit"
                  key="ok"
                  loading={eventSubmitLoading}
                >
                  登録
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
