import { useParams } from "react-router-dom"
import { Col, Form, Row } from "antd"
import { cn } from "common/utilities"
import registerSimpleCountEgImg from "../../../assets/images/register-simple-count-example.jpg"
import Group from "../Group"
import Switch from "../Switch"
import ChildAge from "./ChildAge"
import ChildNote from "./ChildNote"
import FullName from "./FullName"
import GeneralNode from "./GeneralNote"
import ParentName from "./ParentName"
import ParentPhone from "./ParentPhone"

/**
 * @typedef {Object} BookingOptionsProps
 *
 * @typedef {Object} Params
 * @property {string} id
 *
 * @param {BookingOptionsProps} props
 * @returns {JSX.Element}
 */
const BookingOptions = () => {
  /**
   * @type {Params}
   */
  const { id } = useParams()
  const occasionForm = Form.useFormInstance()
  const keyType = id ? "Update" : "Register"
  const isSimpleCount = Form.useWatch(`occasion${keyType}SimpleCount`)

  return (
    <Group title="予約申込み時の項⽬設定">
      <Row>
        <Col span={24}>
          <Form.Item
            name={`occasion${keyType}SimpleCount`}
            label="シンプル参加者カウント"
            valuePropName="checked"
            className="whitespace-pre-line"
          >
            <Switch
              name={`occasion${keyType}SimpleCount`}
              onClick={(e) => {
                if (e === true) {
                  occasionForm.setFieldsValue({
                    ageMin: 0,
                    ageMax: 0,
                  })
                }
              }}
            />
          </Form.Item>
        </Col>

        {isSimpleCount ? (
          <div className="w-full flex flex-col justify-center gap-y-2">
            <label>表⽰イメージ</label>
            <img
              src={registerSimpleCountEgImg}
              alt="registerSimpleCountEgImg"
              className="w-1/2"
            />
          </div>
        ) : (
          <Col
            span={24}
            className={cn(
              "px-5 py-10 border border-solid border-[#E5E5E5] relative",
              "[&_.ant-form-item]:mb-0",
              "[&_.ant-radio-inner]:border-black",
              "flex flex-col gap-y-6"
            )}
          >
            <div className="w-full absolute -top-[9px] left-0 text-center">
              <span
                className={cn("text-[18px] leading-[18px] px-[14px] bg-white")}
              >
                応募項⽬表⽰項⽬設定
              </span>
            </div>

            <ParentName />

            <ParentPhone />

            <Form.Item
              label="■参加者情報"
              className={cn(
                "flex flex-col gap-y-2",
                "[&>.ant-form-item-row>.ant-form-item-control>.ant-form-item-control-input>.ant-form-item-control-input-content]:flex",
                "[&>.ant-form-item-row>.ant-form-item-control>.ant-form-item-control-input>.ant-form-item-control-input-content]:flex-col",
                "[&>.ant-form-item-row>.ant-form-item-control>.ant-form-item-control-input>.ant-form-item-control-input-content]:gap-y-4"
              )}
            >
              <FullName />

              <ChildAge />

              <ChildNote />

              <GeneralNode />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Group>
  )
}

export default BookingOptions
