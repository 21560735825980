import { Form, Radio } from "antd"
import Switch from "../Switch"

export default function ParentPhone() {
  const isParentPhoneShow = Form.useWatch("parentPhoneShow")
  
  return (
    <div className="flex flex-col">
      <div className="flex gap-7 items-center">
        <span>■ご連絡先電話番号</span>
        <Form.Item name="parentPhoneShow">
          <Switch name="parentPhoneShow" />
        </Form.Item>
      </div>

      {isParentPhoneShow && (
        <Form.Item name="parentPhoneRq" className="pl-4">
          <Radio.Group>
            <Radio value={true}>必須</Radio>
            <Radio value={false}>任意</Radio>
          </Radio.Group>
        </Form.Item>
      )}
    </div>
  )
}
