import {
  Button,
  Form,
  Input as AntInput,
  Modal,
  Row,
  Divider,
  Space,
  Select,
  Checkbox,
} from "antd"
import { Controller } from "react-hook-form"
import styled from "styled-components"
import { SyncOutlined, UndoOutlined } from "@ant-design/icons"

import useAudiencesModal from "./AudiencesModal.hook"

const ButtonCreate = styled(Button)`
  background-color: #017440;
  color: white;
  &:hover {
    background-color: rgb(53 189 128);
    color: white;
    border-color: rgb(53 189 128);
  }
  &:active {
    background-color: rgb(53 189 128);
    color: white;
  }
  &:focus {
    background-color: rgb(53 189 128);
    color: white;
    border-color: rgb(53 189 128);
  }
`

const StyleButton = styled(Button)`
  background-color: #99ca29;
  padding: 0px 39px;
  border-radius: 4px;
  color: white;
  &:hover {
    background-color: rgb(142 197 17);
    color: white;
    border-color: rgb(142 197 17);
  }
  &:active {
    background-color: rgb(142 197 17);
    color: white;
  }
  &:focus {
    background-color: rgb(142 197 17);
    color: white;
    border-color: rgb(142 197 17);
  }
`

const TextResults = styled.div`
  width: 100%;
  text-align: center;
  color: #99ca29;
  font-weight: 600;
`

const Input = styled(AntInput)`
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`

const FormItem = styled(Form.Item)`
  .ant-row {
    flex-flow: column;
  }

  .ant-form-item-label {
    text-align: left;
  }
`

const StyleLabel = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 10px 0;

  .ant-divider {
    position: absolute;
    background-color: #ecedef;
  }
  div {
    position: relative;
    width: fit-content;
    background-color: #f3f4f6;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600;
  }
`
const StyleBackground = styled.div`
  background-color: #f3f4f6;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px 20px;
`
const StyleCheckboxGroup = styled(Checkbox.Group)`
  .ant-checkbox-wrapper {
    width: 30%;
    min-width: 140px;
  }
`

const StyleModal = styled(Modal)`
  @media screen and (min-width: 992px) {
    width: 583px !important;
  }
`
const StyleSpace = styled(Space)`
  box-sizing: border-box;
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
`

const StyleFormItem = styled(Form.Item)`
  margin-bottom: 0px !important;
`

const AudiencesModalModalLayout = ({
  reset,
  minAge,
  errors,
  visible,
  control,
  onCancel,
  onSubmit,
  syncSpin,
  setMinAge,
  minPeople,
  countUsers,
  occasionId,
  handleSubmit,
  setMinPeople,
  handleCountUsers,
  openComfimNameModal,
  loadingSearchResults,
  handleOpenComfimNameModal,
  onChangeReservationStatus,
  handleCloseComfimNameModal,
}) => {
  return (
    <Form layout="vertical">
      <StyleModal
        visible={visible}
        onCancel={onCancel}
        title="新規作成"
        centered
        footer={null}
        style={{
          width: "45%",
        }}
      >
        <Row justify="end">
          <Button
            icon={<UndoOutlined rotate={140} />}
            onClick={() => reset()}
            type="dashed"
            danger
          >
            フィルタークリア
          </Button>
        </Row>

        <StyleBackground>
          <StyleLabel>
            <Divider />
            <div>子どもの年齢</div>
          </StyleLabel>

          <StyleSpace wrap={true}>
            <Controller
              name="minAge"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <StyleFormItem
                  help={error ? error.message : undefined}
                  validateStatus={error ? "error" : undefined}
                >
                  <Space size={8}>
                    <Select
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        setMinAge(e)
                      }}
                      style={{ width: 150 }}
                      options={Array.from({ length: 19 }, (_, i) => ({
                        value: i,
                        label: i,
                      }))}
                    />
                    歳
                  </Space>
                </StyleFormItem>
              )}
            />

            <Controller
              name="maxAge"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <StyleFormItem
                  help={error ? error.message : undefined}
                  validateStatus={error ? "error" : undefined}
                >
                  <Space size={8}>
                    <Select
                      {...field}
                      style={{ width: 150 }}
                      options={Array.from({ length: 19 }, (_, i) => ({
                        value: i,
                        label: i,
                        disabled: i < minAge,
                      }))}
                    />
                    歳
                  </Space>
                </StyleFormItem>
              )}
            />
          </StyleSpace>

          <StyleLabel>
            <Divider />
            <div>参加者氏名</div>
          </StyleLabel>
          <Form.Item>
            <Controller
              name="participantName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox.Group
                  onChange={onChange}
                  value={value}
                  options={[
                    { label: "漢字で書いた氏名", value: "kanji" },
                    { label: "ひらがなで書いた氏名", value: "hiragana" },
                    { label: "カタカナで書いた氏名", value: "katakana" },
                  ]}
                />
              )}
            />
          </Form.Item>

          <StyleLabel>
            <Divider />
            <div>子ども人数</div>
          </StyleLabel>

          <StyleSpace wrap={true}>
            <Controller
              name="minPeople"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <StyleFormItem
                  help={error ? error.message : undefined}
                  validateStatus={error ? "error" : undefined}
                >
                  <Space size={8}>
                    <Select
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        setMinPeople(e)
                      }}
                      style={{ width: 150 }}
                      options={Array.from({ length: 5 }, (_, i) => ({
                        value: i + 1,
                        label: i + 1,
                      }))}
                    />
                    人
                  </Space>
                </StyleFormItem>
              )}
            />

            <Controller
              name="maxPeople"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <StyleFormItem
                  help={error ? error.message : undefined}
                  validateStatus={error ? "error" : undefined}
                >
                  <Space size={8}>
                    <Select
                      {...field}
                      style={{ width: 150 }}
                      options={Array.from({ length: 5 }, (_, i) => ({
                        value: i + 1,
                        label: i + 1,
                        disabled: i + 1 < minPeople,
                      }))}
                    />
                    人
                  </Space>
                </StyleFormItem>
              )}
            />
          </StyleSpace>

          <StyleLabel>
            <Divider />
            <div>予約状態</div>
          </StyleLabel>
          <Form.Item>
            <Controller
              name="reservationStatus"
              control={control}
              render={({ field: { value } }) => (
                <StyleCheckboxGroup
                  onChange={(value) => {
                    onChangeReservationStatus(value)
                  }}
                  value={value}
                  options={[
                    { label: "全予約者", value: "all" },
                    { label: "参加者", value: "participant" },
                    { label: "不参加者", value: "nonParticipant" },
                    { label: "キャセル者", value: "cancel" },
                    { label: "未参加者", value: "nonParticipants" },
                  ]}
                />
              )}
            />
          </Form.Item>
        </StyleBackground>
        <Row gutter={[8, 8]} justify="center" className="m-4">
          <Button
            icon={<SyncOutlined spin={loadingSearchResults} />}
            disabled={loadingSearchResults}
            onClick={handleCountUsers}
            type="dashed"
            style={{
              padding: "0 35px",
            }}
          >
            検索
          </Button>
        </Row>
        <TextResults>
          検索結果：<span>{countUsers}</span> 件
        </TextResults>
        <Divider />
        <Row gutter={[8, 8]} justify="center" className="m-4">
          <StyleButton
            disabled={!countUsers}
            onClick={handleOpenComfimNameModal}
          >
            作成
          </StyleButton>
        </Row>
      </StyleModal>
      <Modal
        visible={openComfimNameModal}
        onCancel={handleCloseComfimNameModal}
        title="オーディエンス作成"
        centered
        footer={null}
      >
        <FormItem
          name="audienceName"
          label="オーディエンス名"
          help={errors.audienceName ? errors.audienceName.message : undefined}
          validateStatus={errors.audienceName ? "error" : undefined}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Controller
            name="audienceName"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FormItem>

        <Divider />

        <Row gutter={[8, 8]} justify="end" className="m-4">
          <Space size={10}>
            <Button onClick={handleCloseComfimNameModal}>閉じる</Button>
            <ButtonCreate
              icon={
                <SyncOutlined
                  spin={syncSpin.occasionId === occasionId && syncSpin.sync}
                />
              }
              disabled={syncSpin.occasionId === occasionId && syncSpin.sync}
              onClick={handleSubmit(onSubmit)}
            >
              確認
            </ButtonCreate>
          </Space>
        </Row>
      </Modal>
    </Form>
  )
}

const AudiencesModal = (props) => (
  <AudiencesModalModalLayout {...useAudiencesModal(props)} />
)

export default AudiencesModal
