import React, { useState, useEffect, useCallback } from "react"
import { withRouter, Link } from "react-router-dom"
import {
  Button,
  Col,
  Divider,
  message,
  Tag,
  Row,
  Modal,
  Space,
  Image as AntdImage,
} from "antd"
import {
  ClockCircleOutlined,
  MobileOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons"
import ImageCarousel from "components/ImageCarousel"
import moment from "moment"
import "moment/locale/ja"
import liff from "@line/liff"
import io from "socket.io-client"
import * as Commons from "common/common"

moment.locale("ja")

const { GET_MAX_RESERVATIONS_PER_ACCOUNT_UNIT_TYPE_LABEL } = Commons

const LiffOccasionsDetail = (props) => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props
  const occasionId = parseInt(props.match.params.id)
  const isMountedRef = Commons.useIsMountedRef()

  const [occasion, setOccasion] = useState({})

  const fetchOccasionDetail = useCallback(() => {
    showLoadingPageSpin()

    let headerData = {}

    try {
      headerData.headers = {
        "access-token": liff.getAccessToken(),
      }
    } catch (error) {
      history.push(Commons.liffLoginRoute)
    }

    Commons.axiosInstance
      .get(Commons.apiLiffOccasionDetail + "/" + occasionId, headerData)
      .then((response) => {
        if (!isMountedRef.current && !response && !response.data) return

        setOccasion(response.data || {})
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        hideLoadingPageSpin()
      })
  }, [
    isMountedRef,
    history,
    occasionId,
    showLoadingPageSpin,
    hideLoadingPageSpin,
  ])

  useEffect(() => {
    fetchOccasionDetail()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const socket = io(Commons.siteURL, { path: "/socket.io" })

    socket.on("updateEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          fetchOccasionDetail()
        }
      }
    })

    socket.on("updateOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          fetchOccasionDetail()
        }
      }
    })

    socket.on("deleteOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          fetchOccasionDetail()
        }
      }
    })

    socket.on("deleteEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          message.warning(Commons.socketLiffEventDeleteMsg)
          history.push(Commons.liffOccasionsRoute)
        }
      }
    })

    return () => {
      socket.off("updateEvent")
      socket.off("updateOccurrence")
      socket.off("deleteOccurrence")
      socket.off("deleteEvent")

      socket.disconnect()
    }

    // eslint-disable-next-line
  }, [])

  const handleNextPage = () => {
    const now = moment()
    const isEarly = now.isSameOrBefore(occasion.startRegister)
    const isLate = now.isSameOrAfter(occasion.endRegister)

    if (
      !occasion.isOpenRegistration ||
      (occasion.isOpenRegistration && !isEarly && !isLate)
    ) {
      // history.push(`${Commons.liffOccurrencesRoute}/${occasionId}`)
      history.push(`${Commons.liffOccasionsRoute}/${occasionId}/calendar`)
    } else {
      Modal.info({
        title: "確認",
        content: isEarly
          ? "応募期間の前ですので応募できません。"
          : "応募期間を過ぎましたので応募できません。",
        okText: "確認",
        centered: true,
      })
    }
  }

  return (
    <>
      <Row gutter={[0, 8]}>
        {/* <Col span={24} className='text-center'>
          <img
            src='/logo.svg'
            alt="ロゴ"
            style={{ maxHeight: "120px" }}
          />
        </Col> */}
        <Col span={24} className="text-center">
          <ImageCarousel occasion={occasion} />
        </Col>
        <Col span={24}>
          <Row justify="center" className="mx-4">
            <Col className="text-center">
              <span className="text-sm font-bold">{occasion.title || ""}</span>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center" className="mx-4">
            <Col className="text-center">
              <span className="text-xs whitespace-pre-wrap">
                {occasion.description || ""}
              </span>
            </Col>
          </Row>
        </Col>
        <Divider plain>イベント予約</Divider>
        {occasion.hasBaseTicketPrice && (
          <Col span={24} className="text-center">
            <span className="text-sm">
              イベント参加料金：
              {Commons.displayJpyPrice(occasion.baseTicketPrice)}
            </span>
          </Col>
        )}
        <Col span={24} className="text-center">
          <Row justify="center" className="mx-4">
            <Col span={24}>
              <Tag
                icon={<MobileOutlined />}
                className="text-center whitespace-pre-wrap m-1"
                style={{
                  backgroundColor: "#f0feff",
                  color: "#21acd7",
                  border: "1px solid #21acd7",
                }}
              >
                {GET_MAX_RESERVATIONS_PER_ACCOUNT_UNIT_TYPE_LABEL(
                  occasion.unitType,
                  occasion.maxPerPerson
                )}
              </Tag>
            </Col>
            {/* <Col>
              <Tag
                icon={
                  occasion.isPreschoolCount ? (
                    <UserAddOutlined />
                  ) : (
                    <UserDeleteOutlined />
                  )
                }
                className="text-center whitespace-pre-wrap m-1"
                style={{
                  backgroundColor: occasion.isPreschoolCount
                    ? "#f0feff"
                    : "#f6ffed",
                  color: occasion.isPreschoolCount ? "#21acd7" : "#52c41a",
                  border: occasion.isPreschoolCount
                    ? "1px solid #21acd7"
                    : "1px solid #52c41a",
                }}
              >
                {`${
                  occasion.isPreschoolCount
                    ? "同伴者も\nカウント"
                    : " 同伴者を\nカウントされません"
                }`}
              </Tag>
            </Col> */}
            <Col>
              <Tag
                icon={
                  occasion.canOverlap ? (
                    <UsergroupAddOutlined />
                  ) : (
                    <UsergroupDeleteOutlined />
                  )
                }
                className="text-center whitespace-pre-wrap m-1"
                style={{
                  backgroundColor: occasion.canOverlap ? "#f6ffed" : "#f0feff",
                  color: occasion.canOverlap ? "#52c41a" : "#21acd7",
                  border: occasion.canOverlap
                    ? "1px solid #52c41a"
                    : "1px solid #21acd7",
                }}
              >
                {occasion.canOverlap
                  ? "他のイベントも\n同時に予約することが可能"
                  : "他のイベントも\n同時に予約することが不可能"}
              </Tag>
            </Col>
            <Col>
              <Tag
                className="text-center whitespace-pre-wrap m-1"
                style={{
                  backgroundColor:
                    occasion.canCancel && occasion.canForceCancel
                      ? "#f6ffed"
                      : occasion.canCancel && !occasion.canForceCancel
                      ? "#f6ffed"
                      : "#fff2e8",
                  color:
                    occasion.canCancel && occasion.canForceCancel
                      ? "#52c41a"
                      : occasion.canCancel && !occasion.canForceCancel
                      ? "#52c41a"
                      : "#fa541c",
                  border:
                    occasion.canCancel && occasion.canForceCancel
                      ? "1px solid #52c41a"
                      : occasion.canCancel && !occasion.canForceCancel
                      ? "1px solid #52c41a"
                      : "1px solid #fa541c",
                }}
              >
                {occasion.canCancel && occasion.canForceCancel
                  ? "イベント開始後も\nキャンセルできる"
                  : occasion.canCancel && !occasion.canForceCancel
                  ? "キャンセル\n可能"
                  : "キャンセル\n不可能"}
              </Tag>
            </Col>
            {occasion.canCancel && !occasion.canForceCancel ? (
              <Col>
                <Tag
                  icon={<ClockCircleOutlined />}
                  className="text-center whitespace-pre-wrap m-1"
                  style={{
                    backgroundColor: "#f6ffed",
                    color: "#52c41a",
                    border: "1px solid #52c41a",
                  }}
                >
                  {`キャンセル\n可能時間：${occasion.timeCancel || 0}分`}
                </Tag>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Col>
        {occasion.isOpenRegistration ? (
          <Col
            span={24}
            style={{
              color: "red",
            }}
          >
            <Row justify="center">
              <Tag
                className="text-center whitespace-pre-wrap m-1"
                style={{
                  backgroundColor: "#f0feff",
                  color: "#21acd7",
                  border: "1px solid #21acd7",
                }}
              >
                <div>
                  <div>応募できる期間</div>
                  <Space>
                    <Space direction="vertical" size={0}>
                      <div>
                        {moment(occasion.startRegister).format("YYYY年M月D日")}
                      </div>
                      <div>
                        {moment(occasion.startRegister).format("HH:mm")}
                      </div>
                    </Space>
                    <div>~</div>
                    <Space direction="vertical" size={0}>
                      <div>
                        {moment(occasion.endRegister).format("YYYY年M月D日")}
                      </div>
                      <div>{moment(occasion.endRegister).format("HH:mm")}</div>
                    </Space>
                  </Space>
                </div>
              </Tag>
            </Row>
          </Col>
        ) : (
          ""
        )}
        <Col span={24}>
          <Row justify="center" className="mx-4">
            <Col className="text-center">
              <span className="text-xs whitespace-pre-wrap">
                {occasion.textCancel ||
                  "キャンセルについてメッセージ設定されていないです。"}
              </span>
            </Col>
          </Row>
        </Col>
        <Divider plain>イベント期間</Divider>
        <Col span={24}>
          <Row justify="center">
            <Col>
              <Tag color="#52c41a" className="whitespace-pre-wrap text-center">
                {occasion.start
                  ? `${moment(occasion.start).format("YYYY年M月D日")}\n${moment(
                      occasion.start
                    ).format("HH:mm")}`
                  : "ー年ー月ー日\nー:ー"}
              </Tag>
            </Col>
            <Col>
              <Tag
                color="#fa541c"
                className="whitespace-pre-wrap text-center mr-0"
              >
                {occasion.end
                  ? `${moment(occasion.end).format("YYYY年M月D日")}\n${moment(
                      occasion.end
                    ).format("HH:mm")}`
                  : "ー年ー月ー日\nー:ー"}
              </Tag>
            </Col>
          </Row>
        </Col>

        <Divider />
        <Col span={24}>
          <Row gutter={[8, 8]} justify="center" className="m-4">
            <Col span={12}>
              <Link to={`${Commons.liffOccasionsRoute}`}>
                <Button size="large" block>
                  戻る
                </Button>
              </Link>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                size="large"
                block
                onClick={handleNextPage}
              >
                予約する
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default withRouter(LiffOccasionsDetail)
