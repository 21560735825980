import { PlusOutlined } from "@ant-design/icons"
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Tag,
  message,
  Image,
} from "antd"
import * as Commons from "common/common"
import Moment from "moment"
import { extendMoment } from "moment-range"
import "moment/locale/ja"
import { useCallback, useEffect, useRef, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import io from "socket.io-client"
import styled from "styled-components"
import AddOccasionsPopup from "./AddOccasionsPopup"
import OrderModal from "./OrderModal"

const moment = extendMoment(Moment)
moment.locale("ja")

const StyledDashedButton = styled(Button)`
  &.ant-btn-dashed {
    border-color: #8c8c8c;
  }

  &.ant-btn-dashed:hover,
  .ant-btn-dashed:focus {
    border-color: #21acd7;
    color: #21acd7;
  }
`

// const getBase64 = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()

//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = (error) => reject(error)
//   })
// }

const {
  GET_RESERVATION_UNIT_TYPE_LABEL,
  GET_UNIT_TYPE_LABEL,
  GET_PARTICIPATION_UNIT_TYPE_LABEL,
  GET_MAX_CAPACITY_LABEL,
} = Commons

const Home = (props) => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props
  const isMountedRef = Commons.useIsMountedRef()
  const [occasionForm] = Form.useForm()

  const [createModalVisible, setCreateModalVisible] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [eventInterval, setEventInterval] = useState(
    Commons.BUSINESS_INTERVAL_TIME_VALUE
  )

  const [occasions, setOccasions] = useState([])
  const [occurrences, setOccurrences] = useState([])
  const [eventTemplates, setEventTemplates] = useState([])

  const fetchOccasionsOverview = useCallback(() => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    Commons.axiosInstance
      .get(Commons.apiOccasions + "/overview")
      .then((response) => {
        if (isMountedRef.current && response) {
          setOccasions(response.data || [])
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }, [isMountedRef, history, showLoadingPageSpin, hideLoadingPageSpin])

  const fetchEventTemplates = useCallback(() => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    Commons.axiosInstance
      .get(Commons.apiEvents + "/list")
      .then((response) => {
        if (isMountedRef.current && response) {
          setEventTemplates(response.data || [])
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }, [isMountedRef, history, showLoadingPageSpin, hideLoadingPageSpin])

  const fetchEventTemplateDetail = (occasionId) => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    Commons.axiosInstance
      .get(Commons.apiEvents + "/detail/" + occasionId)
      .then((response) => {
        if (isMountedRef.current && response) {
          if (response.data) {
            occasionForm.setFieldsValue({
              occasionRegisterName: response.data.title || "",
              occasionRegisterDesc: response.data.description || "",
              occasionRegisterMaxAllow: response.data.maxPerPerson || 0,
              occasionRegisterCancel: response.data.canCancel || false,
              occasionRegisterForceCancel:
                response.data.canForceCancel || false,
              occasionRegisterCancelTime: response.data.timeCancel || 0,
              occasionRegisterCancelMessage: response.data.textCancel || "",
              occasionRegisterRegisterMessage: response.data.regMessage || "",
              occasionRegisterRemindMessage: response.data.remindMessage || "",
              occasionRegisterPreschool:
                response.data.isPreschoolCount || false,

              ...((!response.data.isPreschoolCount && response.data.minAge) ||
              (!response.data.isPreschoolCount && response.data.minAge === 0)
                ? {
                    minAge: response.data.minAge,
                  }
                : {}),
              ...((!response.data.isPreschoolCount && response.data.maxAge) ||
              (!response.data.isPreschoolCount && response.data.maxAge === 0)
                ? {
                    maxAge: response.data.maxAge,
                  }
                : {}),
              ...(response.data.isOpenRegistration &&
              response.data.startRegister &&
              response.data.endRegister
                ? {
                    startRegister: moment(response.data.startRegister),
                    endRegister: moment(response.data.endRegister),
                    isOpenRegistration: response.data.isOpenRegistration,
                  }
                : {}),
            })
            // if (
            //   !response.data.isPreschoolCount &&
            //   response.data.minAge &&
            //   response.data.maxAge
            // ) {
            //   setMinAge(response.data.minAge)
            // }
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  useEffect(() => {
    fetchOccasionsOverview()
    fetchEventTemplates()

    return () => {
      Modal.destroyAll()
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const socket = io(Commons.siteURL, { path: "/socket.io" })

    socket.on("newEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
        fetchEventTemplates()
      }
    })

    socket.on("updateEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
        fetchEventTemplates()
      }
    })

    socket.on("updateOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
      }
    })

    socket.on("deleteOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
      }
    })

    socket.on("deleteEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
        fetchEventTemplates()
      }
    })

    socket.on("newRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
      }
    })

    socket.on("cancelRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
      }
    })

    socket.on("confirmRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchOccasionsOverview()
      }
    })

    return () => {
      socket.off("newEvent")
      socket.off("updateEvent")
      socket.off("updateOccurrence")
      socket.off("deleteOccurrence")
      socket.off("deleteEvent")
      socket.off("newRegistration")
      socket.off("cancelRegistration")
      socket.off("confirmRegistration")

      socket.disconnect()
    }

    // eslint-disable-next-line
  }, [])

  const nextStep = () => {
    occasionForm
      .validateFields()
      .then(() => {
        setCurrentStep(currentStep + 1)
      })
      .catch(() => {})
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const showCreateModal = () => {
    setCreateModalVisible(true)
  }

  const hideCreateModal = () => {
    setCreateModalVisible(false)
  }

  const clearOccurrence = () => {
    setOccurrences([])
  }

  const handleTemplateChange = (occasionId) => {
    fetchEventTemplateDetail(occasionId)
  }
  const [isOrderModalVisible, setIsOrderModalVisible] = useState(false)
  const handleButtonClick = () => {
    setIsOrderModalVisible(true)
  }
  return (
    <>
      <OrderModal
        isOrderModalVisible={isOrderModalVisible}
        setIsOrderModalVisible={setIsOrderModalVisible}
        showLoadingPageSpin={showLoadingPageSpin}
        history={history}
        hideLoadingPageSpin={hideLoadingPageSpin}
      />
      <Card
        bordered={false}
        title={
          <Row justify="space-between" align="middle">
            <Col>イベント管理</Col>
            <Col>
              <Button type="primary" onClick={handleButtonClick}>
                イベント表示順編集
              </Button>
            </Col>
          </Row>
        }
      >
        <Row gutter={[16, 16]}>
          {occasions.map((occasion) => (
            <Col
              xs={24}
              md={12}
              lg={8}
              xl={8}
              xxl={6}
              key={occasion.occasionId}
            >
              <Link to={`${Commons.homeRoute}/${occasion.occasionId}`}>
                <Card
                  hoverable
                  cover={
                    occasion?.occasionImages &&
                    occasion?.occasionImages?.length > 0 &&
                    occasion.occasionImages[0]?.picUrl ? (
                      <Image
                        preview={false}
                        src={`${Commons.occasionImagesURL}${occasion.occasionImages[0]?.picUrl}`}
                        fallback="/no-image.png"
                        style={{ height: "180px", objectFit: "cover" }}
                        width="100%"
                        alt="イベント画像"
                      />
                    ) : (
                      <Image
                        preview={false}
                        src="/no-image.png"
                        style={{ height: "180px", objectFit: "cover" }}
                        width="100%"
                        alt="イベント画像"
                      />
                    )
                  }
                  headStyle={{
                    borderColor: "#21acd7",
                  }}
                  bodyStyle={{
                    padding: "10px",
                  }}
                  style={{
                    borderColor: "#21acd7",
                  }}
                >
                  <Row justify="center" className="mb-4">
                    <Col className="text-center">
                      <span
                        className="text-sm font-bold"
                        style={{
                          textOverflow: "ellipsis",
                          wordWrap: "break-word",
                          overflow: "hidden",
                          height: "3em",
                          lineHeight: "1.5em",
                          display: "inline-block",
                        }}
                      >
                        {occasion.title || ""}
                      </span>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col
                      span={12}
                      style={{
                        backgroundColor: Commons.checkIsEventFull(
                          occasion.maxCapacity || 0,
                          occasion.sumExpected || 0
                        )
                          ? "#7cc7d6"
                          : "#f0feff",
                        color: Commons.checkIsEventFull(
                          occasion.maxCapacity || 0,
                          occasion.sumExpected || 0
                        )
                          ? "#FFF"
                          : "#21acd7",
                        border: "1px solid #21acd7",
                        borderBottom: "none",
                      }}
                    >
                      <Row>
                        <Col span={24} className="text-center">
                          <span>
                            {GET_RESERVATION_UNIT_TYPE_LABEL(occasion.unitType)}
                          </span>
                        </Col>
                        <Col span={24} className="text-center">
                          <span className="text-xl font-bold">
                            {occasion.sumExpected || 0}
                          </span>
                          <span>{GET_UNIT_TYPE_LABEL(occasion.unitType)}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        backgroundColor: Commons.checkIsEventFull(
                          occasion.sumExpected || 0,
                          occasion.sumAttended || 0
                        )
                          ? "#91c46e"
                          : "#fff",
                        color: Commons.checkIsEventFull(
                          occasion.sumExpected || 0,
                          occasion.sumAttended || 0
                        )
                          ? "#FFF"
                          : "#52c41a",
                        border: "1px solid #21acd7",
                        borderLeft: "none",
                        borderBottom: "none",
                      }}
                    >
                      <Row>
                        <Col span={24} className="text-center">
                          <span>
                            {GET_PARTICIPATION_UNIT_TYPE_LABEL(
                              occasion.unitType
                            )}
                          </span>
                        </Col>
                        <Col span={24} className="text-center">
                          <span className="text-xl font-bold">
                            {occasion.sumAttended || 0}
                          </span>
                          <span>{GET_UNIT_TYPE_LABEL(occasion.unitType)}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        backgroundColor: "#f0feff",
                        color: "#21acd7",
                        border: "1px solid #21acd7",
                      }}
                    >
                      <Row>
                        <Col span={24} className="text-center">
                          <span>
                            {GET_MAX_CAPACITY_LABEL(occasion.unitType)}
                          </span>
                        </Col>
                        <Col span={24} className="text-center">
                          <span className="text-xl font-bold">
                            {occasion.maxCapacity || 0}
                          </span>
                          <span>{GET_UNIT_TYPE_LABEL(occasion.unitType)}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                  <Row justify="center" className="mb-4">
                    <Col>
                      <Tag
                        color="#52c41a"
                        className="whitespace-pre-wrap text-center"
                      >
                        {occasion.start
                          ? `${moment(occasion.start).format(
                              "YYYY年M月D日"
                            )}\n${moment(occasion.start).format("HH:mm")}`
                          : "ー年ー月ー日\nー:ー"}
                      </Tag>
                    </Col>
                    <Col>
                      <Tag
                        color="#fa541c"
                        className="whitespace-pre-wrap text-center mr-0"
                      >
                        {occasion.end
                          ? `${moment(occasion.end).format(
                              "YYYY年M月D日"
                            )}\n${moment(occasion.end).format("HH:mm")}`
                          : "ー年ー月ー日\nー:ー"}
                      </Tag>
                    </Col>
                  </Row>
                </Card>
              </Link>
            </Col>
          ))}
          <Col xs={24} md={12} lg={8} xl={8} xxl={6}>
            <StyledDashedButton
              className="w-full h-full"
              style={{ minHeight: "300px" }}
              icon={<PlusOutlined />}
              type="dashed"
              onClick={showCreateModal}
            >
              新規イベント
            </StyledDashedButton>
          </Col>
        </Row>
      </Card>

      <AddOccasionsPopup
        history={history}
        showLoadingPageSpin={showLoadingPageSpin}
        hideLoadingPageSpin={hideLoadingPageSpin}
        isOpen={createModalVisible}
        onCancel={hideCreateModal}
      />
    </>
  )
}

export default withRouter(Home)
