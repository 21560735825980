import { Form, Row, Col, Input, InputNumber } from "antd"
import { useParams } from "react-router-dom"
import Switch from "./Switch"
import Group from "./Group"

const { TextArea } = Input

/**
 * @typedef {object} Params
 * @property {?string} id
 *
 * @typedef {object} CancelFeatureProps
 *
 * @param {CancelFeatureProps} props
 * @returns {JSX.Element}
 */
const CancelFeature = () => {
  /**
   * @type {Params}
   */
  const { id } = useParams()
  const keyType = id ? "Update" : "Register"
  const occasionRegisterCancel = Form.useWatch(`occasion${keyType}Cancel`)
  const forceCancel = Form.useWatch(`occasionRegisterForceCancel`)

  return (
    <>
      <Group title="キャンセル機能">
        <Row>
          <Col span={24}>
            <Form.Item
              name={`occasion${keyType}Cancel`}
              valuePropName="checked"
            >
              <Switch name={`occasion${keyType}Cancel`} />
            </Form.Item>
          </Col>
        </Row>
      </Group>

      {occasionRegisterCancel && (
        <Group title="イベント開始後キャンセル機能">
          <Row>
            <Col span={24}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return (
                    prevValues[`occasion${keyType}Cancel`] !==
                    currentValues[`occasion${keyType}Cancel`]
                  )
                }}
              >
                <Form.Item
                  name={`occasionRegisterForceCancel`}
                  label="イベント開始後キャンセル"
                  valuePropName="checked"
                >
                  <Switch name={`occasionRegisterForceCancel`} />
                </Form.Item>

                <Form.Item
                  name={`occasion${keyType}CancelTime`}
                  label="キャンセル可能時間（イベント開始前何分）"
                  rules={[
                    {
                      required: !forceCancel,
                      message: "キャンセル可能時間は必須です",
                    },
                  ]}
                >
                  <InputNumber
                    disabled={forceCancel}
                    placeholder="キャンセル可能時間"
                    min={forceCancel ? 0 : 1}
                    type="number"
                    onPressEnter={(e) => {
                      e.preventDefault()
                    }}
                    onWheel={(e) => e.target.blur()}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name={`occasion${keyType}CancelMessage`}
                label="キャンセルに関する説明文"
              >
                <TextArea
                  placeholder="キャンセルに関する説明文を入力してください"
                  autoSize
                />
              </Form.Item>
            </Col>
          </Row>
        </Group>
      )}
    </>
  )
}

export default CancelFeature
