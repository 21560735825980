import {
  ClockCircleOutlined,
  ClockCircleTwoTone,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  MinusOutlined,
  MobileOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  StopOutlined,
  StopTwoTone,
  TeamOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons"
import interactionPlugin from "@fullcalendar/interaction"
import momentPlugin from "@fullcalendar/moment"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Progress,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Tag,
  Tooltip,
  message,
} from "antd"
import * as Commons from "common/common"
import fileDownload from "js-file-download"
import Moment from "moment"
import { extendMoment } from "moment-range"
import "moment/locale/ja"
import { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { withRouter } from "react-router-dom"
import io from "socket.io-client"
import styled from "styled-components"
import OccasionModal from "./OccasionModal"
import OccasionEditModal from "../OccasionEditModal"
import OccurrencesTable from "components/OccurrencesTable"
import ImageCarousel from "components/ImageCarousel"

const moment = extendMoment(Moment)
moment.locale("ja")

const CalendarWrapper = styled.div`
  .fc .fc-button {
    padding: 0;
    background-color: #fff;
    color: #00bcd4;
    border-radius: 0;
    border: 1px solid #00bcd4;
    vertical-align: bottom;
    margin-bottom: 0.5rem;
  }

  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
  }

  .fc .fc-button-primary:focus {
    box-shadow: none;
  }

  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #00bcd4;
    border-color: #00bcd4;
  }

  .fc .fc-toolbar-title {
    display: inline-block;
    vertical-align: middle;
    margin-left: 1em;
    margin-right: 1em;
    font-size: 2em;
    font-weight: bold;
    white-space: pre-wrap;
    text-align: center;
  }

  .fc .fc-timeline-header-row-chrono .fc-timeline-slot-frame {
    justify-content: center;
  }

  .fc-datagrid-cell-frame {
    background-color: #00b3c4;
    color: white;
    font-weight: bold;
  }

  .fc .fc-resource-timeline-divider {
    width: 0;
  }

  .fc .fc-datagrid-cell-cushion {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events {
    padding-bottom: 0;
  }

  .fc-CustomPrevMonth-button,
  .fc-CustomNextMonth-button,
  .fc-CustomPrevWeek-button,
  .fc-CustomThisWeek-button,
  .fc-CustomNextWeek-button {
    padding: 0.25rem !important;
    margin: 0.25rem !important;
  }

  .fc-day-sat {
    color: #00c2ff;
  }

  .fc-day-sun {
    color: #c40055;
  }

  .fc .fc-bg-event {
    opacity: 0.7;
    font-weight: bold;
  }

  .fc-timegrid-slot {
    height: 2em !important;
    border-bottom: 0 !important;
  }

  .fc .fc-timegrid-col.fc-day-past {
    background-color: rgba(195, 195, 195, 0.2);
  }

  .fc .fc-timegrid-col.fc-day-today {
    background-color: rgba(255, 220, 40, 0.05);
    background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.05));
  }

  .fc-timegrid-event {
    border-radius: 0;
  }

  .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0;
  }
`

const StyledDashedButton = styled(Button)`
  &.ant-btn-dashed {
    border-color: #8c8c8c;
  }

  &.ant-btn-dashed:hover,
  .ant-btn-dashed:focus {
    border-color: #21acd7;
    color: #21acd7;
  }
`

const StyledProgressBar = styled(Progress)`
  .ant-progress-text {
    white-space: pre !important;
    color: #595959 !important;
    font-size: 14px !important;
  }
`

const StyledTable = styled(Table)`
  td {
    word-break: break-all;
  }

  .ant-table-thead > tr > th {
    background-color: #21acd7 !important;
    color: #fff !important;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: #46c4e3 !important;
  }
`

const StyledCanceledTable = styled(Table)`
  td {
    word-break: break-all;
  }

  .ant-table-thead > tr > th {
    background-color: #8c8c8c !important;
    color: #fff !important;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: #8c8c8c !important;
  }
`

const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #ff7875;
  }

  &.ant-switch-checked:focus {
    -webkit-box-shadow: 0 0 0 2px #fff1f0;
    box-shadow: 0 0 0 2px #fff1f0;
  }
`
const StyledSpace = styled(Space)`
  width: fit-content;
  box-sizing: border-box;
  padding-right: 10px;
  margin-bottom: 24px;

  .ant-form-item {
    margin-bottom: 0;
  }
`
const LabelFormItem = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  .label-custum::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
  .label-custum:after {
    background-color: #ff4d4f;
    color: #fff;
    content: "必須";
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    margin-left: 5px;
    padding: 4px;
    text-align: center;
  }
`

const findPercentage = (x, max) => {
  return Math.round((x / max) * 100)
}

const cardGridStyle = {
  width: "100%",
  textAlign: "center",
  color: "#21acd7",
  padding: "16px",
}

const deleteCardGridStyle = {
  width: "100%",
  textAlign: "center",
  color: "#f5222d",
  padding: "16px",
}

const { FormInstance } = Form
const { Option } = Select
const { TabPane } = Tabs
const {
  GET_PARTICIPATION_UNIT_TYPE_LABEL,
  GET_CANCELLED_UNIT_TYPE_LABEL,
  GET_UNIT_TYPE_LABEL,
  GET_MAX_CAPACITY_LABEL,
  GET_MAX_CAPACITY_CHANGE_LABEL,
  GET_MAX_PARTICIPATION_LABEL,
  GET_RESERVATION_UNIT_TYPE_LABEL,
  GET_RESERVATION_RATE_LABEL,
  GET_PARTICIPATION_RATE_LABEL,
  GET_MAX_RESERVATIONS_PER_ACCOUNT_UNIT_TYPE_LABEL,
} = Commons

const OccasionDetail = (props) => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props
  const occasionId = parseInt(props.match.params.id)
  const isMountedRef = Commons.useIsMountedRef()
  const occurrenceCreateCalendarRef = useRef()
  const currentOccurrenceRef = useRef()
  const occurrenceCreateForm = useRef(FormInstance)
  const [occurrenceForm] = Form.useForm()

  const [createParticipationForm] = Form.useForm()
  const [updateParticipationForm] = Form.useForm()
  const [confirmParticipationForm] = Form.useForm()
  const [updateOccurrenceForm] = Form.useForm()
  const [openRegistration, setOpenRegistration] = useState(false)
  const [hasBaseTicketPrice, setHasBaseTicketPrice] = useState(false)

  const [eventInterval, setEventInterval] = useState(
    Commons.BUSINESS_INTERVAL_TIME_VALUE
  )
  const [eventSubmitLoading, setEventSubmitLoading] = useState(false)
  const [participationLoading, setParticipationLoading] = useState(false)
  const [occurrenceUpdateLoading, setOccurrenceUpdateLoading] = useState(false)
  const [
    occurrenceVisibilityUpdateLoading,
    setOccurrenceVisibilityUpdateLoading,
  ] = useState(false)
  const [csvDownloadLoading, setCSVDownloadLoading] = useState(false)

  const [occurrenceModalVisible, setOccurrenceModalVisible] = useState(false)
  const [createOccurrenceModalVisible, setCreateOccurrenceModalVisible] =
    useState(false)
  const [createParticipationModalVisible, setCreateParticipationModalVisible] =
    useState(false)
  const [updateParticipationModalVisible, setUpdateParticipationModalVisible] =
    useState(false)
  const [
    confirmParticipationModalVisible,
    setConfirmParticipationModalVisible,
  ] = useState(false)
  const [updateOccurrenceModalVisible, setUpdateOccurrenceModalVisible] =
    useState(false)
  const [updateModalVisible, setUpdateModalVisible] = useState(false)

  const [occasion, setOccasion] = useState({})
  const [occurrences, setOccurrences] = useState([])
  const [tmpOccurrences, setTmpOccurrences] = useState([])
  const [currentOccurrence, setCurrentOccurrence] = useState({})

  const [dataUpdateOccurrences, setDataUpdateOccurrences] = useState({})
  const [, setForceCancel] = useState()

  const isChildAgeLimitShow = occasion.childAgeLimitShow

  const showOccurrenceModal = () => {
    setOccurrenceModalVisible(true)
  }

  const hideOccurrenceModal = () => {
    setCurrentOccurrence({})
    setOccurrenceModalVisible(false)
  }

  const showCreateOccurrenceModal = () => {
    setCreateOccurrenceModalVisible(true)
  }

  const hideCreateOccurrenceModal = () => {
    setCreateOccurrenceModalVisible(false)
  }

  const showCreateParticipationModal = () => {
    setCreateParticipationModalVisible(true)
  }

  const hideCreateParticipationModal = () => {
    setCreateParticipationModalVisible(false)
  }

  const showUpdateParticipationModal = (registration) => {
    setUpdateParticipationModalVisible(true)

    setDataUpdateOccurrences({
      participationId: registration.registrationId || null,
      expectedGuardian: registration.expectedGuardian,
      participationAdult: registration.expectedGrownup || 0,
      participationChild: registration.expectedChild || 0,
      participationPreschool: registration.expectedPreschool || 0,
      participationName:
        process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true"
          ? registration.fullName || ""
          : registration.isManual
          ? registration.fullName
          : registration.displayName,
      attachRegistrations: registration.expectedAttachRegistrations,
      telephone: registration.telephone,
      generalNote: registration.generalNote || "",
    })
    updateParticipationForm.setFieldsValue({
      participationName:
        process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true"
          ? registration.fullName || ""
          : registration.isManual
          ? registration.fullName
          : registration.displayName,
      participationAdult: registration.expectedGrownup || 0,
      participationChild: registration.expectedChild || 0,
      participationPreschool: registration.expectedPreschool || 0,
      participationId: registration.registrationId || null,
      generalNote: registration.generalNote || "",
    })
  }

  const hideUpdateParticipationModal = () => {
    setUpdateParticipationModalVisible(false)
  }

  const showConfirmParticipationModal = (registration) => {
    setConfirmParticipationModalVisible(true)

    setDataUpdateOccurrences({
      participationId: registration.registrationId || null,
      expectedGuardian: registration.attendedAttachRegistrations?.length
        ? registration.attendedGuardian
        : registration.expectedGuardian,
      participationAdult: registration.expectedGrownup || 0,
      participationChild: registration.expectedChild || 0,
      participationPreschool: registration.expectedPreschool || 0,
      participationName:
        process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true"
          ? registration.fullName || ""
          : registration.isManual
          ? registration.fullName
          : registration.displayName,
      attachRegistrations: registration.attendedAttachRegistrations?.length
        ? registration.attendedAttachRegistrations
        : registration.expectedAttachRegistrations,
      telephone: registration.telephone,
      generalNote: registration.generalNote || "",
    })

    confirmParticipationForm.setFieldsValue({
      participationName:
        process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true"
          ? registration.fullName || ""
          : registration.isManual
          ? registration.fullName
          : registration.displayName,
      participationExpectedAdult: registration.expectedGrownup || 0,
      participationExpectedChild: registration.expectedChild || 0,
      participationExpectedPreschool: registration.expectedPreschool || 0,
      participationAttendedAdult: registration.attendedGrownup || 0,
      participationAttendedChild: registration.attendedChild || 0,
      participationAttendedPreschool: registration.attendedPreschool || 0,
      participationId: registration.registrationId || null,
    })
  }

  const hideConfirmParticipationModal = () => {
    setConfirmParticipationModalVisible(false)
  }

  const showUpdateModal = () => {
    setForceCancel(occasion.canForceCancel)
    setUpdateModalVisible(true)
    setHasBaseTicketPrice(occasion.hasBaseTicketPrice)
    setOpenRegistration(occasion.isOpenRegistration)
  }

  const showUpdateOccurrenceModal = () => {
    setUpdateOccurrenceModalVisible(true)

    updateOccurrenceForm.setFieldsValue({
      occurrenceMaxParticipation: currentOccurrence.maxAttendee || "",
    })
  }

  const hideUpdateOccurrenceModal = () => {
    setUpdateOccurrenceModalVisible(false)
  }

  const hideUpdateModal = () => {
    setUpdateModalVisible(false)
  }

  const handleOccasionDelete = () => {
    Modal.confirm({
      title: "確認",
      icon: <ExclamationCircleOutlined />,
      content: "このイベントを削除してもよろしいですか？",
      okText: "削除",
      okType: "danger",
      cancelText: "閉じる",
      centered: true,
      onOk() {
        occasionDelete()
      },
    })
  }

  const handleOccurrenceDelete = () => {
    Modal.confirm({
      title: "確認",
      icon: <ExclamationCircleOutlined />,
      content: "このイベント時間を削除してもよろしいですか？",
      okText: "削除",
      okType: "danger",
      cancelText: "閉じる",
      centered: true,
      onOk() {
        occurrenceDelete()
      },
    })
  }

  const clearOccurrence = () => {
    setTmpOccurrences([])
  }

  const socketCheckOccasion = (response) => {
    if (response.occasionId && response.occasionId === occasionId) {
      return true
    } else {
      return false
    }
  }

  const socketCheckOccurrence = (response) => {
    if (
      currentOccurrenceRef.current.occurrenceId &&
      response.occurrenceId &&
      response.occurrenceId === currentOccurrenceRef.current.occurrenceId
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    fetchOccasionData()

    return () => {
      Modal.destroyAll()
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    currentOccurrenceRef.current = currentOccurrence
  }, [currentOccurrence])

  useEffect(() => {
    const socket = io(Commons.siteURL, { path: "/socket.io" })

    socket.on("updateEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (socketCheckOccasion(response)) {
          fetchOccasionData()
        }
      }
    })

    socket.on("updateOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (socketCheckOccasion(response)) {
          fetchOccasionData()

          if (socketCheckOccurrence(response)) {
            handleOccurrenceDetail(response.occurrenceId)
          }
        }
      }
    })

    socket.on("deleteOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (socketCheckOccasion(response)) {
          fetchOccasionData()
          hideOccurrenceModal()
        }
      }
    })

    socket.on("deleteEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (socketCheckOccasion(response)) {
          history.push(Commons.homeRoute)
        }
      }
    })

    socket.on("newRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (socketCheckOccasion(response)) {
          fetchOccasionData()

          if (socketCheckOccurrence(response)) {
            handleOccurrenceDetail(response.occurrenceId)
          }
        }
      }
    })

    socket.on("cancelRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (socketCheckOccasion(response)) {
          fetchOccasionData()

          if (socketCheckOccurrence(response)) {
            handleOccurrenceDetail(response.occurrenceId)
          }
        }
      }
    })

    socket.on("confirmRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (socketCheckOccasion(response)) {
          fetchOccasionData()

          if (socketCheckOccurrence(response)) {
            handleOccurrenceDetail(response.occurrenceId)
          }
        }
      }
    })

    return () => {
      socket.off("updateEvent")
      socket.off("updateOccurrence")
      socket.off("deleteOccurrence")
      socket.off("deleteEvent")
      socket.off("newRegistration")
      socket.off("cancelRegistration")
      socket.off("confirmRegistration")

      socket.disconnect()
    }

    // eslint-disable-next-line
  }, [])

  const fetchOccasionData = useCallback(() => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    Commons.axiosInstance
      .get(Commons.apiOccasions + "/" + occasionId)
      .then((response) => {
        if (isMountedRef.current && response) {
          if (response.data) {
            setOccasion(response.data)

            if (response.data.occurrences) {
              // this gives an object with dates as keys
              const groups = response.data.occurrences.reduce(
                (groups, occurrence) => {
                  // const date = moment(occurrence.startAt).format("YYYY/M/D")

                  if (!groups[occurrence.groupDate]) {
                    groups[occurrence.groupDate] = []
                  }

                  groups[occurrence.groupDate].push(occurrence)

                  return groups
                },
                {}
              )

              // Edit: to add it in the array format instead
              const groupArrays = Object.keys(groups).map((date) => {
                return {
                  date,
                  occurrences: groups[date],
                }
              })

              setOccurrences(groupArrays)
            }
          } else {
            setOccasion({})
            setOccurrences([])
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }, [
    isMountedRef,
    occasionId,
    history,
    showLoadingPageSpin,
    hideLoadingPageSpin,
  ])

  const handleOccurrenceDetail = (occurrenceId) => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    Commons.axiosInstance
      .get(Commons.apiOccurrences + "/" + occurrenceId)
      .then((response) => {
        if (isMountedRef.current && response) {
          if (response.data) {
            setCurrentOccurrence(response.data)
            showOccurrenceModal()
          } else {
            setCurrentOccurrence({})
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const occasionDelete = () => {
    showLoadingPageSpin()

    Commons.axiosInstance
      .delete(Commons.apiOccasions + "/" + occasionId)
      .then((response) => {
        if (isMountedRef.current && response) {
          message.success(Commons.successDeleteMsg)
          history.push(Commons.homeRoute)
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const handleOccurrenceCreateSubmit = (values) => {
    setEventSubmitLoading(true)

    const postData = {
      occasionId: occasionId,
      occurrences: tmpOccurrences.map((occurrence) => ({
        startAt: new Date(occurrence.startAt).toISOString(),
        endAt: new Date(occurrence.endAt).toISOString(),
        maxAttendee: occurrence.maxAttendee,
      })),
    }

    Commons.axiosInstance
      .post(Commons.apiOccurrences, postData)
      .then((response) => {
        if (isMountedRef.current && response) {
          occurrenceForm.resetFields()
          setTmpOccurrences([])
          setEventInterval(Commons.BUSINESS_INTERVAL_TIME_VALUE)

          fetchOccasionData()
          hideCreateOccurrenceModal()

          message.success(Commons.successCreateMsg)
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setEventSubmitLoading(false)
        }
      })
  }

  const occurrenceDelete = () => {
    showLoadingPageSpin()

    Commons.axiosInstance
      .delete(Commons.apiOccurrences + "/" + currentOccurrence.occurrenceId)
      .then((response) => {
        if (isMountedRef.current && response) {
          message.success(Commons.successDeleteMsg)
          fetchOccasionData()
          hideOccurrenceModal()
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const renderChildAgeLimit = (item) => {
    return (
      <Col
        span={8}
        style={{
          borderRight: "solid 1px #21acd7",
          background: "#fff",
          color: "#21acd7",
        }}
      >
        <p className="text-center p-1">
          {isChildAgeLimitShow
            ? `${item.age}歳${item.month ? `${item.month}ヶ月` : ""}`
            : "ー"}
        </p>
      </Col>
    )
  }

  const handleParticipationCancel = (registrationId, isLate = undefined) => {
    showLoadingPageSpin()

    const param = {
      data: {
        registrationId: registrationId,
        isLate: isLate,
      },
    }

    Commons.axiosInstance
      .delete(Commons.apiRegistrations + "/cancel", param)
      .then((response) => {
        fetchOccasionData()
        handleOccurrenceDetail(currentOccurrence.occurrenceId)

        if (isLate) {
          message.success(Commons.successUpdateMsg)
        } else {
          message.success(Commons.successCancelMsg)
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const handleParticipationCreateSubmit = (data) => {
    setParticipationLoading(true)

    const postData = {
      fullName: data.participationName,
      // telephone:
      //   process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true"
      //     ? data.participationPhone || 0
      //     : undefined,
      expectedGrownup: data.participationAdult || 0,
      expectedChild: data.participationChild || 0,
      expectedPreschool: data.participationPreschool || 0,
      occurrenceId: currentOccurrence.occurrenceId,
      expectedGuardian: 0,
    }

    Commons.axiosInstance
      .post(Commons.apiRegistrations + "/new", postData)
      .then((response) => {
        hideCreateParticipationModal()
        fetchOccasionData()
        handleOccurrenceDetail(currentOccurrence.occurrenceId)

        message.success(Commons.successCreateMsg)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setParticipationLoading(false)
        }
      })
  }

  const handleParticipationUpdateSubmit = (data) => {
    setParticipationLoading(true)

    const putData = {
      expectedGrownup: data.participationAdult || 0,
      expectedChild: data.participationChild || 0,
      expectedPreschool: data.participationPreschool || 0,
      registrationId: data.participationId,
      expectedGuardian: 0,
    }

    Commons.axiosInstance
      .put(Commons.apiRegistrations + "/edit", putData)
      .then((response) => {
        hideUpdateParticipationModal()
        fetchOccasionData()
        handleOccurrenceDetail(currentOccurrence.occurrenceId)

        message.success(Commons.successUpdateMsg)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setParticipationLoading(false)
        }
      })
  }

  const handleParticipationConfirmSubmit = (data) => {
    setParticipationLoading(true)

    const putData = {
      expectedGrownup: data.participationExpectedAdult || 0,
      expectedChild: data.participationExpectedChild || 0,
      expectedPreschool: data.participationExpectedPreschool || 0,
      expectedGuardian: data.expectedGuardian || 0,
      attendedGuardian: data.attendedGuardian || 0,
      attendedGrownup: data.participationAttendedAdult || 0,
      attendedChild: data.participationAttendedChild || 0,
      attendedPreschool: data.participationAttendedPreschool || 0,
      registrationId: data.participationId,
    }

    Commons.axiosInstance
      .put(Commons.apiRegistrations + "/edit", putData)
      .then((response) => {
        hideConfirmParticipationModal()
        fetchOccasionData()
        handleOccurrenceDetail(currentOccurrence.occurrenceId)

        message.success(Commons.successSaveMsg)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setParticipationLoading(false)
        }
      })
  }

  const handleOccurrenceUpdateSubmit = (data) => {
    if (
      data.occurrenceMaxParticipation &&
      data.occurrenceMaxParticipation > 0
    ) {
      setOccurrenceUpdateLoading(true)

      const putData = {
        maxAttendee: data.occurrenceMaxParticipation || "",
      }

      Commons.axiosInstance
        .put(
          Commons.apiOccurrences + "/" + currentOccurrence.occurrenceId,
          putData
        )
        .then((response) => {
          hideUpdateOccurrenceModal()
          fetchOccasionData()
          handleOccurrenceDetail(currentOccurrence.occurrenceId)

          message.success(Commons.successUpdateMsg)
        })
        .catch((error) => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 409) {
            message.warning(Commons.warnNotEnoughMaxParticipantMsg)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            setOccurrenceUpdateLoading(false)
          }
        })
    } else {
      message.warning(Commons.warnNotZeroParticipantMsg)
    }
  }

  const handleOccurrenceVisibilityChange = (visibility) => {
    setOccurrenceVisibilityUpdateLoading(true)

    const putData = {
      isDisplayed: visibility,
    }

    Commons.axiosInstance
      .put(
        Commons.apiOccurrences + "/" + currentOccurrence.occurrenceId,
        putData
      )
      .then((response) => {
        fetchOccasionData()
        handleOccurrenceDetail(currentOccurrence.occurrenceId)

        message.success(Commons.successUpdateMsg)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 409) {
          message.warning(Commons.warnNotEnoughMaxParticipantMsg)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setOccurrenceVisibilityUpdateLoading(false)
        }
      })
  }

  const handleOccasionCsvDownload = (e) => {
    setCSVDownloadLoading(true)

    const postData = {
      mode: e?.key,
    }

    Commons.axiosInstance
      .post(Commons.apiOccasions + "/" + occasionId + "/csv", postData)
      .then((response) => {
        fileDownload(
          "\uFEFF" + response.data,
          `${moment().format("YYYYMMDD[_]HHmm")}_${
            occasion.title || "ー"
          }の${Commons.getCsvModeName(e?.key)}リスト.csv`,
          "text/csv"
        )
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setCSVDownloadLoading(false)
        }
      })
  }

  const handleOccurrenceCsvDownload = (e) => {
    setCSVDownloadLoading(true)

    const postData = {
      mode: e?.key,
    }

    Commons.axiosInstance
      .post(
        Commons.apiOccurrences + "/" + currentOccurrence.occurrenceId + "/csv",
        postData
      )
      .then((response) => {
        fileDownload(
          "\uFEFF" + response.data,
          `${moment().format("YYYYMMDD[_]HHmm")}_${
            occasion.title || "ー"
          }の${moment(currentOccurrence.startAt).format(
            "YYYY年M月D日HH時mm分"
          )}の${Commons.getCsvModeName(e?.key)}リスト.csv`,
          "text/csv"
        )
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setCSVDownloadLoading(false)
        }
      })
  }

  const showOccurrenceHandler = () => {
    handleOccurrenceVisibilityChange(true)
  }
  const hideOccurrenceHandler = () => {
    handleOccurrenceVisibilityChange(false)
  }

  const styleExpected = {
    border: "1px solid #21acd7",
    color: "#21acd7",
    backgroundColor: "#f0feff",
    marginRight: 0,
  }

  const styleAttended = {
    border: "1px solid #52c41a",
    color: "#52c41a",
    backgroundColor: "#f6ffed",
    marginRight: 0,
  }

  const styleCanceled = {
    border: "1px solid #8c8c8c",
    color: "#8c8c8c",
    backgroundColor: "#fafafa",
    marginRight: 0,
  }

  const checkRegistrationStyle = (registration) => {
    if (registration.deletedAt) return styleCanceled
    if ((registration.attended || 0) === 0) {
      return styleExpected
    } else {
      return styleAttended
    }
  }

  const registeredColumns = [
    {
      title: "取引ID",
      dataIndex: "dgftOrderId",
      align: "center",
      width: 250,
      render: (dgftOrderId) =>
        dgftOrderId ? (
          <a
            href={
              "https://admin3.veritrans.co.jp/portal/settlements/credit_cards/" +
              dgftOrderId
            }
            target="_blank"
            rel="noreferrer noopener"
            className="text-xs"
          >
            {dgftOrderId}
          </a>
        ) : (
          "ー"
        ),
    },
    {
      title: "LINE名",
      dataIndex: "displayName",
      align: "center",
      width: 70,
      render: (registration) => <p>{registration || "ー"}</p>,
    },
    {
      title: currentOccurrence?.Occasion?.isSimpleCount ? "名前" : "保護者氏名",
      dataIndex: "fullName",
      align: "center",
      width: 150,
      render: (registration) => (
        <Tooltip
          color="red"
          title={
            currentOccurrence?.Occasion?.isSimpleCount
              ? registration.expectedChild > 0
                ? "子供の登録があります"
                : undefined
              : undefined
          }
        >
          {currentOccurrence?.Occasion?.isSimpleCount ? (
            registration.expectedChild > 0 ? (
              <Badge status="error" />
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true"
            ? registration.fullName || ""
            : registration.isManual
            ? registration.fullName
            : registration.displayName}
        </Tooltip>
      ),
    },
    {
      title: currentOccurrence?.Occasion?.isSimpleCount
        ? "予約人数/参加者数"
        : "予約人数/参加者",
      align: "center",
      children: occasion.isSimpleCount
        ? [
            {
              title: (
                <>
                  {occasion.isSimpleCount ? "参加者" : "大人"}
                  <Tooltip
                    placement="bottom"
                    trigger={["hover", "click"]}
                    title="(予約人数/参加者数)"
                  >
                    <Button
                      size="small"
                      type="link"
                      shape="circle"
                      icon={
                        <QuestionCircleOutlined
                          style={{
                            color: "#FFF",
                          }}
                        />
                      }
                    />
                  </Tooltip>
                </>
              ),
              dataIndex: "grownup",
              align: "center",
              render: (registration) => (
                <Tag style={checkRegistrationStyle(registration)}>
                  {registration.expectedGrownup}/{registration.attendedGrownup}
                  人
                </Tag>
              ),
            },
            ...(occasion.isSimpleCount
              ? []
              : [
                  {
                    title: (
                      <>
                        {"子供"}
                        <Tooltip
                          placement="bottom"
                          trigger={["hover", "click"]}
                          title="(予約人数/参加者数)"
                        >
                          <Button
                            size="small"
                            type="link"
                            shape="circle"
                            icon={
                              <QuestionCircleOutlined
                                style={{
                                  color: "#FFF",
                                }}
                              />
                            }
                          />
                        </Tooltip>
                      </>
                    ),
                    dataIndex: "child",
                    align: "center",
                    render: (registration) => (
                      <Tag style={checkRegistrationStyle(registration)}>
                        {registration.expectedChild}/
                        {registration.attendedChild}人
                      </Tag>
                    ),
                  },
                ]),
            {
              title: (
                <>
                  {occasion.isSimpleCount ? "同伴者" : "未就学児"}
                  <Tooltip
                    placement="bottom"
                    trigger={["hover", "click"]}
                    title="(予約人数/参加者数)"
                  >
                    <Button
                      size="small"
                      type="link"
                      shape="circle"
                      icon={
                        <QuestionCircleOutlined
                          style={{
                            color: "#FFF",
                          }}
                        />
                      }
                    />
                  </Tooltip>
                </>
              ),
              dataIndex: "preschool",
              align: "center",
              render: (registration) => (
                <Tag style={checkRegistrationStyle(registration)}>
                  {registration.expectedPreschool}/
                  {registration.attendedPreschool}人
                </Tag>
              ),
            },
            {
              title: (
                <>
                  {"全"}
                  <Tooltip
                    placement="bottom"
                    trigger={["hover", "click"]}
                    className="whitespace-pre-wrap"
                    title={"(予約人数/参加者数)"}
                  >
                    <Button
                      size="small"
                      type="link"
                      shape="circle"
                      icon={
                        <QuestionCircleOutlined
                          style={{
                            color: "#FFF",
                          }}
                        />
                      }
                    />
                  </Tooltip>
                </>
              ),
              dataIndex: "total",
              align: "center",
              render: (registration) => (
                <Tag style={checkRegistrationStyle(registration)}>
                  {registration.expected}/{registration.attended}人
                </Tag>
              ),
            },
          ]
        : [
            {
              title: "子ども",
              dataIndex: "total",
              align: "center",
              render: (registration) => (
                <Tag style={checkRegistrationStyle(registration)}>
                  {registration.expected}/
                  {registration?.attendedAttachRegistrations.length || 0}人
                </Tag>
              ),
            },
          ],
    },
    {
      title: "登録元",
      dataIndex: "source",
      align: "center",
      render: (registration) => (
        <>
          <Tag style={{ marginRight: 0 }}>
            {registration.isManual === 1 ? "システム" : "LINE"}
          </Tag>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      align: "center",
      width: 200,
      render: (registration) =>
        !registration.deletedAt && (
          <Fragment key={registration.registrationId}>
            <Tooltip title="参加">
              <Button
                style={{ margin: "0.25rem" }}
                icon={<TeamOutlined />}
                onClick={() => showConfirmParticipationModal(registration)}
              />
            </Tooltip>
            <Tooltip title="変更">
              <Button
                style={{ margin: "0.25rem" }}
                icon={<EditOutlined />}
                onClick={() => showUpdateParticipationModal(registration)}
              />
            </Tooltip>
            {registration && registration.attended === 0 && (
              <>
                <Popconfirm
                  title="予約を不参加にしてもよろしいでしょうか？"
                  onConfirm={() =>
                    handleParticipationCancel(registration.registrationId, true)
                  }
                  okText="不参加"
                  cancelText="閉じる"
                  okType="danger"
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                >
                  <Tooltip title="不参加">
                    <Button
                      style={{ margin: "0.25rem" }}
                      icon={<UsergroupDeleteOutlined />}
                      danger
                    />
                  </Tooltip>
                </Popconfirm>
                <Popconfirm
                  title="予約をキャンセルしてもよろしいでしょうか？"
                  onConfirm={() =>
                    handleParticipationCancel(registration.registrationId)
                  }
                  okText="キャンセル"
                  cancelText="閉じる"
                  okType="danger"
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                >
                  <Tooltip title="キャンセル">
                    <Button
                      style={{ margin: "0.25rem" }}
                      icon={<StopOutlined />}
                      danger
                    />
                  </Tooltip>
                </Popconfirm>
              </>
            )}
          </Fragment>
        ),
    },
  ]

  const canceledColumns = [
    {
      title: "取引ID",
      dataIndex: "dgftOrderId",
      align: "center",
      width: 120,
      render: (dgftOrderId) =>
        dgftOrderId ? (
          <a
            href={
              "https://admin3.veritrans.co.jp/portal/settlements/credit_cards/" +
              dgftOrderId
            }
            target="_blank"
            rel="noreferrer noopener"
            className="text-xs"
          >
            {dgftOrderId}
          </a>
        ) : (
          "ー"
        ),
    },
    {
      title: "LINE名",
      dataIndex: "displayName",
      align: "center",
      width: 70,
      render: (registration) => <p>{registration || "ー"}</p>,
    },
    {
      title: occasion.isSimpleCount ? "名前" : "保護者氏名",
      dataIndex: "fullName",
      align: "center",
      render: (registration) => (
        <>
          {process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true"
            ? registration.fullName || ""
            : registration.isManual
            ? registration.fullName
            : registration.displayName}
        </>
      ),
    },
    {
      title: occasion.isSimpleCount ? "予約人数" : "予約人数/参加者",
      align: "center",
      children: occasion.isSimpleCount
        ? [
            {
              title: <>{occasion.isSimpleCount ? "参加者" : "大人"}</>,
              dataIndex: "grownup",
              align: "center",
              render: (registration) => (
                <Tag style={styleCanceled}>
                  {registration.expectedGrownup}人
                </Tag>
              ),
            },
            ...(occasion.isSimpleCount
              ? []
              : [
                  {
                    title: <>{"子供"}</>,
                    dataIndex: "child",
                    align: "center",
                    render: (registration) => (
                      <Tag style={styleCanceled}>
                        {registration.expectedChild}人
                      </Tag>
                    ),
                  },
                ]),
            {
              title: <>{occasion.isSimpleCount ? "同伴者" : "未就学児"}</>,
              dataIndex: "preschool",
              align: "center",
              render: (registration) => (
                <Tag style={styleCanceled}>
                  {registration.expectedPreschool}人
                </Tag>
              ),
            },
            {
              title: <>{"全"}</>,
              dataIndex: "total",
              align: "center",
              render: (registration) => (
                <Tag style={styleCanceled}>{registration.expected}人</Tag>
              ),
            },
          ]
        : [
            {
              title: "子ども",
              dataIndex: "total",
              align: "center",
              render: (registration) => (
                <Tag style={checkRegistrationStyle(registration)}>
                  {registration.expected}/
                  {registration?.attendedAttachRegistrations?.length || 0}人
                </Tag>
              ),
            },
          ],
    },
    {
      title: "登録元",
      dataIndex: "source",
      align: "center",
      render: (registration) => (
        <>
          <Tag style={{ marginRight: 0 }}>
            {registration.isManual === 1 ? "システム" : "LINE"}
          </Tag>
        </>
      ),
    },
    {
      title: "キャンセル日付",
      dataIndex: "cancelledAt",
      align: "center",
      render: (cancelledAt) => (
        <>
          {cancelledAt
            ? moment(cancelledAt).format("YYYY年M月D日 HH時mm分")
            : "ー"}
        </>
      ),
    },
  ]

  const csvDownloadMenuItems = [
    {
      key: "all",
      icon: <TeamOutlined className="text-[#21acd7]" />,
      label: Commons.getCsvModeName("all"),
    },
    {
      key: "attended",
      icon: <UserAddOutlined className="text-[#52c41a]" />,
      label: Commons.getCsvModeName("attended"),
    },
    {
      key: "unattended",
      icon: <UserDeleteOutlined className="text-[#fa541c]" />,
      label: Commons.getCsvModeName("unattended"),
    },
    {
      key: "cancelled",
      icon: <UserSwitchOutlined className="text-[#fa8c16]" />,
      label: Commons.getCsvModeName("cancelled"),
    },
  ]

  const occasionCsvDownloadMenu = (
    <Menu onClick={handleOccasionCsvDownload} items={csvDownloadMenuItems} />
  )

  const occurrenceCsvDownloadMenu = (
    <Menu onClick={handleOccurrenceCsvDownload} items={csvDownloadMenuItems} />
  )

  return (
    <>
      <Row>
        <Col
          xl={8}
          lg={10}
          md={12}
          xs={24}
          className="border-r border-gray-200 p-4"
        >
          <Row justify="end">
            <Col>
              <Button icon={<EditOutlined />} onClick={showUpdateModal}>
                イベント情報変更
              </Button>
            </Col>
          </Row>
          <Divider />
          <Row justify="center" gutter={[0, 8]}>
            <Col span={24} className="text-center">
              <ImageCarousel occasion={occasion} />
            </Col>
            <Col span={24} className="text-center">
              <span className="text-sm font-bold">{occasion.title || ""}</span>
            </Col>
            <Col span={24} className="text-center">
              <span className="text-sm whitespace-pre-wrap">
                {occasion.description || ""}
              </span>
            </Col>
            <Divider />
            {occasion.hasBaseTicketPrice && (
              <Col span={24} className="text-center">
                <span className="text-sm">
                  イベント参加料金：
                  {Commons.displayJpyPrice(occasion.baseTicketPrice)}
                </span>
              </Col>
            )}
            <Col span={24} className="text-center">
              <Tag
                icon={<MobileOutlined />}
                className="text-center whitespace-pre-wrap m-1"
                style={{
                  backgroundColor: "#f0feff",
                  color: "#21acd7",
                  border: "1px solid #21acd7",
                }}
              >
                {GET_MAX_RESERVATIONS_PER_ACCOUNT_UNIT_TYPE_LABEL(
                  occasion.unitType,
                  occasion.maxPerPerson
                )}
              </Tag>
              {/* <Tag
                icon={
                  occasion.isPreschoolCount ? (
                    <UserAddOutlined />
                  ) : (
                    <UserDeleteOutlined />
                  )
                }
                className="text-center whitespace-pre-wrap m-1"
                style={{
                  backgroundColor: occasion.isPreschoolCount
                    ? "#f0feff"
                    : "#f6ffed",
                  color: occasion.isPreschoolCount ? "#21acd7" : "#52c41a",
                  border: occasion.isPreschoolCount
                    ? "1px solid #21acd7"
                    : "1px solid #52c41a",
                }}
              >
                {`${
                  occasion.isPreschoolCount
                    ? "同伴者も\nカウント"
                    : " 同伴者を\nカウントされません"
                }`}
              </Tag> */}
              <Tag
                icon={
                  occasion.canOverlap ? (
                    <UsergroupAddOutlined />
                  ) : (
                    <UsergroupDeleteOutlined />
                  )
                }
                className="text-center whitespace-pre-wrap m-1"
                style={{
                  backgroundColor: occasion.canOverlap ? "#f6ffed" : "#f0feff",
                  color: occasion.canOverlap ? "#52c41a" : "#21acd7",
                  border: occasion.canOverlap
                    ? "1px solid #52c41a"
                    : "1px solid #21acd7",
                }}
              >
                {occasion.canOverlap
                  ? "他のイベントも\n同時に予約することが可能"
                  : "他のイベントも\n同時に予約することが不可能"}
              </Tag>
              <Tag
                className="text-center whitespace-pre-wrap m-1"
                style={{
                  backgroundColor:
                    occasion.canCancel && occasion.canForceCancel
                      ? "#f6ffed"
                      : occasion.canCancel && !occasion.canForceCancel
                      ? "#f6ffed"
                      : "#fff2e8",
                  color:
                    occasion.canCancel && occasion.canForceCancel
                      ? "#52c41a"
                      : occasion.canCancel && !occasion.canForceCancel
                      ? "#52c41a"
                      : "#fa541c",
                  border:
                    occasion.canCancel && occasion.canForceCancel
                      ? "1px solid #52c41a"
                      : occasion.canCancel
                      ? "1px solid #52c41a"
                      : "1px solid #fa541c",
                }}
              >
                {/* {occasion.canCancel && !occasion.canForceCancel
                  ? "キャンセル\n可能"
                  : "キャンセル\n不可能"} */}
                {occasion.canCancel && occasion.canForceCancel
                  ? "イベント開始後も\nキャンセルできる"
                  : occasion.canCancel && !occasion.canForceCancel
                  ? "キャンセル\n可能"
                  : "キャンセル\n不可能"}
              </Tag>
              {occasion.canCancel && !occasion.canForceCancel ? (
                <Tag
                  icon={<ClockCircleOutlined />}
                  className="text-center whitespace-pre-wrap m-1"
                  style={{
                    backgroundColor: "#f6ffed",
                    color: "#52c41a",
                    border: "1px solid #52c41a",
                  }}
                >
                  {`キャンセル\n可能時間：${occasion.timeCancel || 0}分`}
                </Tag>
              ) : (
                ""
              )}

              {occasion.isOpenRegistration ? (
                <Tag
                  className="text-center whitespace-pre-wrap m-1"
                  style={{
                    backgroundColor: "#f0feff",
                    color: "#21acd7",
                    border: "1px solid #21acd7",
                  }}
                >
                  <div>
                    <div>応募できる期間</div>
                    <Space>
                      <Space direction="vertical" size={0}>
                        <div>
                          {moment(occasion.startRegister).format(
                            "YYYY年M月D日"
                          )}
                        </div>
                        <div>
                          {moment(occasion.startRegister).format("HH時mm分")}
                        </div>
                      </Space>
                      <div>~</div>
                      <Space direction="vertical" size={0}>
                        <div>
                          {moment(occasion.endRegister).format("YYYY年M月D日")}
                        </div>
                        <div>
                          {moment(occasion.endRegister).format("HH時mm分")}
                        </div>
                      </Space>
                    </Space>
                  </div>
                </Tag>
              ) : (
                ""
              )}
            </Col>
            <Divider />
            <Col span={24} className="text-center">
              <span className="whitespace-pre-wrap">
                {occasion.textCancel ||
                  "キャンセルについてメッセージ設定されていないです。"}
              </span>
            </Col>
            <Divider />
            イベントの単位
            <Col span={24}>
              <Row justify="center" gutter={[24, 24]}>
                <Col>
                  <Row>
                    <Col
                      span={8}
                      style={{
                        backgroundColor: Commons.checkIsEventFull(
                          occasion.maxCapacity || 0,
                          occasion.sumExpected || 0
                        )
                          ? "#7cc7d6"
                          : "#c9faff",
                        color: Commons.checkIsEventFull(
                          occasion.maxCapacity || 0,
                          occasion.sumExpected || 0
                        )
                          ? "#FFF"
                          : "#1286b0",
                        border: "1px solid #1286b0",
                      }}
                      className="border-b-0"
                    >
                      <Row>
                        <Col span={24} className="text-center">
                          <span>
                            {GET_RESERVATION_UNIT_TYPE_LABEL(occasion.unitType)}
                          </span>
                        </Col>
                        <Col span={24} className="text-center">
                          <span className="text-xl font-bold">
                            {occasion.sumExpected || 0}
                          </span>
                          <span>{GET_UNIT_TYPE_LABEL(occasion.unitType)}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        backgroundColor: Commons.checkIsEventFull(
                          occasion.sumExpected || 0,
                          occasion.sumAttended || 0
                        )
                          ? "#91c46e"
                          : "#FFF",
                        color: Commons.checkIsEventFull(
                          occasion.sumExpected || 0,
                          occasion.sumAttended || 0
                        )
                          ? "#FFF"
                          : "#389e0d",
                        border: "1px solid #1286b0",
                      }}
                      className="border-b-0 border-l-0"
                    >
                      <Row>
                        <Col span={24} className="text-center">
                          <span>
                            {GET_PARTICIPATION_UNIT_TYPE_LABEL(
                              occasion.unitType
                            )}
                          </span>
                        </Col>
                        <Col span={24} className="text-center">
                          <span className="text-xl font-bold">
                            {occasion.sumAttended || 0}
                          </span>
                          <span>{GET_UNIT_TYPE_LABEL(occasion.unitType)}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        color: "#8c8c8c",
                        backgroundColor: "#fafafa",
                        border: "1px solid #1286b0",
                      }}
                      className="border-b-0 border-l-0"
                    >
                      <Row>
                        <Col span={24} className="text-center">
                          <span>
                            {GET_CANCELLED_UNIT_TYPE_LABEL(occasion.unitType)}
                          </span>
                        </Col>
                        <Col span={24} className="text-center">
                          <span className="text-xl font-bold">
                            {occasion.sumCancelled || 0}
                          </span>
                          <span>{GET_UNIT_TYPE_LABEL(occasion.unitType)}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        backgroundColor: "#c9faff",
                        color: "#1286b0",
                        border: "1px solid #1286b0",
                      }}
                    >
                      <Row>
                        <Col span={24} className="text-center">
                          <span>
                            {GET_MAX_CAPACITY_LABEL(occasion.unitType)}
                          </span>
                        </Col>
                        <Col span={24} className="text-center">
                          <span className="text-xl font-bold">
                            {occasion.maxCapacity || 0}
                          </span>
                          <span>{GET_UNIT_TYPE_LABEL(occasion.unitType)}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 0]} justify="center">
                    <Col>
                      <Tooltip
                        title={GET_RESERVATION_RATE_LABEL(occasion.unitType)}
                      >
                        <StyledProgressBar
                          type="dashboard"
                          strokeColor="#9ff0fc"
                          percent={findPercentage(
                            occasion.sumExpected || 0,
                            occasion.maxCapacity || 0
                          )}
                          format={(percent) => `予約率\u000A\u000A${percent}%`}
                        />
                      </Tooltip>
                    </Col>
                    <Col>
                      <Tooltip
                        title={GET_PARTICIPATION_RATE_LABEL(occasion.unitType)}
                      >
                        <StyledProgressBar
                          type="dashboard"
                          strokeColor="#b7eb8f"
                          percent={findPercentage(
                            occasion.sumAttended || 0,
                            occasion.sumExpected || 0
                          )}
                          format={(percent) =>
                            `参加者率\u000A\u000A${percent}%`
                          }
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider />
              <Row justify="center" className="my-4">
                <Col>
                  <Dropdown
                    overlay={occasionCsvDownloadMenu}
                    trigger={["click"]}
                    arrow
                  >
                    <Button
                      icon={<DownloadOutlined />}
                      loading={csvDownloadLoading}
                    >
                      CSVダウンロード
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xl={16} lg={14} md={12} xs={24} className="p-4">
          <Row>
            <Col span={24}>
              <Row>
                <Col
                  xxl={6}
                  xl={8}
                  sm={12}
                  xs={24}
                  style={{ paddingRight: "4px" }}
                >
                  <StyledDashedButton
                    className="w-full h-full"
                    style={{ minHeight: "140px" }}
                    icon={<PlusOutlined />}
                    type="dashed"
                    onClick={showCreateOccurrenceModal}
                  >
                    新規イベント時間
                  </StyledDashedButton>
                </Col>
              </Row>
              <Divider />
              {/* {occurrences.length > 0 ? (
                occurrences.map((group, i) => (
                  <div key={group.date} className="mb-4">
                    <Row>
                      <Col span={24} className="mb-2">
                        <span className="text-base font-bold">
                          {moment(group.date).format("YYYY年M月D日 (ddd)")}
                        </span>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[8, 8]}>
                          {group.occurrences.map((occurrence) => (
                            <Col
                              xxl={6}
                              xl={8}
                              sm={12}
                              xs={24}
                              key={occurrence.occurrenceId}
                            >
                              <Tooltip
                                color="red"
                                title={
                                  occurrence.hasChildError
                                    ? `子供の登録：${occurrence.hasChildError}`
                                    : undefined
                                }
                              >
                                <Badge
                                  count={
                                    occurrence.hasChildError
                                      ? occurrence.hasChildError
                                      : 0
                                  }
                                >
                                  <Card
                                    hoverable
                                    bordered={false}
                                    bodyStyle={{ padding: 0 }}
                                    onClick={() =>
                                      handleOccurrenceDetail(
                                        occurrence.occurrenceId
                                      )
                                    }
                                  >
                                    <Row>
                                      <Col
                                        span={24}
                                        style={{
                                          backgroundColor:
                                            occurrence.isDisplayed
                                              ? "#f0feff"
                                              : "#ffffff",
                                          color: occurrence.isDisplayed
                                            ? "#21acd7"
                                            : "#bfbfbf",
                                          border: `1px solid ${
                                            occurrence.isDisplayed
                                              ? "#21acd7"
                                              : "#bfbfbf"
                                          }`,
                                          textAlign: "end",
                                          padding: "0.25rem",
                                        }}
                                        className="border-b-0"
                                      >
                                        <span className="font-bold">
                                          {occurrence.startAt
                                            ? moment(occurrence.startAt).format(
                                                "HH:mm"
                                              )
                                            : "ー：ー"}
                                        </span>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        span={12}
                                        style={{
                                          backgroundColor:
                                            occurrence.isDisplayed
                                              ? Commons.checkIsEventFull(
                                                  occurrence.maxAttendee || 0,
                                                  occurrence.sumExpected || 0
                                                )
                                                ? "#7cc7d6"
                                                : "#f0feff"
                                              : "#ffffff",
                                          color: occurrence.isDisplayed
                                            ? Commons.checkIsEventFull(
                                                occurrence.maxAttendee || 0,
                                                occurrence.sumExpected || 0
                                              )
                                              ? "#FFF"
                                              : "#21acd7"
                                            : "#bfbfbf",
                                          border: `1px solid ${
                                            occurrence.isDisplayed
                                              ? "#21acd7"
                                              : "#bfbfbf"
                                          }`,
                                        }}
                                        className="border-b-0"
                                      >
                                        <Row>
                                          <Col
                                            span={24}
                                            className="text-center"
                                          >
                                            <span>予約人数</span>
                                          </Col>
                                          <Col
                                            span={24}
                                            className="text-center"
                                          >
                                            <span className="text-xl font-bold">
                                              {occurrence.sumExpected || 0}
                                            </span>
                                            <span>人</span>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col
                                        span={12}
                                        style={{
                                          backgroundColor:
                                            occurrence.isDisplayed
                                              ? Commons.checkIsEventFull(
                                                  occurrence.sumExpected || 0,
                                                  occurrence.sumAttended || 0
                                                )
                                                ? "#91c46e"
                                                : "#fff"
                                              : "#ffffff",
                                          color: occurrence.isDisplayed
                                            ? Commons.checkIsEventFull(
                                                occurrence.sumExpected || 0,
                                                occurrence.sumAttended || 0
                                              )
                                              ? "#FFF"
                                              : "#52c41a"
                                            : "#bfbfbf",
                                          border: `1px solid ${
                                            occurrence.isDisplayed
                                              ? "#21acd7"
                                              : "#bfbfbf"
                                          }`,
                                        }}
                                        className="border-b-0 border-l-0"
                                      >
                                        <Row>
                                          <Col
                                            span={24}
                                            className="text-center"
                                          >
                                            <span>参加者数</span>
                                          </Col>
                                          <Col
                                            span={24}
                                            className="text-center"
                                          >
                                            <span className="text-xl font-bold">
                                              {occurrence.sumAttended || 0}
                                            </span>
                                            <span>人</span>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col
                                        span={24}
                                        style={{
                                          backgroundColor:
                                            occurrence.isDisplayed
                                              ? "#f0feff"
                                              : "#ffffff",
                                          color: occurrence.isDisplayed
                                            ? "#21acd7"
                                            : "#bfbfbf",
                                          border: `1px solid ${
                                            occurrence.isDisplayed
                                              ? "#21acd7"
                                              : "#bfbfbf"
                                          }`,
                                        }}
                                      >
                                        <Row>
                                          <Col
                                            span={24}
                                            className="text-center"
                                          >
                                            <span>参加可能最大人数</span>
                                          </Col>
                                          <Col
                                            span={24}
                                            className="text-center"
                                          >
                                            <span className="text-xl font-bold">
                                              {occurrence.maxAttendee || 0}
                                            </span>
                                            <span>人</span>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Card>
                                </Badge>
                              </Tooltip>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                    {occurrences.length - 1 > i ? <Divider /> : ""}
                  </div>
                ))
              ) : (
                <p className="text-center">予約時間がありません。</p>
              )} */}
              <OccurrencesTable
                occurrences={occurrences}
                socketCheckOccasion={socketCheckOccasion}
                socketCheckOccurrence={socketCheckOccurrence}
                setOccurrences={setOccurrences}
                showCreateOccurrenceModal={showCreateOccurrenceModal}
                handleOccurrenceDetail={handleOccurrenceDetail}
                occasion={occasion}
                occasionId={occasionId}
              />
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24} className="px-4 pb-4 pt-2">
          <Row justify="center">
            <Col>
              <Button
                onClick={handleOccasionDelete}
                icon={<DeleteOutlined />}
                danger
              >
                イベント削除
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <OccasionEditModal
        updateModalVisible={updateModalVisible}
        occasion={occasion}
        occasionId={occasionId}
        history={history}
        fetchOccasionData={fetchOccasionData}
        hideUpdateModal={hideUpdateModal}
      />
      <Modal
        visible={occurrenceModalVisible}
        title="イベント詳細"
        onCancel={hideOccurrenceModal}
        footer={null}
        width={720}
        centered
      >
        <Row gutter={[0, 16]} justify="center">
          <Col span={24}>
            <Row className="mb-4" justify="center">
              <Col>
                {currentOccurrence.isDisplayed ? (
                  <Tag
                    icon={<EyeOutlined />}
                    className="p-2"
                    style={{
                      backgroundColor: "#f6ffed",
                      color: "#52c41a",
                      border: "1px solid #52c41a",
                      fontSize: "1em",
                    }}
                  >
                    表示中
                  </Tag>
                ) : (
                  <Tag
                    icon={<EyeInvisibleOutlined />}
                    className="p-2"
                    style={{
                      backgroundColor: "#fff2e8",
                      color: "#fa541c",
                      border: "1px solid #fa541c",
                      fontSize: "1em",
                    }}
                  >
                    非表示中
                  </Tag>
                )}
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                {currentOccurrence.isDisplayed ? (
                  <Button
                    icon={<EyeInvisibleOutlined />}
                    onClick={hideOccurrenceHandler}
                    loading={occurrenceVisibilityUpdateLoading}
                    className="mb-1"
                    danger
                  >
                    このイベントを非表示にする
                  </Button>
                ) : (
                  <Button
                    icon={<EyeOutlined />}
                    onClick={showOccurrenceHandler}
                    loading={occurrenceVisibilityUpdateLoading}
                    className="mb-1"
                  >
                    このイベントを表示する
                  </Button>
                )}
              </Col>
              <Col>
                <Button
                  onClick={showUpdateOccurrenceModal}
                  className="mb-1"
                  icon={<EditOutlined />}
                >
                  {GET_MAX_CAPACITY_CHANGE_LABEL(occasion.unitType)}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  backgroundColor: currentOccurrence.isDisplayed
                    ? "#f0feff"
                    : "#ffffff",
                  color: currentOccurrence.isDisplayed ? "#21acd7" : "#bfbfbf",
                  border: `1px solid ${
                    currentOccurrence.isDisplayed ? "#21acd7" : "#bfbfbf"
                  }`,
                  textAlign: "end",
                  padding: "0.5rem",
                }}
                className="border-b-0"
              >
                <Row justify="center" gutter={[8, 0]}>
                  <Col>
                    <span className="font-bold">
                      {moment(currentOccurrence.startAt).format("YYYY年M月D日")}
                    </span>
                  </Col>
                  <Col>
                    <span className="font-bold">
                      {moment(currentOccurrence.startAt).format("HH:mm")}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                span={8}
                style={{
                  backgroundColor: currentOccurrence.isDisplayed
                    ? Commons.checkIsEventFull(
                        currentOccurrence.maxAttendee || 0,
                        currentOccurrence.sumExpected || 0
                      )
                      ? "#7cc7d6"
                      : "#f0feff"
                    : "#ffffff",
                  color: currentOccurrence.isDisplayed
                    ? Commons.checkIsEventFull(
                        currentOccurrence.maxAttendee || 0,
                        currentOccurrence.sumExpected || 0
                      )
                      ? "#FFF"
                      : "#21acd7"
                    : "#bfbfbf",
                  border: `1px solid ${
                    currentOccurrence.isDisplayed ? "#21acd7" : "#bfbfbf"
                  }`,
                }}
                className="border-b-0"
              >
                <Row>
                  <Col span={24} className="text-center">
                    <span>
                      {GET_RESERVATION_UNIT_TYPE_LABEL(occasion.unitType)}
                    </span>
                  </Col>
                  <Col span={24} className="text-center">
                    <span className="text-xl font-bold">
                      {currentOccurrence.sumExpected || 0}
                    </span>
                    <span>{GET_UNIT_TYPE_LABEL(occasion.unitType)}</span>
                  </Col>
                </Row>
              </Col>
              <Col
                span={8}
                style={{
                  backgroundColor: currentOccurrence.isDisplayed
                    ? Commons.checkIsEventFull(
                        currentOccurrence.sumExpected || 0,
                        currentOccurrence.sumAttended || 0
                      )
                      ? "#91c46e"
                      : "#fff"
                    : "#ffffff",
                  color: currentOccurrence.isDisplayed
                    ? Commons.checkIsEventFull(
                        currentOccurrence.sumExpected || 0,
                        currentOccurrence.sumAttended || 0
                      )
                      ? "#FFF"
                      : "#52c41a"
                    : "#bfbfbf",
                  border: `1px solid ${
                    currentOccurrence.isDisplayed ? "#21acd7" : "#bfbfbf"
                  }`,
                }}
                className="border-b-0 border-l-0"
              >
                <Row>
                  <Col span={24} className="text-center">
                    <span>
                      {GET_PARTICIPATION_UNIT_TYPE_LABEL(occasion.unitType)}
                    </span>
                  </Col>
                  <Col span={24} className="text-center">
                    <span className="text-xl font-bold">
                      {currentOccurrence.sumAttended || 0}
                    </span>
                    <span>{GET_UNIT_TYPE_LABEL(occasion.unitType)}</span>
                  </Col>
                </Row>
              </Col>
              <Col
                span={8}
                style={{
                  color: ` ${
                    currentOccurrence.isDisplayed ? "#8c8c8c" : "#bfbfbf"
                  }`,
                  backgroundColor: ` ${
                    currentOccurrence.isDisplayed ? "#bfbfbf" : "#ffffff"
                  }`,
                  border: `1px solid ${
                    currentOccurrence.isDisplayed ? "#21acd7" : "#bfbfbf"
                  }`,
                }}
                className="border-b-0 border-l-0"
              >
                <Row>
                  <Col span={24} className="text-center">
                    <span>
                      {GET_CANCELLED_UNIT_TYPE_LABEL(occasion.unitType)}
                    </span>
                  </Col>
                  <Col span={24} className="text-center">
                    <span className="text-xl font-bold">
                      {currentOccurrence.sumCancelled || 0}
                    </span>
                    <span>{GET_UNIT_TYPE_LABEL(occasion.unitType)}</span>
                  </Col>
                </Row>
              </Col>
              <Col
                span={24}
                style={{
                  backgroundColor: currentOccurrence.isDisplayed
                    ? "#f0feff"
                    : "#ffffff",
                  color: currentOccurrence.isDisplayed ? "#21acd7" : "#bfbfbf",
                  border: `1px solid ${
                    currentOccurrence.isDisplayed ? "#21acd7" : "#bfbfbf"
                  }`,
                }}
              >
                <Row>
                  <Col span={24} className="text-center">
                    <span>{GET_MAX_CAPACITY_LABEL(occasion.unitType)}</span>
                  </Col>
                  <Col span={24} className="text-center">
                    <span className="text-xl font-bold">
                      {currentOccurrence.maxAttendee || 0}
                    </span>
                    <span>{GET_UNIT_TYPE_LABEL(occasion.unitType)}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className="w-full">
            <Row justify="end">
              <Col>
                <Button
                  onClick={showCreateParticipationModal}
                  className="mb-1"
                  icon={<PlusOutlined />}
                >
                  新規予約
                </Button>
              </Col>
            </Row>
            <Tabs defaultActiveKey="registered" type="card" centered>
              <TabPane tab="予約一覧" key="registered">
                <Row justify="center">
                  <Col span={24}>
                    <StyledTable
                      columns={registeredColumns}
                      dataSource={
                        currentOccurrence.registrations
                          ? currentOccurrence.registrations.map(
                              (registration) => {
                                return {
                                  key: registration.registrationId,
                                  dgftOrderId: registration.dgftOrderId,
                                  displayName: registration?.displayName,
                                  fullName: registration,
                                  grownup: registration,
                                  child: registration,
                                  preschool: registration,
                                  total: registration,
                                  source: registration,
                                  email: registration?.email,
                                  telephone: registration?.telephone,
                                  action: registration,
                                }
                              }
                            )
                          : []
                      }
                      expandable={{
                        expandedRowRender: occasion.isSimpleCount
                          ? undefined
                          : (record) => {
                              return (
                                <Row>
                                  <Col xs={24}>
                                    <Row
                                      style={{
                                        border: "solid 1px #21acd7",
                                      }}
                                    >
                                      <Col
                                        span={8}
                                        style={{
                                          background: "#f0feff",
                                          color: "#21acd7",
                                        }}
                                        className="text-center font-bold border-b-0"
                                      >
                                        名前
                                      </Col>
                                      <Col
                                        span={8}
                                        style={{
                                          borderRight: "solid 1px #21acd7",
                                          borderLeft: "solid 1px #21acd7",
                                          background: "#f0feff",
                                          color: "#21acd7",
                                        }}
                                        className="text-center font-bold border-b-0"
                                      >
                                        年齢
                                      </Col>
                                      <Col
                                        span={8}
                                        style={{
                                          borderRight: "solid 1px #21acd7",
                                          background: "#f0feff",
                                          color: "#21acd7",
                                        }}
                                        className="text-center font-bold border-b-0"
                                      >
                                        メッセージ
                                      </Col>
                                    </Row>
                                  </Col>
                                  {record?.fullName.attended
                                    ? record?.fullName?.attendedAttachRegistrations.map(
                                        (item, key) => (
                                          <Col xs={24} key={key}>
                                            <Row
                                              style={{
                                                borderBottom:
                                                  "solid 1px #21acd7",
                                                borderLeft: "solid 1px #21acd7",
                                              }}
                                            >
                                              <Col
                                                span={8}
                                                style={{
                                                  borderRight:
                                                    "solid 1px #21acd7",
                                                  background: "#fff",
                                                  color: "#21acd7",
                                                }}
                                              >
                                                <p className="text-center">
                                                  {item.fullName}
                                                </p>
                                              </Col>
                                              {renderChildAgeLimit(item)}
                                              <Col
                                                span={8}
                                                style={{
                                                  // borderBottom: "solid 1px #21acd7",
                                                  borderRight:
                                                    "solid 1px #21acd7",
                                                  background: "#fff",
                                                  color: "#21acd7",
                                                }}
                                              >
                                                <p className="text-center p-1">
                                                  {item.note || "-"}
                                                </p>
                                              </Col>
                                            </Row>
                                          </Col>
                                        )
                                      )
                                    : record?.fullName?.expectedAttachRegistrations.map(
                                        (item, idx) => (
                                          <Col xs={24} key={idx}>
                                            <Row
                                              style={{
                                                borderBottom:
                                                  "solid 1px #21acd7",
                                                borderLeft: "solid 1px #21acd7",
                                              }}
                                            >
                                              <Col
                                                span={8}
                                                style={{
                                                  borderRight:
                                                    "solid 1px #21acd7",
                                                  background: "#fff",
                                                  color: "#21acd7",
                                                }}
                                              >
                                                <p className="text-center">
                                                  {item.fullName}
                                                </p>
                                              </Col>
                                              {renderChildAgeLimit(item)}
                                              <Col
                                                span={8}
                                                style={{
                                                  // borderBottom: "solid 1px #21acd7",
                                                  borderRight:
                                                    "solid 1px #21acd7",
                                                  background: "#fff",
                                                  color: "#21acd7",
                                                }}
                                              >
                                                <p className="text-center p-1">
                                                  {item.note || "-"}
                                                </p>
                                              </Col>
                                            </Row>
                                          </Col>
                                        )
                                      )}
                                </Row>
                              )
                            },
                      }}
                      bordered={true}
                      size="small"
                      scroll={{ x: true }}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="キャンセル一覧" key="canceled">
                <Row justify="center">
                  <Col span={24}>
                    <StyledCanceledTable
                      columns={canceledColumns}
                      dataSource={
                        currentOccurrence.cancels
                          ? currentOccurrence.cancels.map((registration) => {
                              return {
                                key: registration.registrationId,
                                dgftOrderId: registration.dgftOrderId,
                                displayName: registration.displayName,
                                fullName: registration,
                                grownup: registration,
                                child: registration,
                                preschool: registration,
                                total: registration,
                                source: registration,
                                email: registration?.email,
                                telephone: registration?.telephone,
                                cancelledAt: registration.cancelledAt,
                              }
                            })
                          : []
                      }
                      expandable={{
                        expandedRowRender: occasion.isSimpleCount
                          ? undefined
                          : (record) => {
                              return (
                                <Row>
                                  <Col xs={24}>
                                    <Row
                                      style={{
                                        border: "solid 1px #21acd7",
                                      }}
                                    >
                                      <Col
                                        span={8}
                                        style={{
                                          background: "#f0feff",
                                          color: "#21acd7",
                                        }}
                                        className="text-center font-bold border-b-0"
                                      >
                                        名前
                                      </Col>
                                      <Col
                                        span={8}
                                        style={{
                                          borderRight: "solid 1px #21acd7",
                                          borderLeft: "solid 1px #21acd7",
                                          background: "#f0feff",
                                          color: "#21acd7",
                                        }}
                                        className="text-center font-bold border-b-0"
                                      >
                                        年齢
                                      </Col>
                                      <Col
                                        span={8}
                                        style={{
                                          borderRight: "solid 1px #21acd7",
                                          background: "#f0feff",
                                          color: "#21acd7",
                                        }}
                                        className="text-center font-bold border-b-0"
                                      >
                                        メッセージ
                                      </Col>
                                    </Row>
                                  </Col>
                                  {record?.fullName?.expectedAttachRegistrations.map(
                                    (item) => (
                                      <Col xs={24}>
                                        <Row
                                          style={{
                                            borderBottom: "solid 1px #21acd7",
                                            borderLeft: "solid 1px #21acd7",
                                          }}
                                        >
                                          <Col
                                            span={8}
                                            style={{
                                              borderRight: "solid 1px #21acd7",
                                              background: "#fff",
                                              color: "#21acd7",
                                            }}
                                          >
                                            <p className="text-center">
                                              {item.fullName}
                                            </p>
                                          </Col>
                                          <Col
                                            span={8}
                                            style={{
                                              borderRight: "solid 1px #21acd7",
                                              background: "#fff",
                                              color: "#21acd7",
                                            }}
                                          >
                                            <p className="text-center p-1">
                                              {`${item.age}歳${
                                                item.month
                                                  ? `${item.month}ヶ月`
                                                  : ""
                                              }`}
                                            </p>
                                          </Col>
                                          <Col
                                            span={8}
                                            style={{
                                              // borderBottom: "solid 1px #21acd7",
                                              borderRight: "solid 1px #21acd7",
                                              background: "#fff",
                                              color: "#21acd7",
                                            }}
                                          >
                                            <p className="text-center p-1">
                                              {item.note || "-"}
                                            </p>
                                          </Col>
                                        </Row>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              )
                            },
                      }}
                      bordered={true}
                      size="small"
                      scroll={{ x: true }}
                    />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Col>
          <Col>
            <Row gutter={[16, 16]}>
              <Col>
                <Tooltip title={GET_RESERVATION_RATE_LABEL(occasion.unitType)}>
                  <StyledProgressBar
                    type="dashboard"
                    strokeColor="#9ff0fc"
                    percent={findPercentage(
                      currentOccurrence.sumExpected || 0,
                      currentOccurrence.maxAttendee || 0
                    )}
                    format={(percent) => `予約率\u000A\u000A${percent}%`}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip
                  title={GET_PARTICIPATION_RATE_LABEL(occasion.unitType)}
                >
                  <StyledProgressBar
                    type="dashboard"
                    strokeColor="#b7eb8f"
                    percent={findPercentage(
                      currentOccurrence.sumAttended || 0,
                      currentOccurrence.sumExpected || 0
                    )}
                    format={(percent) => `参加者率\u000A\u000A${percent}%`}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row justify="center" className="my-4">
              <Col>
                <Dropdown
                  overlay={occurrenceCsvDownloadMenu}
                  trigger={["click"]}
                  arrow
                >
                  <Button
                    icon={<DownloadOutlined />}
                    loading={csvDownloadLoading}
                  >
                    CSVダウンロード
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          </Col>
          <Divider />
          <Col>
            <Row gutter={[8, 8]} justify="center">
              <Col>
                <Button onClick={hideOccurrenceModal}>閉じる</Button>
              </Col>
              <Col>
                <Button
                  onClick={handleOccurrenceDelete}
                  icon={<DeleteOutlined />}
                  danger
                >
                  イベント時間削除
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
      {occasion.isSimpleCount ? (
        <Modal
          visible={createParticipationModalVisible}
          title="新規予約"
          onCancel={hideCreateParticipationModal}
          footer={null}
          centered
          destroyOnClose={true}
        >
          <div className="p-2">
            <Form
              form={createParticipationForm}
              preserve={false}
              layout="vertical"
              initialValues={{
                participationName: "",
                participationPhone:
                  process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true"
                    ? ""
                    : undefined,
                participationAdult: 0,
                participationChild: 0,
                participationPreschool: 0,
              }}
              onFinish={handleParticipationCreateSubmit}
            >
              <Row justify="center">
                <Col xs={24}>
                  <Form.Item
                    name="participationName"
                    label="名前"
                    rules={[
                      {
                        required: true,
                        message: "名前は必須です",
                      },
                    ]}
                  >
                    <Input
                      placeholder="例：山田太郎"
                      onPressEnter={(e) => e.preventDefault()}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row justify="center">
                <Col span={24} className="text-center">
                  <Form.Item
                    label={`${occasion.isSimpleCount ? "参加者" : "大人"}`}
                    className="block"
                  >
                    <Input.Group compact>
                      <Button
                        icon={<MinusOutlined />}
                        style={{ marginRight: 0 }}
                        onClick={() => {
                          if (
                            createParticipationForm.getFieldValue(
                              "participationAdult"
                            ) > 0
                          ) {
                            createParticipationForm.setFieldsValue({
                              participationAdult:
                                createParticipationForm.getFieldValue(
                                  "participationAdult"
                                ) - 1,
                            })
                          }
                        }}
                      />
                      <Form.Item
                        name="participationAdult"
                        rules={[
                          {
                            required: true,
                            message: `${
                              occasion.isSimpleCount ? "参加者" : "大人"
                            }の数は必須です`,
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          style={{
                            width: "80px",
                            borderLeftWidth: 0,
                            borderRightWidth: 0,
                            textAlign: "center",
                          }}
                          readOnly
                          tabIndex="-1"
                        />
                      </Form.Item>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                          createParticipationForm.setFieldsValue({
                            participationAdult:
                              createParticipationForm.getFieldValue(
                                "participationAdult"
                              ) + 1,
                          })
                        }}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={24} className="text-center">
                  <Form.Item
                    label="子供"
                    className={occasion.isSimpleCount ? "" : "block"}
                    hidden={occasion.isSimpleCount ? true : false}
                  >
                    <Input.Group compact>
                      <Button
                        icon={<MinusOutlined />}
                        style={{ marginRight: 0 }}
                        onClick={() => {
                          if (
                            createParticipationForm.getFieldValue(
                              "participationChild"
                            ) > 0
                          ) {
                            createParticipationForm.setFieldsValue({
                              participationChild:
                                createParticipationForm.getFieldValue(
                                  "participationChild"
                                ) - 1,
                            })
                          }
                        }}
                      />
                      <Form.Item
                        name="participationChild"
                        rules={[
                          {
                            required: true,
                            message: "子供の数は必須です",
                          },
                        ]}
                        hidden={occasion.isSimpleCount ? true : false}
                        noStyle
                      >
                        <Input
                          style={{
                            width: "80px",
                            borderLeftWidth: 0,
                            borderRightWidth: 0,
                            textAlign: "center",
                          }}
                          readOnly
                          tabIndex="-1"
                        />
                      </Form.Item>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                          createParticipationForm.setFieldsValue({
                            participationChild:
                              createParticipationForm.getFieldValue(
                                "participationChild"
                              ) + 1,
                          })
                        }}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={24} className="text-center">
                  <Form.Item
                    label={`${occasion.isSimpleCount ? "同伴者" : "未就学児"}`}
                    className="block"
                  >
                    <Input.Group compact>
                      <Button
                        icon={<MinusOutlined />}
                        style={{ marginRight: 0 }}
                        onClick={() => {
                          if (
                            createParticipationForm.getFieldValue(
                              "participationPreschool"
                            ) > 0
                          ) {
                            createParticipationForm.setFieldsValue({
                              participationPreschool:
                                createParticipationForm.getFieldValue(
                                  "participationPreschool"
                                ) - 1,
                            })
                          }
                        }}
                      />
                      <Form.Item
                        name="participationPreschool"
                        rules={[
                          {
                            required: true,
                            message: `${
                              occasion.isSimpleCount ? "同伴者" : "未就学児"
                            }の数は必須です`,
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          style={{
                            width: "80px",
                            borderLeftWidth: 0,
                            borderRightWidth: 0,
                            textAlign: "center",
                          }}
                          readOnly
                          tabIndex="-1"
                        />
                      </Form.Item>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                          createParticipationForm.setFieldsValue({
                            participationPreschool:
                              createParticipationForm.getFieldValue(
                                "participationPreschool"
                              ) + 1,
                          })
                        }}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row gutter={[8, 8]} justify="center" className="m-4">
                    <Col>
                      <Button onClick={hideCreateParticipationModal}>
                        閉じる
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={participationLoading}
                      >
                        登録
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
      ) : (
        <OccasionModal
          visible={createParticipationModalVisible}
          onCancel={hideCreateParticipationModal}
          action="new"
          occasion={occasion}
          history={history}
          occurrenceId={currentOccurrence.occurrenceId}
          fetchOccasionData={fetchOccasionData}
          handleOccurrenceDetail={handleOccurrenceDetail}
        />
      )}
      {occasion.isSimpleCount ? (
        <Modal
          visible={updateParticipationModalVisible}
          title="予約変更"
          onCancel={hideUpdateParticipationModal}
          footer={null}
          centered
          destroyOnClose={true}
        >
          <div className="p-2">
            <Form
              form={updateParticipationForm}
              preserve={false}
              layout="vertical"
              initialValues={{
                participationName: "",
                participationAdult: 0,
                participationChild: 0,
                participationPreschool: 0,
                participationId: "",
              }}
              onFinish={handleParticipationUpdateSubmit}
            >
              <Row justify="center">
                <Col xs={24}>
                  <Form.Item name="participationName" label="名前">
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item name="participationId" label="ID" hidden={true}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row justify="center">
                <Col span={24} className="text-center">
                  <Form.Item
                    label={`${occasion.isSimpleCount ? "参加者" : "大人"}`}
                    className="block"
                  >
                    <Input.Group compact>
                      <Button
                        icon={<MinusOutlined />}
                        style={{ marginRight: 0 }}
                        onClick={() => {
                          if (
                            updateParticipationForm.getFieldValue(
                              "participationAdult"
                            ) > 0
                          ) {
                            updateParticipationForm.setFieldsValue({
                              participationAdult:
                                updateParticipationForm.getFieldValue(
                                  "participationAdult"
                                ) - 1,
                            })
                          }
                        }}
                      />
                      <Form.Item
                        name="participationAdult"
                        rules={[
                          {
                            required: true,
                            message: `${
                              occasion.isSimpleCount ? "参加者" : "大人"
                            }の数は必須です`,
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          style={{
                            width: "80px",
                            borderLeftWidth: 0,
                            borderRightWidth: 0,
                            textAlign: "center",
                          }}
                          readOnly
                          tabIndex="-1"
                        />
                      </Form.Item>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                          updateParticipationForm.setFieldsValue({
                            participationAdult:
                              updateParticipationForm.getFieldValue(
                                "participationAdult"
                              ) + 1,
                          })
                        }}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={24} className="text-center">
                  <Form.Item
                    label="子供"
                    className={
                      occasion.isSimpleCount
                        ? updateParticipationForm.getFieldValue(
                            "participationChild"
                          ) > 0
                          ? "block"
                          : ""
                        : "block"
                    }
                    hidden={
                      occasion.isSimpleCount
                        ? updateParticipationForm.getFieldValue(
                            "participationChild"
                          ) > 0
                          ? false
                          : true
                        : false
                    }
                    help={
                      occasion.isSimpleCount ? (
                        updateParticipationForm.getFieldValue(
                          "participationChild"
                        ) > 0 ? (
                          <span style={{ color: "red" }}>
                            ※子供の数を調整してください
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )
                    }
                  >
                    <Input.Group compact>
                      <Button
                        icon={<MinusOutlined />}
                        style={{ marginRight: 0 }}
                        onClick={() => {
                          if (
                            updateParticipationForm.getFieldValue(
                              "participationChild"
                            ) > 0
                          ) {
                            updateParticipationForm.setFieldsValue({
                              participationChild:
                                updateParticipationForm.getFieldValue(
                                  "participationChild"
                                ) - 1,
                            })
                          }
                        }}
                      />
                      <Form.Item
                        name="participationChild"
                        rules={[
                          {
                            required: true,
                            message: "子供の数は必須です",
                          },
                        ]}
                        hidden={
                          occasion.isSimpleCount
                            ? updateParticipationForm.getFieldValue(
                                "participationChild"
                              ) > 0
                              ? false
                              : true
                            : false
                        }
                        noStyle
                      >
                        <Input
                          style={{
                            width: "80px",
                            borderLeftWidth: 0,
                            borderRightWidth: 0,
                            textAlign: "center",
                          }}
                          readOnly
                          tabIndex="-1"
                        />
                      </Form.Item>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                          updateParticipationForm.setFieldsValue({
                            participationChild:
                              updateParticipationForm.getFieldValue(
                                "participationChild"
                              ) + 1,
                          })
                        }}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={24} className="text-center">
                  <Form.Item
                    label={`${occasion.isSimpleCount ? "同伴者" : "未就学児"}`}
                    className="block"
                  >
                    <Input.Group compact>
                      <Button
                        icon={<MinusOutlined />}
                        style={{ marginRight: 0 }}
                        onClick={() => {
                          if (
                            updateParticipationForm.getFieldValue(
                              "participationPreschool"
                            ) > 0
                          ) {
                            updateParticipationForm.setFieldsValue({
                              participationPreschool:
                                updateParticipationForm.getFieldValue(
                                  "participationPreschool"
                                ) - 1,
                            })
                          }
                        }}
                      />
                      <Form.Item
                        name="participationPreschool"
                        rules={[
                          {
                            required: true,
                            message: `${
                              occasion.isSimpleCount ? "同伴者" : "未就学児"
                            }の数は必須です`,
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          style={{
                            width: "80px",
                            borderLeftWidth: 0,
                            borderRightWidth: 0,
                            textAlign: "center",
                          }}
                          readOnly
                          tabIndex="-1"
                        />
                      </Form.Item>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                          updateParticipationForm.setFieldsValue({
                            participationPreschool:
                              updateParticipationForm.getFieldValue(
                                "participationPreschool"
                              ) + 1,
                          })
                        }}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row gutter={[8, 8]} justify="center" className="m-4">
                    <Col>
                      <Button onClick={hideUpdateParticipationModal}>
                        閉じる
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={participationLoading}
                      >
                        変更
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
      ) : (
        <OccasionModal
          history={history}
          visible={updateParticipationModalVisible}
          onCancel={hideUpdateParticipationModal}
          action="edit"
          occasion={occasion}
          dataUpdateOccurrences={dataUpdateOccurrences}
          fetchOccasionData={fetchOccasionData}
          occurrenceId={currentOccurrence.occurrenceId}
          handleOccurrenceDetail={handleOccurrenceDetail}
        />
      )}
      {occasion?.isSimpleCount ? (
        <Modal
          visible={confirmParticipationModalVisible}
          title="予約参加"
          onCancel={hideConfirmParticipationModal}
          footer={null}
          centered
          destroyOnClose={true}
        >
          <div className="p-2">
            <Form
              form={confirmParticipationForm}
              preserve={false}
              layout="vertical"
              initialValues={{
                participationName: "",
                participationExpectedAdult: 0,
                participationExpectedChild: 0,
                participationExpectedPreschool: 0,
                participationAttendedAdult: 0,
                participationAttendedChild: 0,
                participationAttendedPreschool: 0,
                participationId: "",
                expectedGuardian: 0,
                attendedGuardian: 0,
              }}
              onFinish={handleParticipationConfirmSubmit}
            >
              <Row justify="center">
                <Col xs={24}>
                  <Form.Item name="participationName" label="名前">
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item name="participationId" label="ID" hidden={true}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={12}>
                  <Row justify="center">
                    <Col span={24} className="text-center">
                      <span>予約</span>
                      <Divider />
                    </Col>
                    <Col span={24} className="text-center">
                      <Form.Item
                        label={`${occasion.isSimpleCount ? "参加者" : "大人"}`}
                        className="block"
                      >
                        <Input.Group compact>
                          <Form.Item
                            name="participationExpectedAdult"
                            rules={[
                              {
                                required: true,
                                message: `${
                                  occasion.isSimpleCount ? "参加者" : "大人"
                                }の数は必須です`,
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              style={{
                                width: "80px",
                                textAlign: "center",
                              }}
                              readOnly
                              tabIndex="-1"
                            />
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                    </Col>
                    <Col span={24} className="text-center">
                      <Form.Item
                        label="子供"
                        className={occasion.isSimpleCount ? "" : "block"}
                        hidden={occasion.isSimpleCount ? true : false}
                      >
                        <Input.Group compact>
                          <Form.Item
                            name="participationExpectedChild"
                            rules={[
                              {
                                required: true,
                                message: "子供の数は必須です",
                              },
                            ]}
                            hidden={occasion.isSimpleCount ? true : false}
                            noStyle
                          >
                            <Input
                              style={{
                                width: "80px",
                                textAlign: "center",
                              }}
                              readOnly
                              tabIndex="-1"
                            />
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                    </Col>
                    <Col span={24} className="text-center">
                      <Form.Item
                        label={`${
                          occasion.isSimpleCount ? "同伴者" : "未就学児"
                        }`}
                        className="block"
                      >
                        <Input.Group compact>
                          <Form.Item
                            name="participationExpectedPreschool"
                            rules={[
                              {
                                required: true,
                                message: `${
                                  occasion.isSimpleCount ? "同伴者" : "未就学児"
                                }の数は必須です`,
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              style={{
                                width: "80px",
                                textAlign: "center",
                              }}
                              readOnly
                              tabIndex="-1"
                            />
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify="center">
                    <Col span={24} className="text-center">
                      <span>参加</span>
                      <Divider />
                    </Col>
                    <Col span={24} className="text-center">
                      <Form.Item
                        label={`${occasion.isSimpleCount ? "参加者" : "大人"}`}
                        className="block"
                      >
                        <Input.Group compact>
                          <Button
                            icon={<MinusOutlined />}
                            style={{ marginRight: 0 }}
                            onClick={() => {
                              if (
                                confirmParticipationForm.getFieldValue(
                                  "participationAttendedAdult"
                                ) > 0
                              ) {
                                confirmParticipationForm.setFieldsValue({
                                  participationAttendedAdult:
                                    confirmParticipationForm.getFieldValue(
                                      "participationAttendedAdult"
                                    ) - 1,
                                })
                              }
                            }}
                          />
                          <Form.Item
                            name="participationAttendedAdult"
                            rules={[
                              {
                                required: true,
                                message: `${
                                  occasion.isSimpleCount ? "参加者" : "大人"
                                }の数は必須です`,
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              style={{
                                width: "80px",
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                textAlign: "center",
                              }}
                              readOnly
                              tabIndex="-1"
                            />
                          </Form.Item>
                          <Button
                            icon={<PlusOutlined />}
                            onClick={() => {
                              confirmParticipationForm.setFieldsValue({
                                participationAttendedAdult:
                                  confirmParticipationForm.getFieldValue(
                                    "participationAttendedAdult"
                                  ) + 1,
                              })
                            }}
                          />
                        </Input.Group>
                      </Form.Item>
                    </Col>
                    <Col span={24} className="text-center">
                      <Form.Item
                        label="子供"
                        className={occasion.isSimpleCount ? "" : "block"}
                        hidden={occasion.isSimpleCount ? true : false}
                      >
                        <Input.Group compact>
                          <Button
                            icon={<MinusOutlined />}
                            style={{ marginRight: 0 }}
                            onClick={() => {
                              if (
                                confirmParticipationForm.getFieldValue(
                                  "participationAttendedChild"
                                ) > 0
                              ) {
                                confirmParticipationForm.setFieldsValue({
                                  participationAttendedChild:
                                    confirmParticipationForm.getFieldValue(
                                      "participationAttendedChild"
                                    ) - 1,
                                })
                              }
                            }}
                          />
                          <Form.Item
                            name="participationAttendedChild"
                            rules={[
                              {
                                required: true,
                                message: "子供の数は必須です",
                              },
                            ]}
                            hidden={occasion.isSimpleCount ? true : false}
                            noStyle
                          >
                            <Input
                              style={{
                                width: "80px",
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                textAlign: "center",
                              }}
                              readOnly
                              tabIndex="-1"
                            />
                          </Form.Item>
                          <Button
                            icon={<PlusOutlined />}
                            onClick={() => {
                              confirmParticipationForm.setFieldsValue({
                                participationAttendedChild:
                                  confirmParticipationForm.getFieldValue(
                                    "participationAttendedChild"
                                  ) + 1,
                              })
                            }}
                          />
                        </Input.Group>
                      </Form.Item>
                    </Col>
                    <Col span={24} className="text-center">
                      <Form.Item
                        label={`${
                          occasion.isSimpleCount ? "同伴者" : "未就学児"
                        }`}
                        className="block"
                      >
                        <Input.Group compact>
                          <Button
                            icon={<MinusOutlined />}
                            style={{ marginRight: 0 }}
                            onClick={() => {
                              if (
                                confirmParticipationForm.getFieldValue(
                                  "participationAttendedPreschool"
                                ) > 0
                              ) {
                                confirmParticipationForm.setFieldsValue({
                                  participationAttendedPreschool:
                                    confirmParticipationForm.getFieldValue(
                                      "participationAttendedPreschool"
                                    ) - 1,
                                })
                              }
                            }}
                          />
                          <Form.Item
                            name="participationAttendedPreschool"
                            rules={[
                              {
                                required: true,
                                message: `${
                                  occasion.isSimpleCount ? "同伴者" : "未就学児"
                                }の数は必須です`,
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              style={{
                                width: "80px",
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                textAlign: "center",
                              }}
                              readOnly
                              tabIndex="-1"
                            />
                          </Form.Item>
                          <Button
                            icon={<PlusOutlined />}
                            onClick={() => {
                              confirmParticipationForm.setFieldsValue({
                                participationAttendedPreschool:
                                  confirmParticipationForm.getFieldValue(
                                    "participationAttendedPreschool"
                                  ) + 1,
                              })
                            }}
                          />
                        </Input.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[8, 8]} justify="center" className="m-4">
                    <Col>
                      <Button onClick={hideConfirmParticipationModal}>
                        閉じる
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={participationLoading}
                      >
                        保存
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
      ) : (
        <OccasionModal
          history={history}
          visible={confirmParticipationModalVisible}
          onCancel={hideConfirmParticipationModal}
          occasion={occasion}
          action="confirm"
          dataUpdateOccurrences={dataUpdateOccurrences}
          fetchOccasionData={fetchOccasionData}
          occurrenceId={currentOccurrence.occurrenceId}
          handleOccurrenceDetail={handleOccurrenceDetail}
        />
      )}
      <Modal
        visible={createOccurrenceModalVisible}
        title="新規イベント時間"
        onCancel={hideCreateOccurrenceModal}
        footer={null}
        width={720}
        centered
      >
        <div className="p-2">
          <Form
            form={occurrenceForm}
            layout="vertical"
            initialValues={{
              occurrenceMaxParticipation: 10,
              occurrenceTimeInterval: "" + Commons.BUSINESS_INTERVAL_TIME_VALUE,
            }}
            onFinish={handleOccurrenceCreateSubmit}
          >
            <Row justify="space-between">
              <Col>
                <Form.Item
                  name="occurrenceMaxParticipation"
                  label={GET_MAX_PARTICIPATION_LABEL(occasion.unitType)}
                  rules={[
                    {
                      required: true,
                      message: `${GET_MAX_PARTICIPATION_LABEL(
                        occasion.unitType
                      )}は必須です`,
                    },
                  ]}
                >
                  <Commons.NumericInput
                    autoFocus
                    placeholder={`${GET_MAX_PARTICIPATION_LABEL(
                      occasion.unitType
                    )}を入力してください`}
                    onPressEnter={(e) => {
                      e.preventDefault()
                    }}
                    onChange={(value) => {
                      if (occurrenceCreateForm.current) {
                        occurrenceCreateForm.current.setFieldsValue({
                          occurrenceMaxParticipation: value,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="occurrenceTimeInterval" label="時間期間">
                  <Select
                    onChange={(value) => {
                      setEventInterval(parseInt(value))
                      clearOccurrence()
                    }}
                  >
                    <Option value="10">10分</Option>
                    <Option value="15">15分</Option>
                    <Option value="20">20分</Option>
                    <Option value="25">25分</Option>
                    <Option value="30">30分</Option>
                    <Option value="35">35分</Option>
                    <Option value="40">40分</Option>
                    <Option value="45">45分</Option>
                    <Option value="50">50分</Option>
                    <Option value="55">55分</Option>
                    <Option value="60">60分</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <CalendarWrapper>
              <FullCalendar
                locale="ja"
                ref={occurrenceCreateCalendarRef}
                plugins={[timeGridPlugin, interactionPlugin, momentPlugin]}
                initialView="timeGridWeek"
                height="64.7vh"
                customButtons={{
                  CustomPrevMonth: {
                    text: "先月",
                    click: function () {
                      occurrenceCreateCalendarRef.current
                        .getApi()
                        .gotoDate(
                          moment(
                            occurrenceCreateCalendarRef.current
                              .getApi()
                              .getDate()
                          )
                            .subtract(1, "month")
                            .toDate()
                        )
                    },
                  },
                  CustomNextMonth: {
                    text: "来月",
                    click: function () {
                      occurrenceCreateCalendarRef.current
                        .getApi()
                        .gotoDate(
                          moment(
                            occurrenceCreateCalendarRef.current
                              .getApi()
                              .getDate()
                          )
                            .add(1, "month")
                            .toDate()
                        )
                    },
                  },
                  CustomPrevWeek: {
                    text: "先週",
                    click: function () {
                      occurrenceCreateCalendarRef.current.getApi().prev()
                    },
                  },
                  CustomThisWeek: {
                    text: "今週",
                    click: function () {
                      occurrenceCreateCalendarRef.current.getApi().today()
                    },
                  },
                  CustomNextWeek: {
                    text: "来週",
                    click: function () {
                      occurrenceCreateCalendarRef.current.getApi().next()
                    },
                  },
                }}
                headerToolbar={{
                  left: "CustomPrevMonth,CustomNextMonth",
                  center: "title",
                  right: "CustomPrevWeek,CustomThisWeek,CustomNextWeek",
                }}
                titleFormat={(date) => {
                  return moment(date.date).format("YYYY年M月")
                }}
                firstDay={1}
                businessHours={false}
                allDaySlot={false}
                slotLabelFormat={{
                  hour: "2-digit",
                  minute: "2-digit",
                  omitZeroMinute: false,
                }}
                slotDuration={`00:${eventInterval}:00`}
                slotMinTime={Commons.BUSINESS_OPEN_TIME}
                slotMaxTime={Commons.BUSINESS_CLOSE_TIME}
                slotLabelInterval={`00:${eventInterval}:00`}
                dayHeaderFormat={(date) => {
                  return moment(date.date).format("D（ddd）")
                }}
                eventTimeFormat={{
                  hour: "2-digit",
                  minute: "2-digit",
                  meridiem: false,
                }}
                slotEventOverlap={false}
                displayEventTime={true}
                displayEventEnd={false}
                nowIndicator={true}
                selectable={true}
                selectOverlap={false}
                unselectAuto={true}
                selectAllow={(selectInfo) =>
                  moment().isBefore(selectInfo.start)
                }
                events={[
                  ...(occasion && occasion.occurrences
                    ? occasion.occurrences.map((occurrence) => {
                        return {
                          groupId: "event",
                          display: "background",
                          backgroundColor: "#ffd591",
                          start: moment(occurrence.startAt).format(
                            "YYYY-MM-DD HH:mm"
                          ),
                          end: moment(occurrence.startAt)
                            .add(eventInterval, "minutes")
                            .format("YYYY-MM-DD HH:mm"),
                        }
                      })
                    : []),
                  ...(tmpOccurrences
                    ? tmpOccurrences.map((occurrence) => {
                        return {
                          groupId: "background",
                          display: "background",
                          backgroundColor: "#9ff0fc",
                          start: moment(occurrence.startAt).format(
                            "YYYY-MM-DD HH:mm"
                          ),
                          end: moment(occurrence.startAt)
                            .add(eventInterval, "minutes")
                            .format("YYYY-MM-DD HH:mm"),
                        }
                      })
                    : []),
                ]}
                eventContent={(arg) => {
                  if (arg.event.groupId === "background") {
                    return (
                      <Row justify="center" className="cursor-pointer">
                        <Col>
                          <ClockCircleTwoTone
                            twoToneColor="#21acd7"
                            className="text-lg p-1"
                          />
                        </Col>
                      </Row>
                    )
                  } else if (arg.event.groupId === "event") {
                    return (
                      <Row justify="center" className="cursor-pointer">
                        <Col>
                          <StopTwoTone
                            twoToneColor="#fa8c16"
                            className="text-lg p-1"
                          />
                        </Col>
                      </Row>
                    )
                  }
                }}
                eventClick={(eventInfo) => {
                  if (eventInfo.event.groupId === "background") {
                    Modal.confirm({
                      title: "イベントの時間削除",
                      icon: (
                        <QuestionCircleOutlined style={{ color: "#f5222d" }} />
                      ),
                      content: (
                        <Row gutter={[0, 16]} className="mt-4" justify="center">
                          <Col span={24}>
                            <Card
                              bodyStyle={{
                                maxHeight: "50vh",
                                overflow: "auto",
                              }}
                            >
                              <Card.Grid
                                hoverable={false}
                                style={deleteCardGridStyle}
                              >
                                {`${moment(eventInfo.event.start).format(
                                  "YYYY年M月D日 HH:mm"
                                )}`}
                              </Card.Grid>
                            </Card>
                          </Col>
                        </Row>
                      ),
                      okText: "削除",
                      okButtonProps: {
                        danger: true,
                      },
                      onOk: () => {
                        setTmpOccurrences(
                          tmpOccurrences.filter(
                            (occurrence) =>
                              occurrence.startAt !==
                              moment(eventInfo.event.start).format(
                                "YYYY-MM-DD HH:mm"
                              )
                          )
                        )
                      },
                      cancelText: "閉じる",
                    })
                  }
                }}
                select={(selectInfo) => {
                  Modal.confirm({
                    title: "イベントの時間登録",
                    icon: (
                      <QuestionCircleOutlined style={{ color: "#21acd7" }} />
                    ),
                    content: (
                      <Row gutter={[0, 16]} className="mt-4" justify="center">
                        <Col span={24}>
                          <Card
                            bodyStyle={{
                              maxHeight: "50vh",
                              overflow: "auto",
                            }}
                          >
                            {Array.from(
                              moment
                                .range(selectInfo.start, selectInfo.end)
                                .by("minutes", {
                                  step: eventInterval,
                                  excludeEnd: true,
                                })
                            ).map((chunk) => (
                              <Card.Grid
                                hoverable={false}
                                style={cardGridStyle}
                                key={chunk}
                              >
                                {moment(chunk).format("YYYY年M月D日 HH:mm")}
                              </Card.Grid>
                            ))}
                          </Card>
                        </Col>
                        <Col>
                          <Form
                            ref={occurrenceCreateForm}
                            layout="vertical"
                            initialValues={{
                              occurrenceMaxParticipation:
                                occurrenceForm.getFieldValue(
                                  "occurrenceMaxParticipation"
                                ) || 10,
                            }}
                            size="small"
                            requiredMark={true}
                            scrollToFirstError
                          >
                            <Form.Item
                              name="occurrenceMaxParticipation"
                              label={GET_MAX_CAPACITY_LABEL(occasion.unitType)}
                              rules={[
                                {
                                  required: true,
                                  message: `${GET_MAX_CAPACITY_LABEL(
                                    occasion.unitType
                                  )}は必須です`,
                                },
                              ]}
                            >
                              <Commons.NumericInput
                                autoFocus
                                placeholder={`${GET_MAX_CAPACITY_LABEL(
                                  occasion.unitType
                                )}を入力してください`}
                                onPressEnter={(e) => {
                                  e.preventDefault()
                                }}
                              />
                            </Form.Item>
                          </Form>
                        </Col>
                      </Row>
                    ),
                    okText: "登録",
                    onOk: () => {
                      occurrenceCreateForm.current
                        .validateFields()
                        .then((values) => {
                          const dateRange = moment.range(
                            selectInfo.start,
                            selectInfo.end
                          )

                          const dateChunk = Array.from(
                            dateRange.by("minutes", {
                              step: eventInterval,
                              excludeEnd: true,
                            })
                          )

                          setTmpOccurrences([
                            ...tmpOccurrences,
                            ...dateChunk.map((date) => ({
                              maxAttendee:
                                values.occurrenceMaxParticipation || 10,
                              startAt: moment(date).format("YYYY-MM-DD HH:mm"),
                              endAt: moment(date)
                                .add(eventInterval, "minutes")
                                .format("YYYY-MM-DD HH:mm"),
                            })),
                          ])
                        })
                        .catch((error) => {})
                    },
                    cancelText: "閉じる",
                  })
                }}
              />
            </CalendarWrapper>
            <Row justify="center mt-4">
              <Col>
                <Button
                  style={{ margin: "0 8px" }}
                  onClick={() => clearOccurrence()}
                >
                  すべての日付選択をクリア
                </Button>
              </Col>
            </Row>
            <Divider />
            <Row gutter={[8, 8]} justify="center">
              <Col>
                <Button key="back" onClick={hideCreateOccurrenceModal}>
                  閉じる
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  key="ok"
                  loading={eventSubmitLoading}
                >
                  登録
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      <Modal
        visible={updateOccurrenceModalVisible}
        title={GET_MAX_CAPACITY_CHANGE_LABEL(occasion.unitType)}
        onCancel={hideUpdateOccurrenceModal}
        footer={null}
        centered
      >
        <div className="p-2">
          <Form
            form={updateOccurrenceForm}
            preserve={false}
            layout="vertical"
            initialValues={{
              occurrenceMaxParticipation: "",
            }}
            onFinish={handleOccurrenceUpdateSubmit}
          >
            <Row justify="center">
              <Col xs={24}>
                <Form.Item
                  name="occurrenceMaxParticipation"
                  label={GET_MAX_CAPACITY_LABEL(occasion.unitType)}
                  rules={[
                    {
                      required: true,
                      message: `${GET_MAX_CAPACITY_LABEL(
                        occasion.unitType
                      )}は必須です`,
                    },
                  ]}
                >
                  <Commons.NumericInput
                    autoFocus
                    placeholder={`${GET_MAX_CAPACITY_LABEL(
                      occasion.unitType
                    )}を入力してください`}
                    onPressEnter={(e) => {
                      e.preventDefault()
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]} justify="center">
              <Col>
                <Button key="back" onClick={hideUpdateOccurrenceModal}>
                  閉じる
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  key="ok"
                  loading={occurrenceUpdateLoading}
                >
                  変更
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  )
}

export default withRouter(OccasionDetail)
