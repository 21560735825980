import { Form, Radio, Input } from "antd"
import Switch from "../Switch"

export default function GeneralNode() {
  const isGeneralNoteShow = Form.useWatch("generalNoteShow")

  return (
    <div className="flex flex-col ml-3 gap-y-[6px]">
      <div className="flex gap-7 items-center">
        <span>・追加項⽬2</span>
        <Form.Item name="generalNoteShow">
          <Switch name="generalNoteShow" />
        </Form.Item>
      </div>

      {isGeneralNoteShow && (
        <>
          <Form.Item name="generalNoteRq" className="pl-4">
            <Radio.Group>
              <Radio value={true}>必須</Radio>
              <Radio value={false}>任意</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="generalNoteLabel" className="pl-4">
            <Input placeholder="項⽬2のラベルを設定 例：その他" />
          </Form.Item>
        </>
      )}
    </div>
  )
}
