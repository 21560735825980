import { Col, Form, Input, Row } from "antd"
const { TextArea } = Input

export const OccasionUpdateDescFormItem = () => (
  <Row>
    <Col span={24}>
      <Form.Item
        name="occasionUpdateDesc"
        label="イベント内容"
        rules={[
          {
            required: true,
            message: "イベント内容は必須です",
          },
        ]}
      >
        <TextArea placeholder="イベント内容を入力してください" autoSize />
      </Form.Item>
    </Col>
  </Row>
)
