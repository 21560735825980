import React, { useState, useEffect, useCallback } from "react"
import { withRouter, Link } from "react-router-dom"
import {
  Alert,
  Button,
  Col,
  Descriptions,
  message,
  Modal,
  Row,
  Image,
} from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import moment from "moment"
import "moment/locale/ja"
import liff from "@line/liff"
import QRCode from "qrcode.react"
import { encode } from "base-64"
import io from "socket.io-client"
import * as Commons from "common/common"

moment.locale("ja")

const LiffInfo = (props) => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props
  const occasionId = parseInt(props.match.params.occasionId)
  const occurrenceId = parseInt(props.match.params.occurrenceId)

  const isMountedRef = Commons.useIsMountedRef()

  const [occasion, setOccasion] = useState({})

  const fetchOccasionDetail = useCallback(() => {
    showLoadingPageSpin()

    let headerData = {}

    try {
      headerData.headers = {
        "access-token": liff.getAccessToken(),
      }
    } catch (error) {
      history.push(Commons.liffLoginRoute)
    }

    Commons.axiosInstance
      .get(`/liff/events/${occasionId}/${occurrenceId}`, headerData)
      .then((response) => {
        if (isMountedRef.current && response) {
          setOccasion(response.data || {})
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        hideLoadingPageSpin()
      })
  }, [
    isMountedRef,
    history,
    occasionId,
    occurrenceId,
    showLoadingPageSpin,
    hideLoadingPageSpin,
  ])

  const handleCancellation = () => {
    if (isMountedRef.current) {
      showLoadingPageSpin()

      let headerData = {}

      try {
        headerData.headers = {
          "access-token": liff.getAccessToken(),
        }
        headerData.data = {
          registrationId: occasion.registered?.registrationId || "",
        }
      } catch (error) {
        history.push(Commons.liffLoginRoute)
      }

      Commons.axiosInstance
        .delete(Commons.apiLiffOccasionDetail, headerData)
        .then((response) => {
          message.success(Commons.successCancelMsg)
          history.push(Commons.liffOccasionsRoute)
        })
        .catch((error) => {
          if (error.response.status === 406) {
            Modal.info({
              title: "確認",
              content: Commons.warnAlreadyParticipatedMsg,
              okText: "確認",
              centered: true,
              onOk() {
                history.push(Commons.liffOccasionsRoute)
              },
            })
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    }
  }

  useEffect(() => {
    fetchOccasionDetail()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const socket = io(Commons.siteURL, { path: "/socket.io" })

    socket.on("confirmRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          history.push(Commons.liffOccasionsRoute)
        }
      }
    })
    socket.on("paymentSuccess", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (
          response?.registrationId &&
          response?.occasionId === occasionId &&
          response?.occurrenceId === occurrenceId
        ) {
          fetchOccasionDetail()
        }
      }
    })

    socket.on("updateEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          fetchOccasionDetail()
        }
      }
    })

    socket.on("deleteOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          message.warning(Commons.socketLiffEventUpdateMsg)
          history.push(Commons.liffOccasionsRoute)
        }
      }
    })

    socket.on("deleteEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          message.warning(Commons.socketLiffEventDeleteMsg)
          history.push(Commons.liffOccasionsRoute)
        }
      }
    })

    return () => {
      socket.off("confirmRegistration")
      socket.off("updateEvent")
      socket.off("deleteOccurrence")
      socket.off("paymentSuccess")
      socket.off("deleteEvent")

      socket.disconnect()
    }

    // eslint-disable-next-line
  }, [])

  const handleCancellationConfirmation = () => {
    Modal.confirm({
      title: "確認",
      icon: <ExclamationCircleOutlined />,
      content: "予約をキャンセルしてもよろしいですか？",
      okText: "予約をキャンセル",
      okType: "danger",
      cancelText: "閉じる",
      centered: true,
      onOk() {
        handleCancellation()
      },
    })
  }

  return (
    <>
      <Row gutter={[0, 16]} className="mx-4">
        <Col span={24} className="text-center">
          <img
            src="/logo-header.png"
            alt="ロゴ"
            style={{ maxHeight: "120px" }}
            className="max-w-full mt-2 mx-4"
          />
        </Col>
        <Col span={24}>
          <div className="bg-primary text-center p-4 mt-4 rounded">
            <span className="text-white text-lg font-bold whitespace-pre-wrap">
              {occasion.title || ""}
            </span>
          </div>
        </Col>
        <Col span={24} className="text-center">
          <Image
            preview={false}
            src={
              occasion?.occasionImages &&
              occasion?.occasionImages?.length > 0 &&
              occasion.occasionImages[0]?.picUrl
                ? `${Commons.occasionImagesURL}${occasion.occasionImages[0]?.picUrl}`
                : "/no-image.png"
            }
            fallback="/no-image.png"
            alt={occasion.title}
            style={{ maxHeight: "320px" }}
            className="rounded max-w-full"
          />
        </Col>
        {occasion.registered ? (
          <Col span={24}>
            <Row justify="center">
              <Col>
                <QRCode
                  value={encode(
                    "E-" + occasion.registered.registrationId || ""
                  )}
                  size={200}
                  renderAs="svg"
                />
              </Col>
            </Row>
          </Col>
        ) : (
          ""
        )}
        <Col span={24}>
          <div className="text-center">
            <span className="whitespace-pre-wrap">
              {/* {"当日受付でQRコードを提示ください \nお待ちしております！"} */}
              {`当日受付でQRコードを提示ください\nお待ちしております！`}
            </span>
          </div>
        </Col>
        {occasion.registered ? (
          <Col span={24}>
            <Descriptions
              title="登録情報"
              labelStyle={{ width: "200px" }}
              column={1}
              size="small"
              bordered
            >
              <Descriptions.Item label="イベント名">
                {occasion.title || "-"}
              </Descriptions.Item>

              {/* {occasion.isSimpleCount ? (
                ""
              ) : (
                <Descriptions.Item label="子供の数">
                  {(occasion.registered.expectedChild || 0) + "人"}
                </Descriptions.Item>
              )} */}
              {occasion.isSimpleCount ? (
                <>
                  <Descriptions.Item
                    label={`${
                      occasion.isSimpleCount
                        ? occasion.unitType === "area"
                          ? "区画数"
                          : "参加者の数"
                        : "大人の数"
                    }`}
                  >
                    {`${occasion.registered.expectedGrownup} ${
                      occasion.unitType === "area" ? "区画" : "人"
                    }`}
                  </Descriptions.Item>
                </>
              ) : (
                <>
                  {occasion?.parentNameShow && (
                    <Descriptions.Item label="保護者氏名">
                      {occasion?.registered?.guardianName}
                    </Descriptions.Item>
                  )}
                  {(occasion?.fullNameShow || occasion?.childAgeShow) && (
                    <Descriptions.Item
                      label={`参加者 [${[
                        occasion.fullNameShow ? "名前" : null,
                        occasion?.childAgeShow ? "年齢" : null,
                      ]
                        .filter(Boolean)
                        .join("/")}]`}
                    >
                      {occasion?.registered?.expectedAttachRegistrations.map(
                        (item) => (
                          <div>
                            {[
                              occasion.fullNameShow
                                ? item.fullName || "ー"
                                : null,
                              occasion?.childAgeShow
                                ? `${item.age || "ー"}歳${
                                    item.month || "ー"
                                  }ヶ月`
                                : null,
                            ]
                              .filter(Boolean)
                              .join("/")}
                          </div>
                        )
                      )}
                    </Descriptions.Item>
                  )}
                </>
              )}

              <Descriptions.Item label="日付">
                {occasion.registered.startAt
                  ? moment(occasion.registered.startAt).format("YYYY年M月D日")
                  : "-"}
              </Descriptions.Item>
              <Descriptions.Item label="時間">
                {occasion.registered.startAt
                  ? moment(occasion.registered.startAt).format("HH時mm分")
                  : "-"}
              </Descriptions.Item>
              {!occasion.isSimpleCount && occasion.parentPhoneShow && (
                <Descriptions.Item
                  label={
                    <div className="flex flex-col">
                      <span>ご連絡先</span>
                      <span>電話番号</span>
                    </div>
                  }
                >
                  {occasion?.registered?.telephone}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Col>
        ) : (
          ""
        )}
        {occasion.textCancel && (
          <Col span={24}>
            <div className="text-center whitespace-pre-wrap">
              <Alert message={occasion.textCancel || ""} type="warning" />
            </div>
          </Col>
        )}
        <Col span={24}>
          <Row gutter={[8, 8]} justify="center" className="m-4">
            <Col span={12}>
              <Link to={`${Commons.liffOccasionsRoute}`}>
                <Button size="large" block>
                  戻る
                </Button>
              </Link>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                size="large"
                onClick={handleCancellationConfirmation}
                block
                disabled={
                  occasion.canCancel && occasion.canForceCancel
                    ? false
                    : occasion.canCancel
                    ? moment().isSameOrBefore(
                        moment(occasion.registered?.startAt).subtract(
                          occasion.timeCancel || 0,
                          "minutes"
                        )
                      )
                      ? false
                      : true
                    : true
                }
              >
                予約キャンセル
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default withRouter(LiffInfo)
