import { Col, Form, Radio, Row } from "antd"
import * as Commons from "common/common"

export const OccasionUpdateUnitTypeFormItem = ({ setIsUnitTypeArea }) => (
  <Row>
    <Col span={24}>
      <Form.Item
        name="unitType"
        label="イベントの単位"
        rules={[
          {
            required: true,
            message: "イベントの単位は必須です",
          },
        ]}
      >
        <Radio.Group>
          {Commons.unitTypes.map((unit) => (
            <Radio
              key={unit.value}
              value={unit.value}
              onChange={(e) => setIsUnitTypeArea(e.target.value === "area")}
            >
              {unit.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    </Col>
  </Row>
)
