import { Form, Radio, Input } from "antd"
import Switch from "../Switch"

export default function ChildNote() {
  const isChildNoteShow = Form.useWatch("childNoteShow")

  return (
    <div className="flex flex-col ml-3 gap-y-[6px]">
      <div className="flex gap-7 items-center">
        <span>・追加項⽬1</span>
        <Form.Item name="childNoteShow">
          <Switch name="childNoteShow" />
        </Form.Item>
      </div>

      {isChildNoteShow && (
        <>
          <Form.Item name="childNoteRq" className="pl-4">
            <Radio.Group>
              <Radio value={true}>必須</Radio>
              <Radio value={false}>任意</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="childNoteLabel" className="pl-4">
            <Input placeholder="項⽬1のラベルを設定 例：備考" />
          </Form.Item>
        </>
      )}
    </div>
  )
}
