import { Switch, Form } from "antd"
import { cn } from "common/utilities"

/**
 * @typedef {object} InternalSwitchProps
 * @property {string} name
 *
 * @typedef {import("antd").SwitchProps & InternalSwitchProps } SwitchProps
 * @param {SwitchProps} props
 * @returns {JSX.Element}
 */
export default function InternalSwitch(props) {
  const value = Form.useWatch(props.name)

  return (
    <>
      <Switch
        {...props}
        checked={value}
        className={cn(
          //
          "[&.ant-switch-checked]:bg-[#ff7875]",
          "[&.ant-switch-checked:focus]:focus:shadow:0_0_0_2px_[#fff1f0]",
          props.className
        )}
      />
      {typeof value === "boolean" && (
        <span className="ml-2">{value ? "ON" : "OFF"}</span>
      )}
    </>
  )
}
