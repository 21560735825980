import React, { Component } from "react"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import ScrollToTop from "./common/scrollToTop"
import Routes from "./Routes"
import "./tailwind-generated.css"
// import { Spin } from "antd"
const browserHistory = createBrowserHistory()
export default class App extends Component {
  render() {
    return (
      <Router history={browserHistory}>
        {/* <Suspense
          fallback={
            <Spin
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1001,
              }}
            />
          }
        > */}
        <ScrollToTop />
        <Routes />
        {/* </Suspense> */}
      </Router>
    )
  }
}
