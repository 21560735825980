import { MinusOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons"
import {
  Button,
  Form,
  Input as AntInput,
  Divider,
  Typography,
  Collapse,
  Space,
  Select,
  Col,
  Row,
} from "antd"
import PropTypes from "prop-types"
import { Controller } from "react-hook-form"
import styled from "styled-components"

import useOccasionModal from "./AddLiffParticipation.hook"

const Input = styled(AntInput)`
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`

const OccasionModalLayout = ({
  control,
  handleAddParticipationChild,
  removeParticipationChild,
  attachRegistrations,
  errors,
  occasion,
  onChangeTelephone,
}) => {
  const genExtra = (id) => (
    <DeleteOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation()
        removeParticipationChild(id)
      }}
    />
  )

  return (
    <div>
      <Form
        layout="vertical"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {occasion.parentNameShow && (
          <Form.Item
            name="guardianName"
            label="保護者氏名"
            help={errors.guardianName ? errors.guardianName.message : undefined}
            validateStatus={errors.guardianName ? "error" : undefined}
            rules={[
              {
                required: occasion.parentNameShow && occasion.parentNameRq,
                message: "",
              },
            ]}
          >
            <Controller
              name="guardianName"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        )}
        {occasion.parentPhoneShow && (
          <Form.Item
            name="telephone"
            label="ご連絡先電話番号"
            placeholder="例：08000000000"
            help={errors.telephone ? errors.telephone.message : undefined}
            validateStatus={errors.telephone ? "error" : undefined}
            rules={[
              {
                required: occasion.parentPhoneShow && occasion.parentPhoneRq,
                message: "",
              },
            ]}
          >
            <Controller
              name="telephone"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(e) => {
                    field.onChange(e)
                    onChangeTelephone(e.target.value)
                  }}
                />
              )}
            />
          </Form.Item>
        )}

        {!occasion?.fullNameShow &&
        !occasion?.childAgeShow &&
        !occasion?.childNoteShow ? null : (
          <>
            {/* Only show divider if something above is shown */}
            {(occasion.parentNameShow || occasion.parentPhoneShow) && (
              <Divider />
            )}
            <Space size={10} style={{ marginBottom: -5 }}>
              <Typography.Text style={{ fontSize: "20px" }}>
                参加者
              </Typography.Text>
              <Button
                type="primary"
                onClick={handleAddParticipationChild}
                disabled={attachRegistrations.length === 5}
                shape="circle"
                icon={<PlusOutlined />}
                size="small"
              />
            </Space>
            <Collapse
              bordered={false}
              style={{
                background: "transparent",
              }}
              defaultActiveKey={attachRegistrations[0].id}
            >
              {attachRegistrations.map((item, idx) => {
                return (
                  <Collapse.Panel
                    extra={idx === 0 ? undefined : genExtra(idx)}
                    header={`参加者 ${idx + 1}`}
                    key={item.id}
                    style={{ marginBottom: -5 }}
                  >
                    {occasion?.fullNameShow && (
                      <Form.Item
                        name={`attachRegistrations.${idx}.fullName`}
                        label="おなまえ（ひらがな入力）"
                        help={
                          errors?.attachRegistrations &&
                          errors?.attachRegistrations[idx]?.fullName
                            ? errors?.attachRegistrations[idx]?.fullName.message
                            : undefined
                        }
                        validateStatus={
                          errors?.attachRegistrations &&
                          errors?.attachRegistrations[idx]?.fullName
                            ? "error"
                            : undefined
                        }
                        rules={[
                          {
                            required:
                              occasion?.fullNameShow && occasion?.fullNameRq,
                            message: "",
                          },
                        ]}
                      >
                        <Controller
                          name={`attachRegistrations.${idx}.fullName`}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <Input
                              {...field}
                              placeholder="あおしすあおい"
                              onPressEnter={(e) => e.preventDefault()}
                            />
                          )}
                        />
                      </Form.Item>
                    )}
                    {occasion?.childAgeShow && (
                      <Form.Item
                        name="age"
                        label="年齢"
                        rules={[
                          {
                            required:
                              occasion?.childAgeShow && occasion?.childAgeRq,
                            message: "",
                          },
                        ]}
                        style={{ marginBottom: -5 }}
                      >
                        <Row gutter={[8, 8]}>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Controller
                              name={`attachRegistrations.${idx}.age`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <Form.Item
                                  help={error ? error.message : undefined}
                                  validateStatus={error ? "error" : undefined}
                                >
                                  <Space size={8}>
                                    <Select
                                      style={{ width: "90px" }}
                                      {...field}
                                      options={Array.from(
                                        { length: 19 },
                                        (_, i) => ({
                                          value: i,
                                          label: i,
                                          disabled:
                                            occasion?.childAgeLimitShow &&
                                            !(
                                              i >= occasion?.minAge &&
                                              i <= occasion?.maxAge
                                            ),
                                        })
                                      )}
                                    />
                                    歳
                                  </Space>
                                </Form.Item>
                              )}
                            />
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Controller
                              name={`attachRegistrations.${idx}.month`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <Form.Item
                                  help={error ? error.message : undefined}
                                  validateStatus={error ? "error" : undefined}
                                >
                                  <Space size={8}>
                                    <Select
                                      {...field}
                                      style={{ width: "90px" }}
                                      options={Array.from(
                                        { length: 11 },
                                        (_, i) => ({
                                          value: i + 1,
                                          label: i + 1,
                                        })
                                      )}
                                    />
                                    ヶ月
                                  </Space>
                                </Form.Item>
                              )}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                    )}

                    {occasion?.childNoteShow && (
                      <Form.Item
                        name={`attachRegistrations.${idx}.note`}
                        help={
                          errors?.attachRegistrations &&
                          errors?.attachRegistrations[idx]?.note
                            ? errors?.attachRegistrations[idx]?.note.message
                            : undefined
                        }
                        validateStatus={
                          errors?.attachRegistrations &&
                          errors?.attachRegistrations[idx]?.note
                            ? "error"
                            : undefined
                        }
                        label={
                          occasion.childNoteLabel !== ""
                            ? occasion.childNoteLabel
                            : "メッセージ　（レースへの意気込み）"
                        }
                        rules={[
                          {
                            required:
                              occasion?.childNoteShow && occasion?.childNoteRq,
                            message: "",
                          },
                        ]}
                        style={{ marginBottom: -5 }}
                      >
                        <Controller
                          name={`attachRegistrations.${idx}.note`}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <Input.TextArea
                              {...field}
                              rows={4}
                            ></Input.TextArea>
                          )}
                        />
                      </Form.Item>
                    )}
                  </Collapse.Panel>
                )
              })}
            </Collapse>
          </>
        )}

        {occasion?.generalNoteShow && (
          <Form.Item
            name={`generalNote`}
            label={
              occasion.generalNoteLabel !== ""
                ? occasion.generalNoteLabel
                : "メッセージ（一般)"
            }
            help={
              errors?.generalNote && errors?.generalNote
                ? errors?.generalNote.message
                : undefined
            }
            validateStatus={
              errors?.generalNote && errors?.generalNote ? "error" : undefined
            }
            rules={[
              {
                required: occasion?.generalNoteShow && occasion?.generalNoteRq,
                message: "メッセージ（一般）は空白のままにすることはできません",
              },
            ]}
            style={{ width: "90%", marginLeft: "15px", marginBottom: 0 }}
          >
            <Controller
              name={`generalNote`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input.TextArea {...field} rows={4}></Input.TextArea>
              )}
            />
          </Form.Item>
        )}
      </Form>
    </div>
  )
}

const OccasionModal = (props) => (
  <OccasionModalLayout {...useOccasionModal(props)} />
)

OccasionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
}

OccasionModalLayout.propTypes = {
  control: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  handleAddParticipationChild: PropTypes.func.isRequired,
  removeParticipationChild: PropTypes.func.isRequired,
  attachRegistrations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      age: PropTypes.number,
      month: PropTypes.number,
      message: PropTypes.string,
    })
  ),
}

export default OccasionModal
