import React, { useState, useEffect, useCallback, useRef } from "react"
import { withRouter, Link } from "react-router-dom"
import interactionPlugin from "@fullcalendar/interaction"
import momentPlugin from "@fullcalendar/moment"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import dayGridPlugin from "@fullcalendar/daygrid"

import { Button, Col, message, Row, Modal, Image as AntdImage } from "antd"
import styled from "styled-components"
import moment from "moment"
import "moment/locale/ja"
import liff from "@line/liff"
import * as Commons from "common/common"

moment.locale("ja")

const CalendarWrapper = styled.div`
  .fc .fc-button {
    padding: 0;
    background-color: #fff;
    color: #00bcd4;
    border-radius: 0;
    border: 1px solid #00bcd4;
    vertical-align: bottom;
    margin-bottom: 0.5rem;
  }

  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
  }

  .fc .fc-button-primary:focus {
    box-shadow: none;
  }

  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #00bcd4;
    border-color: #00bcd4;
  }

  .fc .fc-toolbar-title {
    display: inline-block;
    vertical-align: middle;
    margin-left: 1em;
    margin-right: 1em;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: bold;
    white-space: pre-wrap;
    text-align: center;
  }

  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    flex-direction: column;

    .fc-CustomPrevMonth-button,
    .fc-CustomThisMonth-button,
    .fc-CustomNextMonth-button,
    .fc-prev-button,
    .fc-next-button {
      line-height: 1.5715;
      position: relative;
      display: inline-block;
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      background-image: none;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
      cursor: pointer;
      transition: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      touch-action: manipulation;
      height: 32px;
      padding: 4px 15px;
      font-size: 14px;
      border-radius: 2px;

      margin: 0.25rem;
      color: #434343;
      border-color: #d9d9d9;
      background: #fff;
      border-style: dashed;
    }
  }

  .fc .fc-timeline-header-row-chrono .fc-timeline-slot-frame {
    justify-content: center;
  }

  .fc-datagrid-cell-frame {
    background-color: #00b3c4;
    color: white;
    font-weight: bold;
  }

  .fc-day-sat {
    color: #00c2ff;
  }

  .fc-day-sun {
    color: #c40055;
  }

  .fc-daygrid-day-top {
    justify-content: center;

    .fc-cell-date-text {
    }
  }

  .event-availability-container {
    color: #ff9600;
  }

  .fc-daygrid-day-top .fc-cell-date-text {
    color: black;
  }

  .fc-day-sun .fc-daygrid-day-top .fc-cell-date-text {
    color: red;
  }

  .fc-day-sat .fc-daygrid-day-top .fc-cell-date-text {
    color: #21acd7;
  }

  /* Day that has no availability event */
  .fc-daygrid-event:has(.event-unavailable) {
    color: rgba(0, 0, 0, 0.3);
    .event-unavailable {
      opacity: 0.3;
    }
  }

  .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
    display: flex;
    flex-direction: column;

    .fc-daygrid-day-events {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      > .fc-daygrid-event-harness {
        display: flex;
        flex-grow: 1;
        justify-content: center;

        > .fc-h-event {
          background-color: rgba(0, 0, 0, 0);
          border: none;
        }

        > .fc-daygrid-event.fc-daygrid-dot-event.fc-event {
          width: 100%;
          cursor: default;
          background-color: none;

          :hover {
            background: rgba(0, 0, 0, 0);
          }
        }
      }
    }
  }

  /* Display X mark when there is no availability event */
  .fc-daygrid-day-events:not(:has(.event-availability-container))::after {
    content: "×";
    color: rgb(0, 0, 0, 0.3);
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
    /* Other styles for the ::after pseudo-element */
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* Make unavailable day text more opaque */
  .fc-daygrid-day-frame:not(:has(.event-availability-container))
    .fc-daygrid-day-top
    .fc-cell-date-text {
    opacity: 0.3;
  }

  /* Do not show the X mark when the day is disabled */
  .fc-day-disabled
    .fc-daygrid-day-events:not(:has(.event-availability-container))::after {
    content: "";
  }

  /* Remove focus background from events */
  .fc-event:focus {
    box-shadow: none;
  }
  .fc-event:focus:after {
    content: "";
    background: none;
  }
`

const LiffCalendar = (props) => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props
  const occasionId = parseInt(props.match.params.id)
  const isMountedRef = Commons.useIsMountedRef()
  const occasionCalendarRef = useRef(null)

  const [occasionAvailability, setOccasionAvailability] = useState([])

  const fetchOccasionMonthAvailability = (searchYearMonth = new Date()) => {
    showLoadingPageSpin()

    let headerData = {}

    try {
      headerData.headers = {
        "access-token": liff.getAccessToken(),
      }
    } catch (error) {
      history.push(Commons.liffLoginRoute)
    }

    return Commons.axiosInstance
      .get(
        Commons.apiLiffOccasionDetail +
          "/" +
          occasionId +
          "/availability?date=" +
          moment(searchYearMonth).format(),
        headerData
      )
      .then((response) => {
        if (!isMountedRef.current && !response && !response.data) return

        setOccasionAvailability(response.data || [])
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        hideLoadingPageSpin()
      })
  }

  // Click this month button when the component is mounted fixing to fix Safari rendering issue for :after x and o overlapping
  // https://stackoverflow.com/questions/3485365/how-can-i-force-webkit-to-redraw-repaint-to-propagate-style-changes
  const clickThisMonthButton = () => {
    if (!occasionCalendarRef?.current) return
    const fCalendarThisMonthBtn = document?.getElementsByClassName(
      "fc-CustomThisMonth-button"
    )?.[0]
    if (fCalendarThisMonthBtn) {
      fCalendarThisMonthBtn?.click()
    }
  }

  useEffect(() => {
    // Explicitly trigger fetchOccasionMonthAvailability through a click to prompt Safari to repaint
    clickThisMonthButton()
    // eslint-disable-next-line
  }, [])

  const renderDaysWithEvents = (arg) =>
    arg?.event?.extendedProps?.hasAvailability ? (
      <Row
        justify="center"
        className="flex-row flex-grow event-availability-container text-2xl"
      >
        <Col>⭘</Col>
      </Row>
    ) : (
      <Row
        justify="center"
        className="flex-row flex-grow text-sm event-unavailable"
      >
        <Col></Col>
        {/* <Col>×</Col> */}
      </Row>
    )

  return (
    <CalendarWrapper>
      <FullCalendar
        locale="ja"
        ref={occasionCalendarRef}
        plugins={[
          timeGridPlugin,
          dayGridPlugin,
          interactionPlugin,
          momentPlugin,
        ]}
        initialView="dayGridMonth"
        showNonCurrentDates={false}
        fixedWeekCount={false}
        height="auto"
        buttonIcons={{
          prev: "chevron-left",
          next: "chevron-right",
        }}
        customButtons={{
          CustomPrevMonth: {
            icon: "chevron-left",
            click: (ev, el) => {
              occasionCalendarRef?.current?.getApi()?.prev()
              fetchOccasionMonthAvailability(
                occasionCalendarRef.current.getApi().getDate()
              )
            },
          },
          CustomThisMonth: {
            text: "今月",
            click: (ev, el) => {
              occasionCalendarRef?.current?.getApi()?.today()
              fetchOccasionMonthAvailability(
                occasionCalendarRef.current.getApi().getDate()
              )
            },
          },
          CustomNextMonth: {
            icon: "chevron-right",
            click: (ev, el) => {
              occasionCalendarRef?.current?.getApi()?.next()
              fetchOccasionMonthAvailability(
                occasionCalendarRef.current.getApi().getDate()
              )
            },
          },
        }}
        headerToolbar={{
          left: "",
          center: "title",
          right: "CustomPrevMonth,CustomThisMonth,CustomNextMonth",
        }}
        titleFormat={(input) => moment(input.date).format("YYYY年M月")}
        nowIndicator={true}
        // validRange={(nowDate) => ({
        //   start: moment(nowDate).format("YYYY-MM-01"),
        // })}
        selectable={false}
        events={occasionAvailability}
        dayCellContent={(arg) => (
          <div className="fc-cell-date-text">{arg.dayNumberText}</div>
        )}
        eventContent={renderDaysWithEvents}
        eventClick={(eventInfo) => {
          if (eventInfo.event.extendedProps.hasAvailability) {
            const eventDate = moment(eventInfo.event.start).format()
            const eventDateEnd = moment(eventInfo.event.end).format()
            history.push(
              `${Commons.liffOccurrencesRoute}/${occasionId}?from=${eventDate}&to=${eventDateEnd}`
            )
          }
        }}
        // select={(selectInfo) => {
        //   console.log(selectInfo)
        // }}
      />
    </CalendarWrapper>
  )
}

const LiffOccasionCalendar = (props) => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props
  const occasionId = parseInt(props.match.params.id)
  const isMountedRef = Commons.useIsMountedRef()

  const [occasion, setOccasion] = useState({})

  const fetchOccasionDetail = useCallback(() => {
    showLoadingPageSpin()

    let headerData = {}

    try {
      headerData.headers = {
        "access-token": liff.getAccessToken(),
      }
    } catch (error) {
      history.push(Commons.liffLoginRoute)
    }

    Commons.axiosInstance
      .get(Commons.apiLiffOccasionDetail + "/" + occasionId, headerData)
      .then((response) => {
        if (!isMountedRef.current && !response && !response.data) return

        setOccasion(response.data || {})
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        hideLoadingPageSpin()
      })
  }, [
    isMountedRef,
    history,
    occasionId,
    showLoadingPageSpin,
    hideLoadingPageSpin,
  ])

  useEffect(() => {
    fetchOccasionDetail()

    // eslint-disable-next-line
  }, [])

  const handleNextPage = () => {
    const now = moment()
    const isEarly = now.isSameOrBefore(occasion.startRegister)
    const isLate = now.isSameOrAfter(occasion.endRegister)

    if (
      !occasion.isOpenRegistration ||
      (occasion.isOpenRegistration && !isEarly && !isLate)
    ) {
      history.push(`${Commons.liffOccurrencesRoute}/${occasionId}`)
    } else {
      Modal.info({
        title: "確認",
        content: isEarly
          ? "応募期間の前ですので応募できません。"
          : "応募期間を過ぎましたので応募できません。",
        okText: "確認",
        centered: true,
      })
    }
  }

  return (
    <>
      <Row gutter={[8, 8]} className="mx-4">
        <Col span={24} className="text-center">
          <img
            src="/logo-header.png"
            alt="ロゴ"
            style={{ maxHeight: "120px" }}
            className="max-w-full mt-2 mx-4"
          />
        </Col>
        <Col span={24}>
          <div className="bg-primary text-center p-4 mt-4 rounded">
            <span className="text-white text-lg font-bold whitespace-pre-wrap">
              {"ご希望のイベント日程を\nお選びください"}
            </span>
          </div>
        </Col>
        <Col span={24} className="text-center">
          <AntdImage
            preview={false}
            src={
              occasion?.occasionImages &&
              occasion?.occasionImages?.length > 0 &&
              occasion.occasionImages[0]?.picUrl
                ? `${Commons.occasionImagesURL}${occasion.occasionImages[0]?.picUrl}`
                : "/no-image.png"
            }
            fallback="/no-image.png"
            alt={occasion.title}
            style={{ maxHeight: "320px" }}
            className="rounded max-w-full"
          />
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Col className="text-center">
              <span className="text-sm font-bold">{occasion.title || ""}</span>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <LiffCalendar {...props} />
        </Col>
        <Col span={24}>
          <Row gutter={[8, 8]} justify="center" className="m-4">
            <Col span={12}>
              <Link to={`${Commons.liffOccasionsRoute}/${occasionId}`}>
                <Button size="large" block>
                  戻る
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default withRouter(LiffOccasionCalendar)
