import {
  CommentOutlined,
  DeleteOutlined,
  QrcodeOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Avatar,
  Button,
  Col,
  Form,
  Grid,
  Image,
  Input,
  Layout,
  Modal,
  Row,
  Spin,
  Tooltip,
  message,
  notification,
} from "antd"
import { decode } from "base-64"
import * as Commons from "common/common"
import moment from "moment"
import "moment/locale/ja"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { isMobile } from "react-device-detect"
import { useFieldArray, useForm } from "react-hook-form"
import OnImagesLoaded from "react-on-images-loaded"
import QrReader from "react-qr-reader"
import { withRouter } from "react-router-dom"
import io from "socket.io-client"
import styled from "styled-components"
import * as yup from "yup"
import QRCheckIn from "./QRCheckIn"
import { Sidebar, Topbar } from "./components"

moment.locale("ja")

const CustomTextArea = styled(Input.TextArea)`
  .ant-input {
    border: 1px solid #21acd7;
    border-radius: 0.25rem;
    padding: 1rem;
  }

  .ant-input-affix-wrapper {
    border-radius: 0.25rem;
  }

  &::after {
    color: #21acd7;
  }
`
const DEFAULT_PARTICIPATION_CHILDREN = {
  fullName: "",
  age: null,
  month: null,
  note: "",
}

const DEFAULT_VALUE = {
  attendedGrownup: 0,
  attendedChild: 0,
  attendedPreschool: 0,
  attendedGuardian: 0,

  attachRegistrations: [DEFAULT_PARTICIPATION_CHILDREN],
}

const Main = (props) => {
  const { children, history } = props
  const { Header, Sider, Content } = Layout
  const { useBreakpoint } = Grid

  const isMountedRef = Commons.useIsMountedRef()
  const lineChatModalVisibleRef = useRef()
  const currentUserRef = useRef()

  const [form] = Form.useForm()
  const [lineForm] = Form.useForm()

  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [messageContainer, setMessageContainer] = useState(null)
  const [chatImagesDone, setChatImagesDone] = useState(false)
  const [qrModalVisible, setQrModalVisible] = useState(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [lineModalVisible, setLineModalVisible] = useState(false)

  const [registration, setRegistration] = useState({})
  const [chats, setChats] = useState([])
  const [currentUser, setCurrentUser] = useState(false)

  const schema = yup.object().shape({
    attendedGuardian: yup.number(),
    attachRegistrations: yup.array().of(
      yup.object({
        fullName: yup.string().required("※ひらがなで入力してください"),
        // age: yup.number().required("年齢は必要です。"),
        age:
          registration?.childAgeLimitShow && registration?.childAgeLimitRq
            ? yup.number().required("年齢は必要です。")
            : yup.number().nullable().optional(),
        // month: yup.number(),
        note: yup.string().required("メッセージは必須です。"),
      })
    ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: Object.assign({}, DEFAULT_VALUE),
    mode: "onChange",
  })

  const {
    fields: attachRegistrations,
    append: appendParticipationChild,
    remove: removeParticipationChild,
  } = useFieldArray({
    control,
    name: "attachRegistrations",
  })

  const qrRef = useRef()

  const breakpoint = useBreakpoint()
  const isHamburger = !breakpoint.xl

  const handleAddParticipationChild = (event) => {
    if (attachRegistrations.length === 5) {
      event.preventDefault()
    } else {
      appendParticipationChild(DEFAULT_PARTICIPATION_CHILDREN)
    }
  }
  const genExtra = (id) => (
    <DeleteOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation()
        removeParticipationChild(id)
      }}
    />
  )

  const collapse = () => {
    setIsCollapsed(true)
  }

  const expand = () => {
    setIsCollapsed(false)
  }

  const collapseToggle = () => {
    if (isCollapsed) {
      expand()
    } else {
      collapse()
    }
  }

  const addAdultCount = () => {
    form.setFieldsValue({
      confirmAdultCount: form.getFieldValue("confirmAdultCount") + 1,
    })
  }

  const subtractAdultCount = () => {
    if (form.getFieldValue("confirmAdultCount") > 1) {
      form.setFieldsValue({
        confirmAdultCount: form.getFieldValue("confirmAdultCount") - 1,
      })
    }
  }

  const addChildCount = () => {
    form.setFieldsValue({
      confirmChildCount: form.getFieldValue("confirmChildCount") + 1,
    })
  }

  const subtractChildCount = () => {
    if (form.getFieldValue("confirmChildCount") > 0) {
      form.setFieldsValue({
        confirmChildCount: form.getFieldValue("confirmChildCount") - 1,
      })
    }
  }

  const addPreschoolerCount = () => {
    form.setFieldsValue({
      confirmPreschoolerCount:
        form.getFieldValue("confirmPreschoolerCount") + 1,
    })
  }

  const subtractPreschoolerCount = () => {
    if (form.getFieldValue("confirmPreschoolerCount") > 0) {
      form.setFieldsValue({
        confirmPreschoolerCount:
          form.getFieldValue("confirmPreschoolerCount") - 1,
      })
    }
  }

  const showLoadingPageSpin = () => {
    setIsPageLoading(true)
  }

  const hideLoadingPageSpin = () => {
    setIsPageLoading(false)
  }

  const hideQRModal = () => {
    setQrModalVisible(false)
  }

  const showQRModal = () => {
    setQrModalVisible(true)
  }

  const showConfirmModal = (data) => {
    form.setFieldsValue({
      confirmAdultCount: data.expectedGrownup || 0,
      confirmChildCount: data.expectedChild || 0,
      confirmPreschoolerCount: data.expectedPreschool || 0,
      attendedGuardian: data.expectedGuardian || 0,
    })
    setConfirmModalVisible(true)
  }

  const hideConfirmModal = () => {
    setConfirmModalVisible(false)
  }

  const handleQRError = (err) => {
    console.log(err)
  }

  const handleQRScan = (data) => {
    if (data) {
      if (typeof data === "string" || data instanceof String) {
        hideQRModal()

        let decodedData = ""

        try {
          decodedData = decode(data).split("-")

          if (decodedData.length === 2) {
            if (decodedData[0] === "E" && !isNaN(decodedData[1])) {
              checkRegistration(decodedData[1])
            } else {
              message.warning(Commons.errorQrWrongMsg)
            }
          } else {
            message.warning(Commons.errorQrWrongMsg)
          }
        } catch (e) {
          message.warning(Commons.errorQrWrongMsg)
        }
      } else {
        message.warning(Commons.errorQrWrongMsg)
      }
    }
  }

  const showLineModal = (customerId, customerName) => {
    if (isMountedRef.current) {
      showLoadingPageSpin()

      Commons.axiosInstance
        .get(Commons.apiChats + "/" + customerId)
        .then((response) => {
          if (isMountedRef.current && response) {
            setChats(response.data || [])
            setCurrentUser({
              customerId: customerId,
              customerName: customerName,
            })

            scrollToChatBottom()
            setLineModalVisible(true)
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    }
  }

  const lineFormOnFinish = (data) => {
    if (isMountedRef.current) {
      showLoadingPageSpin()

      const postData = {
        customerId: currentUser.customerId,
        contents: data.lineMessageInput,
      }

      Commons.axiosInstance
        .post(Commons.apiChats, postData)
        .then((response) => {
          if (response.status === 200) {
            message.success(Commons.successSentMsg)
            lineForm.resetFields()
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    }
  }

  const hideLineModal = () => {
    lineForm.resetFields()
    setChats([])
    setChatImagesDone(false)
    setCurrentUser(false)
    setLineModalVisible(false)
  }

  const scrollToChatBottom = useCallback(() => {
    setTimeout(() => {
      messageContainer.scrollIntoView({ behavior: "smooth" })
    }, 100)
  }, [messageContainer])

  const childrenWithProps = React.Children.map(children, (element) =>
    React.cloneElement(element, {
      showLoadingPageSpin: showLoadingPageSpin,
      hideLoadingPageSpin: hideLoadingPageSpin,
      isPageLoading: isPageLoading,
      showLineModal: showLineModal,
    })
  )

  useEffect(() => {
    if (isHamburger) {
      collapse()
    } else {
      expand()
    }
  }, [isHamburger])

  useEffect(() => {
    lineChatModalVisibleRef.current = lineModalVisible
    currentUserRef.current = currentUser
  }, [lineModalVisible, currentUser])

  useEffect(() => {
    if (messageContainer && chatImagesDone) {
      scrollToChatBottom()
    }
  }, [messageContainer, chatImagesDone, scrollToChatBottom])

  useEffect(() => {
    const socket = io(Commons.siteURL, { path: "/socket.io" })

    socket.on("newMessage", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (lineChatModalVisibleRef.current) {
          if (
            currentUserRef?.current?.customerId &&
            currentUserRef?.current?.customerId === response.customerId
          ) {
            showLineModal(
              currentUserRef.current.customerId,
              currentUserRef.current.customerName
            )
          }
        } else {
          if (response.source && response.source === "customer") {
            notification.open({
              key: response.customerId,
              message: `${response.fullName || ""}`,
              description: "新しいチャットが来ました",
              icon: <CommentOutlined style={{ color: "#52c41a" }} />,
              placement: "bottomRight",
              style: { cursor: "pointer" },
              duration: 10,
              onClick: () => {
                notification.close(response.customerId)
                if (response.customerId && response.fullName)
                  showLineModal(response.customerId, response.fullName)
              },
            })
          }
        }
      }
    })

    return () => {
      socket.off("newMessage")

      socket.disconnect()
    }

    // eslint-disable-next-line
  }, [])

  const checkRegistration = (registrationId) => {
    if (isMountedRef.current) {
      showLoadingPageSpin()

      const paramData = {
        registrationId,
      }

      Commons.axiosInstance
        .post(Commons.apiRegistrations, paramData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data) {
              if (
                response.data.attended === 0 &&
                response.data.attendedGrownup === 0 &&
                response.data.attendedChild === 0 &&
                response.data.attendedPreschool === 0
              ) {
                setValue(
                  "attachRegistrations",
                  response?.data?.attended
                    ? response?.data?.attendedAttachRegistrations
                    : response?.data?.expectedAttachRegistrations
                )
                setValue("attendedGuardian", response?.data?.expectedGuardian)

                setRegistration(response.data)
                showConfirmModal(response.data)
              } else {
                message.warning(Commons.errorQrAlreadyUsedMsg)
              }
            } else {
              message.error(Commons.errorSystemMsg)
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            message.warning(Commons.errorQrNotExistMsg)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    }
  }

  const confirmRegistration = (data) => {
    if (isMountedRef.current) {
      showLoadingPageSpin()

      const paramData = {
        registrationId: registration.registrationId,
        customerId: registration.customerId,
        attendedGrownup: data.confirmAdultCount,
        attendedChild: data.confirmChildCount,
        attendedPreschool: data.confirmPreschoolerCount,
        attendedGuardian: 0,
      }

      Commons.axiosInstance
        .put(Commons.apiRegistrations, paramData)
        .then((response) => {
          if (response.status === 200) {
            message.success(Commons.successQrEventMsg)
            hideConfirmModal()
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    }
  }

  const onSubmit = (data) => {
    if (isMountedRef.current) {
      showLoadingPageSpin()

      const paramData = {
        registrationId: registration.registrationId,
        customerId: registration.customerId,
        ...data,
      }

      Commons.axiosInstance
        .put(Commons.apiRegistrations, paramData)
        .then((response) => {
          if (response.status === 200) {
            message.success(Commons.successQrEventMsg)
            hideConfirmModal()
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    }
  }

  return (
    <div className="flex flex-col w-full min-h-full">
      <Spin
        spinning={isPageLoading}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Layout className="min-h-full">
          {isHamburger ? (
            isCollapsed ? (
              <div></div>
            ) : (
              <div
                onClick={() => collapse()}
                className="absolute top-0 left-0 bg-black bg-opacity-50 w-full h-full z-50"
              ></div>
            )
          ) : isCollapsed ? (
            <div
              style={{
                flex: "0 0 80px",
                minWidth: "80px",
                maxWidth: "80px",
                overflow: "hidden",
              }}
            ></div>
          ) : (
            <div
              style={{
                flex: "0 0 200px",
                minWidth: "200px",
                maxWidth: "200px",
                overflow: "hidden",
              }}
            ></div>
          )}
          <Sider
            theme="light"
            collapsible
            collapsed={isCollapsed}
            collapsedWidth={isHamburger ? 0 : 80}
            trigger={null}
            className="fixed top-0 left-0 h-full shadow z-50 border-r border-solid border-gray-300"
          >
            <Sidebar
              isHamburger={isHamburger}
              isCollapsed={isCollapsed}
              collapseToggle={collapseToggle}
            />
          </Sider>
          <Layout className="min-h-screen">
            <Header className="p-0 shadow">
              <Topbar
                isHamburger={isHamburger}
                collapseToggle={collapseToggle}
              />
            </Header>
            <Content className="m-4 bg-white border-solid border border-gray-300 shadow">
              {isMobile ? (
                <>
                  <div
                    className="fixed z-10 bg-white border border-primary rounded p-1 right-0"
                    onClick={showQRModal}
                  >
                    <Row justify="center">
                      <Col span={24}>
                        <QrcodeOutlined className="text-4xl" />
                      </Col>
                    </Row>
                  </div>
                  <Modal
                    visible={qrModalVisible}
                    title="QR読み取り"
                    onCancel={hideQRModal}
                    footer={null}
                    centered
                    destroyOnClose
                  >
                    {qrModalVisible ? (
                      <QrReader
                        ref={qrRef}
                        delay={300}
                        facingMode={"environment"}
                        onError={handleQRError}
                        onScan={handleQRScan}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      ""
                    )}
                  </Modal>
                  <QRCheckIn
                    registration={registration}
                    showLoadingPageSpin={showLoadingPageSpin}
                    hideLoadingPageSpin={hideLoadingPageSpin}
                    confirmModalVisible={confirmModalVisible}
                    hideConfirmModal={hideConfirmModal}
                  />
                </>
              ) : (
                ""
              )}
              <Modal
                title={
                  currentUser && currentUser.customerName
                    ? currentUser.customerName
                    : "LINEチャット"
                }
                visible={lineModalVisible}
                onCancel={hideLineModal}
                footer={null}
                bodyStyle={{
                  height: "90vh",
                  overflow: "auto",
                  padding: 0,
                }}
                centered
                destroyOnClose
              >
                <div
                  className="pb-4 overflow-auto"
                  style={{
                    backgroundColor: "#fafafa",
                    minHeight: "60vh",
                    maxHeight: "60vh",
                  }}
                >
                  <OnImagesLoaded
                    onLoaded={() => {
                      setChatImagesDone(true)
                    }}
                  >
                    {chats && chats.length > 0
                      ? chats.map((chat) => (
                          <Row key={chat.chatId}>
                            <Col
                              span={22}
                              offset={chat.source === "user" ? 0 : 2}
                            >
                              <div className="m-1">
                                {chat.source === "user" ? (
                                  <>
                                    <div className="flex flex-row items-start justify-start">
                                      <div className="mr-1">
                                        <Avatar
                                          style={{
                                            backgroundColor: "#52c41a",
                                          }}
                                          icon={<UserOutlined />}
                                        />
                                      </div>
                                      {chat.chatType === "text" ? (
                                        <div
                                          className="text-white rounded-lg p-2 cursor-pointer mr-1"
                                          style={{
                                            backgroundColor: "#52c41a",
                                          }}
                                        >
                                          <span className="whitespace-pre-wrap">
                                            {chat.contents || ""}
                                          </span>
                                        </div>
                                      ) : chat.chatType === "picture" ? (
                                        <div className="rounded-lg cursor-pointer mr-1">
                                          <Image
                                            src={`${Commons.chatMediaURL}${chat.contents}`}
                                            style={{
                                              maxHeight: "300px",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="flex flex-row items-start justify-start">
                                      <span
                                        className="whitespace-pre-wrap"
                                        style={{
                                          fontSize: "10px",
                                          color: "#8c8c8c",
                                          marginLeft: "35px",
                                        }}
                                      >
                                        {chat.createdAt
                                          ? moment(chat.createdAt).format(
                                              "YYYY年M月D日 HH:mm"
                                            )
                                          : ""}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="flex flex-row items-start justify-end">
                                      <div
                                        className="text-white rounded-lg p-2 cursor-pointer"
                                        style={{
                                          backgroundColor: "#21acd7",
                                        }}
                                      >
                                        <span className="whitespace-pre-wrap">
                                          {chat.contents || ""}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex flex-row items-start justify-end">
                                      <span
                                        className="whitespace-pre-wrap"
                                        style={{
                                          fontSize: "10px",
                                          color: "#8c8c8c",
                                        }}
                                      >
                                        {chat.createdAt
                                          ? moment(chat.createdAt).format(
                                              "YYYY年M月D日 HH:mm"
                                            )
                                          : ""}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </Col>
                          </Row>
                        ))
                      : ""}
                  </OnImagesLoaded>
                  <div ref={setMessageContainer}></div>
                </div>
                <div
                  className="p-4 border-t-2 border-solid border-primary"
                  style={{ backgroundColor: "#f0feff" }}
                >
                  <Row>
                    <Col span={24}>
                      <Form
                        form={lineForm}
                        name="lineMessageForm"
                        onFinish={lineFormOnFinish}
                        colon={false}
                        requiredMark={false}
                      >
                        <Row align="top" gutter={[8, 8]}>
                          {currentUser ? (
                            <Col span={24}>
                              <div className="block text-right">
                                <Tooltip title="送る" placement="top">
                                  <Button
                                    type="primary"
                                    size="large"
                                    className="ml-1 my-1"
                                    style={{ borderColor: "#FFF" }}
                                    icon={
                                      <SendOutlined style={{ color: "#FFF" }} />
                                    }
                                    htmlType="submit"
                                    loading={isPageLoading}
                                  />
                                </Tooltip>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          <Col span={24}>
                            <Form.Item
                              className="block"
                              name="lineMessageInput"
                              rules={[
                                {
                                  required: true,
                                  message: "メッセージを入力してください",
                                },
                              ]}
                            >
                              <CustomTextArea
                                allowClear
                                bordered
                                showCount
                                autoSize={{ minRows: 5, maxRows: 10 }}
                                maxLength={5000}
                                placeholder="メッセージを入力してください"
                                autoFocus={true}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Modal>
              {childrenWithProps}
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </div>
  )
}

export default withRouter(Main)
