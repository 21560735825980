import { Card, Image as AntdImage } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Dot,
  Image as CarouselImage,
  Slide,
  Slider,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import styled from "styled-components"
import * as Commons from "common/common"

const CustomDotGroup = styled.div`
  & {
    display: block;
    text-align: center;
  }
  .carousel__dot {
    border: 1px solid #7cc7d6;
    border-radius: 0.25rem;
  }
  .carousel__dot--selected {
    border: 1px solid #21acd7;
    border-radius: 0.25rem;
    opacity: 0.4;
  }
`

const ImageCarousel = ({ occasion }) => (
  <Card
    bordered={false}
    cover={
      occasion?.occasionImages && occasion?.occasionImages?.length > 0 ? (
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={occasion.occasionImages.length}
          visibleSlides={1}
          dragEnabled={false}
          lockOnWindowScroll
          infinite
          className="flex-col w-full"
        >
          <div className="relative">
            <Slider className="w-full" style={{ maxHeight: "350px" }}>
              {occasion.occasionImages.map((image) => (
                <Slide key={image?.showOrder} index={image?.showOrder}>
                  {image?.picUrl ? (
                    <CarouselImage
                      src={`${Commons.occasionImagesURL}${image?.picUrl}`}
                      renderError={
                        <CarouselImage
                          src="/no-image.png"
                          alt={occasion?.title || ""}
                          style={{
                            maxHeight: "350px",
                          }}
                          className="rounded object-contain max-w-full"
                        />
                      }
                      alt={occasion?.title || ""}
                      className="rounded object-contain max-w-full"
                      style={{ maxHeight: "350px" }}
                    />
                  ) : (
                    <CarouselImage
                      src="/no-image.png"
                      alt={occasion?.title || ""}
                      style={{
                        maxHeight: "350px",
                      }}
                      className="rounded object-contain max-w-full"
                    />
                  )}
                </Slide>
              ))}
            </Slider>
            <div className="absolute top-40 left-1">
              <ButtonBack className="rounded-full bg-white/80 border w-12 border-primary">
                <LeftOutlined className="px-1 py-4 text-primary" />
              </ButtonBack>
            </div>
            <div className="absolute top-40 right-1">
              <ButtonNext className="rounded-full bg-white/80 border w-12 border-primary">
                <RightOutlined className="px-1 py-4 text-primary" />
              </ButtonNext>
            </div>
          </div>
          <div className="mt-1">
            <CustomDotGroup>
              {occasion.occasionImages.map((image) => (
                <Dot
                  key={image?.showOrder}
                  slide={image?.showOrder}
                  className="inline-block p-0 mr-1"
                >
                  {image?.picUrl ? (
                    <CarouselImage
                      src={`${Commons.occasionImagesURL}${image?.picUrl}`}
                      renderError={
                        <CarouselImage
                          src="/no-image.png"
                          alt={occasion?.title || ""}
                          className="inline-block object-cover relative rounded"
                          style={{
                            maxHeight: "50px",
                            width: "auto",
                          }}
                        />
                      }
                      alt={occasion?.title || ""}
                      className="inline-block object-cover relative rounded"
                      style={{
                        maxHeight: "50px",
                        width: "auto",
                      }}
                    />
                  ) : (
                    <CarouselImage
                      src="/no-image.png"
                      alt={occasion?.title || ""}
                      className="inline-block object-cover rounded relative"
                      style={{
                        maxHeight: "50px",
                        width: "auto",
                      }}
                    />
                  )}
                </Dot>
              ))}
            </CustomDotGroup>
          </div>
        </CarouselProvider>
      ) : (
        <AntdImage
          preview={false}
          src="/no-image.png"
          alt={occasion?.title || ""}
          height={350}
          width="100%"
          className="object-contain"
        />
      )
    }
  />
)

export default ImageCarousel
