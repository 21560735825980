import React, { useState, useEffect, useCallback, useRef } from "react"
import { withRouter } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Modal,
  message,
  Space,
  Typography,
  Select,
} from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { decode } from "base-64"
import * as Commons from "common/common"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import QRCheckIn from "../../layouts/Main/QRCheckIn"
import QrScannerComponent from "components/QrScanner"
import { displayError, ErrorCodes } from "common/error"

const { Option } = Select

const DEFAULT_PARTICIPATION_CHILDREN = {
  fullName: "",
  age: null,
  month: null,
  note: "",
}

const DEFAULT_VALUE = {
  attendedGrownup: 0,
  attendedChild: 0,
  attendedPreschool: 0,
  attendedGuardian: 0,
  attachRegistrations: [DEFAULT_PARTICIPATION_CHILDREN],
}

const Login = (props) => {
  const { history } = props
  const [form] = Form.useForm()
  const [qrScannerForm] = Form.useForm()
  const isMountedRef = Commons.useIsMountedRef()
  const { Text } = Typography

  const qrScanInputRef = useRef(null)
  const [qrCodeScannerInputFocusId, setQrCodeScannerInputFocusId] =
    useState(undefined)

  const [isLoginLoading, setIsLoginLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [qrModalVisible, setQrModalVisible] = useState(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [registration, setRegistration] = useState({})

  const schema = yup.object().shape({
    attendedGuardian: yup.number(),
    attachRegistrations: yup.array().of(
      yup.object({
        fullName: yup.string().required("※ひらがなで入力してください"),
        age:
          registration?.childAgeLimitShow && registration?.childAgeLimitRq
            ? yup.number().required("年齢は必要です。")
            : yup.number().nullable().optional(),
        note: yup.string().required("メッセージは必須です。"),
      })
    ),
  })

  const { setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: Object.assign({}, DEFAULT_VALUE),
    mode: "onChange",
  })

  const checkSession = useCallback(() => {
    Commons.axiosInstance
      .get(Commons.apiCheckSession)
      .then((response) => {
        history.push(Commons.rootURL)
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          return
        } else if (error?.response?.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
  }, [history])

  useEffect(checkSession, [checkSession])

  const onFinish = (data) => {
    setIsLoginLoading(true)
    const postData = {
      username: data["loginUsername"],
      password: data["loginPassword"],
    }

    Commons.axiosInstance
      .post(Commons.apiLogin, postData)
      .then((response) => {
        message.success(Commons.successLoginMsg)
        history.push(Commons.rootURL)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          message.warning(Commons.errorLoginMismatchMsg)
        } else if (error?.response?.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoginLoading(false)
        }
      })
  }

  const checkRegistration = Commons.debounce((registrationId) => {
    if (isMountedRef.current) {
      showLoadingPageSpin()

      const paramData = {
        registrationId,
      }

      Commons.axiosInstance
        .post(Commons.apiRegistrationOpen, paramData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data) {
              if (
                response.data.attended === 0 &&
                response.data.attendedGrownup === 0 &&
                response.data.attendedChild === 0 &&
                response.data.attendedPreschool === 0
              ) {
                setValue(
                  "attachRegistrations",
                  response?.data?.attended
                    ? response?.data?.attendedAttachRegistrations
                    : response?.data?.expectedAttachRegistrations
                )
                setValue("attendedGuardian", response?.data?.expectedGuardian)

                setRegistration(response.data)
                hideQRModal()
                showConfirmModal(response.data)
              } else {
                message.warning(Commons.errorQrAlreadyUsedMsg)
              }
            } else {
              message.error(Commons.errorSystemMsg)
            }
          }
        })
        .catch((error) => {
          if (
            error?.response?.status === 404 ||
            error?.response?.status === 409
          ) {
            message.warning(Commons.errorQrNotExistMsg)
          } else {
            if (error?.response?.data) {
              displayError(error?.response?.data)
            } else {
              displayError({ error: ErrorCodes.UNEXPECTED_ERROR })
            }
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    }
  }, 300)

  const autoFocusQrCodeScannerInput = () => {
    // Continually set the input to autoFocus
    const newQrCodeScannerInputFocusId = setInterval(
      () => qrScanInputRef.current.focus(),
      250
    )
    setQrCodeScannerInputFocusId(newQrCodeScannerInputFocusId)
  }

  const disableAutoFocusQrCodeScannerInput = () => {
    clearInterval(qrCodeScannerInputFocusId)
  }

  const showLoadingPageSpin = () => {
    setIsPageLoading(true)
  }

  const hideLoadingPageSpin = () => {
    setIsPageLoading(false)
  }

  const hideQRModal = () => {
    disableAutoFocusQrCodeScannerInput()
    setQrModalVisible(false)
  }

  const showQRModal = () => {
    autoFocusQrCodeScannerInput()
    setQrModalVisible(true)
  }

  const showConfirmModal = (data) => {
    form.setFieldsValue({
      confirmAdultCount: data.expectedGrownup || 0,
      confirmChildCount: data.expectedChild || 0,
      confirmPreschoolerCount: data.expectedPreschool || 0,
      attendedGuardian: data.expectedGuardian || 0,
    })
    setConfirmModalVisible(true)
  }

  const hideConfirmModal = () => {
    setConfirmModalVisible(false)
  }

  const handleQRError = (err) => {
    console.log(err)
  }

  const handleQRScan = (data) => {
    if ((data && typeof data === "string") || data instanceof String) {
      hideQRModal()

      try {
        // Trim end for ^ for Windows or other keyboard set languages
        const decodedData = decode(data.replace(/\^/g, "")).split("-")

        if (decodedData.length === 6 && decodedData[0] === "E") {
          checkRegistration(decodedData.slice(1).join("-"))
        } else {
          message.warning(Commons.errorQrWrongMsg)
        }
      } catch (e) {
        message.warning(Commons.errorQrWrongMsg)
      }
    }
  }

  const blockJapaneseInput = (event) => {
    const japaneseRegex =
      /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/g
    if (japaneseRegex.test(event.target.value)) {
      event.target.value = event.target.value.replace(japaneseRegex, "")
    }
  }

  const QrScanInput = () => (
    <Form
      form={qrScannerForm}
      initialValues={{ qrCode: "" }}
      onFinish={(values) => {
        // Try decoding the QR code and resetFields if it fails
        try {
          // Trim end for ^ for Windows or other keyboard set languages
          const decodedData = decode(values.qrCode.replace(/\^/g, "")).split(
            "-"
          )

          if (decodedData.length === 6 && decodedData[0] === "E") {
            checkRegistration(decodedData.slice(1).join("-"))
            qrScannerForm.resetFields()
          } else {
            qrScannerForm.resetFields()
            qrScanInputRef.current.focus()
            message.warning(Commons.errorQrWrongMsg)
          }
        } catch (e) {
          qrScannerForm.resetFields()
          message.warning(Commons.errorQrWrongMsg)
        }
      }}
    >
      <Form.Item
        name="qrCode"
        rules={[
          {
            pattern: new RegExp(/^[A-Za-z0-9+/=/^]+$/),
            message: "全角・半角の文字は入力しないでください",
          },
        ]}
      >
        <Input
          id="qrCodeScannerInput"
          ref={qrScanInputRef}
          onInput={blockJapaneseInput}
        />
      </Form.Item>
      <Row gutter={[8, 8]} justify="center">
        <Col>
          <Button type="primary" size="large" htmlType="submit">
            確認
          </Button>
        </Col>
      </Row>
    </Form>
  )

  return (
    <>
      <div className="flex h-screen">
        <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-5 m-auto">
          <div className="flex mb-4">
            <img
              src="logo-header.png"
              alt=""
              className="mx-auto"
              style={{ maxHeight: "200px" }}
            />
          </div>
          <div className="text-center mb-10">
            <Text style={{ textAlign: "center", whiteSpace: "pre-line" }}>
              {process.env.REACT_APP_SYSTEM_TITLE_1 || ""} {"\n"}{" "}
              {process.env.REACT_APP_SYSTEM_TITLE_2 || ""}
            </Text>
          </div>
          <Form name="loginForm" onFinish={onFinish} size="large" form={form}>
            <Form.Item
              name="loginUsername"
              rules={[
                {
                  required: true,
                  message: "ユーザー名を入力してください",
                },
              ]}
            >
              <Input
                name="loginUsername"
                autoCapitalize="none"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="ユーザー名"
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="loginPassword"
              rules={[
                {
                  required: true,
                  message: "パスワードを入力してください",
                },
              ]}
            >
              <Input
                name="loginPassword"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="パスワード"
                allowClear
              />
            </Form.Item>
            <Form.Item className="text-center">
              <Space direction="vertical">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoginLoading}
                >
                  ログイン
                </Button>
                {process.env.REACT_APP_ENV !== "PRODUCTION" &&
                  process.env.REACT_APP_TEST_USER &&
                  process.env.REACT_APP_TEST_USER_PASSWORD && (
                    <Button
                      type="primary"
                      className="login-form-button"
                      onClick={() => {
                        setIsLoginLoading(true)

                        const postData = {
                          username: process.env.REACT_APP_TEST_USER,
                          password: process.env.REACT_APP_TEST_USER_PASSWORD,
                        }

                        Commons.axiosInstance
                          .post(Commons.apiLogin, postData)
                          .then((response) => {
                            message.success(Commons.successLoginMsg)
                            history.push(Commons.rootURL)
                          })
                          .catch((error) => {
                            if (error?.response?.status === 401) {
                              message.warning(Commons.errorLoginMismatchMsg)
                            } else if (error?.response?.status === 500) {
                              message.error(Commons.errorSystemMsg)
                            }
                          })
                          .finally(() => {
                            if (isMountedRef.current) {
                              setIsLoginLoading(false)
                            }
                          })
                      }}
                      loading={isLoginLoading}
                    >
                      テストログイン
                    </Button>
                  )}
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "rgb(82, 196, 26)",
                    borderColor: "rgb(82, 196, 26)",
                  }}
                  onClick={showQRModal}
                >
                  QRリーダーを起動する
                </Button>
              </Space>
            </Form.Item>
          </Form>
          <div className="flex mb-10"></div>
        </div>
      </div>
      <Modal
        visible={qrModalVisible}
        title="QR読み取り"
        onCancel={hideQRModal}
        footer={null}
        centered
        style={{ minHeight: 600 }}
        destroyOnClose={true}
        forceRender
      >
        {/* TODO: Fix camera remaining active and scanning indefinitely even after close */}
        {/* {qrModalVisible && (
          <QrScannerComponent onError={handleQRError} onScan={handleQRScan} />
        )} */}
        {qrModalVisible && <QrScanInput />}
      </Modal>

      <QRCheckIn
        registration={registration}
        showLoadingPageSpin={showLoadingPageSpin}
        hideLoadingPageSpin={hideLoadingPageSpin}
        confirmModalVisible={confirmModalVisible}
        hideConfirmModal={hideConfirmModal}
        showQRModal={showQRModal}
      />
    </>
  )
}

export default withRouter(Login)
