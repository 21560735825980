import { message } from "antd"
import { debounce } from "./common"

export const ErrorCodes = {
  INVALID_INPUT: "INVALID_INPUT",
  UNAUTHORIZED: "UNAUTHORIZED",
  RESOURCE_NOT_FOUND: "RESOURCE_NOT_FOUND",
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
  SERVER_ERROR: "SERVER_ERROR",
  USER_INVALID: "USER_INVALID",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  USER_ALREADY_EXISTS: "USER_ALREADY_EXISTS",
  USER_ATTACHED_INVALID: "SERVER_ERROR",
  REGISTRATION_FAILED: "REGISTRATION_FAILED",
  REGISTRATION_OVERLAP: "REGISTRATION_OVERLAP",
  REGISTRATION_ALREADY_EXISTS: "REGISTRATION_ALREADY_EXISTS",
  REGISTRATION_ALREADY_CONFIRMED: "REGISTRATION_ALREADY_CONFIRMED",
  RESERVATION_MAX_EXCEEDED: "RESERVATION_MAX_EXCEEDED",
  RESERVATION_PARTICIPANT_MAX_EXCEEDED: "RESERVATION_PARTICIPANT_MAX_EXCEEDED",
  PAYMENT_REQUIRED: "PAYMENT_REQUIRED",
  PAYMENT_LOAD_FAILED: "PAYMENT_LOAD_FAILED",
  PAYMENT_TRANSACTION_FAILED: "PAYMENT_TRANSACTION_FAILED",
  QR_INVALID: "QR_INVALID",
}

export const errorMessages = {
  [ErrorCodes.INVALID_INPUT]: "有効な入力データを入力してください。",
  [ErrorCodes.UNAUTHORIZED]: "この操作を実行する権限がありません。",
  [ErrorCodes.RESOURCE_NOT_FOUND]: "要求されたリソースが見つかりません。",
  [ErrorCodes.UNEXPECTED_ERROR]: "予期しないエラーが発生しました。",
  [ErrorCodes.SERVER_ERROR]: "サーバーで予期しないエラーが発生しました。",
  [ErrorCodes.USER_INVALID]: "ユーザーが無効です。",
  [ErrorCodes.USER_NOT_FOUND]: "ユーザが見つかりません。",
  [ErrorCodes.USER_ALREADY_EXISTS]: "ユーザは既に存在します。",
  [ErrorCodes.USER_ATTACHED_INVALID]: "接続されたユーザーが無効です。",
  [ErrorCodes.REGISTRATION_FAILED]: "登録に失敗しました。",
  [ErrorCodes.REGISTRATION_OVERLAP]:
    "イベントの時間が他のイベントと重なっています。",
  [ErrorCodes.REGISTRATION_ALREADY_EXISTS]: "既に登録されている",
  [ErrorCodes.REGISTRATION_ALREADY_CONFIRMED]: "既に登録が確定している",
  [ErrorCodes.RESERVATION_MAX_EXCEEDED]: "予約人数の上限を超えました。",
  [ErrorCodes.RESERVATION_PARTICIPANT_MAX_EXCEEDED]:
    "参加人数が定員を超えました。",
  [ErrorCodes.PAYMENT_REQUIRED]: "お支払いが必要です。",
  [ErrorCodes.PAYMENT_LOAD_FAILED]: "決済スクリプトが読み込めませんでした。",
  [ErrorCodes.PAYMENT_TRANSACTION_FAILED]:
    "決済に失敗しました。もう一度お試しください。",
}

export const displayError = debounce((error) => {
  const errorMessage =
    errorMessages[error.errorCode] || errorMessages[ErrorCodes.UNEXPECTED_ERROR]
  message.error(errorMessage)
}, 300)
