import { Col, Form, Input, Row } from "antd"
import Group from "views/Home/Group"

const { TextArea } = Input

export const OccasionUpdateRegisterMessageFormItem = () => (
  <Group title="イベント予約されるとLINEに送られるメッセージ">
    <Row>
      <Col span={24}>
        <Form.Item
          name="occasionUpdateRegisterMessage"
          help="※設定されていない場合は、LINE側でメッセージが送信されません。"
          style={{ marginBottom: 0 }}
        >
          <TextArea placeholder="メッセージを入力してください" autoSize />
        </Form.Item>
      </Col>
    </Row>
  </Group>
)
