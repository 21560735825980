import React, { useState, useEffect, useCallback, useMemo } from "react"
import { withRouter, Link, useParams, useLocation } from "react-router-dom"
import { Button, Col, message, Progress, Row, Image } from "antd"
import moment from "moment"
import "moment/locale/ja"
import styled from "styled-components"
import liff from "@line/liff"
import io from "socket.io-client"
import * as Commons from "common/common"

moment.locale("ja")

const StyledProgressBar = styled(Progress)`
  .ant-progress-text {
    white-space: pre !important;
    color: #3baedacc !important;
    font-size: 14px !important;
    font-weight: bold;
  }
`

const findAvailableSpacePercent = (expected, capacity) => {
  return Math.round((expected / capacity) * 100)
}

const findAvailableSpaceCount = (expected, capacity) => {
  return capacity - expected
}

const {
  GET_REMAINING_UNIT_TYPE_LABEL,
  GET_UNIT_TYPE_LABEL,
  GET_FULL_UNIT_TYPE_LABEL,
} = Commons

const LiffOccurrences = (props) => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props
  const params = useParams()
  const occasionId = parseInt(params.id)

  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const startDate = searchParams.get("from")
  const endDate = searchParams.get("to")

  const isMountedRef = Commons.useIsMountedRef()

  const [occasion, setOccasion] = useState({})
  const [occurrences, setOccurrences] = useState([])

  const getLiffAccessTokenAsHeader = useCallback(() => {
    let headerData = {}

    try {
      headerData.headers = {
        "access-token": liff.getAccessToken(),
      }
    } catch (error) {
      history.push(Commons.liffLoginRoute)
    }
    return headerData
  }, [history])

  // TODO: Return date filtered occurrences
  const fetchOccurrences = useCallback(() => {
    showLoadingPageSpin()

    const headerData = getLiffAccessTokenAsHeader()

    Commons.axiosInstance
      .get(
        Commons.apiLiffOccasionDetail +
          "/" +
          occasionId +
          `/occurrences?from=${startDate}&to=${endDate}`,
        headerData
      )
      .then((response) => {
        if (isMountedRef.current && response) {
          setOccurrences(response.data || [])
        }
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        hideLoadingPageSpin()
      })
  }, [
    showLoadingPageSpin,
    getLiffAccessTokenAsHeader,
    occasionId,
    startDate,
    endDate,
    isMountedRef,
    hideLoadingPageSpin,
  ])

  const fetchOccasionDetail = useCallback(() => {
    showLoadingPageSpin()

    const headerData = getLiffAccessTokenAsHeader()

    Commons.axiosInstance
      .get(Commons.apiLiffOccasionDetail + "/" + occasionId, headerData)
      .then((response) => {
        if (isMountedRef.current && response) {
          setOccasion(response.data || {})
        }
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        hideLoadingPageSpin()
      })
  }, [
    isMountedRef,
    occasionId,
    showLoadingPageSpin,
    hideLoadingPageSpin,
    getLiffAccessTokenAsHeader,
  ])
  const fetchRegistration = (occurrenceId) => {
    showLoadingPageSpin()

    let headerData = {}

    try {
      headerData.headers = {
        "access-token": liff.getAccessToken(),
      }
    } catch (error) {
      history.push(Commons.liffLoginRoute)
    }

    Commons.axiosInstance
      .get(`/liff/events/${occasionId}/${occurrenceId}`, headerData)
      .then((response) => {
        if (isMountedRef.current && response) {
          // Check if the user is already registered for this occurrence
          if (response.data && response.data.registered) {
            return history.push(
              Commons.liffInfoRoute + "/" + occasionId + "/" + occurrenceId
            )
          } else {
            // Otherwise, redirect to the liff participation page to register
            return history.push(
              Commons.liffParticipationRoute +
                "/" +
                occasionId +
                "/" +
                occurrenceId
            )
          }
        }
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        hideLoadingPageSpin()
      })
  }

  useEffect(() => {
    fetchOccasionDetail()
    fetchOccurrences()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const socket = io(Commons.siteURL, { path: "/socket.io" })

    socket.on("updateEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          fetchOccasionDetail()
        }
      }
    })

    socket.on("updateOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          fetchOccasionDetail()
        }
      }
    })

    socket.on("deleteOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          fetchOccasionDetail()
        }
      }
    })

    socket.on("deleteEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          message.warning(Commons.socketLiffEventDeleteMsg)
          history.push(Commons.liffOccasionsRoute)
        }
      }
    })

    socket.on("newRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          fetchOccasionDetail()
        }
      }
    })

    socket.on("cancelRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          fetchOccasionDetail()
        }
      }
    })

    return () => {
      socket.off("updateEvent")
      socket.off("updateOccurrence")
      socket.off("deleteOccurrence")
      socket.off("deleteEvent")
      socket.off("newRegistration")
      socket.off("cancelRegistration")

      socket.disconnect()
    }

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Row gutter={[8, 8]} className="mx-4">
        <Col span={24} className="text-center">
          <img
            src="/logo-header.png"
            alt="ロゴ"
            style={{ maxHeight: "120px" }}
            className="max-w-full mt-2 mx-4"
          />
        </Col>
        <Col span={24}>
          <div className="bg-primary text-center p-4 mt-4 rounded">
            <span className="text-white text-lg font-bold whitespace-pre-wrap">
              {"ご希望のイベント日程を\nお選びください"}
            </span>
          </div>
        </Col>
        <Col span={24} className="text-center">
          <Image
            preview={false}
            src={
              occasion?.occasionImages &&
              occasion?.occasionImages?.length > 0 &&
              occasion.occasionImages[0]?.picUrl
                ? `${Commons.occasionImagesURL}${occasion.occasionImages[0]?.picUrl}`
                : "/no-image.png"
            }
            fallback="/no-image.png"
            alt={occasion.title}
            style={{ maxHeight: "320px" }}
            className="rounded max-w-full"
          />
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Col className="text-center">
              <span className="text-sm font-bold">{occasion.title || ""}</span>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {occurrences && occurrences.length > 0 ? (
            <Row gutter={[8, 8]}>
              {occurrences.map((occurrence) => (
                <Col span={12} key={occurrence.occurrenceId}>
                  <Row>
                    <Col
                      span={24}
                      style={{
                        backgroundColor: "#f0feff",
                        color: "#21acd7",
                        border: "1px solid #21acd7",
                        borderBottom: "none",
                      }}
                      className="text-center p-1 rounded-t"
                    >
                      <span className="text-xs font-bold text-primary whitespace-pre-wrap">
                        {moment(occurrence.startAt).format(
                          "YYYY年M月D日[\n]HH時mm分"
                        )}
                      </span>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        border: "1px solid #21acd7",
                        borderBottom: "none",
                      }}
                      className="text-center p-2"
                    >
                      <StyledProgressBar
                        type="circle"
                        strokeColor="#3baedacc"
                        trailColor="#f0f0f0"
                        strokeLinecap="butt"
                        width={120}
                        strokeWidth="12"
                        percent={findAvailableSpacePercent(
                          occurrence.sumExpected || 0,
                          occurrence.maxAttendee || 0
                        )}
                        format={(count) =>
                          findAvailableSpaceCount(
                            occurrence.sumExpected || 0,
                            occurrence.maxAttendee || 0
                          ) !== 0
                            ? `${GET_REMAINING_UNIT_TYPE_LABEL(
                                occasion.unitType
                              )}${findAvailableSpaceCount(
                                occurrence.sumExpected || 0,
                                occurrence.maxAttendee || 0
                              )}${GET_UNIT_TYPE_LABEL(
                                occasion.unitType
                              ).replace("画", "")}`
                            : GET_FULL_UNIT_TYPE_LABEL(occasion.unitType)
                        }
                      />
                    </Col>
                    <Col
                      span={24}
                      style={{
                        border: "1px solid #21acd7",
                      }}
                      className="rounded-b"
                    >
                      {findAvailableSpaceCount(
                        occurrence.sumExpected || 0,
                        occurrence.maxAttendee || 0
                      ) ? (
                        <Button
                          className="rounded-b rounded-t-none"
                          type="primary"
                          size="large"
                          block
                          onClick={() =>
                            fetchRegistration(occurrence.occurrenceId)
                          }
                        >
                          選択
                        </Button>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          ) : (
            <Row gutter={[0, 4]} className="m-4">
              <Col span={24} className="text-center">
                <img
                  style={{ maxHeight: "220px" }}
                  src="/no_occasion.svg"
                  alt="現在参加可能な時間がありません"
                  className="rounded max-w-full"
                />
              </Col>
              <Col span={24} className="text-center p-2 rounded">
                <span className="whitespace-pre-wrap">
                  {"現在参加可能な時間がありません"}
                </span>
              </Col>
            </Row>
          )}
        </Col>
        <Col span={24}>
          <Row gutter={[8, 8]} justify="center" className="m-4">
            <Col span={12}>
              <Link to={`${Commons.liffOccasionsRoute}/${occasionId}/calendar`}>
                <Button size="large" block>
                  戻る
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default withRouter(LiffOccurrences)
