import { useFieldArray } from "react-hook-form"

const DEFAULT_PARTICIPATION_CHILDREN = {
  fullName: "",
  age: null,
  month: null,
  note: "",
}

const useOccasionModal = (props) => {
  const { control, errors, setValue } = props

  const {
    fields: attachRegistrations,
    append: appendParticipationChild,
    remove: removeParticipationChild,
  } = useFieldArray({
    control,
    name: "attachRegistrations",
  })
  const handleAddParticipationChild = (event) => {
    if (attachRegistrations.length === 5) {
      event.preventDefault()
    } else {
      appendParticipationChild(DEFAULT_PARTICIPATION_CHILDREN)
    }
  }

  const onChangeTelephone = (values) => {
    const regex = /^[0-9]$/

    if (regex.test(values) || values === "") {
      setValue("telephone", values)
    } else {
      setValue("telephone", `${values}`.replace(/[^0-9]/g, ""))
    }
  }

  return {
    ...props,
    attachRegistrations,
    handleAddParticipationChild,
    removeParticipationChild,
    onChangeTelephone,
    errors,
  }
}

export default useOccasionModal
