import { Col, Form, InputNumber, Row } from "antd"
import * as Commons from "common/common"
const { GET_MAX_RESERVATIONS_UNIT_TYPE_LABEL } = Commons

export const OccasionUpdateMaxPerPersonFormItem = ({
  occasion,
  occasionUpdateForm,
}) => (
  <Row>
    <Col span={24}>
      <Form.Item
        name="occasionUpdateMaxAllow"
        label={`1アカウントで${GET_MAX_RESERVATIONS_UNIT_TYPE_LABEL(
          occasionUpdateForm.getFieldValue("unitType")
        )}`}
        rules={[
          {
            required: true,
            message: `${GET_MAX_RESERVATIONS_UNIT_TYPE_LABEL(
              occasionUpdateForm.getFieldValue("unitType")
            )}は必須です`,
          },
        ]}
      >
        <InputNumber
          placeholder={`1アカウントで${GET_MAX_RESERVATIONS_UNIT_TYPE_LABEL(
            occasion.unitType
          )}`}
          min={1}
          type="number"
          onPressEnter={(e) => {
            e.preventDefault()
          }}
          onWheel={(e) => e.target.blur()}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </Col>
  </Row>
)
