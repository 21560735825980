import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react"
import * as yup from "yup"

const schema = yup
  .object({
    audienceName: yup.string().required("オーディエンス名を入力してください "),
  })
  .required()

const useAudiencesModal = (props) => {
  const { occasionId, handleSubmitModal, setCountUsers, countUsers } = props
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      audienceName: "",
      maxAge: "",
      minPeople: "",
      minAge: "",
      maxPeople: "",
      participantName: [],
      reservationStatus: [],
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  })
  const [minAge, setMinAge] = useState(0)
  const [minPeople, setMinPeople] = useState(0)

  const watchMinAge = watch("minAge")
  const watchMaxAge = watch("maxAge")
  const watchMinPeople = watch("minPeople")
  const watchMaxPeople = watch("maxPeople")
  const watchparticipantName = watch("participantName")
  const watchReservationStatus = watch("reservationStatus")

  const handleResetForm = () => {
    setMinAge(0)
    setMinPeople(0)
    reset()
  }

  const onSubmit = (data) => {
    occasionId &&
      handleSubmitModal(
        { occasionId: occasionId, type: "create" },
        data,
        handleResetForm
      )
  }

  const handleCountUsers = () => {
    occasionId &&
      handleSubmitModal(
        { occasionId: occasionId, type: "countUsers" },
        getValues()
      )
  }

  const onChangeReservationStatus = (value) => {
    if (getValues("reservationStatus")[0] === "all") {
      setValue(
        "reservationStatus",
        value.filter((item) => item !== "all")
      )
    } else if (value.find((i) => i === "all")) {
      setValue("reservationStatus", ["all"])
    } else if (
      value.join(",") === "participant,nonParticipant,cancel,nonParticipants"
    ) {
      setValue("reservationStatus", ["all"])
    } else {
      setValue("reservationStatus", value)
    }
  }

  useEffect(() => {
    if (countUsers) {
      setCountUsers(0)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watchMinAge,
    watchMaxAge,
    watchMinPeople,
    watchMaxPeople,
    watchparticipantName,
    watchReservationStatus,
  ])

  return {
    ...props,
    reset,
    control,
    minAge,
    onSubmit,
    setMinAge,
    minPeople,
    handleSubmit,
    setMinPeople,
    handleCountUsers,
    onChangeReservationStatus,
    errors,
  }
}

export default useAudiencesModal
