import { Col, Form, InputNumber, Row, Switch } from "antd"
import Group from "views/Home/Group"

export const OccasionUpdatePriceFormItem = ({
  setHasBaseTicketPrice,
  hasBaseTicketPrice,
  disabled = true,
}) => {
  const BasePrice = () => (
    <Form.Item
      name={`baseTicketPrice`}
      label="参加料金"
      rules={[
        {
          required: true,
          message: "参加料金は必須です",
        },
      ]}
    >
      <InputNumber
        placeholder="参加料金"
        min={0}
        type="number"
        onPressEnter={(e) => {
          e.preventDefault()
        }}
        onWheel={(e) => e.target.blur()}
        style={{ width: "100%" }}
        disabled={disabled}
      />
    </Form.Item>
  )
  return (
    <>
      <Group title="イベント参加料金機能" di>
        <Row>
          <Col span={24}>
            <Form.Item name="hasBaseTicketPrice" valuePropName="checked">
              <Switch
                name="hasBaseTicketPrice"
                disabled={disabled}
                onClick={(e) => {
                  setHasBaseTicketPrice(e)
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Group>
      {hasBaseTicketPrice && <BasePrice />}
    </>
  )
}
