import React from "react"
import { Switch, Redirect } from "react-router-dom"
import { RouteWithLayout } from "./components"
import * as Commons from "common/common"

import { Minimal as MinimalLayout, Main as MainLayout } from "./layouts"
import {
  Login as LoginView,
  Home as HomeView,
  OccasionDetail as OccasionDetailView,
  Audiences as AudiencesView,
  LiffLogin as LiffLoginView,
  LiffOccasions as LiffOccasionsView,
  LiffOccasionsDetail as LiffOccasionsDetailView,
  LiffOccasionCalendar as LiffOccasionCalendarView,
  LiffOccurrences as LiffOccurrencesView,
  LiffParticipation as LiffParticipationView,
  LiffInfo as LiffInfoView,
  LineAccess as LineAccessView,
  NotFound as NotFoundView,
} from "./views"

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={Commons.loginRoute} />
      <Redirect exact from="/liff" to={Commons.liffOccasionsRoute} />
      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path={Commons.loginRoute}
      />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path={Commons.homeRoute}
      />
      <RouteWithLayout
        component={OccasionDetailView}
        exact
        layout={MainLayout}
        path={`${Commons.homeRoute}/:id`}
      />
      <RouteWithLayout
        component={AudiencesView}
        exact
        layout={MainLayout}
        path={Commons.audiencesRoute}
      />
      <RouteWithLayout
        component={LiffLoginView}
        layout={MinimalLayout}
        path={`${Commons.liffLoginRoute}`}
      />
      <RouteWithLayout
        component={LiffOccasionsView}
        exact
        layout={MinimalLayout}
        path={`${Commons.liffOccasionsRoute}`}
      />
      <RouteWithLayout
        component={LiffOccasionCalendarView}
        exact
        layout={MinimalLayout}
        path={`${Commons.liffOccasionsRoute}/:id/calendar`}
      />
      <RouteWithLayout
        component={LiffOccasionsDetailView}
        exact
        layout={MinimalLayout}
        path={`${Commons.liffOccasionsRoute}/:id`}
      />
      <RouteWithLayout
        component={LiffOccurrencesView}
        exact
        layout={MinimalLayout}
        path={`${Commons.liffOccurrencesRoute}/:id`}
      />
      <RouteWithLayout
        component={LiffParticipationView}
        exact
        layout={MinimalLayout}
        path={`${Commons.liffParticipationRoute}/:occasionId/:occurrenceId`}
      />
      <RouteWithLayout
        component={LiffInfoView}
        exact
        layout={MinimalLayout}
        path={`${Commons.liffInfoRoute}/:id`}
      />
      <RouteWithLayout
        component={LiffInfoView}
        exact
        layout={MinimalLayout}
        path={`${Commons.liffInfoRoute}/:occasionId/:occurrenceId`}
      />
      <RouteWithLayout
        component={LineAccessView}
        exact
        layout={MinimalLayout}
        path={Commons.lineAccessRoute}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path={Commons.notFoundRoute}
      />
      <Redirect to={Commons.notFoundRoute} />
    </Switch>
  )
}

export default Routes
