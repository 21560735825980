import React, { useState, useEffect, useCallback, useRef } from "react"
import { withRouter } from "react-router-dom"
import { Col, message, Row, Image } from "antd"
import moment from "moment"
import "moment/locale/ja"
import liff from "@line/liff"
import io from "socket.io-client"
import * as Commons from "common/common"

moment.locale("ja")

const LiffOccasions = (props) => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props
  const isMountedRef = Commons.useIsMountedRef()
  const registeredOccasionsRef = useRef()
  const currentOccasionsRef = useRef()

  const [registeredOccasions, setRegisteredOccasions] = useState([])
  const [currentOccasions, setCurrentOccasions] = useState([])
  // const [comingSoonOccasions, setComingSoonOccasions] = useState([])

  const fetchOccasions = useCallback(() => {
    showLoadingPageSpin()

    let headerData = {}

    try {
      headerData.headers = {
        "access-token": liff.getAccessToken(),
      }
    } catch (error) {
      history.push(Commons.liffLoginRoute)
    }

    Commons.axiosInstance
      .get(Commons.apiLiffOccasions, headerData)
      .then((response) => {
        if (isMountedRef.current && response) {
          if (response.data) {
            if (response.data.length > 0) {
              setCurrentOccasions(response.data)
            } else {
              setCurrentOccasions([])
            }

            // if (response.data.length > 0) {
            //   setComingSoonOccasions(
            //     response.data.filter((occasion) =>
            //       moment(occasion.start).isAfter(moment(), "day")
            //     )
            //   )
            // } else {
            //   setComingSoonOccasions([])
            // }
          }
        }
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        hideLoadingPageSpin()
      })
  }, [isMountedRef, history, showLoadingPageSpin, hideLoadingPageSpin])

  const fetchRegisteredOccasions = useCallback(() => {
    showLoadingPageSpin()

    let headerData = {}

    try {
      headerData.headers = {
        "access-token": liff.getAccessToken(),
      }
    } catch (error) {
      history.push(Commons.liffLoginRoute)
    }

    Commons.axiosInstance
      .get(Commons.apiLiff + "/myregistrations", headerData)
      .then((response) => {
        if (isMountedRef.current && response) {
          setRegisteredOccasions(response.data || [])
        }
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        hideLoadingPageSpin()
      })
  }, [isMountedRef, history, showLoadingPageSpin, hideLoadingPageSpin])

  const checkEventParticipation = (ids) => {
    const { occasionId, occurrenceId, registrationId } = ids
    showLoadingPageSpin()

    let headerData = {}

    try {
      headerData.headers = {
        "access-token": liff.getAccessToken(),
      }
    } catch (error) {
      history.push(Commons.liffLoginRoute)
    }

    Commons.axiosInstance
      .get(Commons.apiLiffOccasionDetail + "/" + occasionId, headerData)
      .then((response) => {
        if (!isMountedRef.current && !response && !response.data) return

        // Redirect to registered occurrence page
        if (registrationId) {
          return history.push(
            Commons.liffInfoRoute + "/" + occasionId + "/" + occurrenceId
          )
        }
        // Redirect to general occasion page
        if (!occurrenceId) {
          return history.push(Commons.liffOccasionsRoute + "/" + occasionId)
        } else {
          // Redidrect to general occurrence page
          return history.push(Commons.liffOccurrencesRoute + "/" + occasionId)
        }

        // if (isWithinTime(response.data.registered.startAt)) {
        //   history.push(Commons.liffInfoRoute + "/" + occasionId)
        // } else {
        //   Modal.confirm({
        //     title: "受付時間は過ぎております",
        //     icon: <ExclamationCircleOutlined />,
        //     content: "登録の受付時間はすでに過ぎております",
        //     okText: "確認",
        //     okButtonProps: { style: { display: "none" } },
        //     cancelText: "閉じる",
        //     centered: true,
        //   })
        // }
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        hideLoadingPageSpin()
      })
  }

  useEffect(() => {
    fetchRegisteredOccasions()
    fetchOccasions()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    registeredOccasionsRef.current = registeredOccasions
  }, [registeredOccasions])

  useEffect(() => {
    currentOccasionsRef.current = currentOccasions
  }, [currentOccasions])

  useEffect(() => {
    const socket = io(Commons.siteURL, { path: "/socket.io" })

    socket.on("newEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchRegisteredOccasions()
        fetchOccasions()
      }
    })

    socket.on("updateEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchRegisteredOccasions()
        fetchOccasions()
      }
    })

    socket.on("updateOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchRegisteredOccasions()
        fetchOccasions()
      }
    })

    socket.on("deleteOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchRegisteredOccasions()
        fetchOccasions()
      }
    })

    socket.on("deleteEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        fetchRegisteredOccasions()
        fetchOccasions()
      }
    })

    socket.on("cancelRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId) {
          registeredOccasionsRef.current.forEach((registeredOccasion) => {
            if (
              response.occasionId ===
              registeredOccasion.Occurrence?.Occasion?.occasionId
            ) {
              fetchRegisteredOccasions()
              fetchOccasions()
            }
          })
        }
      }
    })

    socket.on("confirmRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId) {
          registeredOccasionsRef.current.forEach((registeredOccasion) => {
            if (
              response.occasionId ===
              registeredOccasion.Occurrence?.Occasion?.occasionId
            ) {
              fetchRegisteredOccasions()
              fetchOccasions()
            }
          })
        }
      }
    })

    socket.on("paymentSuccess", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response?.registrationId && response?.occasionId) {
          currentOccasionsRef.current.forEach((currentOccasion) => {
            if (response?.occasionId === currentOccasion?.occasionId) {
              fetchRegisteredOccasions()
              fetchOccasions()
            }
          })
        }
      }
    })

    return () => {
      socket.off("newEvent")
      socket.off("updateEvent")
      socket.off("updateOccurrence")
      socket.off("deleteOccurrence")
      socket.off("deleteEvent")
      socket.off("cancelRegistration")
      socket.off("confirmRegistration")
      socket.off("paymentSuccess")

      socket.disconnect()
    }

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Row gutter={[0, 8]}>
        <Col span={24} className="text-center">
          <img
            src="/logo-header.png"
            alt="ロゴ"
            style={{ maxHeight: "120px" }}
            className="max-w-full mt-2 mx-4"
          />
        </Col>
        <Col span={24}>
          <div className="bg-primary text-center p-4 m-4 rounded">
            <span className="text-white text-lg font-bold whitespace-pre-wrap">
              {"ご希望のイベントを\nお選びください"}
            </span>
          </div>
        </Col>
        {registeredOccasions.length > 0 ? (
          <Col span={24}>
            <Row>
              <Col>
                <div className="bg-cyan text-center p-2 rounded-r-full">
                  <span className="text-white font-bold text-base">
                    予約したイベント
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        ) : (
          ""
        )}
        {registeredOccasions.length > 0 ? (
          <Col span={24} className="p-4">
            <Row gutter={[0, 16]} justify="center">
              {registeredOccasions.map((registration, registrationIndex) => (
                <Col xs={24} key={`registeredOccasions-${registrationIndex}`}>
                  <Row
                    onClick={() =>
                      checkEventParticipation({
                        occasionId:
                          registration.Occurrence?.Occasion?.occasionId,
                        occurrenceId: registration.Occurrence?.occurrenceId,
                        registrationId: registration.registrationId,
                      })
                    }
                  >
                    <Col
                      span={24}
                      className="border rounded border-cyan"
                      // className={`border rounded ${
                      //   isWithinTime(registration.Occurrence?.startAt)
                      //     ? "border-cyan"
                      //     : "border-volcano"
                      // }`}
                    >
                      <Row justify="center">
                        <Col>
                          <Image
                            preview={false}
                            src={
                              registration.Occurrence?.Occasion
                                ?.occasionImages &&
                              registration.Occurrence?.Occasion?.occasionImages
                                ?.length > 0 &&
                              registration.Occurrence?.Occasion
                                .occasionImages[0]?.picUrl
                                ? `${Commons.occasionImagesURL}${registration.Occurrence?.Occasion.occasionImages[0]?.picUrl}`
                                : "/no-image.png"
                            }
                            fallback="/no-image.png"
                            alt={registration.Occurrence?.Occasion.title}
                            className="rounded-t object-cover max-w-full"
                            style={{
                              minHeight: "100%",
                              maxHeight: "300px",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row
                        justify="center"
                        className={"mb-1 border-t border-cyan"}
                        // className={`mb-1 border-t ${
                        //   isWithinTime(registration.Occurrence?.startAt)
                        //     ? "border-cyan"
                        //     : "border-volcano"
                        // }`}
                      >
                        <Col>
                          <span
                            className={`text-xs font-bold text-cyan`}
                            // className={`text-xs font-bold ${
                            //   isWithinTime(registration.Occurrence?.startAt)
                            //     ? "text-cyan"
                            //     : "text-volcano"
                            // }`}
                          >
                            {moment(registration.Occurrence?.startAt).format(
                              "YYYY年M月D日 HH:mm"
                            )}
                          </span>
                        </Col>
                      </Row>
                      <Row
                        justify="center"
                        className="bg-cyan"
                        // className={`${
                        //   isWithinTime(registration.Occurrence?.startAt)
                        //     ? "bg-cyan"
                        //     : "bg-volcano"
                        // }`}
                      >
                        <Col className="text-center p-4">
                          <span
                            className="text-sm font-bold text-white"
                            style={{
                              textOverflow: "ellipsis",
                              wordWrap: "break-word",
                              overflow: "hidden",
                              height: "3em",
                              lineHeight: "1.5em",
                              display: "inline-block",
                            }}
                          >
                            {registration.Occurrence?.Occasion?.title}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        ) : (
          ""
        )}
        <Col span={24}>
          <Row>
            <Col>
              <div className="bg-primary text-center p-2 rounded-r-full">
                <span className="text-white font-bold text-base">
                  現在予約受付中のイベント
                </span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="p-4">
          {currentOccasions.length > 0 ? (
            <Row gutter={[0, 16]} justify="center">
              {currentOccasions.map((occasion, occasionIndex) => (
                <Col xs={24} key={`currentOccasions-${occasionIndex}`}>
                  <Row
                    onClick={() =>
                      checkEventParticipation({
                        occasionId: occasion.occasionId,
                      })
                    }
                  >
                    <Col span={24} className="border border-primary rounded">
                      <Row justify="center">
                        <Col>
                          <Image
                            preview={false}
                            src={
                              occasion?.occasionImages &&
                              occasion?.occasionImages?.length > 0 &&
                              occasion.occasionImages[0]?.picUrl
                                ? `${Commons.occasionImagesURL}${occasion.occasionImages[0]?.picUrl}`
                                : "/no-image.png"
                            }
                            fallback="/no-image.png"
                            alt={occasion.title}
                            style={{
                              minHeight: "100%",
                              maxHeight: "300px",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row
                        justify="center"
                        className="mb-1 border-t border-primary"
                      >
                        <Col>
                          <span className="text-xs font-bold text-primary">
                            {moment(occasion.start).format("YYYY年M月D日")}
                          </span>
                          <span className="text-xs font-bold text-primary">
                            {" "}
                            ～{" "}
                          </span>
                          <span className="text-xs font-bold text-primary">
                            {moment(occasion.end).format("YYYY年M月D日")}
                          </span>
                        </Col>
                      </Row>
                      <Row justify="center" className="bg-primary">
                        <Col className="text-center p-4">
                          <span
                            className="text-sm font-bold text-white"
                            style={{
                              textOverflow: "ellipsis",
                              wordWrap: "break-word",
                              overflow: "hidden",
                              height: "3em",
                              lineHeight: "1.5em",
                              display: "inline-block",
                            }}
                          >
                            {occasion.title}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          ) : (
            <Row gutter={[0, 4]} className="m-4">
              <Col span={24} className="text-center">
                <img
                  style={{ maxHeight: "220px" }}
                  src="/no_occasion.svg"
                  alt="現在予約受付中のイベントはありません"
                  className="rounded max-w-full"
                />
              </Col>
              <Col span={24} className="text-center p-2 rounded">
                <span className="whitespace-pre-wrap">
                  {"現在予約受付中のイベントはありません"}
                </span>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  )
}

export default withRouter(LiffOccasions)
