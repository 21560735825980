import React, { useState, useEffect } from "react"
import { Table, Button, Tooltip, Tag, Typography } from "antd"
import moment from "moment"
import styled from "styled-components"
import {
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  DoubleRightOutlined,
  RightOutlined,
  LeftOutlined,
  DoubleLeftOutlined,
} from "@ant-design/icons"
import * as Commons from "common/common"
import io from "socket.io-client"

const { Text } = Typography

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #f0feff !important;
    color: #21acd7 !important;
    text-align: center;
  }
  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-summary > tr > td {
    border-top: 1px solid #f0f0f0;
    border-bottom: none;
    background-color: #f0feff !important;
  }
`

const OccurrencesTable = (props) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [occurrences, setOccurrences] = useState([])
  const {
    occasion,
    handleOccurrenceDetail,
    socketCheckOccurrence,
    socketCheckOccasion,
    occasionId,
  } = props

  const [filterOccurrencesFrom, setFilterOccurrencesFrom] = useState(
    moment().startOf("week").format("YYYY-MM-DD HH:mm")
  )
  const [filterOccurrencesTo, setFilterOccurrencesTo] = useState(
    moment().endOf("week").format("YYYY-MM-DD HH:mm")
  )

  const fetchOccurrences = async () => {
    try {
      const response = await Commons.axiosInstance.get(
        `${Commons.apiOccasions}/${occasionId}/occurrences`,
        {
          params: {
            from: filterOccurrencesFrom,
            to: filterOccurrencesTo,
          },
        }
      )
      setOccurrences(response.data)
    } catch (error) {
      console.error("Error fetching occurrences:", error)
    }
  }

  useEffect(() => {
    if (occasion?.occasionId) {
      fetchOccurrences()
    }
  }, [occasion?.occasionId, filterOccurrencesFrom, filterOccurrencesTo])

  // Pass latest state of occurrences
  const updateOccurrences = (response, occurrences) => {
    if (response !== undefined && Object.keys(response).length !== 0) {
      if (socketCheckOccasion(response)) {
        // Only update the table if the occurrence is within the current viewing week
        // if (
        //   occurrences.find(
        //     (occurrence) => occurrence.occurrenceId === response?.occurrenceId
        //   )
        // ) {
        fetchOccurrences()
        // }
      }
    }
  }

  useEffect(() => {
    const socket = io(Commons.siteURL, { path: "/socket.io" })

    socket.on("updateEvent", (res) => updateOccurrences(res, occurrences))
    socket.on("deleteOccurrence", (res) => updateOccurrences(res, occurrences))
    socket.on("newRegistration", (res) => updateOccurrences(res, occurrences))
    socket.on("cancelRegistration", (res) =>
      updateOccurrences(res, occurrences)
    )
    socket.on("updateOccurrence", (res) => updateOccurrences(res, occurrences))
    socket.on("confirmRegistration", (res) =>
      updateOccurrences(res, occurrences)
    )

    return () => {
      socket.off("updateEvent")
      socket.off("updateOccurrence")
      socket.off("deleteOccurrence")
      socket.off("deleteEvent")
      socket.off("newRegistration")
      socket.off("cancelRegistration")
      socket.off("confirmRegistration")

      socket.disconnect()
    }
  }, [occurrences])

  const getExpectedColumnStyle = (occurrence) => {
    const baseStyle = {}

    if (occurrence.isDisplayed && occurrence.sumExpected > 0) {
      baseStyle.backgroundColor = "#f0feff"
      baseStyle.color = "#21acd7"
      baseStyle.border = "1px solid #7cc7d6"

      // Color for expecting a full event
      if (
        Commons.checkIsEventFull(
          occurrence.sumExpected || 0,
          occurrence.maxAttendee || 0
        )
      ) {
        baseStyle.backgroundColor = "#21acd7"
        baseStyle.color = "#FFF"
      }
    }

    return baseStyle
  }

  const getCancelledColumnStyle = (occurrence) => {
    const baseStyle = {}

    if (occurrence.isDisplayed && occurrence.sumCancelled > 0) {
      baseStyle.backgroundColor = "#ececec"
      baseStyle.color = "#8c8c8c"
      baseStyle.border = "1px solid #8c8c8c"
    }

    return baseStyle
  }

  const columns = [
    {
      title: "予約日",
      dataIndex: "groupDate",
      key: "groupDate",
      width: 150,
      render: (text) => (
        <div className="text-center">
          <p className="text-xs">{moment(text).format("YYYY年")}</p>
          <p className="text-sm">{moment(text).format("M月D日")}</p>
        </div>
      ),
    },
    {
      title: "予約時間",
      dataIndex: "startAt",
      key: "startAt",
      width: 150,
      render: (text) => (
        <div className="text-center">{moment(text).format("HH:mm")}</div>
      ),
    },
    {
      title: "予約数",
      dataIndex: "maxAttendee",
      key: "maxAttendee",
      width: 150,
      render: (maxAttendee, occurrence) => (
        <div style={getExpectedColumnStyle(occurrence)} className="text-center">
          <p className="text-lg font-bold">
            {occurrence.sumExpected}/
            <span className="text-xs font-light text-center">
              {maxAttendee}
            </span>
          </p>
        </div>
      ),
    },
    {
      title: "参加数",
      dataIndex: "sumAttended",
      key: "sumAttended",
      width: 150,
      render: (text) => <div className="text-center font-bold">{text}</div>,
    },
    {
      title: "キャンセル数",
      dataIndex: "sumCancelled",
      key: "sumCancelled",
      width: 150,
      render: (sumCancelled, occurrence) => (
        <div
          style={getCancelledColumnStyle(occurrence)}
          className="text-center font-bold"
        >
          {sumCancelled || 0}
        </div>
      ),
    },
  ]

  if (occasion?.hasBaseTicketPrice) {
    columns.push({
      title: "売上",
      key: "operation",
      width: 150,
      render: (_, occurrence) => (
        <div className="text-center">
          {Commons.displayJpyPrice(
            occasion?.baseTicketPrice * occurrence.sumAttended
          )}
        </div>
      ),
    })
  }

  const expandedRowRender = (record) => {
    return (
      <StyledTable
        columns={columns}
        dataSource={record.occurrences}
        pagination={false}
        locale={{ emptyText: "当選者のデータがありません。" }}
        rowKey="occurrenceId"
        bordered
        onRow={(occurrence) => ({
          onClick: () => handleOccurrenceDetail(occurrence.occurrenceId),
        })}
        summary={(occurrence) => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell
                index={0}
                colSpan={2}
                className="text-center"
                style={{
                  backgroundColor: "#21acd7",
                  border: "1px solid #21acd7",
                }}
              >
                合計
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <Text className="text-center">
                  <p className="text-lg font-bold m-auto">
                    {record.totalExpected}/
                    <span className="text-xs font-light text-center">
                      {record.totalMax}
                    </span>
                  </p>
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} className="text-center font-bold">
                {record.totalAttended || 0}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} className="text-center font-bold">
                {record.totalCancelled || 0}
              </Table.Summary.Cell>
              {occasion.hasBaseTicketPrice && (
                <Table.Summary.Cell index={4} className="text-center font-bold">
                  {Commons.displayJpyPrice(
                    occasion?.baseTicketPrice * record.totalAttended
                  )}
                </Table.Summary.Cell>
              )}
            </Table.Summary.Row>
          )
        }}
      />
    )
  }

  // TODO: Optimize occurrences data structure into map instead of array since we array.find later on
  const dataSource = occurrences.reduce((acc, occurrence) => {
    const existingGroup = acc.find(
      (group) => group.date === occurrence.groupDate
    )
    if (existingGroup) {
      existingGroup.occurrences.push(occurrence)
      existingGroup.totalMax += occurrence.maxAttendee || 0
      existingGroup.totalExpected += occurrence.sumExpected || 0
      existingGroup.totalAttended += occurrence.sumAttended || 0
      existingGroup.totalCancelled += occurrence.sumCancelled || 0
    } else {
      acc.push({
        key: occurrence.groupDate,
        date: occurrence.groupDate,
        occurrences: [occurrence],
        totalMax: occurrence.maxAttendee || 0,
        totalExpected: occurrence.sumExpected || 0,
        totalAttended: occurrence.sumAttended || 0,
        totalCancelled: occurrence.sumCancelled || 0,
      })
    }
    return acc
  }, [])

  const handleExpand = (record) => {
    setExpandedRowKeys((prevExpandedRowKeys) =>
      prevExpandedRowKeys.includes(record.key)
        ? prevExpandedRowKeys.filter((key) => key !== record.key)
        : [...prevExpandedRowKeys, record.key]
    )
  }

  const dateColumns = [
    {
      title: "日付",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <div className=" text-center w-full cursor-pointer">
          <p className="">{moment(text).format("YYYY年M月D日 (ddd)")}</p>
        </div>
      ),
    },
  ]

  const adjustDateRange = (weeks) => {
    setFilterOccurrencesFrom(
      moment(filterOccurrencesFrom)
        .add(weeks, "week")
        .startOf("week")
        .format("YYYY-MM-DD HH:mm")
    )
    setFilterOccurrencesTo(
      moment(filterOccurrencesTo)
        .add(weeks, "week")
        .endOf("week")
        .format("YYYY-MM-DD HH:mm")
    )
  }

  return (
    <>
      <div className="my-4">
        <div className="flex justify-center mb-2">
          <div className="p-2 border text-sm bg-[#f0feff] text-[#21acd7] font-bold text-center rounded">
            {moment(filterOccurrencesFrom).format("YYYY年M月D日")}～
            {moment(filterOccurrencesTo).format("YYYY年M月D日")}
          </div>
        </div>
        <div className="flex justify-center items-center">
          <Button
            className="m-1"
            onClick={() => adjustDateRange(-2)}
            type="dashed"
          >
            <DoubleLeftOutlined />
          </Button>
          <Button
            className="m-1"
            onClick={() => adjustDateRange(-1)}
            type="dashed"
          >
            <LeftOutlined />
          </Button>
          <Button
            className="m-1"
            onClick={() => {
              setFilterOccurrencesFrom(
                moment().startOf("week").format("YYYY-MM-DD HH:mm")
              )
              setFilterOccurrencesTo(
                moment().endOf("week").format("YYYY-MM-DD HH:mm")
              )
            }}
            type="dashed"
          >
            今週
          </Button>
          <Button
            className="m-1"
            onClick={() => adjustDateRange(1)}
            type="dashed"
          >
            <RightOutlined />
          </Button>
          <Button
            className="m-1"
            onClick={() => adjustDateRange(2)}
            type="dashed"
          >
            <DoubleRightOutlined />
          </Button>
        </div>
      </div>
      <StyledTable
        columns={dateColumns}
        expandable={{
          defaultExpandedRowKeys: expandedRowKeys,
          expandedRowRender,
          rowExpandable: (record) => record?.occurrences?.length > 0,
          expandedRowKeys,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
            ) : (
              <PlusCircleTwoTone
                twoToneColor="#52c41a"
                onClick={(e) => onExpand(record, e)}
              />
            ),
        }}
        showHeader={false}
        dataSource={dataSource}
        pagination={false}
        bordered
        size="small"
        scroll={{
          x: 640,
        }}
        onRow={(record) => ({
          onClick: () => handleExpand(record),
        })}
      />
    </>
  )
}

export default OccurrencesTable
