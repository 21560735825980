import * as Commons from "common/common"
import { useCallback, useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { Button, message, Modal, Alert, Space } from "antd"
const OrderModal = (props) => {
  const {
    publicSettings,
    isOrderModalVisible,
    setIsOrderModalVisible,
    showLoadingPageSpin,
    hideLoadingPageSpin,
    history,
  } = props

  const isMountedRef = Commons.useIsMountedRef()
  const [eventTemplates, setEventTemplates] = useState([])
  const [orderedOrder, setOrderedOrder] = useState([])
  const handelCancel = () => {
    setIsOrderModalVisible(false)
  }

  const fetchOccasionsOverview = useCallback(() => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    Commons.axiosInstance
      .get(Commons.apiOccasions + "/overview")
      .then((response) => {
        if (isMountedRef.current && response) {
          setEventTemplates(response.data || [])
          setOrderedOrder(response.data || [])
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }, [])
  const fetchOccasionsOrder = (data) => {
    if (isMountedRef.current) {
      showLoadingPageSpin()

      Commons.axiosInstance
        .put(Commons.apiOccasions + '/order/new', data)
        .then((response) => {
          if (response.status === 200) {
            message.success("位置情報の更新に成功しました")
            setIsOrderModalVisible(false)
            history.push("/occasions")
            window.location.reload();
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    }
  }
  useEffect(() => {
    fetchOccasionsOverview()
    if (!isOrderModalVisible) {
      setEventTemplates([])
    }
  }, [isOrderModalVisible, fetchOccasionsOverview])

  const handleTemplateItemOrder = () => {
    const paramData = {
      Order: orderedOrder.map((q, i) => ({
        occasionId: q?.occasionId,
        showOrder: i+1,
      })),
    }
    fetchOccasionsOrder(paramData)
  }

  const itemOnDragEndHandle = (result) => {
    if (!result.destination) {
      return
    } else {
      const reorderedOrder = Commons.RE_ORDER(
        orderedOrder,
        result.source.index,
        result.destination.index
      )

      setOrderedOrder(reorderedOrder)
    }
  }

  const handleRevert = () => {
    setOrderedOrder(eventTemplates)
  }

  return (
    <>
      <Modal
        visible={isOrderModalVisible}
        onCancel={handelCancel}
        title="イベント表示順"
        footer={null}
        destroyOnClose
        maskClosable={false}
        centered
        width={720}
        bodyStyle={{
          maxHeight: "90vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div>
          <div className="flex justify-end mb-4">
            <div>
              <Button
                className="m-1"
                size="large"
                danger
                onClick={handleRevert}
              >
                元に戻す
              </Button>
            </div>
            <div>
              <Button
                type="primary"
                className="m-1 w-32"
                size="large"
                onClick={handleTemplateItemOrder}
              >
                保存
              </Button>
            </div>
          </div>
          <div
            className="flex justify-center rounded mb-8 my-4"
            style={{
              color: publicSettings?.PRIMARY_COLOR,
              backgroundColor: publicSettings?.PRIMARY_LIGHT_COLOR,
            }}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Alert
                message=<a>イベントを上下にドラッグして並べ替えます</a>
                type="info"
                style={{ textAlign: "center"}}
                color="blue"
              />
            </Space>
          </div>
        </div>
        <div gutter={[32, 32]}>
          <div xs={24}>
            {orderedOrder && orderedOrder?.length > 0 ? (
              <DragDropContext onDragEnd={itemOnDragEndHandle}>
                <Droppable droppableId="droppableQuestion" direction="vertical">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="flex flex-col"
                    >
                      {orderedOrder.map((item, index) => (
                        <Draggable
                          key={item?.occasionId + ""}
                          draggableId={item?.occasionId + ""}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="mb-4 cursor-pointer"
                            >
                              <div
                                className="flex items-center px-4 border border-cyan-500 rounded w-full"
                                style={{
                                  borderColor: "#21acd7",
                                  backgroundColor:
                                    publicSettings?.PRIMARY_LIGHT_COLOR,
                                  color: "#21acd7",
                                }}
                              >
                                <div className="p-4 font-bold whitespace-pre-wrap">
                                  <div style={{ textAlign: "center" }}>
                                    <img
                                      src={`${Commons.mediaURL}${item.picUrl}`}
                                      style={{
                                        height: "70px",
                                        width:"180px",
                                        objectFit: "cover",
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                      }}
                                      alt="イベント画像"
                                    />
                                    <p
                                    className="mx-4"
                                      style={{
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                      }}
                                    >{`${item?.title || ""}`}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <p className="text-center">質問がありません。</p>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default OrderModal
