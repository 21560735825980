import React, { useState, useEffect, useCallback } from "react"
import { withRouter, Link } from "react-router-dom"
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Progress,
  Row,
  Tag,
  Image,
} from "antd"
import {
  MinusOutlined,
  PlusOutlined,
  MobileOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons"
import moment from "moment"
import "moment/locale/ja"
import styled from "styled-components"
import liff from "@line/liff"
import io from "socket.io-client"
import * as Commons from "common/common"
import AddLiffParticipation from "./AddLiffParticipation"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { displayError, ErrorCodes } from "common/error"

moment.locale("ja")

const DEFAULT_PARTICIPATION_CHILDREN = {
  fullName: "",
  age: null,
  month: null,
  note: "",
}

const DEFAULT_VALUE = {
  expectedGrownup: 0,
  expectedChild: 0,
  expectedPreschool: 0,
  guardianName: "",
  expectedGuardian: 0,

  attachRegistrations: [DEFAULT_PARTICIPATION_CHILDREN],
}

const StyledProgressBar = styled(Progress)`
  .ant-progress-text {
    white-space: pre !important;
    color: #3baedacc !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }
`

const findAvailableSpacePercent = (expected, capacity) => {
  return Math.round((expected / capacity) * 100)
}

const findAvailableSpaceCount = (expected, capacity) => {
  return capacity - expected
}

const {
  GET_REMAINING_UNIT_TYPE_LABEL,
  GET_UNIT_TYPE_LABEL,
  GET_MAX_RESERVATIONS_PER_ACCOUNT_UNIT_TYPE_LABEL,
} = Commons

const LiffParticipation = (props) => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props
  const occasionId = parseInt(props.match.params.occasionId)
  const occurrenceId = parseInt(props.match.params.occurrenceId)
  const isMountedRef = Commons.useIsMountedRef()
  const [form] = Form.useForm()

  const [occasion, setOccasion] = useState({})
  const [occurrence, setOccurrence] = useState({})
  const [registration, setRegistration] = useState({})

  const [isAgreementModalVisible, setIsAgreementModalVisible] = useState(false)
  const schema = yup
    .object({
      guardianName:
        occasion.parentNameShow && occasion.parentNameRq
          ? yup.string().required("保護者氏名は必須です。")
          : yup.string().optional(),
      telephone:
        occasion.parentPhoneShow && occasion.parentPhoneRq
          ? yup
              .string()
              .matches(/[0-9]/, "電話番号は必須です。")
              .required("電話番号は必須です。")
          : yup.string().optional(),
      expectedGuardian: yup.number(),

      generalNote:
        occasion?.generalNoteShow && occasion?.generalNoteRq
          ? yup.string().required(`${occasion?.generalNoteLabel}は必須です。`)
          : "",
      attachRegistrations: yup.array().of(
        yup.object({
          fullName:
            occasion?.fullNameShow && occasion?.fullNameRq
              ? yup.string().required("※ひらがなで入力してください")
              : yup.string().nullable().optional(),
          age:
            occasion?.childAgeShow && occasion?.childAgeRq
              ? yup.number().required("年齢は必要です。")
              : yup.number().nullable().optional(),
          note:
            occasion.childNoteShow && occasion.childNoteRq
              ? yup.string().required(`${occasion.childNoteLabel} は必須です。`)
              : "",
        })
      ),
    })
    .required()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },

    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: Object.assign({}, DEFAULT_VALUE),
    mode: "onChange",
  })

  const getLiffAccessTokenAsHeader = useCallback(() => {
    let headerData = {}

    try {
      headerData.headers = {
        "access-token": liff.getAccessToken(),
      }
    } catch (error) {
      history.push(Commons.liffLoginRoute)
    }
    return headerData
  }, [history])

  const fetchOccasionDetail = useCallback(() => {
    showLoadingPageSpin()

    const headerData = getLiffAccessTokenAsHeader()

    Commons.axiosInstance
      .get(`/liff/events/${occasionId}/${occurrenceId}`, headerData)
      .then((response) => {
        if (isMountedRef.current && response) {
          setOccasion(response.data || {})

          if (response.data.occurrence) {
            setOccurrence(response.data.occurrence || {})
          }
        }
      })
      .catch((error) => {
        if (error?.errorCode) {
          displayError({ error: error.errorCode })
        } else {
          displayError({ error: ErrorCodes.UNEXPECTED_ERROR })
        }
      })
      .finally(() => {
        hideLoadingPageSpin()
      })
  }, [
    isMountedRef,
    occasionId,
    showLoadingPageSpin,
    hideLoadingPageSpin,
    getLiffAccessTokenAsHeader,
  ])

  const fetchRegistration = useCallback(() => {
    showLoadingPageSpin()

    const headerData = getLiffAccessTokenAsHeader()

    Commons.axiosInstance
      .get(Commons.apiLiff, headerData)
      .then((response) => {
        if (isMountedRef.current && response) {
          setRegistration(response.data || {})

          form.setFieldsValue({
            registerName: response.data?.fullName || "",
            registerTelephone: response.data?.telephone || "",
            registerEmail: response.data?.email || "",
          })
        }
      })
      .catch((error) => {
        if (error?.errorCode) {
          displayError({ error: error.errorCode })
        } else {
          displayError({ error: ErrorCodes.UNEXPECTED_ERROR })
        }
      })
      .finally(() => {
        hideLoadingPageSpin()
      })
  }, [
    isMountedRef,
    form,
    showLoadingPageSpin,
    hideLoadingPageSpin,
    getLiffAccessTokenAsHeader,
  ])

  const handleRegistration = () => {
    const headerData = getLiffAccessTokenAsHeader()

    form
      .validateFields()
      .then((values) => {
        if (isMountedRef.current) {
          showLoadingPageSpin()

          const paramData = {
            fullName: values.registerName || "",
            // telephone: values.registerTelephone || "",
            // email: values.registerEmail || "",
          }

          Commons.axiosInstance
            .post(Commons.apiLiffRegister, paramData, headerData)
            .then((response) => {
              handleParticipationSimpleCount()
            })
            .catch((error) => {
              if (error?.error) {
                displayError({ error: error.errorCode })
              } else {
                displayError({ error: ErrorCodes.UNEXPECTED_ERROR })
              }
            })
            .finally(() => {
              if (isMountedRef.current) {
                hideLoadingPageSpin()
              }
            })
        }
      })
      .catch((error) => {})
  }

  useEffect(() => {
    fetchOccasionDetail()
    if (process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true") {
      fetchRegistration()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const socket = io(Commons.siteURL, { path: "/socket.io" })

    socket.on("updateEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          if (Object.keys(occasion).length !== 0) {
            fetchOccasionDetail()
          }
        }
      }
    })

    socket.on("updateOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          if (Object.keys(occasion).length !== 0) {
            fetchOccasionDetail()
          }
        }
      }
    })

    socket.on("deleteOccurrence", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          message.warning(Commons.socketLiffEventUpdateMsg)
          history.push(`${Commons.liffOccurrencesRoute}/${occasionId}`)
        }
      }
    })

    socket.on("deleteEvent", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          message.warning(Commons.socketLiffEventDeleteMsg)
          history.push(Commons.liffOccasionsRoute)
        }
      }
    })

    socket.on("newRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          if (Object.keys(occasion).length !== 0) {
            fetchOccasionDetail()
          }
        }
      }
    })

    socket.on("cancelRegistration", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.occasionId && response.occasionId === occasionId) {
          if (Object.keys(occasion).length !== 0) {
            fetchOccasionDetail()
          }
        }
      }
    })

    return () => {
      socket.off("updateEvent")
      socket.off("updateOccurrence")
      socket.off("deleteOccurrence")
      socket.off("deleteEvent")
      socket.off("newRegistration")
      socket.off("cancelRegistration")

      socket.disconnect()
    }

    // eslint-disable-next-line
  }, [])

  const checkCount = (occasionObj, occurrenceObj) => {
    if (occasion.isPreschoolCount) {
      if (
        (form.getFieldValue("registerAdultCount") || 0) +
          (form.getFieldValue("registerChildCount") || 0) +
          (form.getFieldValue("registerPreschoolerCount") || 0) <=
          occasionObj.maxPerPerson &&
        (form.getFieldValue("registerAdultCount") || 0) +
          (form.getFieldValue("registerChildCount") || 0) +
          (form.getFieldValue("registerPreschoolerCount") || 0) <=
          findAvailableSpaceCount(
            occurrenceObj.sumExpected,
            occurrenceObj.maxAttendee
          )
      ) {
        return true
      } else {
        return false
      }
    } else {
      if (
        (form.getFieldValue("registerAdultCount") || 0) +
          (form.getFieldValue("registerChildCount") || 0) <=
          occasionObj.maxPerPerson &&
        (form.getFieldValue("registerAdultCount") || 0) +
          (form.getFieldValue("registerChildCount") || 0) <=
          findAvailableSpaceCount(
            occurrenceObj.sumExpected,
            occurrenceObj.maxAttendee
          )
      ) {
        return true
      } else {
        return false
      }
    }
  }

  const checkNotZero = () => {
    return (
      (form.getFieldValue("registerAdultCount") || 0) +
        (form.getFieldValue("registerChildCount") || 0) +
        (occasion.isPreschoolCount
          ? form.getFieldValue("registerPreschoolerCount") || 0
          : 0) >
      0
    )
  }

  // const addCount = (type) => {
  //   if (occasion.isPreschoolCount) {
  //     if (
  //       form.getFieldValue("registerAdultCount") +
  //         form.getFieldValue("registerChildCount") +
  //         form.getFieldValue("registerPreschoolerCount") <
  //         occasion.maxPerPerson &&
  //       form.getFieldValue("registerAdultCount") +
  //         form.getFieldValue("registerChildCount") +
  //         form.getFieldValue("registerPreschoolerCount") <
  //         findAvailableSpaceCount(
  //           occurrence.sumExpected,
  //           occurrence.maxAttendee
  //         )
  //     ) {
  //       if (type === "adult") {
  //         form.setFieldsValue({
  //           registerAdultCount: form.getFieldValue("registerAdultCount") + 1,
  //         })
  //       } else if (type === "child") {
  //         form.setFieldsValue({
  //           registerChildCount: form.getFieldValue("registerChildCount") + 1,
  //         })
  //       } else if (type === "preschooler") {
  //         form.setFieldsValue({
  //           registerPreschoolerCount: form.getFieldValue("registerPreschoolerCount") + 1,
  //         })
  //       }
  //     }
  //   } else {
  //     if (type === "preschooler") {
  //       form.setFieldsValue({
  //         registerPreschoolerCount:
  //           form.getFieldValue("registerPreschoolerCount") + 1,
  //       })
  //     } else {
  //       if (
  //         form.getFieldValue("registerAdultCount") +
  //           form.getFieldValue("registerChildCount") <
  //           occasion.maxPerPerson &&
  //         form.getFieldValue("registerAdultCount") +
  //           form.getFieldValue("registerChildCount") <
  //           findAvailableSpaceCount(
  //             occurrence.sumExpected,
  //             occurrence.maxAttendee
  //           )
  //       ) {
  //         if (type === "adult") {
  //           form.setFieldsValue({
  //             registerAdultCount: form.getFieldValue("registerAdultCount") + 1,
  //           })
  //         } else if (type === "child") {
  //           form.setFieldsValue({
  //             registerChildCount: form.getFieldValue("registerChildCount") + 1,
  //           })
  //         }
  //       }
  //     }
  //   }
  // }

  const addCount = (type) => {
    if (type === "adult") {
      form.setFieldsValue({
        registerAdultCount: form.getFieldValue("registerAdultCount") + 1,
      })
    } else if (type === "child") {
      form.setFieldsValue({
        registerChildCount: form.getFieldValue("registerChildCount") + 1,
      })
    } else if (type === "preschooler") {
      form.setFieldsValue({
        registerPreschoolerCount:
          form.getFieldValue("registerPreschoolerCount") + 1,
      })
    }
  }

  const subtractCount = (type) => {
    if (type === "adult") {
      if (form.getFieldValue("registerAdultCount") > 0) {
        form.setFieldsValue({
          registerAdultCount: form.getFieldValue("registerAdultCount") - 1,
        })
      }
    } else if (type === "child") {
      if (form.getFieldValue("registerChildCount") > 0) {
        form.setFieldsValue({
          registerChildCount: form.getFieldValue("registerChildCount") - 1,
        })
      }
    } else if (type === "preschooler") {
      if (form.getFieldValue("registerPreschoolerCount") > 0) {
        form.setFieldsValue({
          registerPreschoolerCount:
            form.getFieldValue("registerPreschoolerCount") - 1,
        })
      }
    }
  }

  const displayNotEnoughSpaceConfirmModal = () => {
    Modal.info({
      title: "確認",
      content: Commons.warnNotEnoughAvailableSpaceMsg,
      okText: "確認",
      centered: true,
    })
  }

  const handleAgreement = () => {
    if (process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true") {
      if (checkCount(occasion, occurrence)) {
        handleRegistration()
      } else {
        hideAgreementModal()
        displayNotEnoughSpaceConfirmModal()
      }
    } else {
      if (checkCount(occasion, occurrence)) {
        handleParticipationSimpleCount()
      } else {
        displayNotEnoughSpaceConfirmModal()
      }
    }
  }

  const showAgreementModal = () => {
    setIsAgreementModalVisible(true)
  }

  const hideAgreementModal = () => {
    setIsAgreementModalVisible(false)
  }

  // Ensure the payment script is loaded
  const loadPaymentScript = (data) => {
    return new Promise((resolve, reject) => {
      const existingScript = document.querySelector(
        "script[src='https://pay3.veritrans.co.jp/pop/v1/javascripts/pop.js']"
      )
      if (existingScript) {
        // If script already exists, resolve immediately
        console.log("Payment script already loaded")
        resolve(data)
      } else {
        const script = document.createElement("script")
        script.src = "https://pay3.veritrans.co.jp/pop/v1/javascripts/pop.js"
        script.setAttribute(
          "data-client-key",
          process.env.REACT_APP_DGFT_POP_CLIENT_KEY
        )
        script.async = true
        script.onload = () => {
          console.log("Script loaded successfully")
          resolve(data)
        }
        script.onerror = () => {
          console.error("Error loading script")
          reject(new Error("Error loading payment script"))
        }
        document.body.appendChild(script)
      }
    })
  }

  // Define the success and failure handlers
  const handlePaymentSuccess = () => {
    message.success(Commons.successPaymentMsg)
    history.push(
      `${Commons.liffInfoRoute}/${occasionId}/${occurrence.occurrenceId}`
    )
  }

  const handlePaymentFailure = (paymentResult) => {
    displayError({ error: ErrorCodes.PAYMENT_TRANSACTION_FAILED })
  }

  // Initialize the payment
  const popPayOptions = {
    onSuccess: handlePaymentSuccess,
    onFailure: handlePaymentFailure,
  }

  const registerForPaidEvent = (data) => {
    const headerData = getLiffAccessTokenAsHeader()
    Commons.axiosInstance
      .post(
        `${Commons.apiLiffOccasionValidate}/${occasionId}/occurrence/${occurrence.occurrenceId}/payment`,
        {
          ...data,
          occurrenceId: occurrence.occurrenceId,
          fullName: data.guardianName,
        },
        headerData
      )
      .then(loadPaymentScript)
      .then((response) => {
        const { payment_key } = response.data

        if (typeof pop == "undefined") {
          displayError({ error: ErrorCodes.PAYMENT_LOAD_FAILED })
        }

        console.info("Initiating payment")
        // eslint-disable-next-line no-undef
        pop.pay(payment_key, popPayOptions)
      })
      .catch((error) => {
        if (error?.errorCode) {
          displayError({ error: error.errorCode })
        } else {
          displayError({ error: ErrorCodes.UNEXPECTED_ERROR })
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const registerForUnpaidEvent = (data) => {
    const headerData = getLiffAccessTokenAsHeader()
    Commons.axiosInstance
      .post(
        Commons.apiLiffOccasionDetail,
        {
          ...data,
          occurrenceId: occurrence.occurrenceId,
          fullName: data.guardianName,
        },
        headerData
      )
      .then((response) => {
        message.success(Commons.successCreateParticipationMsg)
        history.push(
          Commons.liffInfoRoute +
            "/" +
            occasionId +
            "/" +
            occurrence.occurrenceId
        )
      })
      .catch(handleUnpaidEventError)
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const handleUnpaidEventError = (error) => {
    if (error?.response?.status === 403) {
      Modal.info({
        title: "確認",
        content: "応募期間を過ぎましたので応募できません。",
        okText: "確認",
        centered: true,
      })
    } else if (error?.response?.status === 406) {
      if (
        error?.response?.data?.errorCode === ErrorCodes.RESERVATION_MAX_EXCEEDED
      ) {
        displayError(error?.response?.data)
      } else {
        Modal.info({
          title: "確認",
          content: Commons.warnNoAvailableSpaceMsg,
          okText: "確認",
          centered: true,
          onOk() {
            if (process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true") {
              fetchRegistration()
            }
            fetchOccasionDetail()
          },
        })
      }
    } else if (error?.response?.status === 409) {
      Modal.info({
        title: "確認",
        content: Commons.warnRegistrationOverlapMsg,
        okText: "確認",
        centered: true,
      })
    } else if (error?.response?.status === 500) {
      message.error(Commons.errorSystemMsg)
    } else {
      displayError(error?.response?.data)
    }
  }

  const handleParticipationSimpleCount = () => {
    if (isMountedRef.current) {
      if (
        (form.getFieldValue("registerAdultCount") || 0) +
          (form.getFieldValue("registerChildCount") || 0) +
          (occasion.isPreschoolCount
            ? form.getFieldValue("registerPreschoolerCount") || 0
            : 0) >
        0
      ) {
        showLoadingPageSpin()

        const paramData = {
          expectedGrownup: form.getFieldValue("registerAdultCount"),
          expectedChild: form.getFieldValue("registerChildCount"),
          expectedPreschool: form.getFieldValue("registerPreschoolerCount"),
          expectedGuardian: 0,
          occurrenceId: occurrence.occurrenceId,
        }

        if (occasion.hasBaseTicketPrice && occasion.baseTicketPrice) {
          registerForPaidEvent(paramData)
        } else {
          registerForUnpaidEvent(paramData)
        }
      } else {
        Modal.info({
          title: "確認",
          content: Commons.warnRegistrationZeroMsg,
          okText: "確認",
          centered: true,
        })
      }
    }
  }

  const handleParticipationNew = (data) => {
    if (isMountedRef.current) {
      if (
        occasion.isPreschoolCount
          ? data.expectedGuardian + data.attachRegistrations.length <=
              occasion.maxPerPerson &&
            data.expectedGuardian + data.attachRegistrations.length <=
              findAvailableSpaceCount(
                occurrence.sumExpected,
                occurrence.maxAttendee
              )
          : data.attachRegistrations.length <= occasion.maxPerPerson &&
            data.attachRegistrations.length <=
              findAvailableSpaceCount(
                occurrence.sumExpected,
                occurrence.maxAttendee
              )
      ) {
        showLoadingPageSpin()

        if (occasion.hasBaseTicketPrice && occasion.baseTicketPrice) {
          registerForPaidEvent(data)
        } else {
          registerForUnpaidEvent(data)
        }
      } else {
        displayNotEnoughSpaceConfirmModal()
      }
    }
  }

  const onSubmit = (values) => {
    const headerData = getLiffAccessTokenAsHeader()

    Commons.axiosInstance
      .post(
        Commons.apiLiffRegister,
        { fullName: values.guardianName, telephone: values.telephone },
        headerData
      )
      .then((response) => {
        handleParticipationNew(values)
      })
      .catch((error) => {
        if (error?.errorCode) {
          displayError({ error: error.errorCode })
        } else {
          displayError({ error: ErrorCodes.UNEXPECTED_ERROR })
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const SimpleCountParticipation = () => (
    <Col span={24}>
      <Row>
        <Col
          style={{
            backgroundColor: "#f0feff",
            color: "#21acd7",
            border: "1px solid #21acd7",
          }}
          className="p-4 rounded"
          span={24}
        >
          <Row justify="center">
            <Col xs={24} md={20} lg={16} xl={8}>
              <Form
                form={form}
                name="registerForm"
                scrollToFirstError
                colon={false}
                requiredMark={false}
                initialValues={{
                  registerName: registration.fullName || "",
                  // registerTelephone: registration.telephone || "",
                  // registerEmail: registration.email || "",
                  registerAdultCount: 0,
                  registerChildCount: 0,
                  registerPreschoolerCount: 0,
                }}
                size="large"
              >
                <Form.Item
                  label="名前"
                  name="registerName"
                  className={
                    process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true"
                      ? "block"
                      : ""
                  }
                  help="※本人確認の為、フルネームの記入をお願い致します"
                  hasFeedback
                  rules={[
                    {
                      required:
                        process.env.REACT_APP_DEFAULT_NEED_REGISTRATION ===
                        "true"
                          ? true
                          : false,
                      message: "名前は必須です",
                    },
                    {
                      whitespace: true,
                      message: "有効な名前を入力してください",
                    },
                    {
                      max: 50,
                      message: "名前は50文字未満である必要があります",
                    },
                  ]}
                  hidden={
                    process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true"
                      ? false
                      : true
                  }
                >
                  <Input
                    placeholder="例：山田太郎"
                    onPressEnter={(e) => e.preventDefault()}
                  />
                </Form.Item>
                {/* <Form.Item
                  label="電話番号"
                  name="registerTelephone"
                  className={
                    process.env.REACT_APP_DEFAULT_NEED_REGISTRATION ===
                    "true"
                      ? "block"
                      : ""
                  }
                  hasFeedback
                  rules={[
                    {
                      required:
                        process.env
                          .REACT_APP_DEFAULT_NEED_REGISTRATION === "true"
                          ? true
                          : false,
                      message: "電話番号は必須です",
                    },
                  ]}
                  hidden={
                    process.env.REACT_APP_DEFAULT_NEED_REGISTRATION ===
                    "true"
                      ? false
                      : true
                  }
                >
                  <Commons.NumericInput placeholder="例：08000000000" />
                </Form.Item>
                <Form.Item
                  label="メールアドレス"
                  name="registerEmail"
                  className={
                    process.env.REACT_APP_DEFAULT_NEED_REGISTRATION ===
                    "true"
                      ? "block"
                      : ""
                  }
                  hidden={
                    process.env.REACT_APP_DEFAULT_NEED_REGISTRATION ===
                    "true"
                      ? false
                      : true
                  }
                >
                  <Input
                    placeholder="例：t_yamada@sample.jp"
                    onPressEnter={(e) => e.preventDefault()}
                  />
                </Form.Item> */}
                <Row justify="center" className="mx-4">
                  <Col>
                    <Tag
                      icon={<MobileOutlined />}
                      className="text-center whitespace-pre-wrap m-1"
                      style={{
                        backgroundColor: "#f0feff",
                        color: "#21acd7",
                        border: "1px solid #21acd7",
                      }}
                    >
                      {GET_MAX_RESERVATIONS_PER_ACCOUNT_UNIT_TYPE_LABEL(
                        occasion.unitType,
                        occasion.maxPerPerson
                      )}
                    </Tag>
                  </Col>
                  {/* <Col>
                    <Tag
                      icon={
                        occasion.isPreschoolCount ? (
                          <UserAddOutlined />
                        ) : (
                          <UserDeleteOutlined />
                        )
                      }
                      className="text-center whitespace-pre-wrap m-1"
                      style={{
                        backgroundColor: occasion.isPreschoolCount
                          ? "#f0feff"
                          : "#f6ffed",
                        color: occasion.isPreschoolCount
                          ? "#21acd7"
                          : "#52c41a",
                        border: occasion.isPreschoolCount
                          ? "1px solid #21acd7"
                          : "1px solid #52c41a",
                      }}
                    >
                      {`${
                        occasion.isPreschoolCount
                          ? "同伴者も\nカウント"
                          : "同伴者を\nカウントされません"
                      }`}
                    </Tag>
                  </Col> */}
                </Row>
                <Divider />
                <Row justify="center">
                  <Col span={24} className="text-center">
                    <Form.Item
                      label={`${
                        occasion.isSimpleCount
                          ? occasion.unitType === "area"
                            ? "区画数"
                            : "参加者"
                          : "大人"
                      }`}
                      className="block"
                    >
                      <Input.Group compact>
                        <Button
                          icon={<MinusOutlined />}
                          style={{ marginRight: 0 }}
                          onClick={() => {
                            subtractCount("adult")
                          }}
                        />
                        <Form.Item
                          className="mb-0"
                          name="registerAdultCount"
                          rules={[
                            {
                              required: true,
                              message: `${
                                occasion.isSimpleCount
                                  ? occasion.unitType === "area"
                                    ? "区画数"
                                    : "参加者"
                                  : "大人"
                              }の数は必須です`,
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            style={{
                              width: "80px",
                              borderLeftWidth: 0,
                              borderRightWidth: 0,
                              textAlign: "center",
                            }}
                            readOnly
                            tabIndex="-1"
                          />
                        </Form.Item>
                        <Button
                          icon={<PlusOutlined />}
                          onClick={() => {
                            addCount("adult")
                          }}
                        />
                      </Input.Group>
                    </Form.Item>
                    {occasion.isSimpleCount && occasion.unitType === "area" ? (
                      <p>※1区画4名まで利用可能です。</p>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col span={24} className="text-center">
                    <Form.Item
                      label="子供"
                      className={occasion.isSimpleCount ? "" : "block"}
                      hidden={occasion.isSimpleCount ? true : false}
                    >
                      <Input.Group compact>
                        <Button
                          icon={<MinusOutlined />}
                          style={{ marginRight: 0 }}
                          onClick={() => {
                            subtractCount("child")
                          }}
                        />
                        <Form.Item
                          name="registerChildCount"
                          rules={[
                            {
                              required: true,
                              message: "子供の数は必須です",
                            },
                          ]}
                          hidden={occasion.isSimpleCount ? true : false}
                          noStyle
                        >
                          <Input
                            style={{
                              width: "80px",
                              borderLeftWidth: 0,
                              borderRightWidth: 0,
                              textAlign: "center",
                            }}
                            readOnly
                            tabIndex="-1"
                          />
                        </Form.Item>
                        <Button
                          icon={<PlusOutlined />}
                          onClick={() => {
                            addCount("child")
                          }}
                        />
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  {/* {occasion.isPreschoolCount ? "" : <Divider />} */}
                  {/* <Col span={24} className="text-center">
                    <Form.Item
                      label={`${
                        occasion.isSimpleCount ? "同伴者" : "未就学児"
                      }`}
                      className="block"
                    >
                      <Input.Group compact>
                        <Button
                          icon={<MinusOutlined />}
                          style={{ marginRight: 0 }}
                          onClick={() => {
                            subtractCount("preschooler")
                          }}
                        />
                        <Form.Item
                          name="registerPreschoolerCount"
                          rules={[
                            {
                              required: true,
                              message: `${
                                occasion.isSimpleCount ? "同伴者" : "未就学児"
                              }の数は必須です`,
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            style={{
                              width: "80px",
                              borderLeftWidth: 0,
                              borderRightWidth: 0,
                              textAlign: "center",
                            }}
                            readOnly
                            tabIndex="-1"
                          />
                        </Form.Item>
                        <Button
                          icon={<PlusOutlined />}
                          onClick={() => {
                            addCount("preschooler")
                          }}
                        />
                      </Input.Group>
                    </Form.Item>
                  </Col> */}
                  {occasion.isPreschoolCount ? (
                    ""
                  ) : (
                    <Col span={24} className="text-center whitespace-pre-wrap">
                      <Alert
                        message={`${
                          occasion.isSimpleCount
                            ? "※2歳～6歳の同伴保護者（入場無料）は参加者には含まないでください。"
                            : "※未就学児は、参加カウントされません。"
                        }`}
                        type="warning"
                      />
                    </Col>
                  )}
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )

  return (
    <>
      <Row gutter={[8, 8]} className="mx-4">
        <Col span={24} className="text-center">
          <img
            src="/logo-header.png"
            alt="ロゴ"
            style={{ maxHeight: "120px" }}
            className="max-w-full mt-2 mx-4"
          />
        </Col>
        <Col span={24}>
          <div className="bg-primary text-center p-4 mt-4 rounded">
            <span className="text-white text-lg font-bold whitespace-pre-wrap">
              {"参加者数と登録情報を\n入力してください "}
            </span>
          </div>
        </Col>
        <Col span={24} className="text-center">
          <Image
            preview={false}
            src={
              occasion?.occasionImages &&
              occasion?.occasionImages?.length > 0 &&
              occasion.occasionImages[0]?.picUrl
                ? `${Commons.occasionImagesURL}${occasion.occasionImages[0]?.picUrl}`
                : "/no-image.png"
            }
            fallback="/no-image.png"
            alt={occasion.title || ""}
            style={{ maxHeight: "320px" }}
            className="rounded max-w-full"
          />
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Col className="text-center">
              <span className="text-sm font-bold">{occasion.title || ""}</span>
            </Col>
          </Row>
        </Col>
        {occurrence ? (
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Row>
                  <Col
                    span={24}
                    style={{
                      backgroundColor: "#f0feff",
                      color: "#21acd7",
                      border: "1px solid #21acd7",
                      borderBottom: "none",
                    }}
                    className="text-center p-1 rounded-t"
                  >
                    <span className="text-xs font-bold text-primary whitespace-pre-wrap">
                      {moment(occurrence.startAt).format(
                        "YYYY年M月D日[\n]HH時mm分"
                      )}
                    </span>
                  </Col>
                  <Col
                    span={24}
                    style={{
                      border: "1px solid #21acd7",
                    }}
                    className="text-center p-2"
                  >
                    <StyledProgressBar
                      type="circle"
                      strokeColor="#3baedacc"
                      trailColor="#f0f0f0"
                      strokeLinecap="butt"
                      width={120}
                      strokeWidth="12"
                      percent={findAvailableSpacePercent(
                        occurrence.sumExpected || 0,
                        occurrence.maxAttendee || 0
                      )}
                      format={(count) =>
                        `${GET_REMAINING_UNIT_TYPE_LABEL(
                          occasion.unitType
                        )}${findAvailableSpaceCount(
                          occurrence.sumExpected || 0,
                          occurrence.maxAttendee || 0
                        )}${GET_UNIT_TYPE_LABEL(occasion.unitType).replace(
                          "画",
                          ""
                        )}`
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        ) : (
          ""
        )}

        {occasion.isSimpleCount ? (
          <SimpleCountParticipation />
        ) : (
          (occasion.parentNameShow ||
            occasion.parentPhoneShow ||
            occasion?.fullNameShow ||
            occasion?.childAgeShow ||
            occasion?.childNoteShow ||
            occasion?.generalNoteShow) && (
            <Col span={24}>
              <Row>
                <Col
                  style={{
                    backgroundColor: "#f0feff",
                    color: "#21acd7",
                    border: "1px solid #21acd7",
                  }}
                  className="p-4 rounded"
                  span={24}
                >
                  <Row justify="center">
                    <Col xs={24} md={20} lg={16} xl={8}>
                      <AddLiffParticipation
                        occasion={occasion}
                        control={control}
                        setValue={setValue}
                        reset={reset}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )
        )}

        <Col span={24}>
          <Row gutter={[8, 8]} justify="center" className="m-4">
            <Col span={12}>
              <Button size="large" block onClick={history.goBack}>
                戻る
              </Button>
            </Col>
            <Col span={12}>
              {occasion.isSimpleCount ? (
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    checkCount(occasion, occurrence)
                      ? checkNotZero()
                        ? process.env.REACT_APP_DEFAULT_NEED_TERMS === "true"
                          ? showAgreementModal()
                          : handleAgreement()
                        : Modal.info({
                            title: "確認",
                            content: Commons.warnRegistrationZeroMsg,
                            okText: "確認",
                            centered: true,
                          })
                      : Modal.info({
                          title: "確認",
                          content: Commons.warnNotEnoughAvailableSpaceMsg,
                          okText: "確認",
                          centered: true,
                        })
                  }}
                  block
                >
                  登録
                </Button>
              ) : (
                <Button
                  type="primary"
                  size="large"
                  onClick={handleSubmit(onSubmit)}
                  block
                >
                  登録
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        visible={isAgreementModalVisible}
        title="同意書"
        onCancel={hideAgreementModal}
        footer={null}
        centered
        destroyOnClose
      >
        <div className="mb-4 masked-overflow">
          <p className="text-base text-center text-primary mt-6 mb-4 whitespace-pre-wrap">
            {"AIBOKU Friends(アイボクフレンズ)\n参加申込・同意書"}
          </p>
          <p className="text-sm text-center text-primary underline mb-2">
            保護者の皆さまへ
          </p>
          <p className="text-xs mb-2">
            {" "}
            私は、愛知牧場の主催するAIBOKU
            Friends(以下「本イベント」といいます)に参加するにあたり以下に同意のうえ参加申し込みします。
          </p>
          <ol className="list-decimal list-outside ml-4 mb-2">
            <li className="text-xs">
              私は自分自身の意思で、かつ以下の内容を理解し、同意の上で本イベントに参加します。
            </li>
            <li className="text-xs">
              本イベントに参加するにあたり、すべての責任は保護者及び参加者自身にあることを承諾します。
            </li>
            <li className="text-xs">
              本イベントに参加するにあたり、お子様の健康状態に無理がない状態で臨むことを約束します。
            </li>
            <li className="text-xs">
              本イベントに参加するにあたり、当牧場スタッフの指示に従います。
            </li>
            <li className="text-xs">
              自ら、またはお子様が怪我を負った場合、または他人に怪我を負わせた場合、その責任は保護者にあり主催者、協力会社、及びそのスタッフのいずれに対しても、怪我等に関する費用や損害賠償等の請求をしません。
            </li>
            <li className="text-xs">
              貴重品、その他荷物の管理は自身で行います。
            </li>
            <li className="text-xs">
              本イベントはイベント実行に影響するような悪天候や周辺環境の都合によって、中止や内容の変更があることを理解します。
            </li>
            <li className="text-xs">
              本イベント参加料等はいかなる理由があっても返金できないことを承諾します。
            </li>
          </ol>
          <p className="text-xs text-center text-primary underline mb-2">
            お子様の写真掲載に関する確認
          </p>
          <p className="text-xs">
            {" "}
            当牧場では、地域や保護者の皆様にご理解とご協力をいただくため、お子様の活動の様子を愛知牧場のWEBサイト・SNSならびに他メディア媒体に掲載し、牧場の活動について広く皆様に紹介していきたいと考えております。お子様に関する写真等の掲載について、ご理解いただきますようよろしくお願い申し上げます。
          </p>
        </div>
        <Row justify="center" gutter={[8, 0]}>
          <Col>
            <Button size="large" onClick={hideAgreementModal}>
              同意しない
            </Button>
          </Col>
          <Col>
            <Button size="large" type="primary" onClick={handleAgreement}>
              同意
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default withRouter(LiffParticipation)
