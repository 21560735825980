import { Col, Form, Input, Row } from "antd"
import Group from "views/Home/Group"

const { TextArea } = Input

export const OccasionUpdateRemindMessage24HrFormItem = () => (
  <Group title="リマインドメッセージ（イベント前日に届く）">
    <Row>
      <Col span={24}>
        <Form.Item
          name="occasionUpdateRemindMessage"
          help="※1日過ぎた後の予約は直ぐに届きます。設定されていない場合は、LINE側でメッセージが送信されません。"
          style={{ marginBottom: 0 }}
        >
          <TextArea placeholder="メッセージを入力してください" autoSize />
        </Form.Item>
      </Col>
    </Row>
  </Group>
)
