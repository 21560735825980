import { Col, Form, Row, Switch } from "antd"
import Group from "views/Home/Group"

export const OccasionUpdatePreschoolCountFormItem = () => (
  <Group title="「未就学児の同伴者」を参加者としてカウントする">
    <Row>
      <Col span={24}>
        <Form.Item name="occasionUpdatePreschool" valuePropName="checked">
          <Switch name="occasionUpdatePreschool" />
        </Form.Item>
      </Col>
    </Row>
  </Group>
)
