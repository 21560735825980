import { Col, Form, Row, Switch } from "antd"
import Group from "views/Home/Group"

export const OccasionUpdateCanOverlapFormItem = () => (
  <Group title="他のイベントと同時に予約を可能にする">
    <Row>
      <Col span={24}>
        <Form.Item
          name="occasionUpdateCanOverlap"
          valuePropName="checked"
          style={{ marginBottom: 0 }}
        >
          <Switch name="occasionUpdateCanOverlap" />
        </Form.Item>
      </Col>
    </Row>
  </Group>
)
