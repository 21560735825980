/**
 * @typedef {Object} GroupProps
 * @property {string} title
 * @property {React.ReactNode} children
 *
 * @param {GroupProps} props
 */
export default function Group({ title, children }) {
  return (
    <div className="w-full mb-3">
      <div className="bg-[#E8E8E8] p-2 mb-2 w-full">
        <div className="text-[16px] leading-4">{title}</div>
      </div>

      <div className="px-3">{children}</div>
    </div>
  )
}
