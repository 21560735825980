import { MinusOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons"
import {
  Button,
  Form,
  Input as AntInput,
  Modal,
  Row,
  Divider,
  Typography,
  Col,
  Collapse,
  Space,
  Select,
} from "antd"
import PropTypes from "prop-types"
import { Controller } from "react-hook-form"
import styled from "styled-components"

import useOccasionModal from "./OccasionModal.hook"

/**
 * @typedef {Object} Occasion
 * @property {boolean} childAgeLimitShow
 * @property {boolean} fullNameShow
 * @property {boolean} childNoteShow
 * @property {boolean} childAgeShow
 * @property {boolean} generalNoteShow
 * @property {boolean} parentNameShow
 * @property {boolean} parentPhoneShow
 * @property {boolean} fullNameRq
 * @property {boolean} childAgeRq
 * @property {boolean} parentNameRq
 * @property {boolean} parentPhoneRq
 * @property {boolean} childNoteRq
 * @property {boolean} generalNoteRq
 * @property {boolean} childAgeLimitRq
 * @property {boolean} isSimpleCount
 * @property {string} fullName
 * @property {string} telephone
 * @property {string} displayName
 * @property {string} childNoteLabel
 * @property {string} generalNoteLabel
 * @property {boolean} isManual
 * @property {number} minAge
 * @property {number} maxAge
 *
 * @typedef {Object} Props
 * @property {Occasion} occasion
 */

const Input = styled(AntInput)`
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`

/**
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
const OccasionModalLayout = ({
  onCancel,
  visible,
  control,
  onSubmit,
  handleSubmit,
  submitting,
  handleAddParticipationChild,
  removeParticipationChild,
  attachRegistrations,
  errors,
  action,
  onChangeTelephone,
  occasion,
}) => {
  const genExtra = (id) => (
    <DeleteOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation()
        removeParticipationChild(id)
      }}
    />
  )

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={
        action === "new"
          ? "新規予約"
          : action === "edit"
          ? "予約変更"
          : "予約参加"
      }
      centered
      footer={null}
    >
      <Form layout="vertical">
        {occasion.parentNameShow && (
          <Form.Item
            name="guardianName"
            label="保護者氏名"
            help={errors.guardianName ? errors.guardianName.message : undefined}
            validateStatus={errors.guardianName ? "error" : undefined}
            rules={[
              {
                required: occasion.parentNameShow && occasion.parentNameRq,
                message: "",
              },
            ]}
          >
            <Controller
              name="guardianName"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={action === "edit" || action === "confirm"}
                />
              )}
            />
          </Form.Item>
        )}

        {occasion.parentPhoneShow && (
          <Form.Item
            name="telephone"
            label="ご連絡先電話番号"
            placeholder="例：08000000000"
            help={errors.telephone ? errors.telephone.message : undefined}
            validateStatus={errors.telephone ? "error" : undefined}
            rules={[
              {
                required: occasion.parentPhoneShow && occasion.parentPhoneRq,
                message: "",
              },
            ]}
          >
            <Controller
              name="telephone"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(e) => {
                    field.onChange(e)
                    onChangeTelephone(e.target.value)
                  }}
                  disabled={action === "edit" || action === "confirm"}
                />
              )}
            />
          </Form.Item>
        )}

        {occasion?.isSimpleCount && (
          <>
            <Controller
              name="expectedGuardian"
              control={control}
              render={({
                field: { onBlur, onChange, value },
                fieldState: { error },
              }) => (
                <Form.Item
                  label="同伴者"
                  help={error ? error.message : undefined}
                  validateStatus={error ? "error" : undefined}
                >
                  <Input.Group compact>
                    <Button
                      disabled={value === 0}
                      icon={<MinusOutlined />}
                      onClick={(e) => {
                        if (value === 0) {
                          e.preventDefault()
                        } else {
                          onChange(value - 1)
                        }
                      }}
                    />
                    <Input
                      style={{
                        width: "80px",
                        textAlign: "center",
                      }}
                      readOnly
                      tabIndex="-1"
                      onBlur={onBlur}
                      value={value}
                    />
                    <Button
                      icon={<PlusOutlined />}
                      onClick={(e) => {
                        onChange(value + 1)
                      }}
                    />
                  </Input.Group>
                </Form.Item>
              )}
            />
            <Divider />
          </>
        )}

        {!occasion?.fullNameShow &&
        !occasion?.childAgeShow &&
        !occasion?.childNoteShow ? null : (
          <>
            <Space size={10} style={{ marginBottom: 15 }}>
              <Typography.Text>参加者</Typography.Text>
              <Button
                type="primary"
                onClick={handleAddParticipationChild}
                disabled={attachRegistrations.length === 5}
                shape="circle"
                icon={<PlusOutlined />}
                size="small"
              />
              {/* <Button type="text" onClick={handleAddParticipationChild} disabled={attachRegistrations.length === 5}>
            Add child
          </Button> */}
            </Space>

            <Collapse
              bordered={false}
              accordio
              defaultActiveKey={attachRegistrations[0].id}
            >
              {attachRegistrations.map((item, idx) => {
                return (
                  <Collapse.Panel
                    extra={idx === 0 ? undefined : genExtra(idx)}
                    header={`参加者 ${idx + 1}`}
                    key={item.id}
                  >
                    {occasion?.fullNameShow && (
                      <Form.Item
                        name={`attachRegistrations.${idx}.fullName`}
                        label="おなまえ（ひらがな入力）"
                        help={
                          errors?.attachRegistrations &&
                          errors?.attachRegistrations[idx]?.fullName
                            ? errors?.attachRegistrations[idx]?.fullName.message
                            : undefined
                        }
                        validateStatus={
                          errors?.attachRegistrations &&
                          errors?.attachRegistrations[idx]?.fullName
                            ? "error"
                            : undefined
                        }
                        rules={[
                          {
                            required:
                              occasion?.fullNameShow && occasion?.fullNameRq,
                            message: "",
                          },
                        ]}
                      >
                        <Controller
                          name={`attachRegistrations.${idx}.fullName`}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <Input
                              {...field}
                              placeholder="あおしすあおい"
                              onPressEnter={(e) => e.preventDefault()}
                            />
                          )}
                        />
                      </Form.Item>
                    )}

                    {occasion?.childAgeShow ? (
                      <Form.Item
                        name="age"
                        label="年齢"
                        rules={[
                          {
                            required:
                              occasion?.childAgeShow &&
                              occasion?.childAgeLimitRq,
                            message: "",
                          },
                        ]}
                      >
                        <Space size={30} wrap={true}>
                          <Controller
                            name={`attachRegistrations.${idx}.age`}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <Form.Item
                                help={error ? error.message : undefined}
                                validateStatus={error ? "error" : undefined}
                              >
                                <Space size={8}>
                                  <Select
                                    {...field}
                                    style={{ width: 150 }}
                                    options={Array.from(
                                      { length: 19 },
                                      (_, i) => ({
                                        value: i,
                                        label: i,
                                        disabled: !(
                                          i >= occasion?.minAge &&
                                          i <= occasion?.maxAge
                                        ),
                                      })
                                    )}
                                  />
                                  歳
                                </Space>
                              </Form.Item>
                            )}
                          />
                          <Controller
                            name={`attachRegistrations.${idx}.month`}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <Form.Item
                                help={error ? error.message : undefined}
                                validateStatus={error ? "error" : undefined}
                              >
                                <Space size={8}>
                                  <Select
                                    {...field}
                                    style={{ width: 150 }}
                                    options={Array.from(
                                      { length: 11 },
                                      (_, i) => ({
                                        value: i + 1,
                                        label: i + 1,
                                      })
                                    )}
                                  />
                                  ヶ月
                                </Space>
                              </Form.Item>
                            )}
                          />
                        </Space>
                      </Form.Item>
                    ) : null}

                    {occasion?.childNoteShow && (
                      <Form.Item
                        name={`attachRegistrations.${idx}.note`}
                        help={
                          errors?.attachRegistrations &&
                          errors?.attachRegistrations[idx]?.note
                            ? errors?.attachRegistrations[idx]?.note.message
                            : undefined
                        }
                        validateStatus={
                          errors?.attachRegistrations &&
                          errors?.attachRegistrations[idx]?.note
                            ? "error"
                            : undefined
                        }
                        rules={[
                          {
                            required:
                              occasion?.childNoteShow && occasion.childNoteRq,
                            message: "",
                          },
                        ]}
                        label={
                          occasion.childNoteLabel !== ""
                            ? occasion.childNoteLabel
                            : "メッセージ　（レースへの意気込み）"
                        }
                      >
                        <Controller
                          name={`attachRegistrations.${idx}.note`}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <Input.TextArea
                              {...field}
                              rows={4}
                            ></Input.TextArea>
                          )}
                        />
                      </Form.Item>
                    )}
                  </Collapse.Panel>
                )
              })}
            </Collapse>
          </>
        )}
        {occasion.generalNoteShow && (
          <Form.Item
            className="my-4"
            name={`generalNote`}
            label={
              occasion.generalNoteLabel !== ""
                ? occasion.generalNoteLabel
                : "メッセージ（一般)"
            }
            help={
              errors?.generalNote && errors?.generalNote
                ? errors?.generalNote.message
                : undefined
            }
            validateStatus={
              errors?.generalNote && errors?.generalNote ? "error" : undefined
            }
            rules={[
              {
                required: occasion.generalNoteShow && occasion.generalNoteRq,
                message: "メッセージ（一般）は空白のままにすることはできません",
              },
            ]}
          >
            <Controller
              name={`generalNote`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input.TextArea {...field} rows={4}></Input.TextArea>
              )}
            />
          </Form.Item>
        )}
        <Divider />

        <Row gutter={[8, 8]} justify="center" className="m-4">
          <Col>
            <Button onClick={onCancel}>閉じる</Button>
          </Col>
          <Col>
            <Button
              onClick={handleSubmit(onSubmit)}
              type="primary"
              loading={submitting}
            >
              {action === "new" ? "登録" : action === "edit" ? "変更" : "保存"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const OccasionModal = (props) => (
  <OccasionModalLayout {...useOccasionModal(props)} />
)

OccasionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
}

OccasionModalLayout.propTypes = {
  control: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  submitting: PropTypes.bool.isRequired,
  handleAddParticipationChild: PropTypes.func.isRequired,
  removeParticipationChild: PropTypes.func.isRequired,
  onChangeTelephone: PropTypes.func.isRequired,
  attachRegistrations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      age: PropTypes.number,
      month: PropTypes.number,
      note: PropTypes.string,
    })
  ),
}

export default OccasionModal
