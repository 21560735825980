import React from "react"
import { Button, Form, Upload, Image } from "antd"
import { PlusOutlined, CameraOutlined, CloseOutlined } from "@ant-design/icons"
import styled from "styled-components"
import Moment from "moment"
import "moment/locale/ja"
import { extendMoment } from "moment-range"
import * as Commons from "common/common"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"

const moment = extendMoment(Moment)
moment.locale("ja")

const CustomUpload = styled(Upload)`
  .ant-upload {
    width: 100%;
    max-height: "200px";
  }
`

const MultiImageUpload = ({
  form,
  occasionImages,
  setOccasionImages,
  isPreview,
}) => {
  const occasionImagesOnDragEndHandle = (result) => {
    if (!result.destination) {
      return
    } else {
      const orderedOccasionImages = Commons.RE_ORDER(
        form.getFieldValue("occasionImages"),
        result.source.index,
        result.destination.index
      )

      const orderedOccasionImageFiles = Commons.RE_ORDER(
        occasionImages,
        result.source.index,
        result.destination.index
      )

      form.setFieldsValue({
        occasionImages: orderedOccasionImages,
      })

      setOccasionImages(orderedOccasionImageFiles)
    }
  }

  const ImageUploadSectionTitle = () => (
    <>
      <p>
        <span className="font-bold">イベント画像</span>
        <span className="custom-required-decoration">必須</span>
      </p>
      <p className="text-xs text-gray-400 mb-4 mt-2">
        ※項目をドラッグして表示順を自由に調整できます
      </p>
    </>
  )

  const AddImageButton = ({ addToFormList }) => (
    <Form.Item>
      <Button
        type="link"
        onClick={() => addToFormList()}
        block
        icon={<PlusOutlined className="mr-1" />}
      >
        イベント画像追加
      </Button>
    </Form.Item>
  )

  return (
    <div>
      {!isPreview && <ImageUploadSectionTitle />}
      <div>
        <Form.List name="occasionImages">
          {(fields, { add, remove }) => (
            <DragDropContext onDragEnd={occasionImagesOnDragEndHandle}>
              <Droppable
                droppableId="droppableOccasionImage"
                direction="vertical"
                isDropDisabled={isPreview}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="w-full"
                  >
                    {fields.map((field, index) => (
                      <Draggable
                        key={field.key + ""}
                        draggableId={field.key + ""}
                        index={index}
                        isDragDisabled={isPreview}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="border border-dashed border-gray-300 rounded bg-white p-4 mb-4"
                          >
                            <Form.Item
                              {...field}
                              key={[field.key, "image"]}
                              name={[field.name, "image"]}
                              label={`イベント画像（${index + 1}）`}
                              valuePropName="file"
                              className="mb-0 text-center"
                              rules={[
                                {
                                  required: !isPreview,
                                  message: `イベント画像をアプロードしてください`,
                                },
                              ]}
                            >
                              <span>
                                <CustomUpload
                                  accept=".jpg, .jpeg, .png"
                                  maxCount={1}
                                  showUploadList={false}
                                  disabled={isPreview}
                                  beforeUpload={() => {
                                    return false
                                  }}
                                  onChange={async (param) => {
                                    const _file = await Commons.resizeFile(
                                      param?.file,
                                      "file"
                                    )
                                    const _preview = await Commons.resizeFile(
                                      param?.file,
                                      "base64"
                                    )

                                    if (occasionImages[index]) {
                                      setOccasionImages(
                                        occasionImages.map((ci, i) =>
                                          i === index
                                            ? {
                                                file: _file,
                                                preview: _preview,
                                              }
                                            : ci
                                        )
                                      )
                                    } else {
                                      let duplicateArray = [...occasionImages]
                                      duplicateArray[index] = {
                                        file: _file,
                                        preview: _preview,
                                      }

                                      setOccasionImages(duplicateArray)
                                    }
                                  }}
                                >
                                  <div>
                                    {occasionImages[index] &&
                                    (occasionImages[index]?.preview ||
                                      occasionImages[index]?.picUrl) ? (
                                      <div className="flex justify-center">
                                        <Image
                                          preview={false}
                                          src={
                                            occasionImages[index]?.preview ||
                                            `${Commons.occasionImagesURL}${occasionImages[index]?.picUrl}`
                                          }
                                          alt="イベント画像"
                                          fallback="/no-image.png"
                                          style={{
                                            maxHeight: "250px",
                                          }}
                                          className="max-w-full cursor-pointer object-contain"
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="flex justify-center items-center bg-white border border-gray-300 rounded w-full cursor-pointer"
                                        style={{
                                          height: "250px",
                                          maxHeight: "250px",
                                        }}
                                      >
                                        <p className="text-center text-2xl font-bold">
                                          <CameraOutlined className="mr-2" />
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </CustomUpload>
                              </span>
                            </Form.Item>
                            {!isPreview && fields.length > 1 ? (
                              <Form.Item {...field} className="text-right">
                                <Button
                                  size="default"
                                  danger
                                  onClick={() => {
                                    remove(field.name)
                                    setOccasionImages(
                                      occasionImages.filter(
                                        (ci, i) => i !== index
                                      )
                                    )
                                  }}
                                  icon={<CloseOutlined />}
                                >
                                  削除する
                                </Button>
                              </Form.Item>
                            ) : null}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    {!isPreview && <AddImageButton addToFormList={add} />}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Form.List>
      </div>
    </div>
  )
}

export default MultiImageUpload
