import { Form, Radio } from "antd"
import Switch from "../Switch"

const ParentName = () => {
  const isParentNameShow = Form.useWatch("parentNameShow")
  
  return (
    <div className="flex flex-col">
      <div className="flex gap-7 items-center">
        <span>■保護者⽒名表⽰</span>
        <Form.Item name="parentNameShow">
          <Switch name="parentNameShow" />
        </Form.Item>
      </div>

      {isParentNameShow && (
        <Form.Item name="parentNameRq" className="pl-4">
          <Radio.Group>
            <Radio value={true}>必須</Radio>
            <Radio value={false}>任意</Radio>
          </Radio.Group>
        </Form.Item>
      )}
    </div>
  )
}

export default ParentName