import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { message } from "antd"
import liff from "@line/liff"
import * as Commons from "common/common"

const LiffLogin = (props) => {
  const { history } = props

  useEffect(() => {
    setTimeout(() => {
      liff
        .init({
          liffId: process.env.REACT_APP_LIFF_ID || "",
        })
        .then(() => {
          if (!liff.isLoggedIn()) {
            liff.login()
          }

          history.push(Commons.liffOccasionsRoute)
        })
        .catch((err) => {
          message.error(Commons.errorSystemMsg)
        })
    }, 500)
    // eslint-disable-next-line
  }, [])

  return <></>
}

export default withRouter(LiffLogin)
