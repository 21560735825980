import React from "react"
import { Button, Divider, Menu, Typography } from "antd"
import {
  IdcardOutlined,
  SolutionOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ExportOutlined,
} from "@ant-design/icons"
import { withRouter, useLocation } from "react-router-dom"
import styled from "styled-components"
import * as Commons from "common/common"

const StyledMenu = styled(Menu)`
  border: none;
`

const StyledMenuGroup = styled(Menu.ItemGroup)`
  .ant-menu-item-group-title {
    color: #bfbfbf;
  }
`

const Sidebar = (props) => {
  const { history, isHamburger, isCollapsed, collapseToggle } = props
  const location = useLocation()
  const { Text } = Typography

  const getCurrentPathWithoutLastPart = () => {
    return location.pathname.slice(0, location.pathname.lastIndexOf("/")) !== ""
      ? location.pathname.slice(0, location.pathname.lastIndexOf("/"))
      : location.pathname
  }

  const handleClick = (event) => {
    switch (event.key) {
      case Commons.homeRoute:
        history.push(Commons.homeRoute)
        break
      case Commons.audiencesRoute:
        history.push(Commons.audiencesRoute)
        break
      case "linePublicAccountLink":
        window.open(process.env.REACT_APP_LINE_MANAGER_URL, "_blank")
        break
      case "collapse":
        collapseToggle()
        break
      default:
        break
    }

    if (isHamburger) collapseToggle()
  }

  return (
    <div
      className="flex flex-col h-full"
      style={{ overflowX: "hidden", overflowY: "auto" }}
    >
      <div className="flex mt-4 mx-4">
        <img
          src="/logo-header.png"
          alt=""
          className="max-w-full"
          style={{ maxHeight: "110px" }}
        />
      </div>
      <div className="relative flex text-center px-1">
        <Text
          style={{
            fontSize: 16,
            marginTop: 20,
            textAlign: "center",
            whiteSpace: "pre-line",
          }}
          className="mx-auto"
        >
          {process.env.REACT_APP_SYSTEM_TITLE_1 || ""} {"\n"}{" "}
          {process.env.REACT_APP_SYSTEM_TITLE_2 || ""}
        </Text>
      </div>
      <Divider />
      <div className="flex-grow flex-shrink">
        <StyledMenu
          onClick={handleClick}
          selectedKeys={[getCurrentPathWithoutLastPart()]}
          mode="inline"
        >
          <StyledMenuGroup>
            <Menu.Item
              key={Commons.homeRoute}
              icon={
                <SolutionOutlined
                  style={{
                    marginRight: 5,
                    fontSize: 22,
                  }}
                />
              }
            >
              イベント管理
            </Menu.Item>
            <Menu.Item
              key={Commons.audiencesRoute}
              icon={
                <IdcardOutlined
                  style={{
                    marginRight: 5,
                    fontSize: 22,
                  }}
                />
              }
            >
              オーディエンス
            </Menu.Item>
            <Menu.Item
              key="linePublicAccountLink"
              icon={<ExportOutlined className="text-base" />}
            >
              <a
                href={process.env.REACT_APP_LINE_MANAGER_URL}
                target="_blank"
                rel="noreferrer noopener"
                className={`${isCollapsed ? "text-xs" : "text-base"}`}
              >
                {Commons.LABEL_ADMIN_LINE_LINK}
              </a>
            </Menu.Item>
          </StyledMenuGroup>
        </StyledMenu>
      </div>
      <div className="w-auto p-2 " style={{ width: "198px" }}>
        <div
          style={{ background: "#7CFC00", padding: "8px 0" }}
          className=" text-white bg-green text-center w-full text-lg"
        >
          LINE
        </div>
        <img
          src={process.env.REACT_APP_LINE_QR_URL}
          alt=""
          style={{ border: "1px solid #7CFC00", width: "100%" }}
        />
      </div>
      <Button className="p-0" onClick={collapseToggle}>
        {isCollapsed ? (
          <MenuUnfoldOutlined
            style={{
              fontSize: 22,
            }}
          />
        ) : (
          <MenuFoldOutlined
            style={{
              fontSize: 22,
            }}
          />
        )}
      </Button>
    </div>
  )
}

export default withRouter(Sidebar)
