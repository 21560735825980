import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from "antd"
import jaJP from "antd/es/locale/ja_JP"

import App from './App'

ReactDOM.render(
  // <React.StrictMode>
    <ConfigProvider locale={jaJP}>
      <App />
    </ConfigProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
)
