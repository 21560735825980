import React, { useEffect, useRef } from "react"
import axios from "axios"
import { Input } from "antd"
import Resizer from "react-image-file-resizer"

/* BASE URL */
export const siteURL = process.env.REACT_APP_API_URL

export const baseURL = siteURL + "/api"
export const mediaURL = siteURL + "/uploads/"
export const occasionImagesURL = siteURL + "/uploads/occasions/"
export const courseImageURL = siteURL + "/uploads/courses/"
export const chatMediaURL = siteURL + "/imageuploads/"

/* ROUTES */
export const loginRoute = "/login"
export const homeRoute = "/occasions"
export const audiencesRoute = "/audiences"
export const liffLoginRoute = "/liff/login"
export const liffOccasionsRoute = "/liff/occasions"
export const liffOccurrencesRoute = "/liff/occurrences"
export const liffParticipationRoute = "/liff/participation"
export const liffPaymentRoute = "/liff/payment"
export const liffInfoRoute = "/liff/info"
export const lineAccessRoute = "/line-access"
export const notFoundRoute = "/not-found"

/* REDIRECT URL */
export const rootURL = homeRoute
export const loginURL = loginRoute

/* BACKEND API URLS */
export const apiCheckSession = baseURL + "/sess"
export const apiLogin = baseURL + "/login"
export const apiLogout = baseURL + "/logout"
export const apiOccasions = baseURL + "/master/occasions"
export const apiOccasionsOrder = baseURL + "/master/new"
export const apiOccurrences = baseURL + "/master/occurrences"
export const apiRegistrations = baseURL + "/master/registrations"
export const apiRegistrationOpen = baseURL + "/registrations"
export const apiEvents = baseURL + "/master/events"
export const apiAudiences = baseURL + "/master/audiences"
export const apiAudiencesSimple = baseURL + "/master/audiences-simple"
export const apiChats = baseURL + "/master/chats"
export const apiLiff = baseURL + "/liff"
export const apiLiffOccasions = apiLiff + "/events/overview"
export const apiLiffOccasionDetail = apiLiff + "/events"
export const apiLiffOccasionValidate = apiLiff + "/occasions"
export const apiLiffRegister = apiLiff + "/register"
export const API_REGISTRATIONS_ATTACH_GUARDIAN =
  "master/registrations/$registrationId/attach-guardian"

export const BUSINESS_OPEN_TIME = "07:00:00"
export const BUSINESS_CLOSE_TIME = "21:00:00"
export const BUSINESS_INTERVAL_TIME_LABEL = "00:15:00"
export const BUSINESS_INTERVAL_TIME = "00:15:00"
export const BUSINESS_INTERVAL_TIME_VALUE = 15 //MUST BE MINUTE | 15 | 30 | 45 | 60
export const RE_ORDER = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
/* ALERT MESSAGES */
export const errorLoginMismatchMsg =
  "メールアドレスまたはパスワードが間違っています"
export const errorSystemMsg = "システムエラー"
export const errorSystemDetailMsg =
  "ご不便をおかけして申し訳ありません。 システムに障害が発生しました"
export const errorSessionMsg = "もう一度ログインしてください"
export const errorPermissionMsg = "許可が足りない"
export const error404Msg =
  "申し訳ありませんが、アクセスしたページは存在しません。"
export const errorLine403Msg = "LINEアプリからアクセスしてください"
export const errorUniqueMsg = "この電話番号はすでに登録されています "
export const errorQrWrongMsg = "QRコードが違います、再度ご確認ください"
export const errorQrAlreadyUsedMsg =
  "QRコードはすでに読み取られているため使えません"
export const errorQrNotExistMsg = "QRコードが存在しません"
export const warnRegistrationFullMsg =
  "ご不便をおかけして申し訳ありませんが、利用可能な空席が変更されたため、画面が自動的に更新されます"
export const warnRegistrationOverlapMsg =
  "別の予約時間と重なっているため、こちらの予約ができません"
export const warnRegistrationZeroMsg =
  "申し訳ございませんが、空席でのご参加はできません"
export const warnAlreadyParticipatedMsg =
  "ご不便をおかけして申し訳ありませんが、すでに参加されているため、画面は自動的にメイン画面に変わります"
export const warnNoAvailableSpaceMsg =
  "申し訳ございませんが、すべての時間帯はご予約のお客様でお席がうまっております"
export const warnNotEnoughAvailableSpaceMsg =
  "申し訳ございませんが、ご希望の人数では予約出来る時間帯が空いておりません"
export const warnNotEnoughMaxParticipantMsg =
  "参加可能最大人数は現在の登録した人数より少ないです"
export const warnNotZeroParticipantMsg =
  "参加可能最大人数は少なくとも0より大きくなければなりません"
export const successLoginMsg = "ログインしました。"
export const successLogoutMsg = "ログアウトしました。"
export const successMakeMsg = "作成しました。"
export const successCreateMsg = "登録しました。"
export const successCreateRegistrationMsg = "ユーザー情報登録しました。"
export const successCreateParticipationMsg = "予約しました。"
export const successPaymentMsg = "支払いが成功しました。"
export const successUpdateMsg = "変更しました。"
export const successSaveMsg = "保存しました。"
export const successDeleteMsg = "削除しました。"
export const successSentMsg = "送信しました。"
export const successCancelMsg = "キャンセルしました。"
export const successQrEventMsg = "QR読み取りしました。"
export const successSyncAudienceMsg = "オーディエンス同期しました。"
export const socketEventDeleteMsg = "別の管理者がこのイベントを削除しました"
export const socketOccurrenceUpdateMsg = "別の管理者がこの時間を変更しました"
export const socketLiffEventDeleteMsg =
  "ご不便をおかけして申し訳ありませんが、イベントを削除しました"
export const socketLiffEventUpdateMsg =
  "ご不便をおかけして申し訳ありませんが、イベントを変更しました"
export const errorAudienceNameMsg = "オーディエンス名が重複しています"

/* LABELS */
export const LABEL_ADMIN_LINE_LINK = "LINEマネージャー"

export const GET_PARTICIPATION_UNIT_TYPE_LABEL = (unitType) =>
  unitType === "area" ? "参加区画" : "参加者数"

export const GET_CANCELLED_UNIT_TYPE_LABEL = (unitType) =>
  unitType === "area" ? "キャンセル区画" : "キャンセル人数"

export const GET_UNIT_TYPE_LABEL = (unitType) =>
  unitType === "area" ? "区画" : "人"

export const GET_MAX_CAPACITY_LABEL = (unitType) =>
  unitType === "area" ? "参加可能最区画数" : "参加可能最大人数"

export const GET_MAX_CAPACITY_CHANGE_LABEL = (unitType) =>
  unitType === "area" ? "参加可能最区画数変更" : "参加可能最大人数変更"

export const GET_MAX_PARTICIPATION_LABEL = (unitType) =>
  unitType === "area" ? "既定参加可能最大区画数" : "既定参加可能最大人数"

export const GET_RESERVATION_UNIT_TYPE_LABEL = (unitType) =>
  unitType === "area" ? "予約区画" : "予約人数"

export const GET_RESERVATION_RATE_LABEL = (unitType) =>
  unitType === "area"
    ? "(予約区画数/参加可能最大区画数)*100"
    : "(予約人数/参加可能最大人数)*100"

export const GET_PARTICIPATION_RATE_LABEL = (unitType) =>
  unitType === "area"
    ? "(参加区画数/予約区画数)*100"
    : "(参加者数/予約人数)*100"

export const GET_REMAINING_UNIT_TYPE_LABEL = (unitType) =>
  unitType === "area" ? "利用可能\u000A\u000A残り" : "利用可能\u000A\u000A空席"

export const GET_FULL_UNIT_TYPE_LABEL = (unitType) =>
  unitType === "area"
    ? "満区画\u000A\u000A受付終了"
    : "満席\u000A\u000A受付終了"

export const GET_MAX_RESERVATIONS_UNIT_TYPE_LABEL = (unitType) =>
  "予約可能最大予約枠数"

export const GET_MAX_PEOPLE_PER_RESERVATION_UNIT_TYPE_LABEL = (unitType) =>
  unitType === "area" ? "1予約枠最大区画数" : "1予約枠最大人数"

export const GET_MAX_RESERVATIONS_PER_ACCOUNT_UNIT_TYPE_LABEL = (
  unitType,
  maxPeople = 0
) =>
  unitType === "area"
    ? `1アカウントで\n予約可能最大区画数：${maxPeople}区`
    : `1アカウントで\n予約可能最大人数：${maxPeople}人`

export const getCsvModeName = (key) => {
  switch (key) {
    case "all":
      return "全予約者"
    case "attended":
      return "参加者"
    case "unattended":
      return "不参加者"
    case "cancelled":
      return "キャンセルした予約者"
    default:
      return "ー"
  }
}

export const checkIsEventFull = (expected, attended) => {
  return expected > 0 && attended > 0 && expected === attended
}

/* AXIOS CONFIG */
export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: baseURL,
})

/* CHECK MOUNTED REACT */
export function useIsMountedRef() {
  const isMountedRef = useRef(null)

  useEffect(() => {
    isMountedRef.current = true
    return () => (isMountedRef.current = false)
  })

  return isMountedRef
}

export class NumericInput extends React.Component {
  onChange = (e) => {
    const { value } = e.target
    const reg = /^-?\d*(\.\d*)?$/

    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      this.props.onChange(value)
    }
  }

  render() {
    return (
      <Input
        {...this.props}
        onChange={this.onChange}
        onPressEnter={(e) => e.preventDefault()}
      />
    )
  }
}

export const resizeFile = (file, type) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri)
      },
      type
    )
  })

export const unitTypes = [
  { value: "personCapacity", label: "人数" },
  { value: "area", label: "区画" },
]

export const displayJpyPrice = (price) => {
  if (!price) price = 0
  return new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: "JPY",
    currencyDisplay: "name",
  }).format(price)
}

export const debounce = (func, timeout = 300) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}
