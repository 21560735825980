import { useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { message } from "antd"
import * as Commons from "common/common"

const DEFAULT_PARTICIPATION_CHILDREN = {
  fullName: "",
  age: null,
  month: null,
  note: "",
}

const UPDATE_DEFAULT_VALUE = {
  attendedGrownup: 0,
  attendedChild: 0,
  attendedPreschool: 0,
  isAttended: false,
}

const DEFAULT_VALUE = {
  expectedGrownup: 0,
  expectedChild: 0,
  expectedPreschool: 0,
  guardianName: "",
  expectedGuardian: 0,
  generalNote: "",
  attachRegistrations: [DEFAULT_PARTICIPATION_CHILDREN],
}

const useOccasionModal = (props) => {
  const {
    occasion,
    history,
    onCancel,
    fetchOccasionData,
    handleOccurrenceDetail,
    occurrenceId,
    action,
    dataUpdateOccurrences,
  } = props

  const schema = yup
    .object({
      guardianName:
        occasion.parentNameShow
          ? ""
          : occasion.parentNameRq
          ? yup.string().required("名前は必須です")
          : "",
      telephone:
        occasion.parentPhoneShow
          ? ""
          : occasion.parentPhoneRq
          ? yup
              .string()
              .matches(/[0-9]/, "電話番号は必須です。")
              .required("電話番号は必須です。")
          : "",
      expectedGuardian: yup.number(),

      generalNote: occasion.generalNoteRq
        ? yup.string().required(`${occasion.generalNoteLabel} は必須です。`)
        : "",
      attachRegistrations: yup.array().of(
        yup.object({
          fullName: yup.string().required("※ひらがなで入力してください"),
          age:
            occasion?.childAgeLimitShow &&
            occasion?.childAgeLimitRq
              ? yup.number().required("年齢は必要です。")
              : yup.number().nullable().optional(),
          note: occasion.childNoteRq
            ? yup.string().required(`${occasion.childNoteLabel} は必須です。`)
            : "",
        })
      ),
    })
    .required()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: Object.assign(
      {},
      DEFAULT_VALUE,
      action === "edit" || action === "confirm" ? UPDATE_DEFAULT_VALUE : {}
    ),
    mode: "onChange",
  })

  const [submitting, setSubmitting] = useState(false)
  const {
    fields: attachRegistrations,
    append: appendParticipationChild,
    remove: removeParticipationChild,
  } = useFieldArray({
    control,
    name: "attachRegistrations",
  })
  const handleAddParticipationChild = (event) => {
    if (attachRegistrations.length === 5) {
      event.preventDefault()
    } else {
      appendParticipationChild(DEFAULT_PARTICIPATION_CHILDREN)
    }
  }

  useEffect(() => {
    if ((action === "edit" || action === "confirm") && dataUpdateOccurrences) {
      setValue("guardianName", dataUpdateOccurrences.participationName)
      setValue("expectedGuardian", dataUpdateOccurrences?.expectedGuardian || 0)
      setValue("telephone", dataUpdateOccurrences.telephone)
      setValue(
        "attachRegistrations",
        dataUpdateOccurrences?.attachRegistrations
      )
      setValue("generalNote", dataUpdateOccurrences?.generalNote)
    }
  }, [action, dataUpdateOccurrences, setValue])

  const onAddOccasion = (values) => {
    setSubmitting(true)
    Commons.axiosInstance
      .post(Commons.apiRegistrations + "/new", {
        ...values,
        occurrenceId: occurrenceId,
      })
      .then((response) => {
        reset()

        onCancel()
        fetchOccasionData()
        handleOccurrenceDetail(occurrenceId)

        message.success(Commons.successCreateMsg)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        // if (isMountedRef.current) {
        //   setParticipationLoading(false)
        // }
        setSubmitting(false)
      })
  }

  const onUpdateOccasion = (values) => {
    setSubmitting(true)
    Commons.axiosInstance
      .put(Commons.apiRegistrations + "/edit", {
        ...values,
        registrationId: dataUpdateOccurrences.participationId,
      })
      .then((response) => {
        onCancel()
        fetchOccasionData()
        handleOccurrenceDetail(occurrenceId)

        message.success(Commons.successUpdateMsg)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        // if (isMountedRef.current) {
        //   setParticipationLoading(false)
        // }
        setSubmitting(false)
      })
  }

  const onSubmit = (values) => {
    if (action === "new") {
      onAddOccasion(values)
    } else {
      if (action === "edit") {
        onUpdateOccasion({ ...values, isAttended: false })
      } else if (action === "confirm") {
        onUpdateOccasion({ ...values, isAttended: true })
      }
    }
  }

  const onChangeTelephone = (values) => {
    const regex = /^[0-9]$/

    if (regex.test(values) || values === "") {
      setValue("telephone", values)
    } else {
      setValue("telephone", `${values}`.replace(/[^0-9]/g, ""))
    }
  }

  return {
    ...props,
    control,
    submitting,
    attachRegistrations,
    onSubmit,
    handleSubmit,
    onChangeTelephone,
    handleAddParticipationChild,
    removeParticipationChild,
    errors,
  }
}

export default useOccasionModal
