import { Form, Radio } from "antd"
import Switch from "../Switch"

export default function FullName() {
  const isFullNameShow = Form.useWatch("fullNameShow")

  return (
    <div className="flex flex-col ml-3">
      <div className="flex gap-7 items-center">
        <span>・おなまえ（ひらがな⼊⼒）</span>
        <Form.Item name="fullNameShow">
          <Switch name="fullNameShow" />
        </Form.Item>
      </div>

      {isFullNameShow && (
        <Form.Item name="fullNameRq" className="pl-4">
          <Radio.Group>
            <Radio value={true}>必須</Radio>
            <Radio value={false}>任意</Radio>
          </Radio.Group>
        </Form.Item>
      )}
    </div>
  )
}
