import { Col, DatePicker, Form, Row, Space, Switch } from "antd"
import jaJP from "antd/lib/date-picker/locale/ja_JP"
import Moment from "moment"
import { extendMoment } from "moment-range"
import "moment/locale/ja"
import { useState } from "react"
import styled from "styled-components"
import Group from "views/Home/Group"

const moment = extendMoment(Moment)
moment.locale("ja")

const CardTime = styled.div`
  width: fit-content;

  .titile {
    box-sizing: border-box;
    font-weight: 500;
    background-color: white;
    width: fit-content;
    margin-bottom: 10px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`

const FormItem = styled(Form.Item)`
  margin-bottom: ${(props) => (props.$isOpenRegistration ? "0" : "24px")};
`

export const OccasionUpdateEventApplicationPeriodFormItem = ({
  setOpenRegistration,
  occasionUpdateForm,
  openRegistration,
}) => {
  const [minTimeRegistration, setMinTimeRegistration] = useState(false)

  const OpenRegistrationDateSelection = () => (
    <Row style={{ marginBottom: 20 }}>
      <Col span={24}>
        <Space direction="vertical" size={15}>
          <CardTime>
            <div className="titile">開始時間の選択</div>

            <Space>
              <Form.Item
                name="startRegister"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  locale={jaJP}
                  placeholder=""
                  format="YYYY-MM-DD HH:mm"
                  showTime={{
                    defaultValue: moment("00:00:00", "HH:mm"),
                  }}
                  onChange={(value) => {
                    setMinTimeRegistration(value)
                    occasionUpdateForm.resetFields(["endRegister"])
                  }}
                />
              </Form.Item>
              {/* <TimePicker placeholder="" format={"HH:mm"} /> */}
            </Space>
          </CardTime>

          <CardTime>
            <div className="titile">終了時間の選択</div>
            <Space>
              <Form.Item
                name="endRegister"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  locale={jaJP}
                  disabledDate={(current) => {
                    return (
                      minTimeRegistration &&
                      current &&
                      moment(current).format("YYYY-MM-DD") <
                        moment(minTimeRegistration).format("YYYY-MM-DD")
                    )
                  }}
                  disabledTime={(current) => {
                    if (
                      moment(current).format("YYYY-MM-DD") ===
                      moment(minTimeRegistration).format("YYYY-MM-DD")
                    ) {
                      return {
                        disabledHours: () =>
                          minTimeRegistration &&
                          Array.from({ length: 60 }, (_, i) => i).filter(
                            (i) =>
                              i <
                              Number(moment(minTimeRegistration).format("HH"))
                          ),
                        disabledMinutes: () =>
                          Array.from({ length: 60 }, (_, i) => i).filter(
                            (i) =>
                              i <
                              Number(moment(minTimeRegistration).format("mm"))
                          ),
                      }
                    }
                  }}
                  placeholder=""
                  format="YYYY-MM-DD HH:mm"
                  showTime={{
                    defaultValue: moment("00:00:00", "HH:mm"),
                  }}
                />
              </Form.Item>
              {/* <TimePicker placeholder="" format={"HH:mm"} /> */}
            </Space>
          </CardTime>
        </Space>
      </Col>
    </Row>
  )
  return (
    <Group title="イベント応募期間設定">
      <Row>
        <Col span={24}>
          <FormItem
            $isOpenRegistration={openRegistration}
            name="isOpenRegistration"
            // label="イベント応募期間の設定が可能"
            className="whitespace-pre-line"
            valuePropName="checked"
          >
            <Switch
              name="isOpenRegistration"
              onClick={(e) => {
                setOpenRegistration(e)
                if (e === true)
                  occasionUpdateForm.setFieldsValue({
                    startRegister: 0,
                    endRegister: 0,
                  })
              }}
            />
          </FormItem>
        </Col>
      </Row>
      {openRegistration && <OpenRegistrationDateSelection />}
    </Group>
  )
}
