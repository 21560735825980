import { Col, Form, Input, Row } from "antd"

export const OccasionUpdateNameFormItem = () => (
  <Row>
    <Col span={24}>
      <Form.Item
        name="occasionUpdateName"
        label="イベント名"
        rules={[
          {
            required: true,
            message: "イベント名は必須です",
          },
        ]}
      >
        <Input placeholder="イベント名を入力してください" allowClear />
      </Form.Item>
    </Col>
  </Row>
)
