import React, { useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
} from "antd"
import {
  SyncOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons"
import styled from "styled-components"
import moment from "moment"
import "moment/locale/ja"
import io from "socket.io-client"
import * as Commons from "common/common"
import AudiencesModal from "./AudiencesModal"

moment.locale("ja")

const StyledTable = styled(Table)`
  td {
    word-break: break-all;
  }
`
const StyledTableOff = styled(Table)`
  overflow-x: scroll;
  table {
    min-width: 960px;
  }
  td {
    word-break: break-all;
  }

  th:nth-child(n) {
    text-align: center;
  }
`

const StyleTabs = styled(Tabs)`
  padding-left: 12px;
`

const StyleTag = styled(Tag)`
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
`
const StyleSpace = styled(Space)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 12px;
  background-color: #fffbeb;
  flex-wrap: wrap;
`

const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`

const participantName = {
  kanji: "漢字で書いた氏名",
  hiragana: "ひらがなで書いた氏名",
  katakana: "カタカナで書いた氏名",
}

const reservationStatus = {
  all: "全予約者",
  participant: "参加者",
  nonParticipant: "不参加者",
  cancel: "キャセル者",
  nonParticipants: "未参加者",
}

const colorParticipantName = {
  kanji: "#06D6A0",
  hiragana: "#38A3A5",
  katakana: "#F77F00",
}
const colorReservation = {
  all: "#38A3A5",
  participant: "#06D6A0",
  nonParticipant: "#EF476F",
  cancel: "#F77F00",
  nonParticipants: "#FCBF49",
}

const StyleButtonDelete = styled(Button)`
  padding: 4px 8px;
`

const StyleSpan = styled.span`
  color: red;
`

const Audiences = (props) => {
  const { showLoadingPageSpin, hideLoadingPageSpin, history } = props
  const isMountedRef = Commons.useIsMountedRef()

  const [audiencesData, setAudiencesData] = useState([])
  const [syncSpin, setSyncSpin] = useState({
    sync: false,
    occasionId: undefined,
  })

  const [openCreateModal, setOpenCreteModal] = useState(false)
  const [openComfimNameModal, setOpenComfimNameModal] = useState(false)
  const [occasionId, setOccasionId] = useState(null)
  const [countUsers, setCountUsers] = useState(0)
  const [loadingSearchResults, setLoadingSearchResults] = useState(false)

  const columnsTab1 = [
    {
      title: "ID",
      dataIndex: "audienceGroupId",
    },
    {
      title: "オーディエンス名",
      dataIndex: "description",
    },
    {
      title: "サイズ ",
      dataIndex: "audienceCount",
    },
  ]

  const columnsTab2 = [
    {
      title: "ID",
      dataIndex: "audienceGroupId",
    },
    {
      title: "オーディエンス名",
      dataIndex: "description",
    },
    {
      title: "検索条件",
      dataIndex: "filter",
    },
    {
      title: "サイズ",
      dataIndex: "audienceCount",
    },
    {
      title: "",
      dataIndex: "deleteAudience",
      align: "center",
    },
  ]

  const startSyncingSpin = (occasionId) => {
    setSyncSpin({ sync: true, occasionId: occasionId })
  }

  const stopSyncingSpin = () => {
    setSyncSpin({ sync: false, occasionId: undefined })
  }

  const getAudienceData = useCallback(() => {
    if (isMountedRef.current) {
      showLoadingPageSpin()
    }

    Commons.axiosInstance
      .get(Commons.apiAudiences)
      .then((response) => {
        if (isMountedRef.current && response) {
          setAudiencesData(response.data || [])
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }, [isMountedRef, history, showLoadingPageSpin, hideLoadingPageSpin])

  const handleSync = (occasionId) => {
    startSyncingSpin(occasionId)

    const postData = {
      occasionId: occasionId,
    }

    Commons.axiosInstance
      .post(Commons.apiAudiences, postData)
      .then((response) => {
        message.success(Commons.successSyncAudienceMsg)
        getAudienceData()
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          stopSyncingSpin()
        }
      })
  }

  const createModalVisible = (id) => {
    setOpenCreteModal(true)
    setOccasionId(id)
  }

  const hideCreateModal = () => {
    setOpenCreteModal(false)
    setOccasionId(null)
  }

  const handleOpenComfimNameModal = () => {
    setOpenComfimNameModal(true)
  }
  const handleCloseComfimNameModal = () => {
    setOpenComfimNameModal(false)
  }

  const handleSubmitModal = (occasion, data, reset) => {
    startSyncingSpin(occasion.occasionId)
    if (occasion?.type !== "create") {
      setLoadingSearchResults(true)
    }

    const cleanedData = Object.fromEntries(
      Object.entries(data).filter(
        ([_, value]) => value !== undefined && value !== "" && value !== null
      )
    )

    const postData = {
      occasionId: occasion.occasionId,
      ...cleanedData,
      type: occasion.type,
    }

    Commons.axiosInstance
      .post(Commons.apiAudiencesSimple, postData)
      .then((response) => {
        if (occasion?.type === "create") {
          message.success(Commons.successSyncAudienceMsg)
          hideCreateModal()
          handleCloseComfimNameModal()
          getAudienceData()
          setCountUsers(0)
          reset()
        } else {
          setCountUsers(response?.data?.count || 0)
          setLoadingSearchResults(false)
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          message.error(Commons.errorAudienceNameMsg)
        }
        if (error.response.status === 401) {
          message.error("There are no matching results")
        }
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          stopSyncingSpin()
          setLoadingSearchResults(false)
        }
      })
  }

  useEffect(() => {
    getAudienceData()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const socket = io(Commons.siteURL, { path: "/socket.io" })

    socket.on("updateAudience", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        getAudienceData()
      }
    })

    return () => {
      socket.off("updateAudience")

      socket.disconnect()
    }

    // eslint-disable-next-line
  }, [])

  const audienceDelete = (id) => {
    showLoadingPageSpin()

    Commons.axiosInstance
      .delete(Commons.apiAudiences + "/" + id)
      .then((response) => {
        if (isMountedRef.current && response) {
          message.success(Commons.successDeleteMsg)
          getAudienceData()
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const handleAudienceDelete = (id, name) => {
    Modal.confirm({
      title: "確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: (
        <div>
          <StyleSpan>{name}</StyleSpan> を削除してもよろしいでしょうか？
        </div>
      ),
      okText: "削除",
      // okType: "danger",
      okButtonProps: {
        danger: true,
      },
      cancelText: "閉じる",
      centered: true,
      onOk() {
        audienceDelete(id)
      },
    })
  }

  return (
    <StyleTabs defaultValue={1}>
      <Tabs.TabPane tab="オーディエンス情報" key={1}>
        <Card bordered={false}>
          <Row justify="center" className="mb-4">
            <Col>
              <Alert
                message={`※オーディエンスのデータがない場合は、同期ボタンを押してください。自動的にオーディエンスが作成されます。\nまた参加した参加者のオーディエンスデータは、イベント後に作成できるようになります。`}
                type="warning"
                className="whitespace-pre-wrap text-center"
              />
            </Col>
          </Row>
          {audiencesData ? (
            <>
              <Row>
                {audiencesData
                  .filter((item) => item.isSimpleCount)
                  .map((audience) => (
                    <Col span={24} key={audience.occasionId}>
                      <Row className="mb-1" align="middle">
                        <Col span={12}>
                          <Row justify="start">
                            <Col>
                              <span className="font-bold text-base text-primary">
                                {audience.title}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row justify="end">
                            <Col>
                              <Row gutter={[8, 8]}>
                                {audience.audiences
                                  ? audience.audiences.map((a) =>
                                      a.targetType === "all" ? (
                                        <Col>
                                          <Alert
                                            key={a.audienceGroupId}
                                            message={moment(a.updatedAt).format(
                                              "[最終同期：]YYYY/M/D HH:mm"
                                            )}
                                            type="info"
                                          />
                                        </Col>
                                      ) : (
                                        ""
                                      )
                                    )
                                  : ""}
                                <Col>
                                  <Button
                                    size="large"
                                    icon={
                                      <SyncOutlined
                                        spin={
                                          syncSpin.occasionId ===
                                            audience.occasionId && syncSpin.sync
                                        }
                                      />
                                    }
                                    onClick={() =>
                                      handleSync(audience.occasionId)
                                    }
                                  >
                                    同期
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <StyledTable
                            columns={columnsTab1}
                            dataSource={
                              audience.audiences
                                ? audience.audiences.map((a) => {
                                    return {
                                      key: a.audienceGroupId,
                                      audienceGroupId: a.audienceGroupId,
                                      description: a.description,
                                      audienceCount: a.audienceCount,
                                    }
                                  })
                                : []
                            }
                            bordered={true}
                            pagination={false}
                          />
                        </Col>
                      </Row>
                      <Divider />
                    </Col>
                  ))}
              </Row>
            </>
          ) : (
            <Empty description="イベントは登録されていません " />
          )}
        </Card>
      </Tabs.TabPane>
      <Tabs.TabPane tab="オーディエンス情報" key={2}>
        <Card bordered={false}>
          <Row justify="center" className="mb-4">
            <Col>
              <Alert
                message={
                  <div>
                    <div>
                      ※オーディエンスのデータがない場合は、新規作成ボタンを押して作成してください。
                    </div>
                    また参加した参加者のオーディエンスデータは、イベント後に作成できるようになります。
                  </div>
                }
                type="warning"
                className="whitespace-pre-wrap text-center"
              />
            </Col>
          </Row>
          {audiencesData ? (
            <>
              <Row>
                {audiencesData
                  .filter((item) => !item.isSimpleCount)
                  .map((audience) => (
                    <Col span={24} key={audience.occasionId}>
                      <Row className="mb-1" align="middle">
                        <Col span={12}>
                          <Row justify="start">
                            <Col>
                              <span className="font-bold text-base text-primary">
                                {audience.title}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row justify="end">
                            <Col>
                              <Row gutter={[8, 8]}>
                                <Col>
                                  <Button
                                    size="large"
                                    onClick={() =>
                                      createModalVisible(audience.occasionId)
                                    }
                                  >
                                    新規作成
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <StyledTableOff
                            rowKey={"id"}
                            columns={columnsTab2}
                            // scroll={{ x: 960 }}
                            dataSource={
                              audience.audiences
                                ? audience.audiences.map((a) => {
                                    return {
                                      key: a.audienceGroupId,
                                      audienceGroupId: a.audienceGroupId,
                                      description: a.description,
                                      filter: (
                                        <StyleWrapper
                                          direction="vertical"
                                          align="center"
                                        >
                                          {a.minAge || a.maxAge ? (
                                            <StyleSpace>
                                              子どもの年齢:
                                              <StyleTag color="#06D6A0">
                                                {a.minAge || 0}歳 ~{" "}
                                                {a.maxAge || 18}歳
                                              </StyleTag>
                                            </StyleSpace>
                                          ) : (
                                            ""
                                          )}
                                          {a.minPeople || a.maxPeople ? (
                                            <StyleSpace>
                                              子ども人数:
                                              <StyleTag color="#52B788">
                                                {a.minPeople || 0}人 ~{" "}
                                                {a.maxPeople || 18}人
                                              </StyleTag>
                                            </StyleSpace>
                                          ) : (
                                            ""
                                          )}
                                          {a?.participantName ? (
                                            <StyleSpace>
                                              参加者氏名:
                                              {a?.participantName
                                                ?.split(",")
                                                .map((item) => (
                                                  <StyleTag
                                                    color={
                                                      colorParticipantName[item]
                                                    }
                                                  >
                                                    {participantName[item]}
                                                  </StyleTag>
                                                ))}
                                            </StyleSpace>
                                          ) : (
                                            ""
                                          )}
                                          {a?.reservationStatus ? (
                                            <StyleSpace>
                                              予約状態:{" "}
                                              {a.reservationStatus
                                                ?.split(",")
                                                .map((item) => (
                                                  <StyleTag
                                                    color={
                                                      colorReservation[item]
                                                    }
                                                  >
                                                    {reservationStatus[item]}
                                                  </StyleTag>
                                                ))}
                                            </StyleSpace>
                                          ) : (
                                            ""
                                          )}
                                        </StyleWrapper>
                                      ),
                                      audienceCount: a.audienceCount,
                                      deleteAudience: (
                                        <StyleButtonDelete
                                          danger
                                          onClick={() =>
                                            handleAudienceDelete(
                                              a.audienceGroupId,
                                              a.description
                                            )
                                          }
                                        >
                                          <DeleteOutlined />
                                        </StyleButtonDelete>
                                      ),
                                    }
                                  })
                                : []
                            }
                            bordered={true}
                            pagination={false}
                          />
                        </Col>
                      </Row>
                      <Divider />
                    </Col>
                  ))}
              </Row>
            </>
          ) : (
            <Empty description="イベントは登録されていません " />
          )}
          <AudiencesModal
            occasionId={occasionId}
            visible={openCreateModal}
            onCancel={hideCreateModal}
            handleSubmitModal={handleSubmitModal}
            countUsers={countUsers}
            handleOpenComfimNameModal={handleOpenComfimNameModal}
            handleCloseComfimNameModal={handleCloseComfimNameModal}
            openComfimNameModal={openComfimNameModal}
            syncSpin={syncSpin}
            loadingSearchResults={loadingSearchResults}
            setCountUsers={setCountUsers}
          />
        </Card>
      </Tabs.TabPane>
    </StyleTabs>
  )
}

export default withRouter(Audiences)
