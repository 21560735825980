import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Alert,
  Button,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  message,
} from "antd"
import * as Commons from "common/common"
import moment from "moment"
import "moment/locale/ja"
import { useEffect } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import * as yup from "yup"
import { displayError } from "common/error"

/**
 * @typedef {Object} Registration
 * @property {boolean} childAgeLimitShow
 * @property {boolean} fullNameShow
 * @property {boolean} childNoteShow
 * @property {boolean} childAgeShow
 * @property {boolean} generalNoteShow
 * @property {boolean} parentNameShow
 * @property {boolean} parentPhoneShow
 * @property {boolean} fullNameRq
 * @property {boolean} childAgeRq
 * @property {boolean} parentNameRq
 * @property {boolean} parentPhoneRq
 * @property {boolean} childNoteRq
 * @property {boolean} generalNoteRq
 * @property {boolean} childAgeLimitRq
 * @property {boolean} isSimpleCount
 * @property {string} fullName
 * @property {string} telephone
 * @property {string} displayName
 * @property {string} childNoteLabel
 * @property {string} generalNoteLabel
 * @property {boolean} isManual
 * @property {number} minAge
 * @property {number} maxAge
 *
 * @typedef {Object} Props
 * @property {Registration} registration
 */

const DEFAULT_PARTICIPATION_CHILDREN = {
  fullName: "",
  age: null,
  month: null,
  note: "",
}

const DEFAULT_VALUE = {
  attendedGrownup: 0,
  attendedChild: 0,
  attendedPreschool: 0,
  attendedGuardian: 0,

  attachRegistrations: [DEFAULT_PARTICIPATION_CHILDREN],
}

/**
 *
 * @param {Props} props
 * @returns
 */
export default function QRCheckIn({
  registration,
  showLoadingPageSpin,
  hideLoadingPageSpin,
  confirmModalVisible,
  hideConfirmModal,
  showQRModal,
}) {
  const isMountedRef = Commons.useIsMountedRef()
  const [form] = Form.useForm()

  const schema = yup.object().shape({
    attendedGuardian: yup.number(),
    attachRegistrations: yup.array().of(
      yup.object({
        fullName:
          registration.fullNameShow && registration.fullNameRq
            ? yup.string().required("※ひらがなで入力してください")
            : yup.string().optional().nullable(),
        age:
          registration?.childAgeShow && registration?.childAgeRq
            ? yup.number().required("年齢は必要です。")
            : yup.number().nullable().optional(),
        note:
          registration.childNoteShow && registration.childNoteRq
            ? yup.string().required("メッセージは必須です。")
            : yup.string().optional().nullable(),
      })
    ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: Object.assign({}, DEFAULT_VALUE),
    mode: "onChange",
  })

  const {
    fields: attachRegistrations,
    append: appendParticipationChild,
    remove: removeParticipationChild,
  } = useFieldArray({
    control,
    name: "attachRegistrations",
  })

  const handleAddParticipationChild = (event) => {
    if (attachRegistrations.length === 5) {
      event.preventDefault()
    } else {
      appendParticipationChild(DEFAULT_PARTICIPATION_CHILDREN)
    }
  }
  const genExtra = (id) => (
    <DeleteOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation()
        removeParticipationChild(id)
      }}
    />
  )

  const addAdultCount = () => {
    form.setFieldsValue({
      confirmAdultCount: form.getFieldValue("confirmAdultCount") + 1,
    })
  }

  const subtractAdultCount = () => {
    if (form.getFieldValue("confirmAdultCount") > 1) {
      form.setFieldsValue({
        confirmAdultCount: form.getFieldValue("confirmAdultCount") - 1,
      })
    }
  }

  const addChildCount = () => {
    form.setFieldsValue({
      confirmChildCount: form.getFieldValue("confirmChildCount") + 1,
    })
  }

  const subtractChildCount = () => {
    if (form.getFieldValue("confirmChildCount") > 0) {
      form.setFieldsValue({
        confirmChildCount: form.getFieldValue("confirmChildCount") - 1,
      })
    }
  }

  const addPreschoolerCount = () => {
    form.setFieldsValue({
      confirmPreschoolerCount:
        form.getFieldValue("confirmPreschoolerCount") + 1,
    })
  }

  const subtractPreschoolerCount = () => {
    if (form.getFieldValue("confirmPreschoolerCount") > 0) {
      form.setFieldsValue({
        confirmPreschoolerCount:
          form.getFieldValue("confirmPreschoolerCount") - 1,
      })
    }
  }

  const confirmRegistration = Commons.debounce((data) => {
    if (isMountedRef.current) {
      showLoadingPageSpin()

      const paramData = {
        registrationId: registration.registrationId,
        customerId: registration.customerId,
        attendedGrownup: data.confirmAdultCount,
        attendedChild: data.confirmChildCount,
        attendedPreschool: data.confirmPreschoolerCount,
        attendedGuardian: 0,
      }

      Commons.axiosInstance
        .put(Commons.apiRegistrationOpen, paramData)
        .then((response) => {
          if (response.status === 200) {
            message.success(Commons.successQrEventMsg)
            hideConfirmModal()
            showQRModal()
          }
        })
        .catch((error) => {
          displayError({ error: error.errorCode })
          showQRModal()
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    }
  }, 300)

  const onSubmit = (data) => {
    if (isMountedRef.current) {
      showLoadingPageSpin()

      const paramData = {
        registrationId: registration.registrationId,
        customerId: registration.customerId,
        ...data,
      }

      Commons.axiosInstance
        .put(Commons.apiRegistrationOpen, paramData)
        .then((response) => {
          if (response.status === 200) {
            message.success(Commons.successQrEventMsg)
            hideConfirmModal()
            showQRModal()
          }
        })
        .catch((error) => {
          displayError({ error: error.errorCode })
          showQRModal()
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    }
  }

  useEffect(() => {
    if (!registration) {
      return
    }

    form.setFieldsValue({
      confirmAdultCount: registration.expectedGrownup || 0,
      confirmChildCount: registration.expectedChild || 0,
      confirmPreschoolerCount: registration.expectedPreschool || 0,
      attendedGuardian: registration.expectedGuardian || 0,
    })

    reset({
      attendedGrownup: 0,
      attendedChild: 0,
      attendedPreschool: 0,
      attendedGuardian: 0,
      attachRegistrations: registration.expectedAttachRegistrations || [],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registration])

  return (
    <Modal
      visible={confirmModalVisible}
      title="参加者確認"
      onCancel={hideConfirmModal}
      footer={null}
      centered
    >
      <Row gutter={[0, 8]}>
        <Col span={24} className="text-center">
          <Image
            preview={false}
            src={
              registration.Occurrence?.Occasion?.occasionImages &&
              registration.Occurrence?.Occasion?.occasionImages?.length > 0 &&
              registration.Occurrence?.Occasion.occasionImages[0]?.picUrl
                ? `${Commons.occasionImagesURL}${registration.Occurrence?.Occasion.occasionImages[0]?.picUrl}`
                : "/no-image.png"
            }
            fallback="/no-image.png"
            alt={registration.Occurrence?.Occasion.title}
            style={{
              border: "1px solid #21acd7",
              maxHeight: "150px",
            }}
            className="rounded max-w-full"
          />
        </Col>
        <Col span={24}>
          <Descriptions column={1} bordered labelStyle={{ width: "140px" }}>
            <Descriptions.Item label="イベント名">
              <span className="font-bold text-primary">
                {registration.title || "-"}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="イベント日付">
              <span className="font-bold text-primary">
                {registration.startAt
                  ? moment(registration.startAt).format("YYYY年M月D日 (ddd)")
                  : "-"}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="イベント時間">
              <span className="font-bold text-primary">
                {registration.startAt
                  ? moment(registration.startAt).format("HH:mm")
                  : "-"}
              </span>
            </Descriptions.Item>
            {registration.isSimpleCount ||
            (!registration.isSimpleCount && registration.parentNameShow) ? (
              <Descriptions.Item
                label={registration.isSimpleCount ? "名前" : "保護者氏名"}
              >
                <span className="font-bold text-primary">
                  {process.env.REACT_APP_DEFAULT_NEED_REGISTRATION === "true"
                    ? registration.fullName || "-"
                    : registration.isManual
                    ? registration.fullName
                    : registration.displayName}
                </span>
              </Descriptions.Item>
            ) : null}
            {!registration.isSimpleCount && registration.parentPhoneShow ? (
              <Descriptions.Item
                label={
                  <p>
                    ご連絡先 <br />
                    電話番号
                  </p>
                }
              >
                <span className="font-bold text-primary">
                  {registration.telephone}
                </span>
              </Descriptions.Item>
            ) : (
              ""
            )}
            {/* <Descriptions.Item
                            label={`${
                              registration.isSimpleCount
                                ? "参加者の数"
                                : "大人の数"
                            }`}
                          >
                            <span className="font-bold text-primary">
                              {(registration.expectedGrownup || 0) + "人"}
                            </span>
                          </Descriptions.Item>
                          {registration.isSimpleCount ? (
                            ""
                          ) : (
                            <Descriptions.Item label="子供の数">
                              <span className="font-bold text-primary">
                                {(registration.expectedChild || 0) + "人"}
                              </span>
                            </Descriptions.Item>
                          )}
                          <Descriptions.Item
                            label={`${
                              registration.isSimpleCount
                                ? "同伴者の数"
                                : "未就学児の数"
                            }`}
                          >
                            <span className="font-bold text-primary">
                              {(registration.expectedPreschool || 0) + "人"}
                            </span>
                          </Descriptions.Item> */}
          </Descriptions>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            name="confirmForm"
            scrollToFirstError
            colon={false}
            preserve={false}
            requiredMark={false}
            initialValues={{
              confirmAdultCount: 0,
              confirmChildCount: 0,
              confirmPreschoolerCount: 0,
            }}
            size="large"
            layout="vertical"
            onFinish={confirmRegistration}
          >
            <Row justify="center">
              {registration.isSimpleCount ? (
                <>
                  <Col span={24} className="text-center">
                    <Form.Item
                      label={`${
                        registration.isSimpleCount ? "参加者" : "大人"
                      }`}
                      className="block"
                    >
                      <Input.Group compact>
                        <Button
                          icon={<MinusOutlined />}
                          style={{ marginRight: 0 }}
                          onClick={subtractAdultCount}
                        />
                        <Form.Item
                          name="confirmAdultCount"
                          rules={[
                            {
                              required: true,
                              message: `${
                                registration.isSimpleCount ? "参加者" : "大人"
                              }の数は必須です`,
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            style={{
                              width: "80px",
                              borderLeftWidth: 0,
                              borderRightWidth: 0,
                              textAlign: "center",
                            }}
                            readOnly
                            tabIndex="-1"
                          />
                        </Form.Item>
                        <Button
                          icon={<PlusOutlined />}
                          onClick={addAdultCount}
                        />
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24} className="text-center">
                    <Form.Item
                      label="子供"
                      className={registration.isSimpleCount ? "" : "block"}
                      hidden={registration.isSimpleCount}
                    >
                      <Input.Group compact>
                        <Button
                          icon={<MinusOutlined />}
                          style={{ marginRight: 0 }}
                          onClick={subtractChildCount}
                        />
                        <Form.Item
                          name="confirmChildCount"
                          rules={[
                            {
                              required: true,
                              message: "子供の数は必須です",
                            },
                          ]}
                          hidden={registration.isSimpleCount}
                          noStyle
                        >
                          <Input
                            style={{
                              width: "80px",
                              borderLeftWidth: 0,
                              borderRightWidth: 0,
                              textAlign: "center",
                            }}
                            readOnly
                            tabIndex="-1"
                          />
                        </Form.Item>
                        <Button
                          icon={<PlusOutlined />}
                          onClick={addChildCount}
                        />
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </>
              ) : (
                ""
              )}
              {registration.isPreschoolCount ? "" : <Divider />}

              {registration.isSimpleCount ? (
                <Col span={24} className="text-center">
                  <Form.Item
                    label={`${
                      registration.isSimpleCount ? "同伴者" : "未就学児"
                    }`}
                    className="block"
                  >
                    <Input.Group compact>
                      <Button
                        icon={<MinusOutlined />}
                        style={{ marginRight: 0 }}
                        onClick={subtractPreschoolerCount}
                      />
                      <Form.Item
                        name="confirmPreschoolerCount"
                        rules={[
                          {
                            required: true,
                            message: `${
                              registration.isSimpleCount ? "同伴者" : "未就学児"
                            }の数は必須です`,
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          style={{
                            width: "80px",
                            borderLeftWidth: 0,
                            borderRightWidth: 0,
                            textAlign: "center",
                          }}
                          readOnly
                          tabIndex="-1"
                        />
                      </Form.Item>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={addPreschoolerCount}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
              ) : (
                <Col span={24}>
                  {/* <Form.Item label="同伴者" className="block">
                                  <Input.Group compact>
                                    <Button
                                      icon={<MinusOutlined />}
                                      style={{ marginRight: 0 }}
                                      onClick={subtractExpectedGuardian}
                                    />
                                    <Form.Item
                                      name="expectedGuardian"
                                      rules={[
                                        {
                                          required: true,
                                          message: "同伴者 の数は必須です",
                                        },
                                      ]}
                                      noStyle
                                    >
                                      <Input
                                        style={{
                                          width: "80px",
                                          borderLeftWidth: 0,
                                          borderRightWidth: 0,
                                          textAlign: "center",
                                        }}
                                        readOnly
                                        tabIndex="-1"
                                      />
                                    </Form.Item>
                                    <Button
                                      icon={<PlusOutlined />}
                                      onClick={addExpectedGuardian}
                                    />
                                  </Input.Group>
                                </Form.Item> */}
                  {!registration.isSimpleCount ? null : (
                    <Controller
                      name="attendedGuardian"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value },
                        fieldState: { error },
                      }) => (
                        <Form.Item
                          label="同伴者"
                          help={error ? error.message : undefined}
                          validateStatus={error ? "error" : undefined}
                        >
                          <Input.Group compact>
                            <Button
                              disabled={value === 0}
                              icon={<MinusOutlined />}
                              onClick={(e) => {
                                if (value === 0) {
                                  e.preventDefault()
                                } else {
                                  onChange(value - 1)
                                }
                              }}
                            />
                            <Input
                              style={{
                                width: "80px",
                                textAlign: "center",
                              }}
                              readOnly
                              tabIndex="-1"
                              onBlur={onBlur}
                              value={value}
                            />
                            <Button
                              icon={<PlusOutlined />}
                              onClick={(e) => {
                                onChange(value + 1)
                              }}
                            />
                          </Input.Group>
                        </Form.Item>
                      )}
                    />
                  )}
                  {!registration?.fullNameShow &&
                  !registration?.childAgeShow &&
                  !registration?.childNoteShow ? null : (
                    <>
                      <Space size={10} style={{ marginBottom: 15 }}>
                        <Typography.Text>参加者</Typography.Text>
                        <Button
                          type="primary"
                          onClick={handleAddParticipationChild}
                          disabled={attachRegistrations.length === 5}
                          shape="circle"
                          icon={<PlusOutlined />}
                          size="small"
                        />
                      </Space>

                      <Collapse
                        bordered={false}
                        style={{
                          background: "transparent",
                        }}
                        accordio
                        defaultActiveKey={attachRegistrations[0]?.id}
                      >
                        {attachRegistrations.map((item, idx) => {
                          return (
                            <Collapse.Panel
                              extra={idx === 0 ? undefined : genExtra(idx)}
                              header={`参加者 ${idx + 1}`}
                              key={item.id}
                            >
                              {registration.fullNameShow ? (
                                <Form.Item
                                  name={`attachRegistrations.${idx}.fullName`}
                                  label="おなまえ（ひらがな入力）"
                                  help={
                                    errors?.attachRegistrations &&
                                    errors?.attachRegistrations[idx]?.fullName
                                      ? errors?.attachRegistrations[idx]
                                          ?.fullName.message
                                      : undefined
                                  }
                                  validateStatus={
                                    errors?.attachRegistrations &&
                                    errors?.attachRegistrations[idx]?.fullName
                                      ? "error"
                                      : undefined
                                  }
                                  rules={[
                                    {
                                      required:
                                        registration.fullNameShow &&
                                        registration.fullNameRq,
                                      message: "",
                                    },
                                  ]}
                                >
                                  <Controller
                                    name={`attachRegistrations.${idx}.fullName`}
                                    control={control}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <Input
                                        {...field}
                                        placeholder="あおしすあおい"
                                        onPressEnter={(e) => e.preventDefault()}
                                      />
                                    )}
                                  />
                                </Form.Item>
                              ) : null}

                              {registration?.childAgeShow && (
                                <Form.Item
                                  name="age"
                                  label="年齢"
                                  rules={[
                                    {
                                      required:
                                        registration?.childAgeShow &&
                                        registration.childAgeRq,
                                      message: "",
                                    },
                                  ]}
                                >
                                  <Row className="gap-x-6">
                                    <Controller
                                      name={`attachRegistrations.${idx}.age`}
                                      control={control}
                                      render={({
                                        field,
                                        fieldState: { error },
                                      }) => (
                                        <Form.Item
                                          help={
                                            error ? error.message : undefined
                                          }
                                          validateStatus={
                                            error ? "error" : undefined
                                          }
                                          className="flex-1"
                                        >
                                          <div className="w-full flex gap-x-2 items-center">
                                            <Select
                                              {...field}
                                              options={Array.from(
                                                { length: 19 },
                                                (_, i) => ({
                                                  value: i,
                                                  label: i,
                                                  disabled:
                                                    i >= registration.minAge &&
                                                    i <= registration.maxAge
                                                      ? false
                                                      : true,
                                                })
                                              )}
                                            />
                                            歳
                                          </div>
                                        </Form.Item>
                                      )}
                                    />
                                    <Controller
                                      name={`attachRegistrations.${idx}.month`}
                                      control={control}
                                      render={({
                                        field,
                                        fieldState: { error },
                                      }) => (
                                        <Form.Item
                                          help={
                                            error ? error.message : undefined
                                          }
                                          validateStatus={
                                            error ? "error" : undefined
                                          }
                                          className="flex-1"
                                        >
                                          <div className="w-full flex gap-x-2 items-center">
                                            <Select
                                              {...field}
                                              options={Array.from(
                                                { length: 11 },
                                                (_, i) => ({
                                                  value: i + 1,
                                                  label: i + 1,
                                                })
                                              )}
                                            />
                                            <div className="whitespace-nowrap">
                                              ヶ月
                                            </div>
                                          </div>
                                        </Form.Item>
                                      )}
                                    />
                                  </Row>
                                </Form.Item>
                              )}

                              {registration.childNoteShow ? (
                                <Form.Item
                                  help={
                                    errors?.attachRegistrations &&
                                    errors?.attachRegistrations[idx]?.note
                                      ? errors?.attachRegistrations[idx]?.note
                                          .message
                                      : undefined
                                  }
                                  validateStatus={
                                    errors?.attachRegistrations &&
                                    errors?.attachRegistrations[idx]?.note
                                      ? "error"
                                      : undefined
                                  }
                                  rules={[
                                    {
                                      required:
                                        registration.childNoteShow &&
                                        registration.childNoteRq,
                                      message: "",
                                    },
                                  ]}
                                  name={`attachRegistrations.${idx}.note`}
                                  label={
                                    registration.childNoteLabel ||
                                    "メッセージ　（レースへの意気込み）"
                                  }
                                >
                                  <Controller
                                    name={`attachRegistrations.${idx}.note`}
                                    control={control}
                                    render={({ field }) => (
                                      <Input.TextArea
                                        {...field}
                                        rows={4}
                                      ></Input.TextArea>
                                    )}
                                  />
                                </Form.Item>
                              ) : null}
                            </Collapse.Panel>
                          )
                        })}
                      </Collapse>
                    </>
                  )}

                  {/* TODO: */}

                  {/* {!registration.generalNoteShow ? null : (
                    <Form.Item
                      className="my-4"
                      name={`generalNote`}
                      label={
                        registration.generalNoteLabel !== ""
                          ? registration.generalNoteLabel
                          : "メッセージ（一般)"
                      }
                      help={
                        errors?.generalNote && errors?.generalNote
                          ? errors?.generalNote.message
                          : undefined
                      }
                      validateStatus={
                        errors?.generalNote && errors?.generalNote
                          ? "error"
                          : undefined
                      }
                      rules={[
                        {
                          required:
                            registration.generalNoteShow &&
                            registration.generalNoteRq,
                          message:
                            "メッセージ（一般）は空白のままにすることはできません",
                        },
                      ]}
                    >
                      <Controller
                        name={`generalNote`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Input.TextArea {...field} rows={4}></Input.TextArea>
                        )}
                      />
                    </Form.Item>
                  )} */}
                </Col>
              )}

              {registration.isPreschoolCount ? (
                ""
              ) : (
                <Col span={24} className="text-center whitespace-pre-wrap">
                  <Alert
                    message={`${
                      registration.isSimpleCount
                        ? "※同伴者の方は、参加カウントされません。"
                        : "※同伴者は、参加カウントされません。"
                    }`}
                    type="warning"
                  />
                </Col>
              )}
              <Col span={24} className="mt-4">
                <Row gutter={[8, 8]} justify="center">
                  <Col>
                    <Button size="large" onClick={hideConfirmModal}>
                      閉じる
                    </Button>
                  </Col>
                  <Col>
                    {registration.isSimpleCount ? (
                      <Button type="primary" size="large" htmlType="submit">
                        登録
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        size="large"
                        onClick={handleSubmit(onSubmit)}
                      >
                        登録
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}
